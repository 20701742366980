%video-icon {

  &::before {
    content: "";
    top: 0;
    right: 0;
    margin: $spacing-4 $spacing-4 0 0;
    position: absolute;
    width: 40px;
    height: 40px;
    display: block;
    z-index: 2;
    background-color: $color-brand--bright;
    border-radius: 50%;
  }

  &::after {
    content: "";
    position: absolute;
    display: block;
    top: 0.5%;
    right: 0;
    border-style: solid;
    border-width: 9px 0 9px 16px;
    border-color: transparent transparent transparent #fff;
    margin: 1.625rem 1.625rem 0 0;
    z-index: 3;
    transition: all 0.3s ease;
  }

  &:hover,
  &:focus-within {

    &::after {
      transform: scale(1.2);
      transition: transform 0.5s ease;
    }
  }
}
