.uol-section-nav-ctas {
  font-family: $font-family-sans-serif--desktop;
  border-top: 4px solid $color-border--light;

  margin: $spacing-6 $spacing-4;

  @include media(">=uol-media-m") {
    margin: $spacing-7 $spacing-4 $spacing-7;
  }

  @include media(">=uol-media-l") {
    margin: $spacing-7 0;
  }

}

  .uol-section-nav-ctas__title {
    font-size: 1.125rem;
    color: $color-black--dark;
    margin-bottom: 0;
    font-weight: $font-weight-bold--sans-serif;
  }

  .uol-section-nav-ctas__links {
    margin: 0;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: start;
    align-content: start;

    @include media(">=uol-media-xs", "<uol-media-l") {
      margin-right: -16px;
    }

    @include media("<uol-media-l") {
      flex-direction: row;
    }
  }

  .uol-section-nav-ctas__link {
    @extend .uol-button;

    @include button_focus(-4px);

    margin-bottom: $spacing-6;
    padding: 1.125rem 1.375rem 1.4125rem;
    width: 100%;

    @include media(">=uol-media-xs") {
      width: unset;
      margin: $spacing-6 $spacing-6 0 0;
    }
  }
