.text-highlight {
  margin: $spacing-6 0;
  border: 4px solid $color-brand;
  font-weight: $font-weight-medium--sans-serif;
  padding: $spacing-4;
  text-align: center;

  @include media(">=uol-media-m") {
    padding: $spacing-4 $spacing-6;
  }

  :first-child {
    margin-top: 0;
  }

  :last-child {
    margin-bottom: 0;
  }
}
