.uol-widget-container {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  margin-bottom: $spacing-4;
  width: 100%;

  .uol-col-debug & {
    background: rgba(hotpink, .3);
  }

  &:last-of-type {
    margin-bottom: $spacing-7;

    @include media(">=uol-media-m") {
      margin-bottom: $spacing-9;
    }

    @include media(">=uol-media-l") {
      margin-bottom: ($spacing-7 * 2);
    }
  }

  .uol-side-nav-container--populated + .uol-homepage-content & {
    @include media(">=uol-media-l") {
      padding-left: 0;
      padding-right: 0;
    }

    @include media(">=uol-media-xl") {
      padding-left: $spacing-1;
      padding-right: $spacing-1;
    }
  }
}

.uol-widget {
  position: relative;
  margin-top: $spacing-4;
  padding: 0 !important;
  flex-basis: 100% !important;

  @include media("<uol-media-l") {
    max-width: none;
  }

  @include media(">=uol-media-l") {
    margin-top: $spacing-7;
  }
}

  .uol-widget--cards {
    margin-top: 0;
    width: 100%;
  }

  .uol-widget--ctas,
  .uol-widget--content,
  .uol-widget--featured-content,
  .uol-widget--form,
  .uol-widget--news-events,
  .uol-widget--news-tiles,
  .uol-widget--events,
  .uol-widget--description-list,
  .uol-widget--accreditation-logos,
  .uol-widget--wide {
    @extend .uol-col;

    max-width: none;
  }

  .uol-widget--ctas {

    @include media("<uol-media-m") {
      padding-top: $spacing-7 !important;
    }
  }

  .uol-widget--featured-content {
    @include media("<uol-media-l") {
      border-bottom: $spacing-2 solid $color-brand--faded;
      padding-bottom: $spacing-5;
      margin-bottom: $spacing-5;
    }

    @include media(">=uol-media-l") {
      display: flex;
      flex: 2 1 auto;
      flex-basis: auto;
      align-items: flex-start;
    }
  }

  .uol-widget--ctas,
  .uol-widget--form {
    margin-top: 0;
    padding-bottom: 0;
  }

  .uol-widget {

    .uol-side-nav-container--populated + .uol-homepage-content & {
      flex-basis: 100%;
      max-width: none;
      padding: 0 !important;
    }
  }

  .uol-widget__head {
    position: relative;
    box-sizing: border-box;
    padding-bottom: $spacing-5;
    display: flex;
    flex-direction: column;

    // TODO: Horrible IE hack - Find a better way
    .no-csspositionsticky & {
      padding: 3px;
    }

    @include media(">=uol-media-m") {
      // margin: 0 $spacing-5;
      margin: 0;
    }

    @include media(">=uol-media-l") {

      .no-csspositionsticky & {
        padding: 0;
      }
    }

    @include media(">=uol-media-xl") {
      // margin: 0 $spacing-6;
      margin: 0;
    }

    > * {
      max-width: 52rem;
    }

    .uol-widget--featured-content & {
      @include media(">=uol-media-l") {
        // width: 40%;
        // width: calc(100% / 3);
        padding: $spacing-4 $spacing-4 $spacing-7 0;

        // .uol-side-nav-container--populated + .uol-homepage-content & {
        //   width: 40%;
        // }

        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: $spacing-8;
          background: $color-brand--faded;
          border-radius: 0 0 0 6px;
          z-index: -1;
        }
      }
    }

    .uol-widget--news-events & {
      padding-bottom: 0;
    }

    .uol-widget--list-featured & {
      padding-bottom: $spacing-4;

      @include media(">=uol-media-m") {
        padding-bottom: $spacing-5;
      }

      @include media(">=uol-media-l") {
        padding-bottom: $spacing-6;
      }
    }
  }

  .uol-widget__title {
    @extend .uol-typography-heading-2;

    position: relative;
    margin: $spacing-6 0 0;
    overflow-wrap: break-word;

    @include media("<uol-media-l") {
      margin-top: $spacing-7;
    }

    &::before {
      content: "";
      position: absolute;
      display: inline-block;
      top: -#{$spacing-4};
      left: 0;
      width: $spacing-4;
      height: $spacing-2;
      background-color: $color-brand;

      @include media(">=uol-media-m") {
        width: $spacing-5;
      }

      @include media(">=uol-media-l") {
        width: $spacing-6;
        top: -#{$spacing-5};
      }
    }

    .uol-widget--featured-content & {
      max-width: none;
      margin: 0;

      @include media("<uol-media-l") {
        border-radius: 6px 6px 0 0;
        padding: $spacing-7 $spacing-4 $spacing-6;
        background: $color-brand--faded;

        &::before {
          top: $spacing-6;
          left: $spacing-4;
        }
      }

      @include media(">=uol-media-l") {
        margin-top: $spacing-2;
      }
    }
  }

  .uol-widget__lead {
    padding-top: $spacing-2;

    @include media(">=uol-media-l") {
      padding-top: $spacing-4;
    }

    .uol-widget--featured-content & {

      @include media("<uol-media-l") {
        padding-left: $spacing-4;
        padding-right: $spacing-4;
      }

      @include media("<uol-media-l") {
        padding-top: $spacing-2;
      }
    }
  }

  .uol-widget__head,
  .uol-widget__lead {

    p {
      font-size: 1.125rem;

      @include media(">=uol-media-m") {
        font-size: 1.375rem;
      }

      @include media(">=uol-media-l") {
        font-size: 1.5rem;
      }
    }

    p:first-of-type {
      margin-top: $spacing-4;
    }

    &:last-child,
    p:last-of-type {
      margin-bottom: 0;
    }
  }

  .uol-widget__content {
    margin: $spacing-4 0 0;
    clear: both;
    // overflow-x: hidden;

    .uol-widget--cards & {
      overflow-x: initial;
    }

    @include media(">=uol-media-m") {
      margin: $spacing-4 0 0;
    }

    .uol-widget--featured-content & {
      @include media(">=uol-media-l") {
        margin-top: 0;
        width: calc(100% / 3 * 2);
        padding: 0 $spacing-6 $spacing-4;
        border-right: 4px solid $color-brand--faded;

        .uol-side-nav-container--populated + .uol-homepage-content & {
          width: 60%;
        }
      }
    }

    .uol-widget--list-featured & {
        margin-top: 0;
    }

    .uol-widget--form & {
      margin-top: $spacing-2;
    }
  }

    .uol-widget__content__text {
      @include media("<uol-media-l") {
        padding-left: $spacing-4;
        padding-right: $spacing-4;
      }
    }

  .uol-widget__link {
    @extend .uol-chevron-right;
    @include link_focus(1px);

    align-self: flex-start;
    font-size: 1.25rem;
    font-weight: $font-weight-medium--sans-serif;
    margin-top: $spacing-4;
    padding-right: 0.2em;
    color: inherit;
    text-decoration: none;
    text-decoration-color: rgba($color-white, 0);
    transition: text-decoration-color 0.3s ease;

    @include media(">=uol-media-m") {
      font-weight: $font-weight-bold--sans-serif;
    }

    @include media("<uol-media-l") {
      margin-top: $spacing-5;
    }
  }

  .uol-widget__left-col {
    .uol-widget--featured-content & {
      @include media(">=uol-media-l") {
        width: calc(100% / 3);
      }
    }
  }

  .uol-widget__arrow-links {
    list-style-type: none;
    margin: 0;
    padding: 0;

    .uol-widget--featured-content & {
      @include media(">=uol-media-l") {
        margin-top: $spacing-5;
      }
    }
  }

  .uol-widget__arrow-links-item {
    margin: 0 0 $spacing-5 0;

    .uol-widget--featured-content & {
      margin: 0 $spacing-4 $spacing-5 $spacing-4;

      @include media(">=uol-media-l") {
        margin: 0 0 $spacing-4 0;
      }
    }
  }
  
  .uol-widget__arrow-link {
    @extend %text-size-paragraph;
    font-weight: $font-weight-bold--sans-serif;
    padding-top: $spacing-2;
    padding-bottom: $spacing-2;
    display: inline-block;
  } 

