@charset "UTF-8";
/**
 * Toolkit globals
 */
/* ==========================================================================
   Normalize.scss settings
   ========================================================================== */
/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */
/* ==========================================================================
   HTML5 display definitions
   ========================================================================== */
/*
 * Corrects `block` display not defined in IE 8/9.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block; }

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 8/9.
 */
[hidden] {
  display: none; }

/* ==========================================================================
   Base
   ========================================================================== */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 * 3.Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

/* ==========================================================================
   Links
   ========================================================================== */
/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
/**
 * Improves readability when focused and also mouse hovered in all browsers.
 */
a:focus {
  outline: thin dotted; }

a:active, a:hover {
  outline: 0; }

/* ==========================================================================
   Typography
   ========================================================================== */
/**
 * Addresses font sizes and margins set differently in IE 6/7.
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari 5, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Address styling not present in IE 8/9, Safari 5, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari 5, and Chrome.
 */
b,
strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari 5 and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

/**
 * Addresses styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Addresses margins set differently in IE 6/7.
 */
/**
 * Correct font family set oddly in Safari 5 and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em; }

/**
 * Improve readability of pre-formatted text in all browsers.
 */
pre {
  white-space: pre-wrap; }

/**
 *  Set consistent quote types.
 */
q {
  quotes: "\201C" "\201D" "\2018" "\2019"; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* ==========================================================================
   Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
  border: 0; }

/**
 * Correct overflow displayed oddly in IE 9.
 */
svg:not(:root) {
  overflow: hidden; }

/* ==========================================================================
   Figures
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari 5.
 */
figure {
  margin: 0; }

/* ==========================================================================
   Forms
   ========================================================================== */
/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * 1. Correct font family not being inherited in all browsers.
 * 2. Correct font size not being inherited in all browsers.
 * 3. Address margins set differently in Firefox 4+, Safari 5, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 8+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to `content-box` in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Removes excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/* ==========================================================================
   Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

/**
 * Breakpoints are managed using @include-media
 * https://include-media.com/
 * Default breakpoints are:
 * $breakpoints: (
 *  'phone': 320px,
 *  'tablet': 768px,
 *  'desktop': 1024px
 * ) !default;
 *
 * You can override these defaults or set additional ones below.
 *
 * Example usage:
 * @include media(">phone", "<=tablet") {
 *  width: 50%;
 * }
 */
/**
 * Spacing
 * Based on a default 16px root
 */
/**
 * Layout
 * Wrappers & widths
 */
/**
 * Icon font CSS generated by gulp see templates -> _ds-icons-mixins.scss
 * Using lodash https://lodash.com/docs/4.17.4
 */
/**
 * Icon font mixins
 */
/**
 * Borders
 */
/**
 * Clearfix
 */
/* Skin mixins */
/**
 * For white module on a grey background
 */
/**
 * For grey modules on a white background
 */
/* Grey box with a shadow */
/* Grey box with a shadow and a border */
/* White box with a shadow */
/* Black box with a shadow */
/**
 * Show and hide
 */
.hide-accessible, .uol-footer-contact__title, .uol-footer-contact__telephone-email-label, .uol-form__input--password__toggle-label, .js .uol-gallery__item__title, .uol-global-masthead__search-label, .js .uol-icon--icon-only .uol-icon__label, .uol-info-list__term--hidden, .uol-news-tile__meta__term, .page-heading__meta__term, .uol-tile--profile .uol-tile__title, .uol-site-footer__title {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px;
  overflow: hidden;
  left: -9999px; }

.show-accessible {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: visible;
  left: auto; }

/**
 * Skip to main content btn
 */
#skip-main {
  clip: rect(0 0 0 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px; }
  #skip-main:focus {
    background-color: #f1f1f1;
    border-radius: 3px;
    clip: auto !important;
    color: #212529;
    display: block;
    font-size: 16px;
    font-weight: bold;
    height: auto;
    left: 5px;
    padding: 16px 20px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000; }

::placeholder {
  opacity: 1; }

/**
 * Icon font CSS generated by gulp see templates -> _ds-icons.scss
 * Using lodash https://lodash.com/docs/4.17.4
 */
/**
 * Icon font CSS generated by gulp see templates -> _ds-icons-mixins.scss
 * Using lodash https://lodash.com/docs/4.17.4
 */
/**
 * Icon font mixins
 */
@font-face {
  font-family: "ds-icons";
  src: url("../fonts/ds-icons.eot");
  src: url("../fonts/ds-icons.eot?#iefix") format("eot"), url("../fonts/ds-icons.woff2") format("woff2"), url("../fonts/ds-icons.woff") format("woff"), url("../fonts/ds-icons.ttf") format("truetype"), url("../fonts/ds-icons.svg#ds-icons") format("svg");
  font-weight: normal;
  font-style: normal; }

.ds-icon,
[class*="ds-icon"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "ds-icons", sans-serif !important;
  speak: none;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  transform: translate(0, -1px);
  line-height: 1;
  font-size: inherit;
  display: inline-block;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.ds-icon-16 {
  font-size: 16px; }

.ds-icon-24 {
  font-size: 24px; }

.ds-icon-32 {
  font-size: 32px; }

.ds-icon-48 {
  font-size: 48px; }

/**
 * Icon font class names
 */
.ds-icon-add::before {
  content: "\EA01"; }

.ds-icon-alert-danger::before {
  content: "\EA02"; }

.ds-icon-alert-info::before {
  content: "\EA03"; }

.ds-icon-alert-success::before {
  content: "\EA04"; }

.ds-icon-alert-warning::before {
  content: "\EA05"; }

.ds-icon-calendar::before {
  content: "\EA06"; }

.ds-icon-campus-map::before {
  content: "\EA07"; }

.ds-icon-chevron-down::before {
  content: "\EA08"; }

.ds-icon-chevron-left::before {
  content: "\EA09"; }

.ds-icon-chevron-right::before {
  content: "\EA0A"; }

.ds-icon-chevron-up::before {
  content: "\EA0B"; }

.ds-icon-close::before {
  content: "\EA0C"; }

.ds-icon-download::before {
  content: "\EA0D"; }

.ds-icon-external::before {
  content: "\EA0E"; }

.ds-icon-home::before {
  content: "\EA0F"; }

.ds-icon-letter::before {
  content: "\EA10"; }

.ds-icon-mail::before {
  content: "\EA11"; }

.ds-icon-marker::before {
  content: "\EA12"; }

.ds-icon-navicon::before {
  content: "\EA13"; }

.ds-icon-news::before {
  content: "\EA14"; }

.ds-icon-phone::before {
  content: "\EA15"; }

.ds-icon-print::before {
  content: "\EA16"; }

.ds-icon-profile::before {
  content: "\EA17"; }

.ds-icon-remove::before {
  content: "\EA18"; }

.ds-icon-rss::before {
  content: "\EA19"; }

.ds-icon-search::before {
  content: "\EA1A"; }

.ds-icon-share::before {
  content: "\EA1B"; }

.ds-icon-social-facebook::before {
  content: "\EA1C"; }

.ds-icon-social-google::before {
  content: "\EA1D"; }

.ds-icon-social-instagram::before {
  content: "\EA1E"; }

.ds-icon-social-linkedin::before {
  content: "\EA1F"; }

.ds-icon-social-twitter::before {
  content: "\EA20"; }

.ds-icon-social-weibo::before {
  content: "\EA21"; }

.ds-icon-social-youtube::before {
  content: "\EA22"; }

.ds-icon-sort::before {
  content: "\EA23"; }

.ds-icon-star::before {
  content: "\EA24"; }

.ds-icon-triangle-down::before {
  content: "\EA25"; }

.ds-icon-triangle-left::before {
  content: "\EA26"; }

.ds-icon-triangle-right::before {
  content: "\EA27"; }

.ds-icon-triangle-up::before {
  content: "\EA28"; }

.ds-icon-action-add::before {
  content: "\EA29"; }

.ds-icon-action-bookmark::before {
  content: "\EA2A"; }

.ds-icon-action-call::before {
  content: "\EA2B"; }

.ds-icon-action-cancel::before {
  content: "\EA2C"; }

.ds-icon-action-create::before {
  content: "\EA2D"; }

.ds-icon-action-delete::before {
  content: "\EA2E"; }

.ds-icon-action-download::before {
  content: "\EA2F"; }

.ds-icon-action-link-external::before {
  content: "\EA30"; }

.ds-icon-action-lock::before {
  content: "\EA31"; }

.ds-icon-action-pause::before {
  content: "\EA32"; }

.ds-icon-action-play::before {
  content: "\EA33"; }

.ds-icon-action-print::before {
  content: "\EA34"; }

.ds-icon-action-remove::before {
  content: "\EA35"; }

.ds-icon-action-reply::before {
  content: "\EA36"; }

.ds-icon-action-search::before {
  content: "\EA37"; }

.ds-icon-action-upload::before {
  content: "\EA39"; }

.ds-icon-content-bar::before {
  content: "\EA3A"; }

.ds-icon-content-basket::before {
  content: "\EA3B"; }

.ds-icon-content-bbq::before {
  content: "\EA3C"; }

.ds-icon-content-bed::before {
  content: "\EA3D"; }

.ds-icon-content-bike::before {
  content: "\EA3E"; }

.ds-icon-content-bus::before {
  content: "\EA3F"; }

.ds-icon-content-cafe::before {
  content: "\EA40"; }

.ds-icon-content-calendar::before {
  content: "\EA41"; }

.ds-icon-content-couch::before {
  content: "\EA42"; }

.ds-icon-content-email-open::before {
  content: "\EA43"; }

.ds-icon-content-email::before {
  content: "\EA44"; }

.ds-icon-content-fitness::before {
  content: "\EA45"; }

.ds-icon-content-home::before {
  content: "\EA46"; }

.ds-icon-content-hospital::before {
  content: "\EA47"; }

.ds-icon-content-key::before {
  content: "\EA48"; }

.ds-icon-content-location::before {
  content: "\EA49"; }

.ds-icon-content-map::before {
  content: "\EA4A"; }

.ds-icon-content-music::before {
  content: "\EA4B"; }

.ds-icon-content-news::before {
  content: "\EA4C"; }

.ds-icon-content-parking::before {
  content: "\EA4D"; }

.ds-icon-content-restaurant::before {
  content: "\EA4E"; }

.ds-icon-content-rss::before {
  content: "\EA4F"; }

.ds-icon-content-shower::before {
  content: "\EA50"; }

.ds-icon-content-table-tennis::before {
  content: "\EA51"; }

.ds-icon-content-tag::before {
  content: "\EA52"; }

.ds-icon-content-train::before {
  content: "\EA53"; }

.ds-icon-content-tv::before {
  content: "\EA54"; }

.ds-icon-content-user::before {
  content: "\EA55"; }

.ds-icon-content-videogame::before {
  content: "\EA56"; }

.ds-icon-content-wc::before {
  content: "\EA57"; }

.ds-icon-content-wifi::before {
  content: "\EA58"; }

.ds-icon-device-desktop::before {
  content: "\EA59"; }

.ds-icon-device-laptop::before {
  content: "\EA5A"; }

.ds-icon-device-phone::before {
  content: "\EA5B"; }

.ds-icon-form-check-checked::before {
  content: "\EA5C"; }

.ds-icon-form-check-unchecked::before {
  content: "\EA5D"; }

.ds-icon-form-radio-checked::before {
  content: "\EA5E"; }

.ds-icon-form-radio-unchecked::before {
  content: "\EA5F"; }

.ds-icon-nav-arrow-dropdown-down::before {
  content: "\EA60"; }

.ds-icon-nav-arrow-dropdown-up::before {
  content: "\EA61"; }

.ds-icon-nav-chevron-down::before {
  content: "\EA62"; }

.ds-icon-nav-chevron-left::before {
  content: "\EA63"; }

.ds-icon-nav-chevron-right::before {
  content: "\EA64"; }

.ds-icon-nav-chevron-up::before {
  content: "\EA65"; }

.ds-icon-nav-more-horizontal::before {
  content: "\EA66"; }

.ds-icon-nav-more-vertical::before {
  content: "\EA67"; }

.ds-icon-nav-navicon::before {
  content: "\EA68"; }

.ds-icon-nav-select::before {
  content: "\EA69"; }

.ds-icon-content-vr::before {
  content: "\EA6A"; }

.ds-icon-device-tablet::before {
  content: "\EA6B"; }

.ds-icon-nav-chevron-left-large::before {
  content: "\EA6C"; }

.ds-icon-nav-chevron-right-large::before {
  content: "\EA6D"; }

.ds-icon-nav-chevron-down-large::before {
  content: "\EA6E"; }

.ds-icon-nav-chevron-up-large::before {
  content: "\EA6F"; }

.ds-icon-content-washing-machine::before {
  content: "\EA70"; }

.ds-icon-content-credit-card::before {
  content: "\EA71"; }

.ds-icon-content-sport::before {
  content: "\EA72"; }

.ds-icon-content-security::before {
  content: "\EA73"; }

.ds-icon-content-build::before {
  content: "\EA74"; }

.ds-icon-content-document::before {
  content: "\EA75"; }

.ds-icon-action-filter::before {
  content: "\EA76"; }

.ds-icon-action-card::before {
  content: "\EA77"; }

.ds-icon-action-list::before {
  content: "\EA78"; }

.ds-icon-action-cancel-thin::before {
  content: "\EA79"; }

.ds-icon-action-share-circle::before {
  content: "\EA7A"; }

.ds-icon-action-play-circle-outline::before {
  content: "\EA7B"; }

.ds-icon-content-announcement::before {
  content: "\EA7C"; }

.ds-icon-nav-dots-horizontal::before {
  content: "\EA80"; }

.ds-icon-content-marker::before {
  content: "\EA81"; }

.ds-icon-content-security-home::before {
  content: "\EA82"; }

.ds-icon-content-ping-pong::before {
  content: "\EA83"; }

.ds-icon-content-bank::before {
  content: "\EA84"; }

/* Hidden font text for accessibility */
.icon-font-text {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden; }

.fitVids-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0; }
  .fitVids-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.js-focus-visible :focus:not(.focus-visible) {
  outline: none; }

/* Optional: Customize .focus-visible */
.site-container {
  max-width: 103.75rem;
  margin: 0 auto; }

.uol-content-container {
  max-width: 103.75rem;
  margin: 0 auto; }
  @media (min-width: 64em) {
    .uol-banner-outer + .uol-content-container {
      margin-top: 1.5rem; } }

.uol-tile__icon--video::before {
  content: "";
  top: 0;
  right: 0;
  margin: 1rem 1rem 0 0;
  position: absolute;
  width: 40px;
  height: 40px;
  display: block;
  z-index: 2;
  background-color: #ff4e36;
  border-radius: 50%; }

.uol-tile__icon--video::after {
  content: "";
  position: absolute;
  display: block;
  top: 0.5%;
  right: 0;
  border-style: solid;
  border-width: 9px 0 9px 16px;
  border-color: transparent transparent transparent #fff;
  margin: 1.625rem 1.625rem 0 0;
  z-index: 3;
  transition: all 0.3s ease; }

.uol-tile__icon--video:hover::after, .uol-tile__icon--video:focus-within::after {
  transform: scale(1.2);
  transition: transform 0.5s ease; }

.uol-chevron-up::after, .uol-chevron-down::after, .uol-chevron-right::after, .uol-widget__link::after, .uol-chevron-left::after {
  content: "";
  display: inline-block;
  position: relative;
  margin-left: 1ex;
  left: 0.15em;
  top: 0.473em;
  width: 1.3ex;
  height: 1.3ex;
  box-sizing: border-box;
  border-style: solid;
  border-color: #c70000;
  border-width: 0.2em 0.2em 0 0;
  transform: rotate(-45deg);
  vertical-align: top; }

.uol-chevron-up {
  top: 0.5em; }

.uol-chevron-down::after {
  top: 0.3em;
  left: 0;
  transform: rotate(135deg); }

.uol-chevron-right::after, .uol-widget__link::after {
  left: 0;
  transform: rotate(45deg); }

.uol-chevron-left::after {
  left: 0.25em;
  transform: rotate(-135deg); }

[lang]:not([lang=en]):not([lang=fr]):not([lang=de]):not([lang=it]):not([lang=pt]):not([lang=es]) {
  font-family: initial !important; }

.text-size-epic, .uol-typography-epic, .uol-rich-text h1.epic {
  font-size: 2.25rem;
  line-height: 2.75rem;
  letter-spacing: normal; }
  @media (min-width: 48em) {
    .text-size-epic, .uol-typography-epic, .uol-rich-text h1.epic {
      font-size: 3.25rem; } }
  @media (min-width: 64em) {
    .text-size-epic, .uol-typography-epic, .uol-rich-text h1.epic {
      font-size: 4.125rem; } }
  @media (min-width: 48em) {
    .text-size-epic, .uol-typography-epic, .uol-rich-text h1.epic {
      line-height: 4rem; } }
  @media (min-width: 64em) {
    .text-size-epic, .uol-typography-epic, .uol-rich-text h1.epic {
      line-height: 4.75rem; } }
  @media (min-width: 48em) {
    .text-size-epic, .uol-typography-epic, .uol-rich-text h1.epic {
      letter-spacing: -0.05rem; } }
  @media (min-width: 64em) {
    .text-size-epic, .uol-typography-epic, .uol-rich-text h1.epic {
      letter-spacing: -0.13rem; } }

.text-size-heading-1, .uol-typography-heading-1, .event-card__date__start-date__day, .page-heading__title__main, .page-heading__title, .uol-rich-text h1 {
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: normal; }
  @media (min-width: 48em) {
    .text-size-heading-1, .uol-typography-heading-1, .event-card__date__start-date__day, .page-heading__title__main, .page-heading__title, .uol-rich-text h1 {
      font-size: 2.75rem; } }
  @media (min-width: 64em) {
    .text-size-heading-1, .uol-typography-heading-1, .event-card__date__start-date__day, .page-heading__title__main, .page-heading__title, .uol-rich-text h1 {
      font-size: 3.312rem; } }
  @media (min-width: 48em) {
    .text-size-heading-1, .uol-typography-heading-1, .event-card__date__start-date__day, .page-heading__title__main, .page-heading__title, .uol-rich-text h1 {
      line-height: 3.5rem; } }
  @media (min-width: 64em) {
    .text-size-heading-1, .uol-typography-heading-1, .event-card__date__start-date__day, .page-heading__title__main, .page-heading__title, .uol-rich-text h1 {
      line-height: 3.938rem; } }
  @media (min-width: 48em) {
    .text-size-heading-1, .uol-typography-heading-1, .event-card__date__start-date__day, .page-heading__title__main, .page-heading__title, .uol-rich-text h1 {
      letter-spacing: -0.05rem; } }
  @media (min-width: 64em) {
    .text-size-heading-1, .uol-typography-heading-1, .event-card__date__start-date__day, .page-heading__title__main, .page-heading__title, .uol-rich-text h1 {
      letter-spacing: -0.09rem; } }

.text-size-heading-2, .uol-typography-heading-2, .uol-hero-banner__title, .uol-banner__item__title, .js .uolcc-container .uolcc-title,
.js .uolcc-prompt-container .uolcc-title,
.js .uolcc-privacy-update-container .uolcc-title, .uol-gallery-modal__info__title, .uol-profile-supplement__title, .uol-widget__title, .uol-rich-text h2, .uol-form__input--legend-title, .uol-gallery__button--with-count, .js .uol-gallery__item--video-play-icon .uol-button.uol-icon--icon-only, .js .uol-gallery__item--video-play-icon .uolcc-container .uol-icon--icon-only.uolcc-button, .js .uolcc-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button,
.js .uol-gallery__item--video-play-icon .uolcc-prompt-container .uol-icon--icon-only.uolcc-button, .js .uolcc-prompt-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button,
.js .uol-gallery__item--video-play-icon .uolcc-privacy-update-container .uol-icon--icon-only.uolcc-button, .js .uolcc-privacy-update-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button, .js .uol-gallery__item--video-play-icon .uol-icon--icon-only.uol-section-nav-ctas__link, .page-heading__title.page-heading__title--subtitle, .js .uol-profile-supplement__video-icon {
  font-size: 1.75rem;
  line-height: 2.25rem;
  letter-spacing: normal; }
  @media (min-width: 48em) {
    .text-size-heading-2, .uol-typography-heading-2, .uol-hero-banner__title, .uol-banner__item__title, .js .uolcc-container .uolcc-title,
    .js .uolcc-prompt-container .uolcc-title,
    .js .uolcc-privacy-update-container .uolcc-title, .uol-gallery-modal__info__title, .uol-profile-supplement__title, .uol-widget__title, .uol-rich-text h2, .uol-form__input--legend-title, .uol-gallery__button--with-count, .js .uol-gallery__item--video-play-icon .uol-button.uol-icon--icon-only, .js .uol-gallery__item--video-play-icon .uolcc-container .uol-icon--icon-only.uolcc-button, .js .uolcc-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button,
    .js .uol-gallery__item--video-play-icon .uolcc-prompt-container .uol-icon--icon-only.uolcc-button, .js .uolcc-prompt-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button,
    .js .uol-gallery__item--video-play-icon .uolcc-privacy-update-container .uol-icon--icon-only.uolcc-button, .js .uolcc-privacy-update-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button, .js .uol-gallery__item--video-play-icon .uol-icon--icon-only.uol-section-nav-ctas__link, .page-heading__title.page-heading__title--subtitle, .js .uol-profile-supplement__video-icon {
      font-size: 2.25rem; } }
  @media (min-width: 64em) {
    .text-size-heading-2, .uol-typography-heading-2, .uol-hero-banner__title, .uol-banner__item__title, .js .uolcc-container .uolcc-title,
    .js .uolcc-prompt-container .uolcc-title,
    .js .uolcc-privacy-update-container .uolcc-title, .uol-gallery-modal__info__title, .uol-profile-supplement__title, .uol-widget__title, .uol-rich-text h2, .uol-form__input--legend-title, .uol-gallery__button--with-count, .js .uol-gallery__item--video-play-icon .uol-button.uol-icon--icon-only, .js .uol-gallery__item--video-play-icon .uolcc-container .uol-icon--icon-only.uolcc-button, .js .uolcc-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button,
    .js .uol-gallery__item--video-play-icon .uolcc-prompt-container .uol-icon--icon-only.uolcc-button, .js .uolcc-prompt-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button,
    .js .uol-gallery__item--video-play-icon .uolcc-privacy-update-container .uol-icon--icon-only.uolcc-button, .js .uolcc-privacy-update-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button, .js .uol-gallery__item--video-play-icon .uol-icon--icon-only.uol-section-nav-ctas__link, .page-heading__title.page-heading__title--subtitle, .js .uol-profile-supplement__video-icon {
      font-size: 2.625rem; } }
  @media (min-width: 48em) {
    .text-size-heading-2, .uol-typography-heading-2, .uol-hero-banner__title, .uol-banner__item__title, .js .uolcc-container .uolcc-title,
    .js .uolcc-prompt-container .uolcc-title,
    .js .uolcc-privacy-update-container .uolcc-title, .uol-gallery-modal__info__title, .uol-profile-supplement__title, .uol-widget__title, .uol-rich-text h2, .uol-form__input--legend-title, .uol-gallery__button--with-count, .js .uol-gallery__item--video-play-icon .uol-button.uol-icon--icon-only, .js .uol-gallery__item--video-play-icon .uolcc-container .uol-icon--icon-only.uolcc-button, .js .uolcc-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button,
    .js .uol-gallery__item--video-play-icon .uolcc-prompt-container .uol-icon--icon-only.uolcc-button, .js .uolcc-prompt-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button,
    .js .uol-gallery__item--video-play-icon .uolcc-privacy-update-container .uol-icon--icon-only.uolcc-button, .js .uolcc-privacy-update-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button, .js .uol-gallery__item--video-play-icon .uol-icon--icon-only.uol-section-nav-ctas__link, .page-heading__title.page-heading__title--subtitle, .js .uol-profile-supplement__video-icon {
      line-height: 3rem; } }
  @media (min-width: 64em) {
    .text-size-heading-2, .uol-typography-heading-2, .uol-hero-banner__title, .uol-banner__item__title, .js .uolcc-container .uolcc-title,
    .js .uolcc-prompt-container .uolcc-title,
    .js .uolcc-privacy-update-container .uolcc-title, .uol-gallery-modal__info__title, .uol-profile-supplement__title, .uol-widget__title, .uol-rich-text h2, .uol-form__input--legend-title, .uol-gallery__button--with-count, .js .uol-gallery__item--video-play-icon .uol-button.uol-icon--icon-only, .js .uol-gallery__item--video-play-icon .uolcc-container .uol-icon--icon-only.uolcc-button, .js .uolcc-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button,
    .js .uol-gallery__item--video-play-icon .uolcc-prompt-container .uol-icon--icon-only.uolcc-button, .js .uolcc-prompt-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button,
    .js .uol-gallery__item--video-play-icon .uolcc-privacy-update-container .uol-icon--icon-only.uolcc-button, .js .uolcc-privacy-update-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button, .js .uol-gallery__item--video-play-icon .uol-icon--icon-only.uol-section-nav-ctas__link, .page-heading__title.page-heading__title--subtitle, .js .uol-profile-supplement__video-icon {
      line-height: 3.25rem; } }
  @media (min-width: 48em) {
    .text-size-heading-2, .uol-typography-heading-2, .uol-hero-banner__title, .uol-banner__item__title, .js .uolcc-container .uolcc-title,
    .js .uolcc-prompt-container .uolcc-title,
    .js .uolcc-privacy-update-container .uolcc-title, .uol-gallery-modal__info__title, .uol-profile-supplement__title, .uol-widget__title, .uol-rich-text h2, .uol-form__input--legend-title, .uol-gallery__button--with-count, .js .uol-gallery__item--video-play-icon .uol-button.uol-icon--icon-only, .js .uol-gallery__item--video-play-icon .uolcc-container .uol-icon--icon-only.uolcc-button, .js .uolcc-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button,
    .js .uol-gallery__item--video-play-icon .uolcc-prompt-container .uol-icon--icon-only.uolcc-button, .js .uolcc-prompt-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button,
    .js .uol-gallery__item--video-play-icon .uolcc-privacy-update-container .uol-icon--icon-only.uolcc-button, .js .uolcc-privacy-update-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button, .js .uol-gallery__item--video-play-icon .uol-icon--icon-only.uol-section-nav-ctas__link, .page-heading__title.page-heading__title--subtitle, .js .uol-profile-supplement__video-icon {
      letter-spacing: -0.025rem; } }
  @media (min-width: 64em) {
    .text-size-heading-2, .uol-typography-heading-2, .uol-hero-banner__title, .uol-banner__item__title, .js .uolcc-container .uolcc-title,
    .js .uolcc-prompt-container .uolcc-title,
    .js .uolcc-privacy-update-container .uolcc-title, .uol-gallery-modal__info__title, .uol-profile-supplement__title, .uol-widget__title, .uol-rich-text h2, .uol-form__input--legend-title, .uol-gallery__button--with-count, .js .uol-gallery__item--video-play-icon .uol-button.uol-icon--icon-only, .js .uol-gallery__item--video-play-icon .uolcc-container .uol-icon--icon-only.uolcc-button, .js .uolcc-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button,
    .js .uol-gallery__item--video-play-icon .uolcc-prompt-container .uol-icon--icon-only.uolcc-button, .js .uolcc-prompt-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button,
    .js .uol-gallery__item--video-play-icon .uolcc-privacy-update-container .uol-icon--icon-only.uolcc-button, .js .uolcc-privacy-update-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button, .js .uol-gallery__item--video-play-icon .uol-icon--icon-only.uol-section-nav-ctas__link, .page-heading__title.page-heading__title--subtitle, .js .uol-profile-supplement__video-icon {
      letter-spacing: -0.05rem; } }

.text-size-heading-3, .uol-typography-heading-3, .uol-in-text-facts__title, .js .uolcc-container .uolcc-subtitle,
.js .uolcc-prompt-container .uolcc-subtitle,
.js .uolcc-privacy-update-container .uolcc-subtitle, .uol-article--profile .uol-article-section .uol-article-section__title, .uol-rich-text h3, .uol-article-highlight__title {
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: normal; }
  @media (min-width: 48em) {
    .text-size-heading-3, .uol-typography-heading-3, .uol-in-text-facts__title, .js .uolcc-container .uolcc-subtitle,
    .js .uolcc-prompt-container .uolcc-subtitle,
    .js .uolcc-privacy-update-container .uolcc-subtitle, .uol-article--profile .uol-article-section .uol-article-section__title, .uol-rich-text h3, .uol-article-highlight__title {
      font-size: 2rem; } }
  @media (min-width: 64em) {
    .text-size-heading-3, .uol-typography-heading-3, .uol-in-text-facts__title, .js .uolcc-container .uolcc-subtitle,
    .js .uolcc-prompt-container .uolcc-subtitle,
    .js .uolcc-privacy-update-container .uolcc-subtitle, .uol-article--profile .uol-article-section .uol-article-section__title, .uol-rich-text h3, .uol-article-highlight__title {
      font-size: 2.188rem; } }
  @media (min-width: 48em) {
    .text-size-heading-3, .uol-typography-heading-3, .uol-in-text-facts__title, .js .uolcc-container .uolcc-subtitle,
    .js .uolcc-prompt-container .uolcc-subtitle,
    .js .uolcc-privacy-update-container .uolcc-subtitle, .uol-article--profile .uol-article-section .uol-article-section__title, .uol-rich-text h3, .uol-article-highlight__title {
      line-height: 2.75rem; } }
  @media (min-width: 64em) {
    .text-size-heading-3, .uol-typography-heading-3, .uol-in-text-facts__title, .js .uolcc-container .uolcc-subtitle,
    .js .uolcc-prompt-container .uolcc-subtitle,
    .js .uolcc-privacy-update-container .uolcc-subtitle, .uol-article--profile .uol-article-section .uol-article-section__title, .uol-rich-text h3, .uol-article-highlight__title {
      line-height: 2.812rem; } }
  @media (min-width: 48em) {
    .text-size-heading-3, .uol-typography-heading-3, .uol-in-text-facts__title, .js .uolcc-container .uolcc-subtitle,
    .js .uolcc-prompt-container .uolcc-subtitle,
    .js .uolcc-privacy-update-container .uolcc-subtitle, .uol-article--profile .uol-article-section .uol-article-section__title, .uol-rich-text h3, .uol-article-highlight__title {
      letter-spacing: -0.0125rem; } }
  @media (min-width: 64em) {
    .text-size-heading-3, .uol-typography-heading-3, .uol-in-text-facts__title, .js .uolcc-container .uolcc-subtitle,
    .js .uolcc-prompt-container .uolcc-subtitle,
    .js .uolcc-privacy-update-container .uolcc-subtitle, .uol-article--profile .uol-article-section .uol-article-section__title, .uol-rich-text h3, .uol-article-highlight__title {
      letter-spacing: -0.025rem; } }

.text-size-heading-4, .uol-typography-heading-4, .uol-in-text-fact__item__title, .uol-news-tile__title, .uol-results-items__item__link, .uol-rich-text h4, .uol-filters-panel__title, .uol-index-table-caption, .page-heading__title--position, .uol-article--profile .page-heading__meta__data,
.uol-article--person-insight .page-heading__meta__data,
.uol-article--blog .page-heading__meta__data, .uol-people-profile__name {
  font-size: 1.25rem;
  line-height: 1.75rem; }
  @media (min-width: 48em) {
    .text-size-heading-4, .uol-typography-heading-4, .uol-in-text-fact__item__title, .uol-news-tile__title, .uol-results-items__item__link, .uol-rich-text h4, .uol-filters-panel__title, .uol-index-table-caption, .page-heading__title--position, .uol-article--profile .page-heading__meta__data,
    .uol-article--person-insight .page-heading__meta__data,
    .uol-article--blog .page-heading__meta__data, .uol-people-profile__name {
      font-size: 1.75rem; } }
  @media (min-width: 64em) {
    .text-size-heading-4, .uol-typography-heading-4, .uol-in-text-fact__item__title, .uol-news-tile__title, .uol-results-items__item__link, .uol-rich-text h4, .uol-filters-panel__title, .uol-index-table-caption, .page-heading__title--position, .uol-article--profile .page-heading__meta__data,
    .uol-article--person-insight .page-heading__meta__data,
    .uol-article--blog .page-heading__meta__data, .uol-people-profile__name {
      font-size: 1.75rem; } }
  @media (min-width: 48em) {
    .text-size-heading-4, .uol-typography-heading-4, .uol-in-text-fact__item__title, .uol-news-tile__title, .uol-results-items__item__link, .uol-rich-text h4, .uol-filters-panel__title, .uol-index-table-caption, .page-heading__title--position, .uol-article--profile .page-heading__meta__data,
    .uol-article--person-insight .page-heading__meta__data,
    .uol-article--blog .page-heading__meta__data, .uol-people-profile__name {
      line-height: 2.5rem; } }
  @media (min-width: 64em) {
    .text-size-heading-4, .uol-typography-heading-4, .uol-in-text-fact__item__title, .uol-news-tile__title, .uol-results-items__item__link, .uol-rich-text h4, .uol-filters-panel__title, .uol-index-table-caption, .page-heading__title--position, .uol-article--profile .page-heading__meta__data,
    .uol-article--person-insight .page-heading__meta__data,
    .uol-article--blog .page-heading__meta__data, .uol-people-profile__name {
      line-height: 2.375rem; } }

.text-size-heading-5, .uol-typography-heading-5, .uol-description-list__item__title, .event-card__title, .uol-navigation-card__title, .uol-page-nav__item__title, .uol-rich-text table caption, .uol-rich-text h5, .uol-cards__card__title, .js .uolcc-container .uolcc-cookie-detail__button-title,
.js .uolcc-prompt-container .uolcc-cookie-detail__button-title,
.js .uolcc-privacy-update-container .uolcc-cookie-detail__button-title, .footer-supplement, .uol-info-list__data--date {
  font-size: 1.25rem;
  line-height: 1.75rem; }
  @media (min-width: 48em) {
    .text-size-heading-5, .uol-typography-heading-5, .uol-description-list__item__title, .event-card__title, .uol-navigation-card__title, .uol-page-nav__item__title, .uol-rich-text table caption, .uol-rich-text h5, .uol-cards__card__title, .js .uolcc-container .uolcc-cookie-detail__button-title,
    .js .uolcc-prompt-container .uolcc-cookie-detail__button-title,
    .js .uolcc-privacy-update-container .uolcc-cookie-detail__button-title, .footer-supplement, .uol-info-list__data--date {
      font-size: 1.5rem; } }
  @media (min-width: 64em) {
    .text-size-heading-5, .uol-typography-heading-5, .uol-description-list__item__title, .event-card__title, .uol-navigation-card__title, .uol-page-nav__item__title, .uol-rich-text table caption, .uol-rich-text h5, .uol-cards__card__title, .js .uolcc-container .uolcc-cookie-detail__button-title,
    .js .uolcc-prompt-container .uolcc-cookie-detail__button-title,
    .js .uolcc-privacy-update-container .uolcc-cookie-detail__button-title, .footer-supplement, .uol-info-list__data--date {
      font-size: 1.375rem; } }
  @media (min-width: 48em) {
    .text-size-heading-5, .uol-typography-heading-5, .uol-description-list__item__title, .event-card__title, .uol-navigation-card__title, .uol-page-nav__item__title, .uol-rich-text table caption, .uol-rich-text h5, .uol-cards__card__title, .js .uolcc-container .uolcc-cookie-detail__button-title,
    .js .uolcc-prompt-container .uolcc-cookie-detail__button-title,
    .js .uolcc-privacy-update-container .uolcc-cookie-detail__button-title, .footer-supplement, .uol-info-list__data--date {
      line-height: 2.25rem; } }
  @media (min-width: 64em) {
    .text-size-heading-5, .uol-typography-heading-5, .uol-description-list__item__title, .event-card__title, .uol-navigation-card__title, .uol-page-nav__item__title, .uol-rich-text table caption, .uol-rich-text h5, .uol-cards__card__title, .js .uolcc-container .uolcc-cookie-detail__button-title,
    .js .uolcc-prompt-container .uolcc-cookie-detail__button-title,
    .js .uolcc-privacy-update-container .uolcc-cookie-detail__button-title, .footer-supplement, .uol-info-list__data--date {
      line-height: 2rem; } }

.text-size-heading-6, .uol-typography-heading-6, .uol-global-marketing-banner__title, .uol-results-items__item__highlight, .uol-rich-text h6, .js .uolcc-container .uolcc-cookie-detail__checkbox-container,
.js .uolcc-prompt-container .uolcc-cookie-detail__checkbox-container,
.js .uolcc-privacy-update-container .uolcc-cookie-detail__checkbox-container, .uol-filters-panel__title__count, .uol-multilink-results__position, .uol-people-profile__position, .uol-year-of-entry__title, .uol-year-of-entry__date {
  font-size: 1.25rem;
  line-height: 1.75rem; }
  @media (min-width: 48em) {
    .text-size-heading-6, .uol-typography-heading-6, .uol-global-marketing-banner__title, .uol-results-items__item__highlight, .uol-rich-text h6, .js .uolcc-container .uolcc-cookie-detail__checkbox-container,
    .js .uolcc-prompt-container .uolcc-cookie-detail__checkbox-container,
    .js .uolcc-privacy-update-container .uolcc-cookie-detail__checkbox-container, .uol-filters-panel__title__count, .uol-multilink-results__position, .uol-people-profile__position, .uol-year-of-entry__title, .uol-year-of-entry__date {
      font-size: 1.25rem; } }
  @media (min-width: 64em) {
    .text-size-heading-6, .uol-typography-heading-6, .uol-global-marketing-banner__title, .uol-results-items__item__highlight, .uol-rich-text h6, .js .uolcc-container .uolcc-cookie-detail__checkbox-container,
    .js .uolcc-prompt-container .uolcc-cookie-detail__checkbox-container,
    .js .uolcc-privacy-update-container .uolcc-cookie-detail__checkbox-container, .uol-filters-panel__title__count, .uol-multilink-results__position, .uol-people-profile__position, .uol-year-of-entry__title, .uol-year-of-entry__date {
      font-size: 1.25rem; } }
  @media (min-width: 48em) {
    .text-size-heading-6, .uol-typography-heading-6, .uol-global-marketing-banner__title, .uol-results-items__item__highlight, .uol-rich-text h6, .js .uolcc-container .uolcc-cookie-detail__checkbox-container,
    .js .uolcc-prompt-container .uolcc-cookie-detail__checkbox-container,
    .js .uolcc-privacy-update-container .uolcc-cookie-detail__checkbox-container, .uol-filters-panel__title__count, .uol-multilink-results__position, .uol-people-profile__position, .uol-year-of-entry__title, .uol-year-of-entry__date {
      line-height: 1.75rem; } }
  @media (min-width: 64em) {
    .text-size-heading-6, .uol-typography-heading-6, .uol-global-marketing-banner__title, .uol-results-items__item__highlight, .uol-rich-text h6, .js .uolcc-container .uolcc-cookie-detail__checkbox-container,
    .js .uolcc-prompt-container .uolcc-cookie-detail__checkbox-container,
    .js .uolcc-privacy-update-container .uolcc-cookie-detail__checkbox-container, .uol-filters-panel__title__count, .uol-multilink-results__position, .uol-people-profile__position, .uol-year-of-entry__title, .uol-year-of-entry__date {
      line-height: 1.875rem; } }

.text-size-paragraph, .uol-form__input--file, .uol-info-list, .uol-key-facts__list, .uol-typography-paragraph, .uol-in-text-fact__item__description, .uol-article-highlight__subtitle, .uol-article-highlight__paragraph, .uol-hero-banner__lead, .uol-banner__item__lead, .uol-chips__button, .js .uolcc-container p,
.js .uolcc-prompt-container p,
.js .uolcc-privacy-update-container p, .js .uolcc-container ul,
.js .uolcc-prompt-container ul,
.js .uolcc-privacy-update-container ul, .js .uolcc-container .uolcc-prompt-inner p,
.js .uolcc-container .uolcc-privacy-update-inner p,
.js .uolcc-prompt-container .uolcc-prompt-inner p,
.js .uolcc-prompt-container .uolcc-privacy-update-inner p,
.js .uolcc-privacy-update-container .uolcc-prompt-inner p,
.js .uolcc-privacy-update-container .uolcc-privacy-update-inner p, .uol-form__input-wrapper__num-of-selected-message, .uol-form__files-list__item, .uol-global-marketing-banner__description, .uol-global-marketing-banner__link, .uol-page-nav__item__identifier, .uol-publications-list__further-link, .uol-typography-ol, .js .uolcc-container ol,
.js .uolcc-prompt-container ol,
.js .uolcc-privacy-update-container ol, .uol-typography-ul, .uol-in-text-cta .uol-in-text-cta__heading, .uol-in-text-cta .uol-in-text-cta__text, .uol-rich-text p:not(.uol-in-text-fact__item__description):not(.uol-in-text-cta__text), .uol-rich-text ul,
.uol-rich-text ol, .js .uol-accordion__title, .uol-chips, .uol-form__input-label__text, .uol-form__input, .uol-form__custom__legend, .uol-global-masthead__search-form, .uol-header-local-masthead__link--title, .js .uol-header-local-navigation__link, .uol-header-local-navigation__subnav-button, .in-text-icon .uol-icon__label, .uol-profile-supplement__link, .uol-publications-list__publication-title, .uol-widget__arrow-link, .uol-year-of-entry__link {
  font-size: 1.125rem;
  line-height: 1.75rem; }
  @media (min-width: 48em) {
    .text-size-paragraph, .uol-form__input--file, .uol-info-list, .uol-key-facts__list, .uol-typography-paragraph, .uol-in-text-fact__item__description, .uol-article-highlight__subtitle, .uol-article-highlight__paragraph, .uol-hero-banner__lead, .uol-banner__item__lead, .uol-chips__button, .js .uolcc-container p,
    .js .uolcc-prompt-container p,
    .js .uolcc-privacy-update-container p, .js .uolcc-container ul,
    .js .uolcc-prompt-container ul,
    .js .uolcc-privacy-update-container ul, .js .uolcc-container .uolcc-prompt-inner p,
    .js .uolcc-container .uolcc-privacy-update-inner p,
    .js .uolcc-prompt-container .uolcc-prompt-inner p,
    .js .uolcc-prompt-container .uolcc-privacy-update-inner p,
    .js .uolcc-privacy-update-container .uolcc-prompt-inner p,
    .js .uolcc-privacy-update-container .uolcc-privacy-update-inner p, .uol-form__input-wrapper__num-of-selected-message, .uol-form__files-list__item, .uol-global-marketing-banner__description, .uol-global-marketing-banner__link, .uol-page-nav__item__identifier, .uol-publications-list__further-link, .uol-typography-ol, .js .uolcc-container ol,
    .js .uolcc-prompt-container ol,
    .js .uolcc-privacy-update-container ol, .uol-typography-ul, .uol-in-text-cta .uol-in-text-cta__heading, .uol-in-text-cta .uol-in-text-cta__text, .uol-rich-text p:not(.uol-in-text-fact__item__description):not(.uol-in-text-cta__text), .uol-rich-text ul,
    .uol-rich-text ol, .js .uol-accordion__title, .uol-chips, .uol-form__input-label__text, .uol-form__input, .uol-form__custom__legend, .uol-global-masthead__search-form, .uol-header-local-masthead__link--title, .js .uol-header-local-navigation__link, .uol-header-local-navigation__subnav-button, .in-text-icon .uol-icon__label, .uol-profile-supplement__link, .uol-publications-list__publication-title, .uol-widget__arrow-link, .uol-year-of-entry__link {
      font-size: 1.25rem; } }
  @media (min-width: 64em) {
    .text-size-paragraph, .uol-form__input--file, .uol-info-list, .uol-key-facts__list, .uol-typography-paragraph, .uol-in-text-fact__item__description, .uol-article-highlight__subtitle, .uol-article-highlight__paragraph, .uol-hero-banner__lead, .uol-banner__item__lead, .uol-chips__button, .js .uolcc-container p,
    .js .uolcc-prompt-container p,
    .js .uolcc-privacy-update-container p, .js .uolcc-container ul,
    .js .uolcc-prompt-container ul,
    .js .uolcc-privacy-update-container ul, .js .uolcc-container .uolcc-prompt-inner p,
    .js .uolcc-container .uolcc-privacy-update-inner p,
    .js .uolcc-prompt-container .uolcc-prompt-inner p,
    .js .uolcc-prompt-container .uolcc-privacy-update-inner p,
    .js .uolcc-privacy-update-container .uolcc-prompt-inner p,
    .js .uolcc-privacy-update-container .uolcc-privacy-update-inner p, .uol-form__input-wrapper__num-of-selected-message, .uol-form__files-list__item, .uol-global-marketing-banner__description, .uol-global-marketing-banner__link, .uol-page-nav__item__identifier, .uol-publications-list__further-link, .uol-typography-ol, .js .uolcc-container ol,
    .js .uolcc-prompt-container ol,
    .js .uolcc-privacy-update-container ol, .uol-typography-ul, .uol-in-text-cta .uol-in-text-cta__heading, .uol-in-text-cta .uol-in-text-cta__text, .uol-rich-text p:not(.uol-in-text-fact__item__description):not(.uol-in-text-cta__text), .uol-rich-text ul,
    .uol-rich-text ol, .js .uol-accordion__title, .uol-chips, .uol-form__input-label__text, .uol-form__input, .uol-form__custom__legend, .uol-global-masthead__search-form, .uol-header-local-masthead__link--title, .js .uol-header-local-navigation__link, .uol-header-local-navigation__subnav-button, .in-text-icon .uol-icon__label, .uol-profile-supplement__link, .uol-publications-list__publication-title, .uol-widget__arrow-link, .uol-year-of-entry__link {
      font-size: 1.25rem; } }
  @media (min-width: 48em) {
    .text-size-paragraph, .uol-form__input--file, .uol-info-list, .uol-key-facts__list, .uol-typography-paragraph, .uol-in-text-fact__item__description, .uol-article-highlight__subtitle, .uol-article-highlight__paragraph, .uol-hero-banner__lead, .uol-banner__item__lead, .uol-chips__button, .js .uolcc-container p,
    .js .uolcc-prompt-container p,
    .js .uolcc-privacy-update-container p, .js .uolcc-container ul,
    .js .uolcc-prompt-container ul,
    .js .uolcc-privacy-update-container ul, .js .uolcc-container .uolcc-prompt-inner p,
    .js .uolcc-container .uolcc-privacy-update-inner p,
    .js .uolcc-prompt-container .uolcc-prompt-inner p,
    .js .uolcc-prompt-container .uolcc-privacy-update-inner p,
    .js .uolcc-privacy-update-container .uolcc-prompt-inner p,
    .js .uolcc-privacy-update-container .uolcc-privacy-update-inner p, .uol-form__input-wrapper__num-of-selected-message, .uol-form__files-list__item, .uol-global-marketing-banner__description, .uol-global-marketing-banner__link, .uol-page-nav__item__identifier, .uol-publications-list__further-link, .uol-typography-ol, .js .uolcc-container ol,
    .js .uolcc-prompt-container ol,
    .js .uolcc-privacy-update-container ol, .uol-typography-ul, .uol-in-text-cta .uol-in-text-cta__heading, .uol-in-text-cta .uol-in-text-cta__text, .uol-rich-text p:not(.uol-in-text-fact__item__description):not(.uol-in-text-cta__text), .uol-rich-text ul,
    .uol-rich-text ol, .js .uol-accordion__title, .uol-chips, .uol-form__input-label__text, .uol-form__input, .uol-form__custom__legend, .uol-global-masthead__search-form, .uol-header-local-masthead__link--title, .js .uol-header-local-navigation__link, .uol-header-local-navigation__subnav-button, .in-text-icon .uol-icon__label, .uol-profile-supplement__link, .uol-publications-list__publication-title, .uol-widget__arrow-link, .uol-year-of-entry__link {
      line-height: 1.75rem; } }
  @media (min-width: 64em) {
    .text-size-paragraph, .uol-form__input--file, .uol-info-list, .uol-key-facts__list, .uol-typography-paragraph, .uol-in-text-fact__item__description, .uol-article-highlight__subtitle, .uol-article-highlight__paragraph, .uol-hero-banner__lead, .uol-banner__item__lead, .uol-chips__button, .js .uolcc-container p,
    .js .uolcc-prompt-container p,
    .js .uolcc-privacy-update-container p, .js .uolcc-container ul,
    .js .uolcc-prompt-container ul,
    .js .uolcc-privacy-update-container ul, .js .uolcc-container .uolcc-prompt-inner p,
    .js .uolcc-container .uolcc-privacy-update-inner p,
    .js .uolcc-prompt-container .uolcc-prompt-inner p,
    .js .uolcc-prompt-container .uolcc-privacy-update-inner p,
    .js .uolcc-privacy-update-container .uolcc-prompt-inner p,
    .js .uolcc-privacy-update-container .uolcc-privacy-update-inner p, .uol-form__input-wrapper__num-of-selected-message, .uol-form__files-list__item, .uol-global-marketing-banner__description, .uol-global-marketing-banner__link, .uol-page-nav__item__identifier, .uol-publications-list__further-link, .uol-typography-ol, .js .uolcc-container ol,
    .js .uolcc-prompt-container ol,
    .js .uolcc-privacy-update-container ol, .uol-typography-ul, .uol-in-text-cta .uol-in-text-cta__heading, .uol-in-text-cta .uol-in-text-cta__text, .uol-rich-text p:not(.uol-in-text-fact__item__description):not(.uol-in-text-cta__text), .uol-rich-text ul,
    .uol-rich-text ol, .js .uol-accordion__title, .uol-chips, .uol-form__input-label__text, .uol-form__input, .uol-form__custom__legend, .uol-global-masthead__search-form, .uol-header-local-masthead__link--title, .js .uol-header-local-navigation__link, .uol-header-local-navigation__subnav-button, .in-text-icon .uol-icon__label, .uol-profile-supplement__link, .uol-publications-list__publication-title, .uol-widget__arrow-link, .uol-year-of-entry__link {
      line-height: 2rem; } }

.text-size-paragraph--small, .uol-typography-paragraph-small, .uol-description-list__item__content, .event-card__dates,
.event-card__time, .uol-featured-image__caption, .uol-article .uol-featured-image__caption,
.uol-article figcaption, .uol-news-tile__meta, .page-heading__meta, .uol-publications-list .uol-info-list, .uol-publications-list__content-group, .uol-publications-list__link-container, .uol-publications-list__link, .uol-results-items__item__meta__label, .uol-results-items__item__meta__data, .uol-results-items__item__meta__group--has-additional .uol-results-items__item__meta__additional-data, .uol-results-items__item__summary,
.uol-results-items__item__introduction,
.uol-rich-text .uol-typography-paragraph-small, .uol-rich-text .uol-description-list__item__content, .uol-rich-text .event-card__dates,
.uol-rich-text .event-card__time, .uol-rich-text .uol-featured-image__caption,
.uol-rich-text .uol-article figcaption, .uol-article .uol-rich-text figcaption, .uol-rich-text .uol-news-tile__meta, .uol-rich-text .page-heading__meta, .uol-rich-text .uol-publications-list__content-group, .uol-rich-text .uol-publications-list__link-container, .uol-rich-text .uol-publications-list__link, .uol-rich-text .uol-results-items__item__meta__label, .uol-rich-text .uol-results-items__item__meta__data, .uol-rich-text .uol-results-items__item__summary,
.uol-rich-text .uol-results-items__item__introduction, .uol-footer-contact__name, .uol-footer-contact__address, .uol-footer-contact__telephone,
.uol-footer-contact__email, .uol-form__input--character-count, .uol-typeahead__item, .uol-form__input-label__hint, .uol-form__input__requirements, .uol-form__input--inline-field-wrapper .uol-form__input-label, .uol-form__input--inline-field-wrapper .uol-datepicker__range-label, .uol-index-table, .nav-list-group__title, .nav-list-group__list, .uol-multilink-results__paragraph, .uol-info-list, .uol-multilink-results__footer__messages, .uol-people-profile__card .uol-info-list, .uol-people-profile__footer__messages, .uol-tile__subtitle {
  font-size: 1rem;
  line-height: 1.5rem; }
  @media (min-width: 48em) {
    .text-size-paragraph--small, .uol-typography-paragraph-small, .uol-description-list__item__content, .event-card__dates,
    .event-card__time, .uol-featured-image__caption, .uol-article .uol-featured-image__caption,
    .uol-article figcaption, .uol-news-tile__meta, .page-heading__meta, .uol-publications-list .uol-info-list, .uol-publications-list__content-group, .uol-publications-list__link-container, .uol-publications-list__link, .uol-results-items__item__meta__label, .uol-results-items__item__meta__data, .uol-results-items__item__meta__group--has-additional .uol-results-items__item__meta__additional-data, .uol-results-items__item__summary,
    .uol-results-items__item__introduction,
    .uol-rich-text .uol-typography-paragraph-small, .uol-rich-text .uol-description-list__item__content, .uol-rich-text .event-card__dates,
    .uol-rich-text .event-card__time, .uol-rich-text .uol-featured-image__caption,
    .uol-rich-text .uol-article figcaption, .uol-article .uol-rich-text figcaption, .uol-rich-text .uol-news-tile__meta, .uol-rich-text .page-heading__meta, .uol-rich-text .uol-publications-list__content-group, .uol-rich-text .uol-publications-list__link-container, .uol-rich-text .uol-publications-list__link, .uol-rich-text .uol-results-items__item__meta__label, .uol-rich-text .uol-results-items__item__meta__data, .uol-rich-text .uol-results-items__item__summary,
    .uol-rich-text .uol-results-items__item__introduction, .uol-footer-contact__name, .uol-footer-contact__address, .uol-footer-contact__telephone,
    .uol-footer-contact__email, .uol-form__input--character-count, .uol-typeahead__item, .uol-form__input-label__hint, .uol-form__input__requirements, .uol-form__input--inline-field-wrapper .uol-form__input-label, .uol-form__input--inline-field-wrapper .uol-datepicker__range-label, .uol-index-table, .nav-list-group__title, .nav-list-group__list, .uol-multilink-results__paragraph, .uol-info-list, .uol-multilink-results__footer__messages, .uol-people-profile__card .uol-info-list, .uol-people-profile__footer__messages, .uol-tile__subtitle {
      font-size: 1rem; } }
  @media (min-width: 64em) {
    .text-size-paragraph--small, .uol-typography-paragraph-small, .uol-description-list__item__content, .event-card__dates,
    .event-card__time, .uol-featured-image__caption, .uol-article .uol-featured-image__caption,
    .uol-article figcaption, .uol-news-tile__meta, .page-heading__meta, .uol-publications-list .uol-info-list, .uol-publications-list__content-group, .uol-publications-list__link-container, .uol-publications-list__link, .uol-results-items__item__meta__label, .uol-results-items__item__meta__data, .uol-results-items__item__meta__group--has-additional .uol-results-items__item__meta__additional-data, .uol-results-items__item__summary,
    .uol-results-items__item__introduction,
    .uol-rich-text .uol-typography-paragraph-small, .uol-rich-text .uol-description-list__item__content, .uol-rich-text .event-card__dates,
    .uol-rich-text .event-card__time, .uol-rich-text .uol-featured-image__caption,
    .uol-rich-text .uol-article figcaption, .uol-article .uol-rich-text figcaption, .uol-rich-text .uol-news-tile__meta, .uol-rich-text .page-heading__meta, .uol-rich-text .uol-publications-list__content-group, .uol-rich-text .uol-publications-list__link-container, .uol-rich-text .uol-publications-list__link, .uol-rich-text .uol-results-items__item__meta__label, .uol-rich-text .uol-results-items__item__meta__data, .uol-rich-text .uol-results-items__item__summary,
    .uol-rich-text .uol-results-items__item__introduction, .uol-footer-contact__name, .uol-footer-contact__address, .uol-footer-contact__telephone,
    .uol-footer-contact__email, .uol-form__input--character-count, .uol-typeahead__item, .uol-form__input-label__hint, .uol-form__input__requirements, .uol-form__input--inline-field-wrapper .uol-form__input-label, .uol-form__input--inline-field-wrapper .uol-datepicker__range-label, .uol-index-table, .nav-list-group__title, .nav-list-group__list, .uol-multilink-results__paragraph, .uol-info-list, .uol-multilink-results__footer__messages, .uol-people-profile__card .uol-info-list, .uol-people-profile__footer__messages, .uol-tile__subtitle {
      font-size: 1.125rem; } }
  @media (min-width: 48em) {
    .text-size-paragraph--small, .uol-typography-paragraph-small, .uol-description-list__item__content, .event-card__dates,
    .event-card__time, .uol-featured-image__caption, .uol-article .uol-featured-image__caption,
    .uol-article figcaption, .uol-news-tile__meta, .page-heading__meta, .uol-publications-list .uol-info-list, .uol-publications-list__content-group, .uol-publications-list__link-container, .uol-publications-list__link, .uol-results-items__item__meta__label, .uol-results-items__item__meta__data, .uol-results-items__item__meta__group--has-additional .uol-results-items__item__meta__additional-data, .uol-results-items__item__summary,
    .uol-results-items__item__introduction,
    .uol-rich-text .uol-typography-paragraph-small, .uol-rich-text .uol-description-list__item__content, .uol-rich-text .event-card__dates,
    .uol-rich-text .event-card__time, .uol-rich-text .uol-featured-image__caption,
    .uol-rich-text .uol-article figcaption, .uol-article .uol-rich-text figcaption, .uol-rich-text .uol-news-tile__meta, .uol-rich-text .page-heading__meta, .uol-rich-text .uol-publications-list__content-group, .uol-rich-text .uol-publications-list__link-container, .uol-rich-text .uol-publications-list__link, .uol-rich-text .uol-results-items__item__meta__label, .uol-rich-text .uol-results-items__item__meta__data, .uol-rich-text .uol-results-items__item__summary,
    .uol-rich-text .uol-results-items__item__introduction, .uol-footer-contact__name, .uol-footer-contact__address, .uol-footer-contact__telephone,
    .uol-footer-contact__email, .uol-form__input--character-count, .uol-typeahead__item, .uol-form__input-label__hint, .uol-form__input__requirements, .uol-form__input--inline-field-wrapper .uol-form__input-label, .uol-form__input--inline-field-wrapper .uol-datepicker__range-label, .uol-index-table, .nav-list-group__title, .nav-list-group__list, .uol-multilink-results__paragraph, .uol-info-list, .uol-multilink-results__footer__messages, .uol-people-profile__card .uol-info-list, .uol-people-profile__footer__messages, .uol-tile__subtitle {
      line-height: 1.625rem; } }
  @media (min-width: 64em) {
    .text-size-paragraph--small, .uol-typography-paragraph-small, .uol-description-list__item__content, .event-card__dates,
    .event-card__time, .uol-featured-image__caption, .uol-article .uol-featured-image__caption,
    .uol-article figcaption, .uol-news-tile__meta, .page-heading__meta, .uol-publications-list .uol-info-list, .uol-publications-list__content-group, .uol-publications-list__link-container, .uol-publications-list__link, .uol-results-items__item__meta__label, .uol-results-items__item__meta__data, .uol-results-items__item__meta__group--has-additional .uol-results-items__item__meta__additional-data, .uol-results-items__item__summary,
    .uol-results-items__item__introduction,
    .uol-rich-text .uol-typography-paragraph-small, .uol-rich-text .uol-description-list__item__content, .uol-rich-text .event-card__dates,
    .uol-rich-text .event-card__time, .uol-rich-text .uol-featured-image__caption,
    .uol-rich-text .uol-article figcaption, .uol-article .uol-rich-text figcaption, .uol-rich-text .uol-news-tile__meta, .uol-rich-text .page-heading__meta, .uol-rich-text .uol-publications-list__content-group, .uol-rich-text .uol-publications-list__link-container, .uol-rich-text .uol-publications-list__link, .uol-rich-text .uol-results-items__item__meta__label, .uol-rich-text .uol-results-items__item__meta__data, .uol-rich-text .uol-results-items__item__summary,
    .uol-rich-text .uol-results-items__item__introduction, .uol-footer-contact__name, .uol-footer-contact__address, .uol-footer-contact__telephone,
    .uol-footer-contact__email, .uol-form__input--character-count, .uol-typeahead__item, .uol-form__input-label__hint, .uol-form__input__requirements, .uol-form__input--inline-field-wrapper .uol-form__input-label, .uol-form__input--inline-field-wrapper .uol-datepicker__range-label, .uol-index-table, .nav-list-group__title, .nav-list-group__list, .uol-multilink-results__paragraph, .uol-info-list, .uol-multilink-results__footer__messages, .uol-people-profile__card .uol-info-list, .uol-people-profile__footer__messages, .uol-tile__subtitle {
      line-height: 1.75rem; } }

.text-size-paragraph--intro, .uol-typography-paragraph-intro, .uol-rich-text--with-lead > p:first-of-type {
  font-size: 1.25rem;
  line-height: 1.75rem; }
  @media (min-width: 48em) {
    .text-size-paragraph--intro, .uol-typography-paragraph-intro, .uol-rich-text--with-lead > p:first-of-type {
      font-size: 1.375rem; } }
  @media (min-width: 64em) {
    .text-size-paragraph--intro, .uol-typography-paragraph-intro, .uol-rich-text--with-lead > p:first-of-type {
      font-size: 1.5rem; } }
  @media (min-width: 48em) {
    .text-size-paragraph--intro, .uol-typography-paragraph-intro, .uol-rich-text--with-lead > p:first-of-type {
      line-height: 2.125rem; } }
  @media (min-width: 64em) {
    .text-size-paragraph--intro, .uol-typography-paragraph-intro, .uol-rich-text--with-lead > p:first-of-type {
      line-height: 2.25rem; } }

.text-size-blockquote, .uol-typography-blockquote, .uol-typography-blockquote p, .uol-rich-text blockquote, .uol-rich-text blockquote p {
  font-size: 1.125rem;
  line-height: 1.75rem; }
  @media (min-width: 48em) {
    .text-size-blockquote, .uol-typography-blockquote, .uol-typography-blockquote p, .uol-rich-text blockquote, .uol-rich-text blockquote p {
      font-size: 1.5rem; } }
  @media (min-width: 64em) {
    .text-size-blockquote, .uol-typography-blockquote, .uol-typography-blockquote p, .uol-rich-text blockquote, .uol-rich-text blockquote p {
      font-size: 1.75rem; } }
  @media (min-width: 48em) {
    .text-size-blockquote, .uol-typography-blockquote, .uol-typography-blockquote p, .uol-rich-text blockquote, .uol-rich-text blockquote p {
      line-height: 2.25rem; } }
  @media (min-width: 64em) {
    .text-size-blockquote, .uol-typography-blockquote, .uol-typography-blockquote p, .uol-rich-text blockquote, .uol-rich-text blockquote p {
      line-height: 2.25rem; } }

.text-size-pullquote, .uol-typography-pull-quote {
  font-size: 1.125rem;
  line-height: 1.5rem; }
  @media (min-width: 48em) {
    .text-size-pullquote, .uol-typography-pull-quote {
      font-size: 1.25rem; } }
  @media (min-width: 64em) {
    .text-size-pullquote, .uol-typography-pull-quote {
      font-size: 1.25rem; } }
  @media (min-width: 48em) {
    .text-size-pullquote, .uol-typography-pull-quote {
      line-height: 1.625rem; } }
  @media (min-width: 64em) {
    .text-size-pullquote, .uol-typography-pull-quote {
      line-height: 1.75rem; } }

.text-size-caption, .uol-typography-blockquote footer, .uol-rich-text blockquote footer, .uol-rich-text .wp-caption-text,
.uol-rich-text figcaption, .uol-rich-text blockquote footer p,
.uol-rich-text blockquote footer cite, .uol-gallery-modal__image-caption, .uol-gallery__image-caption, .uol-image-pair .uol-image-pair__caption, .uol-pagination__status, .uol-results-items__item__meta {
  font-size: 1rem;
  line-height: 1.125rem; }

.text-size-epic, .uol-typography-epic, .uol-rich-text h1.epic {
  font-size: 2.25rem;
  line-height: 2.75rem;
  letter-spacing: normal; }
  @media (min-width: 48em) {
    .text-size-epic, .uol-typography-epic, .uol-rich-text h1.epic {
      font-size: 3.25rem; } }
  @media (min-width: 64em) {
    .text-size-epic, .uol-typography-epic, .uol-rich-text h1.epic {
      font-size: 4.125rem; } }
  @media (min-width: 48em) {
    .text-size-epic, .uol-typography-epic, .uol-rich-text h1.epic {
      line-height: 4rem; } }
  @media (min-width: 64em) {
    .text-size-epic, .uol-typography-epic, .uol-rich-text h1.epic {
      line-height: 4.75rem; } }
  @media (min-width: 48em) {
    .text-size-epic, .uol-typography-epic, .uol-rich-text h1.epic {
      letter-spacing: -0.05rem; } }
  @media (min-width: 64em) {
    .text-size-epic, .uol-typography-epic, .uol-rich-text h1.epic {
      letter-spacing: -0.13rem; } }

.text-size-heading-1, .uol-typography-heading-1, .event-card__date__start-date__day, .page-heading__title__main, .page-heading__title, .uol-rich-text h1 {
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: normal; }
  @media (min-width: 48em) {
    .text-size-heading-1, .uol-typography-heading-1, .event-card__date__start-date__day, .page-heading__title__main, .page-heading__title, .uol-rich-text h1 {
      font-size: 2.75rem; } }
  @media (min-width: 64em) {
    .text-size-heading-1, .uol-typography-heading-1, .event-card__date__start-date__day, .page-heading__title__main, .page-heading__title, .uol-rich-text h1 {
      font-size: 3.312rem; } }
  @media (min-width: 48em) {
    .text-size-heading-1, .uol-typography-heading-1, .event-card__date__start-date__day, .page-heading__title__main, .page-heading__title, .uol-rich-text h1 {
      line-height: 3.5rem; } }
  @media (min-width: 64em) {
    .text-size-heading-1, .uol-typography-heading-1, .event-card__date__start-date__day, .page-heading__title__main, .page-heading__title, .uol-rich-text h1 {
      line-height: 3.938rem; } }
  @media (min-width: 48em) {
    .text-size-heading-1, .uol-typography-heading-1, .event-card__date__start-date__day, .page-heading__title__main, .page-heading__title, .uol-rich-text h1 {
      letter-spacing: -0.05rem; } }
  @media (min-width: 64em) {
    .text-size-heading-1, .uol-typography-heading-1, .event-card__date__start-date__day, .page-heading__title__main, .page-heading__title, .uol-rich-text h1 {
      letter-spacing: -0.09rem; } }

.text-size-heading-2, .uol-typography-heading-2, .uol-hero-banner__title, .uol-banner__item__title, .js .uolcc-container .uolcc-title,
.js .uolcc-prompt-container .uolcc-title,
.js .uolcc-privacy-update-container .uolcc-title, .uol-gallery-modal__info__title, .uol-profile-supplement__title, .uol-widget__title, .uol-rich-text h2, .uol-form__input--legend-title, .uol-gallery__button--with-count, .js .uol-gallery__item--video-play-icon .uol-button.uol-icon--icon-only, .js .uol-gallery__item--video-play-icon .uolcc-container .uol-icon--icon-only.uolcc-button, .js .uolcc-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button,
.js .uol-gallery__item--video-play-icon .uolcc-prompt-container .uol-icon--icon-only.uolcc-button, .js .uolcc-prompt-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button,
.js .uol-gallery__item--video-play-icon .uolcc-privacy-update-container .uol-icon--icon-only.uolcc-button, .js .uolcc-privacy-update-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button, .js .uol-gallery__item--video-play-icon .uol-icon--icon-only.uol-section-nav-ctas__link, .page-heading__title.page-heading__title--subtitle, .js .uol-profile-supplement__video-icon {
  font-size: 1.75rem;
  line-height: 2.25rem;
  letter-spacing: normal; }
  @media (min-width: 48em) {
    .text-size-heading-2, .uol-typography-heading-2, .uol-hero-banner__title, .uol-banner__item__title, .js .uolcc-container .uolcc-title,
    .js .uolcc-prompt-container .uolcc-title,
    .js .uolcc-privacy-update-container .uolcc-title, .uol-gallery-modal__info__title, .uol-profile-supplement__title, .uol-widget__title, .uol-rich-text h2, .uol-form__input--legend-title, .uol-gallery__button--with-count, .js .uol-gallery__item--video-play-icon .uol-button.uol-icon--icon-only, .js .uol-gallery__item--video-play-icon .uolcc-container .uol-icon--icon-only.uolcc-button, .js .uolcc-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button,
    .js .uol-gallery__item--video-play-icon .uolcc-prompt-container .uol-icon--icon-only.uolcc-button, .js .uolcc-prompt-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button,
    .js .uol-gallery__item--video-play-icon .uolcc-privacy-update-container .uol-icon--icon-only.uolcc-button, .js .uolcc-privacy-update-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button, .js .uol-gallery__item--video-play-icon .uol-icon--icon-only.uol-section-nav-ctas__link, .page-heading__title.page-heading__title--subtitle, .js .uol-profile-supplement__video-icon {
      font-size: 2.25rem; } }
  @media (min-width: 64em) {
    .text-size-heading-2, .uol-typography-heading-2, .uol-hero-banner__title, .uol-banner__item__title, .js .uolcc-container .uolcc-title,
    .js .uolcc-prompt-container .uolcc-title,
    .js .uolcc-privacy-update-container .uolcc-title, .uol-gallery-modal__info__title, .uol-profile-supplement__title, .uol-widget__title, .uol-rich-text h2, .uol-form__input--legend-title, .uol-gallery__button--with-count, .js .uol-gallery__item--video-play-icon .uol-button.uol-icon--icon-only, .js .uol-gallery__item--video-play-icon .uolcc-container .uol-icon--icon-only.uolcc-button, .js .uolcc-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button,
    .js .uol-gallery__item--video-play-icon .uolcc-prompt-container .uol-icon--icon-only.uolcc-button, .js .uolcc-prompt-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button,
    .js .uol-gallery__item--video-play-icon .uolcc-privacy-update-container .uol-icon--icon-only.uolcc-button, .js .uolcc-privacy-update-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button, .js .uol-gallery__item--video-play-icon .uol-icon--icon-only.uol-section-nav-ctas__link, .page-heading__title.page-heading__title--subtitle, .js .uol-profile-supplement__video-icon {
      font-size: 2.625rem; } }
  @media (min-width: 48em) {
    .text-size-heading-2, .uol-typography-heading-2, .uol-hero-banner__title, .uol-banner__item__title, .js .uolcc-container .uolcc-title,
    .js .uolcc-prompt-container .uolcc-title,
    .js .uolcc-privacy-update-container .uolcc-title, .uol-gallery-modal__info__title, .uol-profile-supplement__title, .uol-widget__title, .uol-rich-text h2, .uol-form__input--legend-title, .uol-gallery__button--with-count, .js .uol-gallery__item--video-play-icon .uol-button.uol-icon--icon-only, .js .uol-gallery__item--video-play-icon .uolcc-container .uol-icon--icon-only.uolcc-button, .js .uolcc-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button,
    .js .uol-gallery__item--video-play-icon .uolcc-prompt-container .uol-icon--icon-only.uolcc-button, .js .uolcc-prompt-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button,
    .js .uol-gallery__item--video-play-icon .uolcc-privacy-update-container .uol-icon--icon-only.uolcc-button, .js .uolcc-privacy-update-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button, .js .uol-gallery__item--video-play-icon .uol-icon--icon-only.uol-section-nav-ctas__link, .page-heading__title.page-heading__title--subtitle, .js .uol-profile-supplement__video-icon {
      line-height: 3rem; } }
  @media (min-width: 64em) {
    .text-size-heading-2, .uol-typography-heading-2, .uol-hero-banner__title, .uol-banner__item__title, .js .uolcc-container .uolcc-title,
    .js .uolcc-prompt-container .uolcc-title,
    .js .uolcc-privacy-update-container .uolcc-title, .uol-gallery-modal__info__title, .uol-profile-supplement__title, .uol-widget__title, .uol-rich-text h2, .uol-form__input--legend-title, .uol-gallery__button--with-count, .js .uol-gallery__item--video-play-icon .uol-button.uol-icon--icon-only, .js .uol-gallery__item--video-play-icon .uolcc-container .uol-icon--icon-only.uolcc-button, .js .uolcc-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button,
    .js .uol-gallery__item--video-play-icon .uolcc-prompt-container .uol-icon--icon-only.uolcc-button, .js .uolcc-prompt-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button,
    .js .uol-gallery__item--video-play-icon .uolcc-privacy-update-container .uol-icon--icon-only.uolcc-button, .js .uolcc-privacy-update-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button, .js .uol-gallery__item--video-play-icon .uol-icon--icon-only.uol-section-nav-ctas__link, .page-heading__title.page-heading__title--subtitle, .js .uol-profile-supplement__video-icon {
      line-height: 3.25rem; } }
  @media (min-width: 48em) {
    .text-size-heading-2, .uol-typography-heading-2, .uol-hero-banner__title, .uol-banner__item__title, .js .uolcc-container .uolcc-title,
    .js .uolcc-prompt-container .uolcc-title,
    .js .uolcc-privacy-update-container .uolcc-title, .uol-gallery-modal__info__title, .uol-profile-supplement__title, .uol-widget__title, .uol-rich-text h2, .uol-form__input--legend-title, .uol-gallery__button--with-count, .js .uol-gallery__item--video-play-icon .uol-button.uol-icon--icon-only, .js .uol-gallery__item--video-play-icon .uolcc-container .uol-icon--icon-only.uolcc-button, .js .uolcc-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button,
    .js .uol-gallery__item--video-play-icon .uolcc-prompt-container .uol-icon--icon-only.uolcc-button, .js .uolcc-prompt-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button,
    .js .uol-gallery__item--video-play-icon .uolcc-privacy-update-container .uol-icon--icon-only.uolcc-button, .js .uolcc-privacy-update-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button, .js .uol-gallery__item--video-play-icon .uol-icon--icon-only.uol-section-nav-ctas__link, .page-heading__title.page-heading__title--subtitle, .js .uol-profile-supplement__video-icon {
      letter-spacing: -0.025rem; } }
  @media (min-width: 64em) {
    .text-size-heading-2, .uol-typography-heading-2, .uol-hero-banner__title, .uol-banner__item__title, .js .uolcc-container .uolcc-title,
    .js .uolcc-prompt-container .uolcc-title,
    .js .uolcc-privacy-update-container .uolcc-title, .uol-gallery-modal__info__title, .uol-profile-supplement__title, .uol-widget__title, .uol-rich-text h2, .uol-form__input--legend-title, .uol-gallery__button--with-count, .js .uol-gallery__item--video-play-icon .uol-button.uol-icon--icon-only, .js .uol-gallery__item--video-play-icon .uolcc-container .uol-icon--icon-only.uolcc-button, .js .uolcc-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button,
    .js .uol-gallery__item--video-play-icon .uolcc-prompt-container .uol-icon--icon-only.uolcc-button, .js .uolcc-prompt-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button,
    .js .uol-gallery__item--video-play-icon .uolcc-privacy-update-container .uol-icon--icon-only.uolcc-button, .js .uolcc-privacy-update-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button, .js .uol-gallery__item--video-play-icon .uol-icon--icon-only.uol-section-nav-ctas__link, .page-heading__title.page-heading__title--subtitle, .js .uol-profile-supplement__video-icon {
      letter-spacing: -0.05rem; } }

.text-size-heading-3, .uol-typography-heading-3, .uol-in-text-facts__title, .js .uolcc-container .uolcc-subtitle,
.js .uolcc-prompt-container .uolcc-subtitle,
.js .uolcc-privacy-update-container .uolcc-subtitle, .uol-article--profile .uol-article-section .uol-article-section__title, .uol-rich-text h3, .uol-article-highlight__title {
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: normal; }
  @media (min-width: 48em) {
    .text-size-heading-3, .uol-typography-heading-3, .uol-in-text-facts__title, .js .uolcc-container .uolcc-subtitle,
    .js .uolcc-prompt-container .uolcc-subtitle,
    .js .uolcc-privacy-update-container .uolcc-subtitle, .uol-article--profile .uol-article-section .uol-article-section__title, .uol-rich-text h3, .uol-article-highlight__title {
      font-size: 2rem; } }
  @media (min-width: 64em) {
    .text-size-heading-3, .uol-typography-heading-3, .uol-in-text-facts__title, .js .uolcc-container .uolcc-subtitle,
    .js .uolcc-prompt-container .uolcc-subtitle,
    .js .uolcc-privacy-update-container .uolcc-subtitle, .uol-article--profile .uol-article-section .uol-article-section__title, .uol-rich-text h3, .uol-article-highlight__title {
      font-size: 2.188rem; } }
  @media (min-width: 48em) {
    .text-size-heading-3, .uol-typography-heading-3, .uol-in-text-facts__title, .js .uolcc-container .uolcc-subtitle,
    .js .uolcc-prompt-container .uolcc-subtitle,
    .js .uolcc-privacy-update-container .uolcc-subtitle, .uol-article--profile .uol-article-section .uol-article-section__title, .uol-rich-text h3, .uol-article-highlight__title {
      line-height: 2.75rem; } }
  @media (min-width: 64em) {
    .text-size-heading-3, .uol-typography-heading-3, .uol-in-text-facts__title, .js .uolcc-container .uolcc-subtitle,
    .js .uolcc-prompt-container .uolcc-subtitle,
    .js .uolcc-privacy-update-container .uolcc-subtitle, .uol-article--profile .uol-article-section .uol-article-section__title, .uol-rich-text h3, .uol-article-highlight__title {
      line-height: 2.812rem; } }
  @media (min-width: 48em) {
    .text-size-heading-3, .uol-typography-heading-3, .uol-in-text-facts__title, .js .uolcc-container .uolcc-subtitle,
    .js .uolcc-prompt-container .uolcc-subtitle,
    .js .uolcc-privacy-update-container .uolcc-subtitle, .uol-article--profile .uol-article-section .uol-article-section__title, .uol-rich-text h3, .uol-article-highlight__title {
      letter-spacing: -0.0125rem; } }
  @media (min-width: 64em) {
    .text-size-heading-3, .uol-typography-heading-3, .uol-in-text-facts__title, .js .uolcc-container .uolcc-subtitle,
    .js .uolcc-prompt-container .uolcc-subtitle,
    .js .uolcc-privacy-update-container .uolcc-subtitle, .uol-article--profile .uol-article-section .uol-article-section__title, .uol-rich-text h3, .uol-article-highlight__title {
      letter-spacing: -0.025rem; } }

.text-size-heading-4, .uol-typography-heading-4, .uol-in-text-fact__item__title, .uol-news-tile__title, .uol-results-items__item__link, .uol-rich-text h4, .uol-filters-panel__title, .uol-index-table-caption, .page-heading__title--position, .uol-article--profile .page-heading__meta__data,
.uol-article--person-insight .page-heading__meta__data,
.uol-article--blog .page-heading__meta__data, .uol-people-profile__name {
  font-size: 1.25rem;
  line-height: 1.75rem; }
  @media (min-width: 48em) {
    .text-size-heading-4, .uol-typography-heading-4, .uol-in-text-fact__item__title, .uol-news-tile__title, .uol-results-items__item__link, .uol-rich-text h4, .uol-filters-panel__title, .uol-index-table-caption, .page-heading__title--position, .uol-article--profile .page-heading__meta__data,
    .uol-article--person-insight .page-heading__meta__data,
    .uol-article--blog .page-heading__meta__data, .uol-people-profile__name {
      font-size: 1.75rem; } }
  @media (min-width: 64em) {
    .text-size-heading-4, .uol-typography-heading-4, .uol-in-text-fact__item__title, .uol-news-tile__title, .uol-results-items__item__link, .uol-rich-text h4, .uol-filters-panel__title, .uol-index-table-caption, .page-heading__title--position, .uol-article--profile .page-heading__meta__data,
    .uol-article--person-insight .page-heading__meta__data,
    .uol-article--blog .page-heading__meta__data, .uol-people-profile__name {
      font-size: 1.75rem; } }
  @media (min-width: 48em) {
    .text-size-heading-4, .uol-typography-heading-4, .uol-in-text-fact__item__title, .uol-news-tile__title, .uol-results-items__item__link, .uol-rich-text h4, .uol-filters-panel__title, .uol-index-table-caption, .page-heading__title--position, .uol-article--profile .page-heading__meta__data,
    .uol-article--person-insight .page-heading__meta__data,
    .uol-article--blog .page-heading__meta__data, .uol-people-profile__name {
      line-height: 2.5rem; } }
  @media (min-width: 64em) {
    .text-size-heading-4, .uol-typography-heading-4, .uol-in-text-fact__item__title, .uol-news-tile__title, .uol-results-items__item__link, .uol-rich-text h4, .uol-filters-panel__title, .uol-index-table-caption, .page-heading__title--position, .uol-article--profile .page-heading__meta__data,
    .uol-article--person-insight .page-heading__meta__data,
    .uol-article--blog .page-heading__meta__data, .uol-people-profile__name {
      line-height: 2.375rem; } }

.text-size-heading-5, .uol-typography-heading-5, .uol-description-list__item__title, .event-card__title, .uol-navigation-card__title, .uol-page-nav__item__title, .uol-rich-text table caption, .uol-rich-text h5, .uol-cards__card__title, .js .uolcc-container .uolcc-cookie-detail__button-title,
.js .uolcc-prompt-container .uolcc-cookie-detail__button-title,
.js .uolcc-privacy-update-container .uolcc-cookie-detail__button-title, .footer-supplement, .uol-info-list__data--date {
  font-size: 1.25rem;
  line-height: 1.75rem; }
  @media (min-width: 48em) {
    .text-size-heading-5, .uol-typography-heading-5, .uol-description-list__item__title, .event-card__title, .uol-navigation-card__title, .uol-page-nav__item__title, .uol-rich-text table caption, .uol-rich-text h5, .uol-cards__card__title, .js .uolcc-container .uolcc-cookie-detail__button-title,
    .js .uolcc-prompt-container .uolcc-cookie-detail__button-title,
    .js .uolcc-privacy-update-container .uolcc-cookie-detail__button-title, .footer-supplement, .uol-info-list__data--date {
      font-size: 1.5rem; } }
  @media (min-width: 64em) {
    .text-size-heading-5, .uol-typography-heading-5, .uol-description-list__item__title, .event-card__title, .uol-navigation-card__title, .uol-page-nav__item__title, .uol-rich-text table caption, .uol-rich-text h5, .uol-cards__card__title, .js .uolcc-container .uolcc-cookie-detail__button-title,
    .js .uolcc-prompt-container .uolcc-cookie-detail__button-title,
    .js .uolcc-privacy-update-container .uolcc-cookie-detail__button-title, .footer-supplement, .uol-info-list__data--date {
      font-size: 1.375rem; } }
  @media (min-width: 48em) {
    .text-size-heading-5, .uol-typography-heading-5, .uol-description-list__item__title, .event-card__title, .uol-navigation-card__title, .uol-page-nav__item__title, .uol-rich-text table caption, .uol-rich-text h5, .uol-cards__card__title, .js .uolcc-container .uolcc-cookie-detail__button-title,
    .js .uolcc-prompt-container .uolcc-cookie-detail__button-title,
    .js .uolcc-privacy-update-container .uolcc-cookie-detail__button-title, .footer-supplement, .uol-info-list__data--date {
      line-height: 2.25rem; } }
  @media (min-width: 64em) {
    .text-size-heading-5, .uol-typography-heading-5, .uol-description-list__item__title, .event-card__title, .uol-navigation-card__title, .uol-page-nav__item__title, .uol-rich-text table caption, .uol-rich-text h5, .uol-cards__card__title, .js .uolcc-container .uolcc-cookie-detail__button-title,
    .js .uolcc-prompt-container .uolcc-cookie-detail__button-title,
    .js .uolcc-privacy-update-container .uolcc-cookie-detail__button-title, .footer-supplement, .uol-info-list__data--date {
      line-height: 2rem; } }

.text-size-heading-6, .uol-typography-heading-6, .uol-global-marketing-banner__title, .uol-results-items__item__highlight, .uol-rich-text h6, .js .uolcc-container .uolcc-cookie-detail__checkbox-container,
.js .uolcc-prompt-container .uolcc-cookie-detail__checkbox-container,
.js .uolcc-privacy-update-container .uolcc-cookie-detail__checkbox-container, .uol-filters-panel__title__count, .uol-multilink-results__position, .uol-people-profile__position, .uol-year-of-entry__title, .uol-year-of-entry__date {
  font-size: 1.25rem;
  line-height: 1.75rem; }
  @media (min-width: 48em) {
    .text-size-heading-6, .uol-typography-heading-6, .uol-global-marketing-banner__title, .uol-results-items__item__highlight, .uol-rich-text h6, .js .uolcc-container .uolcc-cookie-detail__checkbox-container,
    .js .uolcc-prompt-container .uolcc-cookie-detail__checkbox-container,
    .js .uolcc-privacy-update-container .uolcc-cookie-detail__checkbox-container, .uol-filters-panel__title__count, .uol-multilink-results__position, .uol-people-profile__position, .uol-year-of-entry__title, .uol-year-of-entry__date {
      font-size: 1.25rem; } }
  @media (min-width: 64em) {
    .text-size-heading-6, .uol-typography-heading-6, .uol-global-marketing-banner__title, .uol-results-items__item__highlight, .uol-rich-text h6, .js .uolcc-container .uolcc-cookie-detail__checkbox-container,
    .js .uolcc-prompt-container .uolcc-cookie-detail__checkbox-container,
    .js .uolcc-privacy-update-container .uolcc-cookie-detail__checkbox-container, .uol-filters-panel__title__count, .uol-multilink-results__position, .uol-people-profile__position, .uol-year-of-entry__title, .uol-year-of-entry__date {
      font-size: 1.25rem; } }
  @media (min-width: 48em) {
    .text-size-heading-6, .uol-typography-heading-6, .uol-global-marketing-banner__title, .uol-results-items__item__highlight, .uol-rich-text h6, .js .uolcc-container .uolcc-cookie-detail__checkbox-container,
    .js .uolcc-prompt-container .uolcc-cookie-detail__checkbox-container,
    .js .uolcc-privacy-update-container .uolcc-cookie-detail__checkbox-container, .uol-filters-panel__title__count, .uol-multilink-results__position, .uol-people-profile__position, .uol-year-of-entry__title, .uol-year-of-entry__date {
      line-height: 1.75rem; } }
  @media (min-width: 64em) {
    .text-size-heading-6, .uol-typography-heading-6, .uol-global-marketing-banner__title, .uol-results-items__item__highlight, .uol-rich-text h6, .js .uolcc-container .uolcc-cookie-detail__checkbox-container,
    .js .uolcc-prompt-container .uolcc-cookie-detail__checkbox-container,
    .js .uolcc-privacy-update-container .uolcc-cookie-detail__checkbox-container, .uol-filters-panel__title__count, .uol-multilink-results__position, .uol-people-profile__position, .uol-year-of-entry__title, .uol-year-of-entry__date {
      line-height: 1.875rem; } }

.text-size-paragraph, .uol-form__input--file, .uol-info-list, .uol-key-facts__list, .uol-typography-paragraph, .uol-in-text-fact__item__description, .uol-article-highlight__subtitle, .uol-article-highlight__paragraph, .uol-hero-banner__lead, .uol-banner__item__lead, .uol-chips__button, .js .uolcc-container p,
.js .uolcc-prompt-container p,
.js .uolcc-privacy-update-container p, .js .uolcc-container ul,
.js .uolcc-prompt-container ul,
.js .uolcc-privacy-update-container ul, .js .uolcc-container .uolcc-prompt-inner p,
.js .uolcc-container .uolcc-privacy-update-inner p,
.js .uolcc-prompt-container .uolcc-prompt-inner p,
.js .uolcc-prompt-container .uolcc-privacy-update-inner p,
.js .uolcc-privacy-update-container .uolcc-prompt-inner p,
.js .uolcc-privacy-update-container .uolcc-privacy-update-inner p, .uol-form__input-wrapper__num-of-selected-message, .uol-form__files-list__item, .uol-global-marketing-banner__description, .uol-global-marketing-banner__link, .uol-page-nav__item__identifier, .uol-publications-list__further-link, .uol-typography-ol, .js .uolcc-container ol,
.js .uolcc-prompt-container ol,
.js .uolcc-privacy-update-container ol, .uol-typography-ul, .uol-in-text-cta .uol-in-text-cta__heading, .uol-in-text-cta .uol-in-text-cta__text, .uol-rich-text p:not(.uol-in-text-fact__item__description):not(.uol-in-text-cta__text), .uol-rich-text ul,
.uol-rich-text ol, .js .uol-accordion__title, .uol-chips, .uol-form__input-label__text, .uol-form__input, .uol-form__custom__legend, .uol-global-masthead__search-form, .uol-header-local-masthead__link--title, .js .uol-header-local-navigation__link, .uol-header-local-navigation__subnav-button, .in-text-icon .uol-icon__label, .uol-profile-supplement__link, .uol-publications-list__publication-title, .uol-widget__arrow-link, .uol-year-of-entry__link {
  font-size: 1.125rem;
  line-height: 1.75rem; }
  @media (min-width: 48em) {
    .text-size-paragraph, .uol-form__input--file, .uol-info-list, .uol-key-facts__list, .uol-typography-paragraph, .uol-in-text-fact__item__description, .uol-article-highlight__subtitle, .uol-article-highlight__paragraph, .uol-hero-banner__lead, .uol-banner__item__lead, .uol-chips__button, .js .uolcc-container p,
    .js .uolcc-prompt-container p,
    .js .uolcc-privacy-update-container p, .js .uolcc-container ul,
    .js .uolcc-prompt-container ul,
    .js .uolcc-privacy-update-container ul, .js .uolcc-container .uolcc-prompt-inner p,
    .js .uolcc-container .uolcc-privacy-update-inner p,
    .js .uolcc-prompt-container .uolcc-prompt-inner p,
    .js .uolcc-prompt-container .uolcc-privacy-update-inner p,
    .js .uolcc-privacy-update-container .uolcc-prompt-inner p,
    .js .uolcc-privacy-update-container .uolcc-privacy-update-inner p, .uol-form__input-wrapper__num-of-selected-message, .uol-form__files-list__item, .uol-global-marketing-banner__description, .uol-global-marketing-banner__link, .uol-page-nav__item__identifier, .uol-publications-list__further-link, .uol-typography-ol, .js .uolcc-container ol,
    .js .uolcc-prompt-container ol,
    .js .uolcc-privacy-update-container ol, .uol-typography-ul, .uol-in-text-cta .uol-in-text-cta__heading, .uol-in-text-cta .uol-in-text-cta__text, .uol-rich-text p:not(.uol-in-text-fact__item__description):not(.uol-in-text-cta__text), .uol-rich-text ul,
    .uol-rich-text ol, .js .uol-accordion__title, .uol-chips, .uol-form__input-label__text, .uol-form__input, .uol-form__custom__legend, .uol-global-masthead__search-form, .uol-header-local-masthead__link--title, .js .uol-header-local-navigation__link, .uol-header-local-navigation__subnav-button, .in-text-icon .uol-icon__label, .uol-profile-supplement__link, .uol-publications-list__publication-title, .uol-widget__arrow-link, .uol-year-of-entry__link {
      font-size: 1.25rem; } }
  @media (min-width: 64em) {
    .text-size-paragraph, .uol-form__input--file, .uol-info-list, .uol-key-facts__list, .uol-typography-paragraph, .uol-in-text-fact__item__description, .uol-article-highlight__subtitle, .uol-article-highlight__paragraph, .uol-hero-banner__lead, .uol-banner__item__lead, .uol-chips__button, .js .uolcc-container p,
    .js .uolcc-prompt-container p,
    .js .uolcc-privacy-update-container p, .js .uolcc-container ul,
    .js .uolcc-prompt-container ul,
    .js .uolcc-privacy-update-container ul, .js .uolcc-container .uolcc-prompt-inner p,
    .js .uolcc-container .uolcc-privacy-update-inner p,
    .js .uolcc-prompt-container .uolcc-prompt-inner p,
    .js .uolcc-prompt-container .uolcc-privacy-update-inner p,
    .js .uolcc-privacy-update-container .uolcc-prompt-inner p,
    .js .uolcc-privacy-update-container .uolcc-privacy-update-inner p, .uol-form__input-wrapper__num-of-selected-message, .uol-form__files-list__item, .uol-global-marketing-banner__description, .uol-global-marketing-banner__link, .uol-page-nav__item__identifier, .uol-publications-list__further-link, .uol-typography-ol, .js .uolcc-container ol,
    .js .uolcc-prompt-container ol,
    .js .uolcc-privacy-update-container ol, .uol-typography-ul, .uol-in-text-cta .uol-in-text-cta__heading, .uol-in-text-cta .uol-in-text-cta__text, .uol-rich-text p:not(.uol-in-text-fact__item__description):not(.uol-in-text-cta__text), .uol-rich-text ul,
    .uol-rich-text ol, .js .uol-accordion__title, .uol-chips, .uol-form__input-label__text, .uol-form__input, .uol-form__custom__legend, .uol-global-masthead__search-form, .uol-header-local-masthead__link--title, .js .uol-header-local-navigation__link, .uol-header-local-navigation__subnav-button, .in-text-icon .uol-icon__label, .uol-profile-supplement__link, .uol-publications-list__publication-title, .uol-widget__arrow-link, .uol-year-of-entry__link {
      font-size: 1.25rem; } }
  @media (min-width: 48em) {
    .text-size-paragraph, .uol-form__input--file, .uol-info-list, .uol-key-facts__list, .uol-typography-paragraph, .uol-in-text-fact__item__description, .uol-article-highlight__subtitle, .uol-article-highlight__paragraph, .uol-hero-banner__lead, .uol-banner__item__lead, .uol-chips__button, .js .uolcc-container p,
    .js .uolcc-prompt-container p,
    .js .uolcc-privacy-update-container p, .js .uolcc-container ul,
    .js .uolcc-prompt-container ul,
    .js .uolcc-privacy-update-container ul, .js .uolcc-container .uolcc-prompt-inner p,
    .js .uolcc-container .uolcc-privacy-update-inner p,
    .js .uolcc-prompt-container .uolcc-prompt-inner p,
    .js .uolcc-prompt-container .uolcc-privacy-update-inner p,
    .js .uolcc-privacy-update-container .uolcc-prompt-inner p,
    .js .uolcc-privacy-update-container .uolcc-privacy-update-inner p, .uol-form__input-wrapper__num-of-selected-message, .uol-form__files-list__item, .uol-global-marketing-banner__description, .uol-global-marketing-banner__link, .uol-page-nav__item__identifier, .uol-publications-list__further-link, .uol-typography-ol, .js .uolcc-container ol,
    .js .uolcc-prompt-container ol,
    .js .uolcc-privacy-update-container ol, .uol-typography-ul, .uol-in-text-cta .uol-in-text-cta__heading, .uol-in-text-cta .uol-in-text-cta__text, .uol-rich-text p:not(.uol-in-text-fact__item__description):not(.uol-in-text-cta__text), .uol-rich-text ul,
    .uol-rich-text ol, .js .uol-accordion__title, .uol-chips, .uol-form__input-label__text, .uol-form__input, .uol-form__custom__legend, .uol-global-masthead__search-form, .uol-header-local-masthead__link--title, .js .uol-header-local-navigation__link, .uol-header-local-navigation__subnav-button, .in-text-icon .uol-icon__label, .uol-profile-supplement__link, .uol-publications-list__publication-title, .uol-widget__arrow-link, .uol-year-of-entry__link {
      line-height: 1.75rem; } }
  @media (min-width: 64em) {
    .text-size-paragraph, .uol-form__input--file, .uol-info-list, .uol-key-facts__list, .uol-typography-paragraph, .uol-in-text-fact__item__description, .uol-article-highlight__subtitle, .uol-article-highlight__paragraph, .uol-hero-banner__lead, .uol-banner__item__lead, .uol-chips__button, .js .uolcc-container p,
    .js .uolcc-prompt-container p,
    .js .uolcc-privacy-update-container p, .js .uolcc-container ul,
    .js .uolcc-prompt-container ul,
    .js .uolcc-privacy-update-container ul, .js .uolcc-container .uolcc-prompt-inner p,
    .js .uolcc-container .uolcc-privacy-update-inner p,
    .js .uolcc-prompt-container .uolcc-prompt-inner p,
    .js .uolcc-prompt-container .uolcc-privacy-update-inner p,
    .js .uolcc-privacy-update-container .uolcc-prompt-inner p,
    .js .uolcc-privacy-update-container .uolcc-privacy-update-inner p, .uol-form__input-wrapper__num-of-selected-message, .uol-form__files-list__item, .uol-global-marketing-banner__description, .uol-global-marketing-banner__link, .uol-page-nav__item__identifier, .uol-publications-list__further-link, .uol-typography-ol, .js .uolcc-container ol,
    .js .uolcc-prompt-container ol,
    .js .uolcc-privacy-update-container ol, .uol-typography-ul, .uol-in-text-cta .uol-in-text-cta__heading, .uol-in-text-cta .uol-in-text-cta__text, .uol-rich-text p:not(.uol-in-text-fact__item__description):not(.uol-in-text-cta__text), .uol-rich-text ul,
    .uol-rich-text ol, .js .uol-accordion__title, .uol-chips, .uol-form__input-label__text, .uol-form__input, .uol-form__custom__legend, .uol-global-masthead__search-form, .uol-header-local-masthead__link--title, .js .uol-header-local-navigation__link, .uol-header-local-navigation__subnav-button, .in-text-icon .uol-icon__label, .uol-profile-supplement__link, .uol-publications-list__publication-title, .uol-widget__arrow-link, .uol-year-of-entry__link {
      line-height: 2rem; } }

.text-size-paragraph--small, .uol-typography-paragraph-small, .uol-description-list__item__content, .event-card__dates,
.event-card__time, .uol-featured-image__caption, .uol-article .uol-featured-image__caption,
.uol-article figcaption, .uol-news-tile__meta, .page-heading__meta, .uol-publications-list .uol-info-list, .uol-publications-list__content-group, .uol-publications-list__link-container, .uol-publications-list__link, .uol-results-items__item__meta__label, .uol-results-items__item__meta__data, .uol-results-items__item__meta__group--has-additional .uol-results-items__item__meta__additional-data, .uol-results-items__item__summary,
.uol-results-items__item__introduction,
.uol-rich-text .uol-typography-paragraph-small, .uol-rich-text .uol-description-list__item__content, .uol-rich-text .event-card__dates,
.uol-rich-text .event-card__time, .uol-rich-text .uol-featured-image__caption,
.uol-rich-text .uol-article figcaption, .uol-article .uol-rich-text figcaption, .uol-rich-text .uol-news-tile__meta, .uol-rich-text .page-heading__meta, .uol-rich-text .uol-publications-list__content-group, .uol-rich-text .uol-publications-list__link-container, .uol-rich-text .uol-publications-list__link, .uol-rich-text .uol-results-items__item__meta__label, .uol-rich-text .uol-results-items__item__meta__data, .uol-rich-text .uol-results-items__item__summary,
.uol-rich-text .uol-results-items__item__introduction, .uol-footer-contact__name, .uol-footer-contact__address, .uol-footer-contact__telephone,
.uol-footer-contact__email, .uol-form__input--character-count, .uol-typeahead__item, .uol-form__input-label__hint, .uol-form__input__requirements, .uol-form__input--inline-field-wrapper .uol-form__input-label, .uol-form__input--inline-field-wrapper .uol-datepicker__range-label, .uol-index-table, .nav-list-group__title, .nav-list-group__list, .uol-multilink-results__paragraph, .uol-info-list, .uol-multilink-results__footer__messages, .uol-people-profile__card .uol-info-list, .uol-people-profile__footer__messages, .uol-tile__subtitle {
  font-size: 1rem;
  line-height: 1.5rem; }
  @media (min-width: 48em) {
    .text-size-paragraph--small, .uol-typography-paragraph-small, .uol-description-list__item__content, .event-card__dates,
    .event-card__time, .uol-featured-image__caption, .uol-article .uol-featured-image__caption,
    .uol-article figcaption, .uol-news-tile__meta, .page-heading__meta, .uol-publications-list .uol-info-list, .uol-publications-list__content-group, .uol-publications-list__link-container, .uol-publications-list__link, .uol-results-items__item__meta__label, .uol-results-items__item__meta__data, .uol-results-items__item__meta__group--has-additional .uol-results-items__item__meta__additional-data, .uol-results-items__item__summary,
    .uol-results-items__item__introduction,
    .uol-rich-text .uol-typography-paragraph-small, .uol-rich-text .uol-description-list__item__content, .uol-rich-text .event-card__dates,
    .uol-rich-text .event-card__time, .uol-rich-text .uol-featured-image__caption,
    .uol-rich-text .uol-article figcaption, .uol-article .uol-rich-text figcaption, .uol-rich-text .uol-news-tile__meta, .uol-rich-text .page-heading__meta, .uol-rich-text .uol-publications-list__content-group, .uol-rich-text .uol-publications-list__link-container, .uol-rich-text .uol-publications-list__link, .uol-rich-text .uol-results-items__item__meta__label, .uol-rich-text .uol-results-items__item__meta__data, .uol-rich-text .uol-results-items__item__summary,
    .uol-rich-text .uol-results-items__item__introduction, .uol-footer-contact__name, .uol-footer-contact__address, .uol-footer-contact__telephone,
    .uol-footer-contact__email, .uol-form__input--character-count, .uol-typeahead__item, .uol-form__input-label__hint, .uol-form__input__requirements, .uol-form__input--inline-field-wrapper .uol-form__input-label, .uol-form__input--inline-field-wrapper .uol-datepicker__range-label, .uol-index-table, .nav-list-group__title, .nav-list-group__list, .uol-multilink-results__paragraph, .uol-info-list, .uol-multilink-results__footer__messages, .uol-people-profile__card .uol-info-list, .uol-people-profile__footer__messages, .uol-tile__subtitle {
      font-size: 1rem; } }
  @media (min-width: 64em) {
    .text-size-paragraph--small, .uol-typography-paragraph-small, .uol-description-list__item__content, .event-card__dates,
    .event-card__time, .uol-featured-image__caption, .uol-article .uol-featured-image__caption,
    .uol-article figcaption, .uol-news-tile__meta, .page-heading__meta, .uol-publications-list .uol-info-list, .uol-publications-list__content-group, .uol-publications-list__link-container, .uol-publications-list__link, .uol-results-items__item__meta__label, .uol-results-items__item__meta__data, .uol-results-items__item__meta__group--has-additional .uol-results-items__item__meta__additional-data, .uol-results-items__item__summary,
    .uol-results-items__item__introduction,
    .uol-rich-text .uol-typography-paragraph-small, .uol-rich-text .uol-description-list__item__content, .uol-rich-text .event-card__dates,
    .uol-rich-text .event-card__time, .uol-rich-text .uol-featured-image__caption,
    .uol-rich-text .uol-article figcaption, .uol-article .uol-rich-text figcaption, .uol-rich-text .uol-news-tile__meta, .uol-rich-text .page-heading__meta, .uol-rich-text .uol-publications-list__content-group, .uol-rich-text .uol-publications-list__link-container, .uol-rich-text .uol-publications-list__link, .uol-rich-text .uol-results-items__item__meta__label, .uol-rich-text .uol-results-items__item__meta__data, .uol-rich-text .uol-results-items__item__summary,
    .uol-rich-text .uol-results-items__item__introduction, .uol-footer-contact__name, .uol-footer-contact__address, .uol-footer-contact__telephone,
    .uol-footer-contact__email, .uol-form__input--character-count, .uol-typeahead__item, .uol-form__input-label__hint, .uol-form__input__requirements, .uol-form__input--inline-field-wrapper .uol-form__input-label, .uol-form__input--inline-field-wrapper .uol-datepicker__range-label, .uol-index-table, .nav-list-group__title, .nav-list-group__list, .uol-multilink-results__paragraph, .uol-info-list, .uol-multilink-results__footer__messages, .uol-people-profile__card .uol-info-list, .uol-people-profile__footer__messages, .uol-tile__subtitle {
      font-size: 1.125rem; } }
  @media (min-width: 48em) {
    .text-size-paragraph--small, .uol-typography-paragraph-small, .uol-description-list__item__content, .event-card__dates,
    .event-card__time, .uol-featured-image__caption, .uol-article .uol-featured-image__caption,
    .uol-article figcaption, .uol-news-tile__meta, .page-heading__meta, .uol-publications-list .uol-info-list, .uol-publications-list__content-group, .uol-publications-list__link-container, .uol-publications-list__link, .uol-results-items__item__meta__label, .uol-results-items__item__meta__data, .uol-results-items__item__meta__group--has-additional .uol-results-items__item__meta__additional-data, .uol-results-items__item__summary,
    .uol-results-items__item__introduction,
    .uol-rich-text .uol-typography-paragraph-small, .uol-rich-text .uol-description-list__item__content, .uol-rich-text .event-card__dates,
    .uol-rich-text .event-card__time, .uol-rich-text .uol-featured-image__caption,
    .uol-rich-text .uol-article figcaption, .uol-article .uol-rich-text figcaption, .uol-rich-text .uol-news-tile__meta, .uol-rich-text .page-heading__meta, .uol-rich-text .uol-publications-list__content-group, .uol-rich-text .uol-publications-list__link-container, .uol-rich-text .uol-publications-list__link, .uol-rich-text .uol-results-items__item__meta__label, .uol-rich-text .uol-results-items__item__meta__data, .uol-rich-text .uol-results-items__item__summary,
    .uol-rich-text .uol-results-items__item__introduction, .uol-footer-contact__name, .uol-footer-contact__address, .uol-footer-contact__telephone,
    .uol-footer-contact__email, .uol-form__input--character-count, .uol-typeahead__item, .uol-form__input-label__hint, .uol-form__input__requirements, .uol-form__input--inline-field-wrapper .uol-form__input-label, .uol-form__input--inline-field-wrapper .uol-datepicker__range-label, .uol-index-table, .nav-list-group__title, .nav-list-group__list, .uol-multilink-results__paragraph, .uol-info-list, .uol-multilink-results__footer__messages, .uol-people-profile__card .uol-info-list, .uol-people-profile__footer__messages, .uol-tile__subtitle {
      line-height: 1.625rem; } }
  @media (min-width: 64em) {
    .text-size-paragraph--small, .uol-typography-paragraph-small, .uol-description-list__item__content, .event-card__dates,
    .event-card__time, .uol-featured-image__caption, .uol-article .uol-featured-image__caption,
    .uol-article figcaption, .uol-news-tile__meta, .page-heading__meta, .uol-publications-list .uol-info-list, .uol-publications-list__content-group, .uol-publications-list__link-container, .uol-publications-list__link, .uol-results-items__item__meta__label, .uol-results-items__item__meta__data, .uol-results-items__item__meta__group--has-additional .uol-results-items__item__meta__additional-data, .uol-results-items__item__summary,
    .uol-results-items__item__introduction,
    .uol-rich-text .uol-typography-paragraph-small, .uol-rich-text .uol-description-list__item__content, .uol-rich-text .event-card__dates,
    .uol-rich-text .event-card__time, .uol-rich-text .uol-featured-image__caption,
    .uol-rich-text .uol-article figcaption, .uol-article .uol-rich-text figcaption, .uol-rich-text .uol-news-tile__meta, .uol-rich-text .page-heading__meta, .uol-rich-text .uol-publications-list__content-group, .uol-rich-text .uol-publications-list__link-container, .uol-rich-text .uol-publications-list__link, .uol-rich-text .uol-results-items__item__meta__label, .uol-rich-text .uol-results-items__item__meta__data, .uol-rich-text .uol-results-items__item__summary,
    .uol-rich-text .uol-results-items__item__introduction, .uol-footer-contact__name, .uol-footer-contact__address, .uol-footer-contact__telephone,
    .uol-footer-contact__email, .uol-form__input--character-count, .uol-typeahead__item, .uol-form__input-label__hint, .uol-form__input__requirements, .uol-form__input--inline-field-wrapper .uol-form__input-label, .uol-form__input--inline-field-wrapper .uol-datepicker__range-label, .uol-index-table, .nav-list-group__title, .nav-list-group__list, .uol-multilink-results__paragraph, .uol-info-list, .uol-multilink-results__footer__messages, .uol-people-profile__card .uol-info-list, .uol-people-profile__footer__messages, .uol-tile__subtitle {
      line-height: 1.75rem; } }

.text-size-paragraph--intro, .uol-typography-paragraph-intro, .uol-rich-text--with-lead > p:first-of-type {
  font-size: 1.25rem;
  line-height: 1.75rem; }
  @media (min-width: 48em) {
    .text-size-paragraph--intro, .uol-typography-paragraph-intro, .uol-rich-text--with-lead > p:first-of-type {
      font-size: 1.375rem; } }
  @media (min-width: 64em) {
    .text-size-paragraph--intro, .uol-typography-paragraph-intro, .uol-rich-text--with-lead > p:first-of-type {
      font-size: 1.5rem; } }
  @media (min-width: 48em) {
    .text-size-paragraph--intro, .uol-typography-paragraph-intro, .uol-rich-text--with-lead > p:first-of-type {
      line-height: 2.125rem; } }
  @media (min-width: 64em) {
    .text-size-paragraph--intro, .uol-typography-paragraph-intro, .uol-rich-text--with-lead > p:first-of-type {
      line-height: 2.25rem; } }

.text-size-blockquote, .uol-typography-blockquote, .uol-typography-blockquote p, .uol-rich-text blockquote, .uol-rich-text blockquote p {
  font-size: 1.125rem;
  line-height: 1.75rem; }
  @media (min-width: 48em) {
    .text-size-blockquote, .uol-typography-blockquote, .uol-typography-blockquote p, .uol-rich-text blockquote, .uol-rich-text blockquote p {
      font-size: 1.5rem; } }
  @media (min-width: 64em) {
    .text-size-blockquote, .uol-typography-blockquote, .uol-typography-blockquote p, .uol-rich-text blockquote, .uol-rich-text blockquote p {
      font-size: 1.75rem; } }
  @media (min-width: 48em) {
    .text-size-blockquote, .uol-typography-blockquote, .uol-typography-blockquote p, .uol-rich-text blockquote, .uol-rich-text blockquote p {
      line-height: 2.25rem; } }
  @media (min-width: 64em) {
    .text-size-blockquote, .uol-typography-blockquote, .uol-typography-blockquote p, .uol-rich-text blockquote, .uol-rich-text blockquote p {
      line-height: 2.25rem; } }

.text-size-pullquote, .uol-typography-pull-quote {
  font-size: 1.125rem;
  line-height: 1.5rem; }
  @media (min-width: 48em) {
    .text-size-pullquote, .uol-typography-pull-quote {
      font-size: 1.25rem; } }
  @media (min-width: 64em) {
    .text-size-pullquote, .uol-typography-pull-quote {
      font-size: 1.25rem; } }
  @media (min-width: 48em) {
    .text-size-pullquote, .uol-typography-pull-quote {
      line-height: 1.625rem; } }
  @media (min-width: 64em) {
    .text-size-pullquote, .uol-typography-pull-quote {
      line-height: 1.75rem; } }

.text-size-caption, .uol-typography-blockquote footer, .uol-rich-text blockquote footer, .uol-rich-text .wp-caption-text,
.uol-rich-text figcaption, .uol-rich-text blockquote footer p,
.uol-rich-text blockquote footer cite, .uol-gallery-modal__image-caption, .uol-gallery__image-caption, .uol-image-pair .uol-image-pair__caption, .uol-pagination__status, .uol-results-items__item__meta {
  font-size: 1rem;
  line-height: 1.125rem; }

.collator-item {
  margin-bottom: 2rem; }

.collator-title {
  font-size: 18px;
  font-weight: 400; }

body {
  background-color: #fff;
  color: #212529; }

a {
  color: inherit; }

.uol-font-sans-serif--bold, .uol-typography-ol .uol-typography-ol__item::before, .js .uolcc-container ol .uol-typography-ol__item::before, .js .uolcc-prompt-container ol .uol-typography-ol__item::before, .js .uolcc-privacy-update-container ol .uol-typography-ol__item::before, .uol-rich-text strong, .uol-rich-text ol > li::before, .uol-article-highlight__subtitle, .uol-font-sans-serif--italic, .uol-typography-blockquote footer cite,
.uol-rich-text blockquote footer cite, .uol-rich-text blockquote footer p, body, .uol-font-sans-serif, .uol-typography-epic.uol-typography-epic--sans-serif, .uol-typography-heading-1.uol-typography-heading-1--sans-serif, .uol-typography-heading-1--sans-serif.event-card__date__start-date__day, .uol-typography-heading-1--sans-serif.page-heading__title__main, .uol-typography-heading-1--sans-serif.page-heading__title, .uol-typography-heading-2.uol-typography-heading-2--sans-serif, .uol-typography-heading-2--sans-serif.uol-hero-banner__title, .uol-typography-heading-2--sans-serif.uol-banner__item__title, .js .uolcc-container .uol-typography-heading-2--sans-serif.uolcc-title,
.js .uolcc-prompt-container .uol-typography-heading-2--sans-serif.uolcc-title,
.js .uolcc-privacy-update-container .uol-typography-heading-2--sans-serif.uolcc-title, .uol-typography-heading-2--sans-serif.uol-gallery-modal__info__title, .uol-typography-heading-2--sans-serif.uol-profile-supplement__title, .uol-typography-heading-2--sans-serif.uol-widget__title, .uol-typography-heading-3.uol-typography-heading-3--sans-serif, .uol-typography-heading-3--sans-serif.uol-in-text-facts__title, .js .uolcc-container .uol-typography-heading-3--sans-serif.uolcc-subtitle,
.js .uolcc-prompt-container .uol-typography-heading-3--sans-serif.uolcc-subtitle,
.js .uolcc-privacy-update-container .uol-typography-heading-3--sans-serif.uolcc-subtitle, .uol-article--profile .uol-article-section .uol-typography-heading-3--sans-serif.uol-article-section__title, .uol-typography-heading-4.uol-typography-heading-4--sans-serif, .uol-typography-heading-4--sans-serif.uol-in-text-fact__item__title, .uol-typography-heading-4--sans-serif.uol-news-tile__title, .uol-typography-heading-4--sans-serif.uol-results-items__item__link, .uol-typography-heading-5.uol-typography-heading-5--sans-serif, .uol-typography-heading-5--sans-serif.uol-description-list__item__title, .uol-typography-heading-5--sans-serif.event-card__title, .uol-typography-heading-5--sans-serif.uol-navigation-card__title, .uol-typography-heading-5--sans-serif.uol-page-nav__item__title, .uol-typography-heading-6.uol-typography-heading-6--sans-serif, .uol-typography-heading-6--sans-serif.uol-global-marketing-banner__title, .uol-typography-heading-6--sans-serif.uol-results-items__item__highlight, .uol-typography-paragraph-intro, .uol-rich-text--with-lead > p:first-of-type, .uol-typography-paragraph-small, .uol-description-list__item__content, .event-card__dates,
.event-card__time, .uol-featured-image__caption, .uol-article .uol-featured-image__caption,
.uol-article figcaption, .uol-news-tile__meta, .page-heading__meta, .uol-publications-list .uol-info-list, .uol-publications-list__content-group, .uol-publications-list__link-container, .uol-publications-list__link, .uol-results-items__item__meta__label, .uol-results-items__item__meta__data, .uol-results-items__item__meta__group--has-additional .uol-results-items__item__meta__additional-data, .uol-results-items__item__summary,
.uol-results-items__item__introduction,
.uol-rich-text .uol-typography-paragraph-small, .uol-rich-text .uol-description-list__item__content, .uol-rich-text .event-card__dates,
.uol-rich-text .event-card__time, .uol-rich-text .uol-featured-image__caption,
.uol-rich-text .uol-article figcaption, .uol-article .uol-rich-text figcaption, .uol-rich-text .uol-news-tile__meta, .uol-rich-text .page-heading__meta, .uol-rich-text .uol-publications-list .uol-info-list, .uol-publications-list .uol-rich-text .uol-info-list, .uol-rich-text .uol-publications-list__content-group, .uol-rich-text .uol-publications-list__link-container, .uol-rich-text .uol-publications-list__link, .uol-rich-text .uol-results-items__item__meta__label, .uol-rich-text .uol-results-items__item__meta__data, .uol-rich-text .uol-results-items__item__meta__group--has-additional .uol-results-items__item__meta__additional-data, .uol-results-items__item__meta__group--has-additional .uol-rich-text .uol-results-items__item__meta__additional-data, .uol-rich-text .uol-results-items__item__summary,
.uol-rich-text .uol-results-items__item__introduction, .uol-typography-paragraph, .uol-in-text-fact__item__description, .uol-article-highlight__paragraph, .uol-hero-banner__lead, .uol-banner__item__lead, .uol-chips__button, .js .uolcc-container p,
.js .uolcc-prompt-container p,
.js .uolcc-privacy-update-container p, .js .uolcc-container ul,
.js .uolcc-prompt-container ul,
.js .uolcc-privacy-update-container ul, .js .uolcc-container .uolcc-prompt-inner p,
.js .uolcc-container .uolcc-privacy-update-inner p,
.js .uolcc-prompt-container .uolcc-prompt-inner p,
.js .uolcc-prompt-container .uolcc-privacy-update-inner p,
.js .uolcc-privacy-update-container .uolcc-prompt-inner p,
.js .uolcc-privacy-update-container .uolcc-privacy-update-inner p, .uol-form__input-wrapper__num-of-selected-message, .uol-form__files-list__item, .uol-global-marketing-banner__description, .uol-global-marketing-banner__link, .uol-page-nav__item__identifier, .uol-publications-list__further-link, .uol-in-text-cta .uol-in-text-cta__heading, .uol-rich-text, .uol-rich-text .wp-caption-text,
.uol-rich-text figcaption, .js .uol-accordion__title, .js .uolcc-container .uolcc-cookie-detail__button-title,
.js .uolcc-prompt-container .uolcc-cookie-detail__button-title,
.js .uolcc-privacy-update-container .uolcc-cookie-detail__button-title, .js .uolcc-container .uolcc-button,
.js .uolcc-prompt-container .uolcc-button,
.js .uolcc-privacy-update-container .uolcc-button, .uol-filters-panel__title, .footer-supplement, .uol-navigation-card__description, .uol-publications-list__publication-title {
  font-family: "San Francisco", Roboto, "Helvetica Neue", Helvetica, "Noto Sans", "Segoe UI", Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-variant-numeric: lining-nums; }
  @media (min-width: 48em) {
    .uol-font-sans-serif--bold, .uol-typography-ol .uol-typography-ol__item::before, .js .uolcc-container ol .uol-typography-ol__item::before, .js .uolcc-prompt-container ol .uol-typography-ol__item::before, .js .uolcc-privacy-update-container ol .uol-typography-ol__item::before, .uol-rich-text strong, .uol-rich-text ol > li::before, .uol-article-highlight__subtitle, .uol-font-sans-serif--italic, .uol-typography-blockquote footer cite,
    .uol-rich-text blockquote footer cite, .uol-rich-text blockquote footer p, body, .uol-font-sans-serif, .uol-typography-epic.uol-typography-epic--sans-serif, .uol-typography-heading-1.uol-typography-heading-1--sans-serif, .uol-typography-heading-1--sans-serif.event-card__date__start-date__day, .uol-typography-heading-1--sans-serif.page-heading__title__main, .uol-typography-heading-1--sans-serif.page-heading__title, .uol-typography-heading-2.uol-typography-heading-2--sans-serif, .uol-typography-heading-2--sans-serif.uol-hero-banner__title, .uol-typography-heading-2--sans-serif.uol-banner__item__title, .js .uolcc-container .uol-typography-heading-2--sans-serif.uolcc-title,
    .js .uolcc-prompt-container .uol-typography-heading-2--sans-serif.uolcc-title,
    .js .uolcc-privacy-update-container .uol-typography-heading-2--sans-serif.uolcc-title, .uol-typography-heading-2--sans-serif.uol-gallery-modal__info__title, .uol-typography-heading-2--sans-serif.uol-profile-supplement__title, .uol-typography-heading-2--sans-serif.uol-widget__title, .uol-typography-heading-3.uol-typography-heading-3--sans-serif, .uol-typography-heading-3--sans-serif.uol-in-text-facts__title, .js .uolcc-container .uol-typography-heading-3--sans-serif.uolcc-subtitle,
    .js .uolcc-prompt-container .uol-typography-heading-3--sans-serif.uolcc-subtitle,
    .js .uolcc-privacy-update-container .uol-typography-heading-3--sans-serif.uolcc-subtitle, .uol-article--profile .uol-article-section .uol-typography-heading-3--sans-serif.uol-article-section__title, .uol-typography-heading-4.uol-typography-heading-4--sans-serif, .uol-typography-heading-4--sans-serif.uol-in-text-fact__item__title, .uol-typography-heading-4--sans-serif.uol-news-tile__title, .uol-typography-heading-4--sans-serif.uol-results-items__item__link, .uol-typography-heading-5.uol-typography-heading-5--sans-serif, .uol-typography-heading-5--sans-serif.uol-description-list__item__title, .uol-typography-heading-5--sans-serif.event-card__title, .uol-typography-heading-5--sans-serif.uol-navigation-card__title, .uol-typography-heading-5--sans-serif.uol-page-nav__item__title, .uol-typography-heading-6.uol-typography-heading-6--sans-serif, .uol-typography-heading-6--sans-serif.uol-global-marketing-banner__title, .uol-typography-heading-6--sans-serif.uol-results-items__item__highlight, .uol-typography-paragraph-intro, .uol-rich-text--with-lead > p:first-of-type, .uol-typography-paragraph-small, .uol-description-list__item__content, .event-card__dates,
    .event-card__time, .uol-featured-image__caption, .uol-article .uol-featured-image__caption,
    .uol-article figcaption, .uol-news-tile__meta, .page-heading__meta, .uol-publications-list .uol-info-list, .uol-publications-list__content-group, .uol-publications-list__link-container, .uol-publications-list__link, .uol-results-items__item__meta__label, .uol-results-items__item__meta__data, .uol-results-items__item__meta__group--has-additional .uol-results-items__item__meta__additional-data, .uol-results-items__item__summary,
    .uol-results-items__item__introduction,
    .uol-rich-text .uol-typography-paragraph-small, .uol-rich-text .uol-description-list__item__content, .uol-rich-text .event-card__dates,
    .uol-rich-text .event-card__time, .uol-rich-text .uol-featured-image__caption,
    .uol-rich-text .uol-article figcaption, .uol-article .uol-rich-text figcaption, .uol-rich-text .uol-news-tile__meta, .uol-rich-text .page-heading__meta, .uol-rich-text .uol-publications-list .uol-info-list, .uol-publications-list .uol-rich-text .uol-info-list, .uol-rich-text .uol-publications-list__content-group, .uol-rich-text .uol-publications-list__link-container, .uol-rich-text .uol-publications-list__link, .uol-rich-text .uol-results-items__item__meta__label, .uol-rich-text .uol-results-items__item__meta__data, .uol-rich-text .uol-results-items__item__meta__group--has-additional .uol-results-items__item__meta__additional-data, .uol-results-items__item__meta__group--has-additional .uol-rich-text .uol-results-items__item__meta__additional-data, .uol-rich-text .uol-results-items__item__summary,
    .uol-rich-text .uol-results-items__item__introduction, .uol-typography-paragraph, .uol-in-text-fact__item__description, .uol-article-highlight__paragraph, .uol-hero-banner__lead, .uol-banner__item__lead, .uol-chips__button, .js .uolcc-container p,
    .js .uolcc-prompt-container p,
    .js .uolcc-privacy-update-container p, .js .uolcc-container ul,
    .js .uolcc-prompt-container ul,
    .js .uolcc-privacy-update-container ul, .js .uolcc-container .uolcc-prompt-inner p,
    .js .uolcc-container .uolcc-privacy-update-inner p,
    .js .uolcc-prompt-container .uolcc-prompt-inner p,
    .js .uolcc-prompt-container .uolcc-privacy-update-inner p,
    .js .uolcc-privacy-update-container .uolcc-prompt-inner p,
    .js .uolcc-privacy-update-container .uolcc-privacy-update-inner p, .uol-form__input-wrapper__num-of-selected-message, .uol-form__files-list__item, .uol-global-marketing-banner__description, .uol-global-marketing-banner__link, .uol-page-nav__item__identifier, .uol-publications-list__further-link, .uol-in-text-cta .uol-in-text-cta__heading, .uol-rich-text, .uol-rich-text .wp-caption-text,
    .uol-rich-text figcaption, .js .uol-accordion__title, .js .uolcc-container .uolcc-cookie-detail__button-title,
    .js .uolcc-prompt-container .uolcc-cookie-detail__button-title,
    .js .uolcc-privacy-update-container .uolcc-cookie-detail__button-title, .js .uolcc-container .uolcc-button,
    .js .uolcc-prompt-container .uolcc-button,
    .js .uolcc-privacy-update-container .uolcc-button, .uol-filters-panel__title, .footer-supplement, .uol-navigation-card__description, .uol-publications-list__publication-title {
      font-family: freight-sans-pro, "San Francisco", Roboto, "Helvetica Neue", Helvetica, "Noto Sans", "Segoe UI", Arial, sans-serif; } }

.uol-font-sans-serif--bold, .uol-typography-ol .uol-typography-ol__item::before, .js .uolcc-container ol .uol-typography-ol__item::before, .js .uolcc-prompt-container ol .uol-typography-ol__item::before, .js .uolcc-privacy-update-container ol .uol-typography-ol__item::before, .uol-rich-text strong, .uol-rich-text ol > li::before, .uol-article-highlight__subtitle {
  font-weight: 600; }

.uol-font-sans-serif--italic, .uol-typography-blockquote footer cite,
.uol-rich-text blockquote footer cite, .uol-rich-text blockquote footer p {
  font-style: italic; }

.uol-font-serif, .uol-typography-epic, .uol-typography-heading-1, .event-card__date__start-date__day, .page-heading__title__main, .page-heading__title, .uol-typography-heading-2, .uol-hero-banner__title, .uol-banner__item__title, .js .uolcc-container .uolcc-title,
.js .uolcc-prompt-container .uolcc-title,
.js .uolcc-privacy-update-container .uolcc-title, .uol-gallery-modal__info__title, .uol-profile-supplement__title, .uol-widget__title, .uol-typography-heading-3, .uol-in-text-facts__title, .js .uolcc-container .uolcc-subtitle,
.js .uolcc-prompt-container .uolcc-subtitle,
.js .uolcc-privacy-update-container .uolcc-subtitle, .uol-article--profile .uol-article-section .uol-article-section__title, .uol-typography-heading-4, .uol-in-text-fact__item__title, .uol-news-tile__title, .uol-results-items__item__link, .uol-typography-heading-5, .uol-description-list__item__title, .event-card__title, .uol-navigation-card__title, .uol-page-nav__item__title, .uol-typography-heading-6, .uol-global-marketing-banner__title, .uol-results-items__item__highlight, .uol-typography-paragraph-intro.uol-typography-paragraph-intro--serif, .uol-rich-text--with-lead > p.uol-typography-paragraph-intro--serif:first-of-type, .uol-typography-paragraph-small.uol-typography-paragraph-small--serif, .uol-typography-paragraph-small--serif.uol-description-list__item__content, .uol-typography-paragraph-small--serif.event-card__dates,
.uol-typography-paragraph-small--serif.event-card__time, .uol-typography-paragraph-small--serif.uol-featured-image__caption,
.uol-article figcaption.uol-typography-paragraph-small--serif, .uol-typography-paragraph-small--serif.uol-news-tile__meta, .uol-typography-paragraph-small--serif.page-heading__meta, .uol-publications-list .uol-typography-paragraph-small--serif.uol-info-list, .uol-typography-paragraph-small--serif.uol-publications-list__content-group, .uol-typography-paragraph-small--serif.uol-publications-list__link-container, .uol-typography-paragraph-small--serif.uol-publications-list__link, .uol-typography-paragraph-small--serif.uol-results-items__item__meta__label, .uol-typography-paragraph-small--serif.uol-results-items__item__meta__data, .uol-results-items__item__meta__group--has-additional .uol-typography-paragraph-small--serif.uol-results-items__item__meta__additional-data, .uol-typography-paragraph-small--serif.uol-results-items__item__summary,
.uol-typography-paragraph-small--serif.uol-results-items__item__introduction,
.uol-rich-text .uol-typography-paragraph-small.uol-typography-paragraph-small--serif, .uol-rich-text .uol-typography-paragraph-small--serif.uol-description-list__item__content, .uol-rich-text .uol-typography-paragraph-small--serif.event-card__dates,
.uol-rich-text .uol-typography-paragraph-small--serif.event-card__time, .uol-rich-text .uol-typography-paragraph-small--serif.uol-featured-image__caption,
.uol-rich-text .uol-article figcaption.uol-typography-paragraph-small--serif, .uol-article .uol-rich-text figcaption.uol-typography-paragraph-small--serif, .uol-rich-text .uol-typography-paragraph-small--serif.uol-news-tile__meta, .uol-rich-text .uol-typography-paragraph-small--serif.page-heading__meta, .uol-rich-text .uol-publications-list .uol-typography-paragraph-small--serif.uol-info-list, .uol-publications-list .uol-rich-text .uol-typography-paragraph-small--serif.uol-info-list, .uol-rich-text .uol-typography-paragraph-small--serif.uol-publications-list__content-group, .uol-rich-text .uol-typography-paragraph-small--serif.uol-publications-list__link-container, .uol-rich-text .uol-typography-paragraph-small--serif.uol-publications-list__link, .uol-rich-text .uol-typography-paragraph-small--serif.uol-results-items__item__meta__label, .uol-rich-text .uol-typography-paragraph-small--serif.uol-results-items__item__meta__data, .uol-rich-text .uol-results-items__item__meta__group--has-additional .uol-typography-paragraph-small--serif.uol-results-items__item__meta__additional-data, .uol-results-items__item__meta__group--has-additional .uol-rich-text .uol-typography-paragraph-small--serif.uol-results-items__item__meta__additional-data, .uol-rich-text .uol-typography-paragraph-small--serif.uol-results-items__item__summary,
.uol-rich-text .uol-typography-paragraph-small--serif.uol-results-items__item__introduction, .uol-typography-paragraph.uol-typography-paragraph--serif, .uol-typography-paragraph--serif.uol-in-text-fact__item__description, .uol-typography-paragraph--serif.uol-article-highlight__subtitle, .uol-typography-paragraph--serif.uol-article-highlight__paragraph, .uol-typography-paragraph--serif.uol-hero-banner__lead, .uol-typography-paragraph--serif.uol-banner__item__lead, .uol-typography-paragraph--serif.uol-chips__button, .js .uolcc-container p.uol-typography-paragraph--serif,
.js .uolcc-prompt-container p.uol-typography-paragraph--serif,
.js .uolcc-privacy-update-container p.uol-typography-paragraph--serif, .js .uolcc-container ul.uol-typography-paragraph--serif,
.js .uolcc-prompt-container ul.uol-typography-paragraph--serif,
.js .uolcc-privacy-update-container ul.uol-typography-paragraph--serif, .uol-typography-paragraph--serif.uol-form__input-wrapper__num-of-selected-message, .uol-typography-paragraph--serif.uol-form__files-list__item, .uol-typography-paragraph--serif.uol-global-marketing-banner__description, .uol-typography-paragraph--serif.uol-global-marketing-banner__link, .uol-typography-paragraph--serif.uol-page-nav__item__identifier, .uol-typography-paragraph--serif.uol-publications-list__further-link, .uol-typography-blockquote, .uol-typography-pull-quote, .uol-rich-text h1, .uol-rich-text h2, .uol-rich-text h3,
.uol-rich-text h4:not(.uol-in-text-fact__item__title), .uol-rich-text h5, .uol-rich-text h6, .uol-rich-text blockquote, .uol-cards__card__title, .uol-facts-figures__headline, .uol-info-list__data--date, .page-heading__title.page-heading__title--subtitle, .page-heading__title--position, .uol-article--profile .page-heading__meta__data,
.uol-article--person-insight .page-heading__meta__data,
.uol-article--blog .page-heading__meta__data, .uol-tile__title {
  font-family: freight-text-pro, Georgia, Cambria, "Times New Roman", Times, serif;
  font-weight: 500; }
  @media (min-width: 48em) {
    .uol-font-serif, .uol-typography-epic, .uol-typography-heading-1, .event-card__date__start-date__day, .page-heading__title__main, .page-heading__title, .uol-typography-heading-2, .uol-hero-banner__title, .uol-banner__item__title, .js .uolcc-container .uolcc-title,
    .js .uolcc-prompt-container .uolcc-title,
    .js .uolcc-privacy-update-container .uolcc-title, .uol-gallery-modal__info__title, .uol-profile-supplement__title, .uol-widget__title, .uol-typography-heading-3, .uol-in-text-facts__title, .js .uolcc-container .uolcc-subtitle,
    .js .uolcc-prompt-container .uolcc-subtitle,
    .js .uolcc-privacy-update-container .uolcc-subtitle, .uol-article--profile .uol-article-section .uol-article-section__title, .uol-typography-heading-4, .uol-in-text-fact__item__title, .uol-news-tile__title, .uol-results-items__item__link, .uol-typography-heading-5, .uol-description-list__item__title, .event-card__title, .uol-navigation-card__title, .uol-page-nav__item__title, .uol-typography-heading-6, .uol-global-marketing-banner__title, .uol-results-items__item__highlight, .uol-typography-paragraph-intro.uol-typography-paragraph-intro--serif, .uol-rich-text--with-lead > p.uol-typography-paragraph-intro--serif:first-of-type, .uol-typography-paragraph-small.uol-typography-paragraph-small--serif, .uol-typography-paragraph-small--serif.uol-description-list__item__content, .uol-typography-paragraph-small--serif.event-card__dates,
    .uol-typography-paragraph-small--serif.event-card__time, .uol-typography-paragraph-small--serif.uol-featured-image__caption,
    .uol-article figcaption.uol-typography-paragraph-small--serif, .uol-typography-paragraph-small--serif.uol-news-tile__meta, .uol-typography-paragraph-small--serif.page-heading__meta, .uol-publications-list .uol-typography-paragraph-small--serif.uol-info-list, .uol-typography-paragraph-small--serif.uol-publications-list__content-group, .uol-typography-paragraph-small--serif.uol-publications-list__link-container, .uol-typography-paragraph-small--serif.uol-publications-list__link, .uol-typography-paragraph-small--serif.uol-results-items__item__meta__label, .uol-typography-paragraph-small--serif.uol-results-items__item__meta__data, .uol-results-items__item__meta__group--has-additional .uol-typography-paragraph-small--serif.uol-results-items__item__meta__additional-data, .uol-typography-paragraph-small--serif.uol-results-items__item__summary,
    .uol-typography-paragraph-small--serif.uol-results-items__item__introduction,
    .uol-rich-text .uol-typography-paragraph-small.uol-typography-paragraph-small--serif, .uol-rich-text .uol-typography-paragraph-small--serif.uol-description-list__item__content, .uol-rich-text .uol-typography-paragraph-small--serif.event-card__dates,
    .uol-rich-text .uol-typography-paragraph-small--serif.event-card__time, .uol-rich-text .uol-typography-paragraph-small--serif.uol-featured-image__caption,
    .uol-rich-text .uol-article figcaption.uol-typography-paragraph-small--serif, .uol-article .uol-rich-text figcaption.uol-typography-paragraph-small--serif, .uol-rich-text .uol-typography-paragraph-small--serif.uol-news-tile__meta, .uol-rich-text .uol-typography-paragraph-small--serif.page-heading__meta, .uol-rich-text .uol-publications-list .uol-typography-paragraph-small--serif.uol-info-list, .uol-publications-list .uol-rich-text .uol-typography-paragraph-small--serif.uol-info-list, .uol-rich-text .uol-typography-paragraph-small--serif.uol-publications-list__content-group, .uol-rich-text .uol-typography-paragraph-small--serif.uol-publications-list__link-container, .uol-rich-text .uol-typography-paragraph-small--serif.uol-publications-list__link, .uol-rich-text .uol-typography-paragraph-small--serif.uol-results-items__item__meta__label, .uol-rich-text .uol-typography-paragraph-small--serif.uol-results-items__item__meta__data, .uol-rich-text .uol-results-items__item__meta__group--has-additional .uol-typography-paragraph-small--serif.uol-results-items__item__meta__additional-data, .uol-results-items__item__meta__group--has-additional .uol-rich-text .uol-typography-paragraph-small--serif.uol-results-items__item__meta__additional-data, .uol-rich-text .uol-typography-paragraph-small--serif.uol-results-items__item__summary,
    .uol-rich-text .uol-typography-paragraph-small--serif.uol-results-items__item__introduction, .uol-typography-paragraph.uol-typography-paragraph--serif, .uol-typography-paragraph--serif.uol-in-text-fact__item__description, .uol-typography-paragraph--serif.uol-article-highlight__subtitle, .uol-typography-paragraph--serif.uol-article-highlight__paragraph, .uol-typography-paragraph--serif.uol-hero-banner__lead, .uol-typography-paragraph--serif.uol-banner__item__lead, .uol-typography-paragraph--serif.uol-chips__button, .js .uolcc-container p.uol-typography-paragraph--serif,
    .js .uolcc-prompt-container p.uol-typography-paragraph--serif,
    .js .uolcc-privacy-update-container p.uol-typography-paragraph--serif, .js .uolcc-container ul.uol-typography-paragraph--serif,
    .js .uolcc-prompt-container ul.uol-typography-paragraph--serif,
    .js .uolcc-privacy-update-container ul.uol-typography-paragraph--serif, .uol-typography-paragraph--serif.uol-form__input-wrapper__num-of-selected-message, .uol-typography-paragraph--serif.uol-form__files-list__item, .uol-typography-paragraph--serif.uol-global-marketing-banner__description, .uol-typography-paragraph--serif.uol-global-marketing-banner__link, .uol-typography-paragraph--serif.uol-page-nav__item__identifier, .uol-typography-paragraph--serif.uol-publications-list__further-link, .uol-typography-blockquote, .uol-typography-pull-quote, .uol-rich-text h1, .uol-rich-text h2, .uol-rich-text h3,
    .uol-rich-text h4:not(.uol-in-text-fact__item__title), .uol-rich-text h5, .uol-rich-text h6, .uol-rich-text blockquote, .uol-cards__card__title, .uol-facts-figures__headline, .uol-info-list__data--date, .page-heading__title.page-heading__title--subtitle, .page-heading__title--position, .uol-article--profile .page-heading__meta__data,
    .uol-article--person-insight .page-heading__meta__data,
    .uol-article--blog .page-heading__meta__data, .uol-tile__title {
      font-family: freight-text-pro, Georgia, Cambria, "Times New Roman", Times, serif;
      font-style: normal; } }

.uol-font-serif--bold, .uol-typography-blockquote::before, .uol-rich-text h1 strong,
.uol-rich-text h2 strong,
.uol-rich-text h3 strong,
.uol-rich-text h4:not(.uol-in-text-fact__item__title) strong,
.uol-rich-text h5 strong,
.uol-rich-text h6 strong, .uol-rich-text blockquote::before, .uol-article-highlight__title, .uol-tile__profile-quote::before {
  font-family: freight-text-pro, Georgia, Cambria, "Times New Roman", Times, serif;
  font-weight: 800; }
  @media (min-width: 48em) {
    .uol-font-serif--bold, .uol-typography-blockquote::before, .uol-rich-text h1 strong,
    .uol-rich-text h2 strong,
    .uol-rich-text h3 strong,
    .uol-rich-text h4:not(.uol-in-text-fact__item__title) strong,
    .uol-rich-text h5 strong,
    .uol-rich-text h6 strong, .uol-rich-text blockquote::before, .uol-article-highlight__title, .uol-tile__profile-quote::before {
      font-family: freight-text-pro, Georgia, Cambria, "Times New Roman", Times, serif;
      font-style: normal; } }

.Prose {
  font-feature-settings: normal; }

.uol-typography-epic {
  font-weight: 800; }
  .uol-typography-epic.uol-typography-epic--sans-serif {
    font-weight: 600; }

.uol-typography-heading-1, .event-card__date__start-date__day, .page-heading__title__main, .page-heading__title {
  font-weight: 800; }
  .uol-typography-heading-1.uol-typography-heading-1--sans-serif, .uol-typography-heading-1--sans-serif.event-card__date__start-date__day, .uol-typography-heading-1--sans-serif.page-heading__title__main, .uol-typography-heading-1--sans-serif.page-heading__title {
    font-weight: 600; }

.uol-typography-heading-2, .uol-hero-banner__title, .uol-banner__item__title, .js .uolcc-container .uolcc-title,
.js .uolcc-prompt-container .uolcc-title,
.js .uolcc-privacy-update-container .uolcc-title, .uol-gallery-modal__info__title, .uol-profile-supplement__title, .uol-widget__title {
  font-weight: 800; }
  .uol-typography-heading-2.uol-typography-heading-2--sans-serif, .uol-typography-heading-2--sans-serif.uol-hero-banner__title, .uol-typography-heading-2--sans-serif.uol-banner__item__title, .js .uolcc-container .uol-typography-heading-2--sans-serif.uolcc-title,
  .js .uolcc-prompt-container .uol-typography-heading-2--sans-serif.uolcc-title,
  .js .uolcc-privacy-update-container .uol-typography-heading-2--sans-serif.uolcc-title, .uol-typography-heading-2--sans-serif.uol-gallery-modal__info__title, .uol-typography-heading-2--sans-serif.uol-profile-supplement__title, .uol-typography-heading-2--sans-serif.uol-widget__title {
    font-weight: 600; }

.uol-typography-heading-3, .uol-in-text-facts__title, .js .uolcc-container .uolcc-subtitle,
.js .uolcc-prompt-container .uolcc-subtitle,
.js .uolcc-privacy-update-container .uolcc-subtitle, .uol-article--profile .uol-article-section .uol-article-section__title {
  font-weight: 800; }
  .uol-typography-heading-3.uol-typography-heading-3--sans-serif, .uol-typography-heading-3--sans-serif.uol-in-text-facts__title, .js .uolcc-container .uol-typography-heading-3--sans-serif.uolcc-subtitle,
  .js .uolcc-prompt-container .uol-typography-heading-3--sans-serif.uolcc-subtitle,
  .js .uolcc-privacy-update-container .uol-typography-heading-3--sans-serif.uolcc-subtitle, .uol-article--profile .uol-article-section .uol-typography-heading-3--sans-serif.uol-article-section__title {
    font-weight: 600; }

.uol-typography-heading-4, .uol-in-text-fact__item__title, .uol-news-tile__title, .uol-results-items__item__link {
  font-weight: 800; }
  .uol-typography-heading-4.uol-typography-heading-4--sans-serif, .uol-typography-heading-4--sans-serif.uol-in-text-fact__item__title, .uol-typography-heading-4--sans-serif.uol-news-tile__title, .uol-typography-heading-4--sans-serif.uol-results-items__item__link {
    font-weight: 600; }

.uol-typography-heading-5, .uol-description-list__item__title, .event-card__title, .uol-navigation-card__title, .uol-page-nav__item__title {
  font-weight: 800; }
  .uol-typography-heading-5.uol-typography-heading-5--sans-serif, .uol-typography-heading-5--sans-serif.uol-description-list__item__title, .uol-typography-heading-5--sans-serif.event-card__title, .uol-typography-heading-5--sans-serif.uol-navigation-card__title, .uol-typography-heading-5--sans-serif.uol-page-nav__item__title {
    font-weight: 600; }

.uol-typography-heading-6, .uol-global-marketing-banner__title, .uol-results-items__item__highlight {
  font-weight: 800; }
  .uol-typography-heading-6.uol-typography-heading-6--sans-serif, .uol-typography-heading-6--sans-serif.uol-global-marketing-banner__title, .uol-typography-heading-6--sans-serif.uol-results-items__item__highlight {
    font-weight: 600; }

.uol-typography-ol, .js .uolcc-container ol,
.js .uolcc-prompt-container ol,
.js .uolcc-privacy-update-container ol {
  list-style: none;
  counter-reset: item;
  padding-left: 2em; }
  .uol-typography-ol .uol-typography-ol__item, .js .uolcc-container ol .uol-typography-ol__item, .js .uolcc-prompt-container ol .uol-typography-ol__item, .js .uolcc-privacy-update-container ol .uol-typography-ol__item {
    margin-bottom: 0.6em;
    position: relative;
    counter-increment: item; }
    .uol-typography-ol .uol-typography-ol__item::before, .js .uolcc-container ol .uol-typography-ol__item::before, .js .uolcc-prompt-container ol .uol-typography-ol__item::before, .js .uolcc-privacy-update-container ol .uol-typography-ol__item::before {
      content: counter(item) ".";
      font-variant-numeric: lining-nums;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      width: 2em;
      transform: translateX(-126%);
      text-align: right; }

.uol-typography-ul, .js .uolcc-container ul,
.js .uolcc-prompt-container ul,
.js .uolcc-privacy-update-container ul {
  padding-left: 2em; }
  .uol-typography-ul .uol-typography-ul__item, .js .uolcc-container ul .uol-typography-ul__item, .js .uolcc-prompt-container ul .uol-typography-ul__item, .js .uolcc-privacy-update-container ul .uol-typography-ul__item {
    margin-bottom: 0.6em; }

.uol-typography-blockquote {
  font-weight: 800;
  position: relative;
  margin: 2em 0 1em;
  padding: 0 1.75em; }
  @media (min-width: 48em) {
    .uol-typography-blockquote {
      padding: 0 1.7em; } }
  @media (min-width: 64em) {
    .uol-typography-blockquote {
      padding: 0 1.5em; } }
  .uol-typography-blockquote::before {
    content: "\201C";
    font-size: 400%;
    height: 1ex;
    display: block;
    position: absolute;
    top: -0.125em;
    left: 0;
    color: #c70000;
    line-height: 0.3 !important; }
  .uol-typography-blockquote p {
    margin-bottom: 0.5em; }
  .uol-typography-blockquote footer cite {
    color: #636363; }
    .uol-typography-blockquote footer cite a {
      color: inherit; }
      .uol-typography-blockquote footer cite a:hover, .uol-typography-blockquote footer cite a:focus {
        color: #212529;
        text-decoration-color: #c70000; }

.uol-typography-pull-quote {
  display: inline-block;
  font-weight: 800;
  padding: 1rem 1.5rem;
  margin-bottom: 2rem; }
  .uol-typography-pull-quote:last-child {
    margin-bottom: 0; }
  @media (max-width: 47.99em) {
    .uol-typography-pull-quote {
      border-left: 0.25rem solid #c70000; } }
  .uol-typography-pull-quote p:last-child {
    margin-bottom: 0; }

@media (min-width: 48em) {
  .uol-typography-pull-quote--left {
    text-align: left;
    border-left: 0.25rem solid #c70000; } }

@media (min-width: 48em) {
  .uol-typography-pull-quote--right {
    text-align: right;
    border-right: 0.25rem solid #c70000; } }

.uol-typography-hr, .uol-rich-text hr {
  border: none;
  margin: 1.5rem auto;
  height: 4px;
  width: 4px;
  position: relative;
  overflow: visible;
  background: #ff4e36;
  border-radius: 50%; }
  .uol-typography-hr::before, .uol-rich-text hr::before, .uol-typography-hr::after, .uol-rich-text hr::after {
    content: "";
    position: absolute;
    height: inherit;
    width: inherit;
    border-radius: 50%;
    background: #ff8a7a; }
  .uol-typography-hr::before, .uol-rich-text hr::before {
    left: -1.5rem; }
  .uol-typography-hr::after, .uol-rich-text hr::after {
    right: -1.5rem; }
  @media (min-width: 64em) {
    .uol-typography-hr, .uol-rich-text hr {
      margin: 2rem auto; } }

.uol-table-outer {
  position: relative;
  overflow: hidden; }
  .uol-table-outer::before, .uol-table-outer::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 3rem;
    transition: all 0.7s ease; }
  .uol-table-outer::before {
    left: -3rem;
    background: linear-gradient(to left, rgba(95, 87, 84, 0), rgba(95, 87, 84, 0.2) 67%, rgba(95, 87, 84, 0.3) 100%);
    z-index: 2; }
  .uol-table-outer::after {
    right: -3rem;
    background: linear-gradient(to right, rgba(95, 87, 84, 0), rgba(95, 87, 84, 0.2) 67%, rgba(95, 87, 84, 0.3) 100%); }
  .uol-table-outer.scrollable-left::before {
    left: 0; }
  .uol-table-outer.scrollable-right::after {
    right: 0; }

.uol-table-container {
  position: relative;
  max-width: 100%;
  overflow-x: auto; }

.uol-rich-text table {
  font-size: 1.125rem;
  border-collapse: collapse;
  margin-bottom: 1rem;
  font-variant-numeric: lining-nums; }
  .uol-rich-text table caption {
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: left; }
    @media (min-width: 25.885em) {
      .uol-rich-text table caption {
        margin-bottom: 0; } }
  .uol-rich-text table thead th {
    font-weight: 600;
    color: #0e0c0c;
    border-bottom: 2px solid #51504c; }
  .uol-rich-text table th,
  .uol-rich-text table td {
    padding: 1rem 2rem 1rem 0; }
  .uol-rich-text table th {
    text-align: left; }
    @media (min-width: 25.885em) {
      .uol-rich-text table th[scope=row] {
        border-bottom: 1px solid #c9c8c5; }
      .uol-rich-text table th:empty {
        border-bottom: none; } }
  .uol-rich-text table td {
    vertical-align: top;
    border-bottom: 1px solid #c9c8c5; }

/*
 * Stackable tables
 */
@media (max-width: 25.875em) {
  .uol-table--stacked {
    border-collapse: collapse;
    border-top: 0.123rem solid #51504c;
    min-width: 100%; }
    .uol-table--stacked thead {
      position: absolute !important;
      clip: rect(1px, 1px, 1px, 1px);
      height: 1px;
      width: 1px;
      overflow: hidden;
      left: -9999px; }
    .uol-table--stacked tbody tr {
      display: block;
      margin-bottom: 0;
      border-bottom: 1px solid #c9c8c5; }
      .uol-table--stacked tbody tr:first-of-type {
        border-top: 1px solid #c9c8c5; }
    .uol-table--stacked tbody td {
      display: block;
      color: #0e0c0c;
      padding: 1rem 0 0.5rem;
      border-bottom: none; } }

.uol-table__pseudo-th {
  display: block;
  font-weight: 600;
  color: #0e0c0c;
  margin-bottom: 4px; }
  @media (min-width: 25.885em) {
    .uol-table__pseudo-th {
      display: none; } }

.uol-rich-text .uol-in-text-ctas-wrapper {
  margin: 2rem 0; }
  @media (min-width: 48em) {
    .uol-rich-text .uol-in-text-ctas-wrapper {
      margin: 3rem 0; } }

.uol-in-text-cta {
  position: relative;
  box-sizing: border-box;
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  width: 100%;
  background-color: #ffeded;
  border: 2px solid #c70000;
  border-radius: 10px; }
  .uol-in-text-cta:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.12); }
  @media (min-width: 90em) {
    .uol-in-text-cta {
      margin-bottom: 2rem; } }
  @media (min-width: 64em) {
    .uol-widget .uol-in-text-cta {
      width: calc((100% + 1.5rem) / 12 * 9 - 1.5rem); } }
  @media (min-width: 90em) {
    .uol-widget .uol-in-text-cta {
      width: calc((100% + 2rem) / 12 * 7 - 2rem); } }
  @media (min-width: 64em) {
    .uol-side-nav-container--populated + .uol-homepage-content .uol-widget .uol-in-text-cta {
      width: calc(100% - 0.75rem - 4px); } }
  @media (min-width: 90em) {
    .uol-side-nav-container--populated + .uol-homepage-content .uol-widget .uol-in-text-cta {
      width: calc((100% + 2rem) / 9 * 7 - 2rem - 10px); } }
  .uol-in-text-cta .uol-in-text-cta__heading {
    margin: 0;
    color: #212529;
    line-height: 1.4;
    font-weight: 600; }
    @media (min-width: 90em) {
      .uol-in-text-cta .uol-in-text-cta__heading {
        line-height: 1.4; } }
  .uol-in-text-cta .uol-in-text-cta__link {
    text-decoration: none; }
    .uol-in-text-cta .uol-in-text-cta__link::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
    .uol-in-text-cta .uol-in-text-cta__link:hover, .uol-in-text-cta .uol-in-text-cta__link:focus-within {
      text-decoration: underline; }
    .uol-in-text-cta .uol-in-text-cta__link:focus-within {
      text-decoration-color: #ff4e36; }
  .uol-in-text-cta .uol-in-text-cta__text {
    line-height: 1.625;
    margin: 0.5rem 0 0;
    color: #3b3b3b; }
    @media (min-width: 90em) {
      .uol-in-text-cta .uol-in-text-cta__text {
        line-height: 1.556; } }

.uol-in-text-facts {
  margin-bottom: 1rem; }
  @media (min-width: 48em) {
    .uol-in-text-facts {
      margin-bottom: 2rem; } }

.uol-in-text-facts__title {
  color: #0e0c0c; }

.uol-in-text-facts__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.uol-in-text-fact__item {
  width: 100%;
  background-color: #026E7A;
  color: #ffffff;
  text-align: center;
  padding: 1rem 0 1.5rem;
  margin-bottom: 1rem; }
  @media (min-width: 48em) {
    .uol-in-text-fact__item:not(:only-child) {
      width: calc(50% - 1rem / 2);
      margin-bottom: 0; } }
  @media (min-width: 64em) {
    .uol-in-text-fact__item:not(:only-child) {
      width: calc(50% - 1.5rem / 2); } }
  @media (min-width: 90em) {
    .uol-in-text-fact__item:not(:only-child) {
      width: calc(50% - 2rem / 2); } }

.uol-in-text-fact__item__title {
  margin: 0 0 0.25rem 0;
  text-decoration: none; }

.uol-in-text-fact__item__description {
  margin: 0; }

.uol-in-text-fact__link {
  text-decoration: none; }
  .uol-in-text-fact__link:hover, .uol-in-text-fact__link:focus-within {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.12); }

.uol-in-text-fact__link .uol-in-text-fact__item__description {
  text-decoration: underline; }

.text-highlight {
  margin: 2rem 0;
  border: 4px solid #c70000;
  font-weight: 500;
  padding: 1rem;
  text-align: center; }
  @media (min-width: 48em) {
    .text-highlight {
      padding: 1rem 2rem; } }
  .text-highlight :first-child {
    margin-top: 0; }
  .text-highlight :last-child {
    margin-bottom: 0; }

.uol-rich-text {
  max-width: 47.5rem;
  color: #212529; }
  .uol-rich-text > :first-child {
    margin-top: 0; }
  .uol-rich-text > *,
  .uol-rich-text > * > * {
    max-width: 47.5rem; }
  .uol-rich-text img:not(.uol-logo-group-item__logo) {
    width: 100%;
    max-width: 47.5rem;
    height: auto; }
  .uol-rich-text h2 + h3, .uol-rich-text h3 + h4, .uol-rich-text h4 + h5, .uol-rich-text h5 + h6 {
    margin-top: 1rem; }
    @media (min-width: 48em) {
      .uol-rich-text h2 + h3, .uol-rich-text h3 + h4, .uol-rich-text h4 + h5, .uol-rich-text h5 + h6 {
        margin-top: 1.5rem; } }
  .uol-rich-text p + ul {
    margin-top: -16px !important; }
  .uol-rich-text .fluid-width-video-wrapper {
    width: 100%;
    max-width: none;
    margin-top: 2rem;
    margin-bottom: 2rem; }
    @media (min-width: 48em) {
      .uol-rich-text .fluid-width-video-wrapper {
        margin-top: 3rem;
        margin-bottom: 3rem; } }
    .uol-rich-text .fluid-width-video-wrapper iframe {
      width: 100%;
      max-width: none; }
  .uol-rich-text ul {
    margin-top: 0; }
  .uol-rich-text figure {
    width: 100%;
    margin-bottom: 2rem; }
    @media (min-width: 48em) {
      .uol-rich-text figure {
        margin-top: 1rem;
        margin-bottom: 3rem; } }
    .uol-rich-text figure img {
      margin-top: 0;
      margin-bottom: 0; }
  .uol-rich-text h1,
  .uol-rich-text h2,
  .uol-rich-text h3,
  .uol-rich-text h4:not(.uol-in-text-fact__item__title),
  .uol-rich-text h5,
  .uol-rich-text h6 {
    font-weight: 800;
    font-variant-numeric: lining-nums;
    margin: 2rem 0 0.5rem; }
    @media (min-width: 48em) {
      .uol-rich-text h1,
      .uol-rich-text h2,
      .uol-rich-text h3,
      .uol-rich-text h4:not(.uol-in-text-fact__item__title),
      .uol-rich-text h5,
      .uol-rich-text h6 {
        margin: 3rem 0 1rem; } }
  .uol-rich-text p:not(.uol-in-text-fact__item__description):not(.uol-in-text-cta__text) {
    margin-top: 0;
    margin-bottom: 2rem; }
  @media (max-width: 47.99em) {
    .uol-rich-text p:has(+ .uol-in-text-cta) {
      margin-bottom: 1.5rem !important; } }
  .uol-rich-text img + p {
    margin-top: 0; }
  .uol-rich-text blockquote {
    font-weight: 800;
    display: inline-block;
    position: relative;
    margin: 2rem 0;
    padding: 0 1.75em; }
    @media (min-width: 48em) {
      .uol-rich-text blockquote {
        padding: 0 1.7em;
        margin: 4rem 0 3rem; } }
    .uol-rich-text blockquote::before {
      content: "\201C";
      font-size: 400%;
      height: 1ex;
      display: block;
      position: absolute;
      top: -0.125em;
      left: 0;
      color: #c70000;
      line-height: 0.3; }
      @media (forced-colors: active) {
        .uol-rich-text blockquote::before {
          font-size: 300%; } }
    .uol-rich-text blockquote:first-child {
      margin-top: 1.5rem; }
      @media (min-width: 48em) {
        .uol-rich-text blockquote:first-child {
          margin-top: 3rem; } }
      @media (min-width: 64em) {
        .uol-rich-text blockquote:first-child {
          margin-top: 4rem; } }
    .uol-rich-text blockquote p {
      margin-bottom: 0.5em; }
    .uol-rich-text blockquote footer cite {
      color: #636363; }
      .uol-rich-text blockquote footer cite a {
        transition: background 0.15s ease-out, text-decoration 0.15s ease-out;
        color: inherit; }
        .uol-rich-text blockquote footer cite a:focus {
          color: #212529;
          background-color: #ffeded;
          text-decoration: underline;
          text-decoration-thickness: 3px;
          text-decoration-color: #ff4e36;
          text-underline-offset: 4px;
          outline: 2px dotted inherit;
          outline-offset: 1px;
          outline-color: transparent; }
        .uol-rich-text blockquote footer cite a:focus:not(:focus-visible) {
          text-decoration: none;
          outline-color: transparent; }
        .uol-rich-text blockquote footer cite a:focus-visible {
          color: #212529;
          outline-color: transparent;
          outline-offset: 1px;
          background-color: #ffeded; }
        .uol-rich-text blockquote footer cite a:hover {
          text-decoration-color: #c70000; }
  .uol-rich-text ul,
  .uol-rich-text ol {
    padding-left: 35px;
    margin-bottom: 1.5rem; }
    @media (min-width: 48em) {
      .uol-rich-text ul,
      .uol-rich-text ol {
        padding-left: 51px; } }
    .uol-rich-text ul li,
    .uol-rich-text ol li {
      margin-bottom: 0.5rem; }
    .uol-rich-text ul ul,
    .uol-rich-text ul ol,
    .uol-rich-text ol ul,
    .uol-rich-text ol ol {
      margin-top: 0.5em;
      margin-bottom: 0; }
      .uol-rich-text ul ul :first-child,
      .uol-rich-text ul ol :first-child,
      .uol-rich-text ol ul :first-child,
      .uol-rich-text ol ol :first-child {
        margin-top: 0.5em; }
  .uol-rich-text ol {
    list-style: none;
    counter-reset: item; }
    .uol-rich-text ol > li {
      position: relative;
      counter-increment: item; }
      .uol-rich-text ol > li::before {
        content: counter(item) ".";
        font-variant-numeric: lining-nums;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        width: 2em;
        transform: translateX(-126%);
        text-align: right; }
  .uol-rich-text p a,
  .uol-rich-text li a {
    transition: background 0.15s ease-out, text-decoration 0.15s ease-out; }
    .uol-rich-text p a:focus,
    .uol-rich-text li a:focus {
      color: #212529;
      background-color: #ffeded;
      text-decoration: underline;
      text-decoration-thickness: 3px;
      text-decoration-color: #ff4e36;
      text-underline-offset: 4px;
      outline: 2px dotted inherit;
      outline-offset: 1px;
      outline-color: transparent; }
    .uol-rich-text p a:focus:not(:focus-visible),
    .uol-rich-text li a:focus:not(:focus-visible) {
      text-decoration: none;
      outline-color: transparent; }
    .uol-rich-text p a:focus-visible,
    .uol-rich-text li a:focus-visible {
      color: #212529;
      outline-color: transparent;
      outline-offset: 1px;
      background-color: #ffeded; }
  .uol-rich-text .wp-caption-text,
  .uol-rich-text figcaption {
    color: #636363; }
    .uol-rich-text .wp-caption-text a,
    .uol-rich-text figcaption a {
      transition: background 0.15s ease-out, text-decoration 0.15s ease-out;
      font-style: italic;
      color: inherit; }
      .uol-rich-text .wp-caption-text a:focus,
      .uol-rich-text figcaption a:focus {
        color: #212529;
        background-color: #ffeded;
        text-decoration: underline;
        text-decoration-thickness: 3px;
        text-decoration-color: #ff4e36;
        text-underline-offset: 4px;
        outline: 2px dotted inherit;
        outline-offset: 1px;
        outline-color: transparent; }
      .uol-rich-text .wp-caption-text a:focus:not(:focus-visible),
      .uol-rich-text figcaption a:focus:not(:focus-visible) {
        text-decoration: none;
        outline-color: transparent; }
      .uol-rich-text .wp-caption-text a:focus-visible,
      .uol-rich-text figcaption a:focus-visible {
        color: #212529;
        outline-color: transparent;
        outline-offset: 1px;
        background-color: #ffeded; }
  .uol-rich-text blockquote footer p,
  .uol-rich-text blockquote footer cite {
    color: #636363; }

.uol-rich-text--with-lead > p:first-of-type {
  font-weight: 600; }

.uol-col-container, .footer-supplement, .uol-site-footer, .uol-site-footer__nav-container .uol-site-footer__nav-outer, .uol-homepage-content-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0.5rem; }
  @media (min-width: 64em) {
    .uol-col-container, .footer-supplement, .uol-site-footer, .uol-site-footer__nav-container .uol-site-footer__nav-outer, .uol-homepage-content-container {
      padding: 0.75rem; } }
  @media (min-width: 90em) {
    .uol-col-container, .footer-supplement, .uol-site-footer, .uol-site-footer__nav-container .uol-site-footer__nav-outer, .uol-homepage-content-container {
      padding: 1rem; } }
  .uol-col-container > [class^="uol-col-"], .footer-supplement > [class^="uol-col-"], .uol-site-footer > [class^="uol-col-"], .uol-site-footer__nav-container .uol-site-footer__nav-outer > [class^="uol-col-"], .uol-homepage-content-container > [class^="uol-col-"],
  .uol-col-container .uol-col,
  .footer-supplement .uol-col,
  .uol-site-footer .uol-col,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-col,
  .uol-homepage-content-container .uol-col,
  .uol-col-container .uol-page-width,
  .footer-supplement .uol-page-width,
  .uol-site-footer .uol-page-width,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-page-width,
  .uol-homepage-content-container .uol-page-width,
  .uol-col-container .uol-page-width--wide-content,
  .footer-supplement .uol-page-width--wide-content,
  .uol-site-footer .uol-page-width--wide-content,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-page-width--wide-content,
  .uol-homepage-content-container .uol-page-width--wide-content,
  .uol-col-container .uol-page-width--x-wide-content,
  .footer-supplement .uol-page-width--x-wide-content,
  .uol-site-footer .uol-page-width--x-wide-content,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-page-width--x-wide-content,
  .uol-homepage-content-container .uol-page-width--x-wide-content,
  .uol-col-container .uol-page-width--index,
  .footer-supplement .uol-page-width--index,
  .uol-site-footer .uol-page-width--index,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-page-width--index,
  .uol-homepage-content-container .uol-page-width--index,
  .uol-col-container .uol-page-width--full,
  .footer-supplement .uol-page-width--full,
  .uol-site-footer .uol-page-width--full,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-page-width--full,
  .uol-homepage-content-container .uol-page-width--full,
  .uol-col-container .uol-form-container--centered,
  .footer-supplement .uol-form-container--centered,
  .uol-site-footer .uol-form-container--centered,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-form-container--centered,
  .uol-homepage-content-container .uol-form-container--centered,
  .uol-col-container .uol-side-nav-container--populated,
  .footer-supplement .uol-side-nav-container--populated,
  .uol-site-footer .uol-side-nav-container--populated,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-side-nav-container--populated,
  .uol-homepage-content-container .uol-side-nav-container--populated,
  .uol-col-container .uol-widget--ctas,
  .footer-supplement .uol-widget--ctas,
  .uol-site-footer .uol-widget--ctas,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--ctas,
  .uol-homepage-content-container .uol-widget--ctas,
  .uol-col-container .uol-widget--content,
  .footer-supplement .uol-widget--content,
  .uol-site-footer .uol-widget--content,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--content,
  .uol-homepage-content-container .uol-widget--content,
  .uol-col-container .uol-widget--featured-content,
  .footer-supplement .uol-widget--featured-content,
  .uol-site-footer .uol-widget--featured-content,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--featured-content,
  .uol-homepage-content-container .uol-widget--featured-content,
  .uol-col-container .uol-widget--form,
  .footer-supplement .uol-widget--form,
  .uol-site-footer .uol-widget--form,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--form,
  .uol-homepage-content-container .uol-widget--form,
  .uol-col-container .uol-widget--news-events,
  .footer-supplement .uol-widget--news-events,
  .uol-site-footer .uol-widget--news-events,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--news-events,
  .uol-homepage-content-container .uol-widget--news-events,
  .uol-col-container .uol-widget--news-tiles,
  .footer-supplement .uol-widget--news-tiles,
  .uol-site-footer .uol-widget--news-tiles,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--news-tiles,
  .uol-homepage-content-container .uol-widget--news-tiles,
  .uol-col-container .uol-widget--events,
  .footer-supplement .uol-widget--events,
  .uol-site-footer .uol-widget--events,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--events,
  .uol-homepage-content-container .uol-widget--events,
  .uol-col-container .uol-widget--description-list,
  .footer-supplement .uol-widget--description-list,
  .uol-site-footer .uol-widget--description-list,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--description-list,
  .uol-homepage-content-container .uol-widget--description-list,
  .uol-col-container .uol-widget--accreditation-logos,
  .footer-supplement .uol-widget--accreditation-logos,
  .uol-site-footer .uol-widget--accreditation-logos,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--accreditation-logos,
  .uol-homepage-content-container .uol-widget--accreditation-logos,
  .uol-col-container .uol-widget--wide,
  .footer-supplement .uol-widget--wide,
  .uol-site-footer .uol-widget--wide,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--wide,
  .uol-homepage-content-container .uol-widget--wide,
  .uol-col-container .uol-article,
  .footer-supplement .uol-article,
  .uol-site-footer .uol-article,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-article,
  .uol-homepage-content-container .uol-article,
  .uol-col-container .uol-site-footer__nav-container,
  .footer-supplement .uol-site-footer__nav-container,
  .uol-site-footer .uol-site-footer__nav-container,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-site-footer__nav-container,
  .uol-homepage-content-container .uol-site-footer__nav-container,
  .uol-col-container .uol-site-footer__nav-container .uol-site-footer__nav,
  .uol-site-footer__nav-container .uol-col-container .uol-site-footer__nav,
  .footer-supplement .uol-site-footer__nav-container .uol-site-footer__nav,
  .uol-site-footer__nav-container .footer-supplement .uol-site-footer__nav,
  .uol-site-footer .uol-site-footer__nav-container .uol-site-footer__nav,
  .uol-site-footer__nav-container .uol-site-footer .uol-site-footer__nav,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-site-footer__nav,
  .uol-homepage-content-container .uol-site-footer__nav-container .uol-site-footer__nav,
  .uol-site-footer__nav-container .uol-homepage-content-container .uol-site-footer__nav,
  .uol-col-container .uol-site-footer__address-container,
  .footer-supplement .uol-site-footer__address-container,
  .uol-site-footer .uol-site-footer__address-container,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-site-footer__address-container,
  .uol-homepage-content-container .uol-site-footer__address-container,
  .uol-col-container .uol-site-footer__accreditations,
  .footer-supplement .uol-site-footer__accreditations,
  .uol-site-footer .uol-site-footer__accreditations,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-site-footer__accreditations,
  .uol-homepage-content-container .uol-site-footer__accreditations,
  .uol-col-container .uol-site-footer__site-information-container,
  .footer-supplement .uol-site-footer__site-information-container,
  .uol-site-footer .uol-site-footer__site-information-container,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-site-footer__site-information-container,
  .uol-homepage-content-container .uol-site-footer__site-information-container,
  .uol-col-container .uol-site-footer__logo-outer,
  .footer-supplement .uol-site-footer__logo-outer,
  .uol-site-footer .uol-site-footer__logo-outer,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-site-footer__logo-outer,
  .uol-homepage-content-container .uol-site-footer__logo-outer,
  .uol-col-container .uol-side-nav-container--populated + .uol-homepage-content,
  .footer-supplement .uol-side-nav-container--populated + .uol-homepage-content,
  .uol-site-footer .uol-side-nav-container--populated + .uol-homepage-content,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-side-nav-container--populated + .uol-homepage-content,
  .uol-homepage-content-container .uol-side-nav-container--populated + .uol-homepage-content,
  .uol-col-container .uol-search-results__page-heading,
  .footer-supplement .uol-search-results__page-heading,
  .uol-site-footer .uol-search-results__page-heading,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-search-results__page-heading,
  .uol-homepage-content-container .uol-search-results__page-heading,
  .uol-col-container .uol-search-results__content-area,
  .footer-supplement .uol-search-results__content-area,
  .uol-site-footer .uol-search-results__content-area,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-search-results__content-area,
  .uol-homepage-content-container .uol-search-results__content-area,
  .uol-col-container .uol-search-results__main,
  .footer-supplement .uol-search-results__main,
  .uol-site-footer .uol-search-results__main,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-search-results__main,
  .uol-homepage-content-container .uol-search-results__main,
  .uol-col-container .uol-search-result__filters,
  .footer-supplement .uol-search-result__filters,
  .uol-site-footer .uol-search-result__filters,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-search-result__filters,
  .uol-homepage-content-container .uol-search-result__filters,
  .uol-col-container .uol-staff-a-z,
  .footer-supplement .uol-staff-a-z,
  .uol-site-footer .uol-staff-a-z,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-staff-a-z,
  .uol-homepage-content-container .uol-staff-a-z,
  .uol-col-container .uol-page,
  .footer-supplement .uol-page,
  .uol-site-footer .uol-page,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-page,
  .uol-homepage-content-container .uol-page,
  .uol-col-container .uol-page--index,
  .footer-supplement .uol-page--index,
  .uol-site-footer .uol-page--index,
  .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-page--index,
  .uol-homepage-content-container .uol-page--index {
    flex-basis: 100%;
    width: 100%; }
    @media (min-width: 25.875em) {
      .uol-col-container > [class^="uol-col-"], .footer-supplement > [class^="uol-col-"], .uol-site-footer > [class^="uol-col-"], .uol-site-footer__nav-container .uol-site-footer__nav-outer > [class^="uol-col-"], .uol-homepage-content-container > [class^="uol-col-"],
      .uol-col-container .uol-col,
      .footer-supplement .uol-col,
      .uol-site-footer .uol-col,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-col,
      .uol-homepage-content-container .uol-col,
      .uol-col-container .uol-page-width,
      .footer-supplement .uol-page-width,
      .uol-site-footer .uol-page-width,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-page-width,
      .uol-homepage-content-container .uol-page-width,
      .uol-col-container .uol-page-width--wide-content,
      .footer-supplement .uol-page-width--wide-content,
      .uol-site-footer .uol-page-width--wide-content,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-page-width--wide-content,
      .uol-homepage-content-container .uol-page-width--wide-content,
      .uol-col-container .uol-page-width--x-wide-content,
      .footer-supplement .uol-page-width--x-wide-content,
      .uol-site-footer .uol-page-width--x-wide-content,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-page-width--x-wide-content,
      .uol-homepage-content-container .uol-page-width--x-wide-content,
      .uol-col-container .uol-page-width--index,
      .footer-supplement .uol-page-width--index,
      .uol-site-footer .uol-page-width--index,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-page-width--index,
      .uol-homepage-content-container .uol-page-width--index,
      .uol-col-container .uol-page-width--full,
      .footer-supplement .uol-page-width--full,
      .uol-site-footer .uol-page-width--full,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-page-width--full,
      .uol-homepage-content-container .uol-page-width--full,
      .uol-col-container .uol-form-container--centered,
      .footer-supplement .uol-form-container--centered,
      .uol-site-footer .uol-form-container--centered,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-form-container--centered,
      .uol-homepage-content-container .uol-form-container--centered,
      .uol-col-container .uol-side-nav-container--populated,
      .footer-supplement .uol-side-nav-container--populated,
      .uol-site-footer .uol-side-nav-container--populated,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-side-nav-container--populated,
      .uol-homepage-content-container .uol-side-nav-container--populated,
      .uol-col-container .uol-widget--ctas,
      .footer-supplement .uol-widget--ctas,
      .uol-site-footer .uol-widget--ctas,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--ctas,
      .uol-homepage-content-container .uol-widget--ctas,
      .uol-col-container .uol-widget--content,
      .footer-supplement .uol-widget--content,
      .uol-site-footer .uol-widget--content,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--content,
      .uol-homepage-content-container .uol-widget--content,
      .uol-col-container .uol-widget--featured-content,
      .footer-supplement .uol-widget--featured-content,
      .uol-site-footer .uol-widget--featured-content,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--featured-content,
      .uol-homepage-content-container .uol-widget--featured-content,
      .uol-col-container .uol-widget--form,
      .footer-supplement .uol-widget--form,
      .uol-site-footer .uol-widget--form,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--form,
      .uol-homepage-content-container .uol-widget--form,
      .uol-col-container .uol-widget--news-events,
      .footer-supplement .uol-widget--news-events,
      .uol-site-footer .uol-widget--news-events,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--news-events,
      .uol-homepage-content-container .uol-widget--news-events,
      .uol-col-container .uol-widget--news-tiles,
      .footer-supplement .uol-widget--news-tiles,
      .uol-site-footer .uol-widget--news-tiles,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--news-tiles,
      .uol-homepage-content-container .uol-widget--news-tiles,
      .uol-col-container .uol-widget--events,
      .footer-supplement .uol-widget--events,
      .uol-site-footer .uol-widget--events,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--events,
      .uol-homepage-content-container .uol-widget--events,
      .uol-col-container .uol-widget--description-list,
      .footer-supplement .uol-widget--description-list,
      .uol-site-footer .uol-widget--description-list,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--description-list,
      .uol-homepage-content-container .uol-widget--description-list,
      .uol-col-container .uol-widget--accreditation-logos,
      .footer-supplement .uol-widget--accreditation-logos,
      .uol-site-footer .uol-widget--accreditation-logos,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--accreditation-logos,
      .uol-homepage-content-container .uol-widget--accreditation-logos,
      .uol-col-container .uol-widget--wide,
      .footer-supplement .uol-widget--wide,
      .uol-site-footer .uol-widget--wide,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--wide,
      .uol-homepage-content-container .uol-widget--wide,
      .uol-col-container .uol-article,
      .footer-supplement .uol-article,
      .uol-site-footer .uol-article,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-article,
      .uol-homepage-content-container .uol-article,
      .uol-col-container .uol-site-footer__nav-container,
      .footer-supplement .uol-site-footer__nav-container,
      .uol-site-footer .uol-site-footer__nav-container,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-site-footer__nav-container,
      .uol-homepage-content-container .uol-site-footer__nav-container,
      .uol-col-container .uol-site-footer__nav-container .uol-site-footer__nav,
      .uol-site-footer__nav-container .uol-col-container .uol-site-footer__nav,
      .footer-supplement .uol-site-footer__nav-container .uol-site-footer__nav,
      .uol-site-footer__nav-container .footer-supplement .uol-site-footer__nav,
      .uol-site-footer .uol-site-footer__nav-container .uol-site-footer__nav,
      .uol-site-footer__nav-container .uol-site-footer .uol-site-footer__nav,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-site-footer__nav,
      .uol-homepage-content-container .uol-site-footer__nav-container .uol-site-footer__nav,
      .uol-site-footer__nav-container .uol-homepage-content-container .uol-site-footer__nav,
      .uol-col-container .uol-site-footer__address-container,
      .footer-supplement .uol-site-footer__address-container,
      .uol-site-footer .uol-site-footer__address-container,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-site-footer__address-container,
      .uol-homepage-content-container .uol-site-footer__address-container,
      .uol-col-container .uol-site-footer__accreditations,
      .footer-supplement .uol-site-footer__accreditations,
      .uol-site-footer .uol-site-footer__accreditations,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-site-footer__accreditations,
      .uol-homepage-content-container .uol-site-footer__accreditations,
      .uol-col-container .uol-site-footer__site-information-container,
      .footer-supplement .uol-site-footer__site-information-container,
      .uol-site-footer .uol-site-footer__site-information-container,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-site-footer__site-information-container,
      .uol-homepage-content-container .uol-site-footer__site-information-container,
      .uol-col-container .uol-site-footer__logo-outer,
      .footer-supplement .uol-site-footer__logo-outer,
      .uol-site-footer .uol-site-footer__logo-outer,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-site-footer__logo-outer,
      .uol-homepage-content-container .uol-site-footer__logo-outer,
      .uol-col-container .uol-side-nav-container--populated + .uol-homepage-content,
      .footer-supplement .uol-side-nav-container--populated + .uol-homepage-content,
      .uol-site-footer .uol-side-nav-container--populated + .uol-homepage-content,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-side-nav-container--populated + .uol-homepage-content,
      .uol-homepage-content-container .uol-side-nav-container--populated + .uol-homepage-content,
      .uol-col-container .uol-search-results__page-heading,
      .footer-supplement .uol-search-results__page-heading,
      .uol-site-footer .uol-search-results__page-heading,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-search-results__page-heading,
      .uol-homepage-content-container .uol-search-results__page-heading,
      .uol-col-container .uol-search-results__content-area,
      .footer-supplement .uol-search-results__content-area,
      .uol-site-footer .uol-search-results__content-area,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-search-results__content-area,
      .uol-homepage-content-container .uol-search-results__content-area,
      .uol-col-container .uol-search-results__main,
      .footer-supplement .uol-search-results__main,
      .uol-site-footer .uol-search-results__main,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-search-results__main,
      .uol-homepage-content-container .uol-search-results__main,
      .uol-col-container .uol-search-result__filters,
      .footer-supplement .uol-search-result__filters,
      .uol-site-footer .uol-search-result__filters,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-search-result__filters,
      .uol-homepage-content-container .uol-search-result__filters,
      .uol-col-container .uol-staff-a-z,
      .footer-supplement .uol-staff-a-z,
      .uol-site-footer .uol-staff-a-z,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-staff-a-z,
      .uol-homepage-content-container .uol-staff-a-z,
      .uol-col-container .uol-page,
      .footer-supplement .uol-page,
      .uol-site-footer .uol-page,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-page,
      .uol-homepage-content-container .uol-page,
      .uol-col-container .uol-page--index,
      .footer-supplement .uol-page--index,
      .uol-site-footer .uol-page--index,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-page--index,
      .uol-homepage-content-container .uol-page--index {
        flex-basis: calc(100% - 1rem);
        width: calc(100% - 1rem);
        padding: 0.5rem; } }
    @media (min-width: 64em) {
      .uol-col-container > [class^="uol-col-"], .footer-supplement > [class^="uol-col-"], .uol-site-footer > [class^="uol-col-"], .uol-site-footer__nav-container .uol-site-footer__nav-outer > [class^="uol-col-"], .uol-homepage-content-container > [class^="uol-col-"],
      .uol-col-container .uol-col,
      .footer-supplement .uol-col,
      .uol-site-footer .uol-col,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-col,
      .uol-homepage-content-container .uol-col,
      .uol-col-container .uol-page-width,
      .footer-supplement .uol-page-width,
      .uol-site-footer .uol-page-width,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-page-width,
      .uol-homepage-content-container .uol-page-width,
      .uol-col-container .uol-page-width--wide-content,
      .footer-supplement .uol-page-width--wide-content,
      .uol-site-footer .uol-page-width--wide-content,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-page-width--wide-content,
      .uol-homepage-content-container .uol-page-width--wide-content,
      .uol-col-container .uol-page-width--x-wide-content,
      .footer-supplement .uol-page-width--x-wide-content,
      .uol-site-footer .uol-page-width--x-wide-content,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-page-width--x-wide-content,
      .uol-homepage-content-container .uol-page-width--x-wide-content,
      .uol-col-container .uol-page-width--index,
      .footer-supplement .uol-page-width--index,
      .uol-site-footer .uol-page-width--index,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-page-width--index,
      .uol-homepage-content-container .uol-page-width--index,
      .uol-col-container .uol-page-width--full,
      .footer-supplement .uol-page-width--full,
      .uol-site-footer .uol-page-width--full,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-page-width--full,
      .uol-homepage-content-container .uol-page-width--full,
      .uol-col-container .uol-form-container--centered,
      .footer-supplement .uol-form-container--centered,
      .uol-site-footer .uol-form-container--centered,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-form-container--centered,
      .uol-homepage-content-container .uol-form-container--centered,
      .uol-col-container .uol-side-nav-container--populated,
      .footer-supplement .uol-side-nav-container--populated,
      .uol-site-footer .uol-side-nav-container--populated,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-side-nav-container--populated,
      .uol-homepage-content-container .uol-side-nav-container--populated,
      .uol-col-container .uol-widget--ctas,
      .footer-supplement .uol-widget--ctas,
      .uol-site-footer .uol-widget--ctas,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--ctas,
      .uol-homepage-content-container .uol-widget--ctas,
      .uol-col-container .uol-widget--content,
      .footer-supplement .uol-widget--content,
      .uol-site-footer .uol-widget--content,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--content,
      .uol-homepage-content-container .uol-widget--content,
      .uol-col-container .uol-widget--featured-content,
      .footer-supplement .uol-widget--featured-content,
      .uol-site-footer .uol-widget--featured-content,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--featured-content,
      .uol-homepage-content-container .uol-widget--featured-content,
      .uol-col-container .uol-widget--form,
      .footer-supplement .uol-widget--form,
      .uol-site-footer .uol-widget--form,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--form,
      .uol-homepage-content-container .uol-widget--form,
      .uol-col-container .uol-widget--news-events,
      .footer-supplement .uol-widget--news-events,
      .uol-site-footer .uol-widget--news-events,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--news-events,
      .uol-homepage-content-container .uol-widget--news-events,
      .uol-col-container .uol-widget--news-tiles,
      .footer-supplement .uol-widget--news-tiles,
      .uol-site-footer .uol-widget--news-tiles,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--news-tiles,
      .uol-homepage-content-container .uol-widget--news-tiles,
      .uol-col-container .uol-widget--events,
      .footer-supplement .uol-widget--events,
      .uol-site-footer .uol-widget--events,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--events,
      .uol-homepage-content-container .uol-widget--events,
      .uol-col-container .uol-widget--description-list,
      .footer-supplement .uol-widget--description-list,
      .uol-site-footer .uol-widget--description-list,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--description-list,
      .uol-homepage-content-container .uol-widget--description-list,
      .uol-col-container .uol-widget--accreditation-logos,
      .footer-supplement .uol-widget--accreditation-logos,
      .uol-site-footer .uol-widget--accreditation-logos,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--accreditation-logos,
      .uol-homepage-content-container .uol-widget--accreditation-logos,
      .uol-col-container .uol-widget--wide,
      .footer-supplement .uol-widget--wide,
      .uol-site-footer .uol-widget--wide,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--wide,
      .uol-homepage-content-container .uol-widget--wide,
      .uol-col-container .uol-article,
      .footer-supplement .uol-article,
      .uol-site-footer .uol-article,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-article,
      .uol-homepage-content-container .uol-article,
      .uol-col-container .uol-site-footer__nav-container,
      .footer-supplement .uol-site-footer__nav-container,
      .uol-site-footer .uol-site-footer__nav-container,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-site-footer__nav-container,
      .uol-homepage-content-container .uol-site-footer__nav-container,
      .uol-col-container .uol-site-footer__nav-container .uol-site-footer__nav,
      .uol-site-footer__nav-container .uol-col-container .uol-site-footer__nav,
      .footer-supplement .uol-site-footer__nav-container .uol-site-footer__nav,
      .uol-site-footer__nav-container .footer-supplement .uol-site-footer__nav,
      .uol-site-footer .uol-site-footer__nav-container .uol-site-footer__nav,
      .uol-site-footer__nav-container .uol-site-footer .uol-site-footer__nav,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-site-footer__nav,
      .uol-homepage-content-container .uol-site-footer__nav-container .uol-site-footer__nav,
      .uol-site-footer__nav-container .uol-homepage-content-container .uol-site-footer__nav,
      .uol-col-container .uol-site-footer__address-container,
      .footer-supplement .uol-site-footer__address-container,
      .uol-site-footer .uol-site-footer__address-container,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-site-footer__address-container,
      .uol-homepage-content-container .uol-site-footer__address-container,
      .uol-col-container .uol-site-footer__accreditations,
      .footer-supplement .uol-site-footer__accreditations,
      .uol-site-footer .uol-site-footer__accreditations,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-site-footer__accreditations,
      .uol-homepage-content-container .uol-site-footer__accreditations,
      .uol-col-container .uol-site-footer__site-information-container,
      .footer-supplement .uol-site-footer__site-information-container,
      .uol-site-footer .uol-site-footer__site-information-container,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-site-footer__site-information-container,
      .uol-homepage-content-container .uol-site-footer__site-information-container,
      .uol-col-container .uol-site-footer__logo-outer,
      .footer-supplement .uol-site-footer__logo-outer,
      .uol-site-footer .uol-site-footer__logo-outer,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-site-footer__logo-outer,
      .uol-homepage-content-container .uol-site-footer__logo-outer,
      .uol-col-container .uol-side-nav-container--populated + .uol-homepage-content,
      .footer-supplement .uol-side-nav-container--populated + .uol-homepage-content,
      .uol-site-footer .uol-side-nav-container--populated + .uol-homepage-content,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-side-nav-container--populated + .uol-homepage-content,
      .uol-homepage-content-container .uol-side-nav-container--populated + .uol-homepage-content,
      .uol-col-container .uol-search-results__page-heading,
      .footer-supplement .uol-search-results__page-heading,
      .uol-site-footer .uol-search-results__page-heading,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-search-results__page-heading,
      .uol-homepage-content-container .uol-search-results__page-heading,
      .uol-col-container .uol-search-results__content-area,
      .footer-supplement .uol-search-results__content-area,
      .uol-site-footer .uol-search-results__content-area,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-search-results__content-area,
      .uol-homepage-content-container .uol-search-results__content-area,
      .uol-col-container .uol-search-results__main,
      .footer-supplement .uol-search-results__main,
      .uol-site-footer .uol-search-results__main,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-search-results__main,
      .uol-homepage-content-container .uol-search-results__main,
      .uol-col-container .uol-search-result__filters,
      .footer-supplement .uol-search-result__filters,
      .uol-site-footer .uol-search-result__filters,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-search-result__filters,
      .uol-homepage-content-container .uol-search-result__filters,
      .uol-col-container .uol-staff-a-z,
      .footer-supplement .uol-staff-a-z,
      .uol-site-footer .uol-staff-a-z,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-staff-a-z,
      .uol-homepage-content-container .uol-staff-a-z,
      .uol-col-container .uol-page,
      .footer-supplement .uol-page,
      .uol-site-footer .uol-page,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-page,
      .uol-homepage-content-container .uol-page,
      .uol-col-container .uol-page--index,
      .footer-supplement .uol-page--index,
      .uol-site-footer .uol-page--index,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-page--index,
      .uol-homepage-content-container .uol-page--index {
        padding: 0.75rem; } }
    @media (min-width: 90em) {
      .uol-col-container > [class^="uol-col-"], .footer-supplement > [class^="uol-col-"], .uol-site-footer > [class^="uol-col-"], .uol-site-footer__nav-container .uol-site-footer__nav-outer > [class^="uol-col-"], .uol-homepage-content-container > [class^="uol-col-"],
      .uol-col-container .uol-col,
      .footer-supplement .uol-col,
      .uol-site-footer .uol-col,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-col,
      .uol-homepage-content-container .uol-col,
      .uol-col-container .uol-page-width,
      .footer-supplement .uol-page-width,
      .uol-site-footer .uol-page-width,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-page-width,
      .uol-homepage-content-container .uol-page-width,
      .uol-col-container .uol-page-width--wide-content,
      .footer-supplement .uol-page-width--wide-content,
      .uol-site-footer .uol-page-width--wide-content,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-page-width--wide-content,
      .uol-homepage-content-container .uol-page-width--wide-content,
      .uol-col-container .uol-page-width--x-wide-content,
      .footer-supplement .uol-page-width--x-wide-content,
      .uol-site-footer .uol-page-width--x-wide-content,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-page-width--x-wide-content,
      .uol-homepage-content-container .uol-page-width--x-wide-content,
      .uol-col-container .uol-page-width--index,
      .footer-supplement .uol-page-width--index,
      .uol-site-footer .uol-page-width--index,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-page-width--index,
      .uol-homepage-content-container .uol-page-width--index,
      .uol-col-container .uol-page-width--full,
      .footer-supplement .uol-page-width--full,
      .uol-site-footer .uol-page-width--full,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-page-width--full,
      .uol-homepage-content-container .uol-page-width--full,
      .uol-col-container .uol-form-container--centered,
      .footer-supplement .uol-form-container--centered,
      .uol-site-footer .uol-form-container--centered,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-form-container--centered,
      .uol-homepage-content-container .uol-form-container--centered,
      .uol-col-container .uol-side-nav-container--populated,
      .footer-supplement .uol-side-nav-container--populated,
      .uol-site-footer .uol-side-nav-container--populated,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-side-nav-container--populated,
      .uol-homepage-content-container .uol-side-nav-container--populated,
      .uol-col-container .uol-widget--ctas,
      .footer-supplement .uol-widget--ctas,
      .uol-site-footer .uol-widget--ctas,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--ctas,
      .uol-homepage-content-container .uol-widget--ctas,
      .uol-col-container .uol-widget--content,
      .footer-supplement .uol-widget--content,
      .uol-site-footer .uol-widget--content,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--content,
      .uol-homepage-content-container .uol-widget--content,
      .uol-col-container .uol-widget--featured-content,
      .footer-supplement .uol-widget--featured-content,
      .uol-site-footer .uol-widget--featured-content,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--featured-content,
      .uol-homepage-content-container .uol-widget--featured-content,
      .uol-col-container .uol-widget--form,
      .footer-supplement .uol-widget--form,
      .uol-site-footer .uol-widget--form,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--form,
      .uol-homepage-content-container .uol-widget--form,
      .uol-col-container .uol-widget--news-events,
      .footer-supplement .uol-widget--news-events,
      .uol-site-footer .uol-widget--news-events,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--news-events,
      .uol-homepage-content-container .uol-widget--news-events,
      .uol-col-container .uol-widget--news-tiles,
      .footer-supplement .uol-widget--news-tiles,
      .uol-site-footer .uol-widget--news-tiles,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--news-tiles,
      .uol-homepage-content-container .uol-widget--news-tiles,
      .uol-col-container .uol-widget--events,
      .footer-supplement .uol-widget--events,
      .uol-site-footer .uol-widget--events,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--events,
      .uol-homepage-content-container .uol-widget--events,
      .uol-col-container .uol-widget--description-list,
      .footer-supplement .uol-widget--description-list,
      .uol-site-footer .uol-widget--description-list,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--description-list,
      .uol-homepage-content-container .uol-widget--description-list,
      .uol-col-container .uol-widget--accreditation-logos,
      .footer-supplement .uol-widget--accreditation-logos,
      .uol-site-footer .uol-widget--accreditation-logos,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--accreditation-logos,
      .uol-homepage-content-container .uol-widget--accreditation-logos,
      .uol-col-container .uol-widget--wide,
      .footer-supplement .uol-widget--wide,
      .uol-site-footer .uol-widget--wide,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-widget--wide,
      .uol-homepage-content-container .uol-widget--wide,
      .uol-col-container .uol-article,
      .footer-supplement .uol-article,
      .uol-site-footer .uol-article,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-article,
      .uol-homepage-content-container .uol-article,
      .uol-col-container .uol-site-footer__nav-container,
      .footer-supplement .uol-site-footer__nav-container,
      .uol-site-footer .uol-site-footer__nav-container,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-site-footer__nav-container,
      .uol-homepage-content-container .uol-site-footer__nav-container,
      .uol-col-container .uol-site-footer__nav-container .uol-site-footer__nav,
      .uol-site-footer__nav-container .uol-col-container .uol-site-footer__nav,
      .footer-supplement .uol-site-footer__nav-container .uol-site-footer__nav,
      .uol-site-footer__nav-container .footer-supplement .uol-site-footer__nav,
      .uol-site-footer .uol-site-footer__nav-container .uol-site-footer__nav,
      .uol-site-footer__nav-container .uol-site-footer .uol-site-footer__nav,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-site-footer__nav,
      .uol-homepage-content-container .uol-site-footer__nav-container .uol-site-footer__nav,
      .uol-site-footer__nav-container .uol-homepage-content-container .uol-site-footer__nav,
      .uol-col-container .uol-site-footer__address-container,
      .footer-supplement .uol-site-footer__address-container,
      .uol-site-footer .uol-site-footer__address-container,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-site-footer__address-container,
      .uol-homepage-content-container .uol-site-footer__address-container,
      .uol-col-container .uol-site-footer__accreditations,
      .footer-supplement .uol-site-footer__accreditations,
      .uol-site-footer .uol-site-footer__accreditations,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-site-footer__accreditations,
      .uol-homepage-content-container .uol-site-footer__accreditations,
      .uol-col-container .uol-site-footer__site-information-container,
      .footer-supplement .uol-site-footer__site-information-container,
      .uol-site-footer .uol-site-footer__site-information-container,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-site-footer__site-information-container,
      .uol-homepage-content-container .uol-site-footer__site-information-container,
      .uol-col-container .uol-site-footer__logo-outer,
      .footer-supplement .uol-site-footer__logo-outer,
      .uol-site-footer .uol-site-footer__logo-outer,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-site-footer__logo-outer,
      .uol-homepage-content-container .uol-site-footer__logo-outer,
      .uol-col-container .uol-side-nav-container--populated + .uol-homepage-content,
      .footer-supplement .uol-side-nav-container--populated + .uol-homepage-content,
      .uol-site-footer .uol-side-nav-container--populated + .uol-homepage-content,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-side-nav-container--populated + .uol-homepage-content,
      .uol-homepage-content-container .uol-side-nav-container--populated + .uol-homepage-content,
      .uol-col-container .uol-search-results__page-heading,
      .footer-supplement .uol-search-results__page-heading,
      .uol-site-footer .uol-search-results__page-heading,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-search-results__page-heading,
      .uol-homepage-content-container .uol-search-results__page-heading,
      .uol-col-container .uol-search-results__content-area,
      .footer-supplement .uol-search-results__content-area,
      .uol-site-footer .uol-search-results__content-area,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-search-results__content-area,
      .uol-homepage-content-container .uol-search-results__content-area,
      .uol-col-container .uol-search-results__main,
      .footer-supplement .uol-search-results__main,
      .uol-site-footer .uol-search-results__main,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-search-results__main,
      .uol-homepage-content-container .uol-search-results__main,
      .uol-col-container .uol-search-result__filters,
      .footer-supplement .uol-search-result__filters,
      .uol-site-footer .uol-search-result__filters,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-search-result__filters,
      .uol-homepage-content-container .uol-search-result__filters,
      .uol-col-container .uol-staff-a-z,
      .footer-supplement .uol-staff-a-z,
      .uol-site-footer .uol-staff-a-z,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-staff-a-z,
      .uol-homepage-content-container .uol-staff-a-z,
      .uol-col-container .uol-page,
      .footer-supplement .uol-page,
      .uol-site-footer .uol-page,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-page,
      .uol-homepage-content-container .uol-page,
      .uol-col-container .uol-page--index,
      .footer-supplement .uol-page--index,
      .uol-site-footer .uol-page--index,
      .uol-site-footer__nav-container .uol-site-footer__nav-outer .uol-page--index,
      .uol-homepage-content-container .uol-page--index {
        padding: 1rem; } }
  @media (min-width: 25.875em) {
    [class*="uol-col-"] .uol-col-container, [class*="uol-col-"] .footer-supplement, [class*="uol-col-"] .uol-site-footer, [class*="uol-col-"] .uol-site-footer__nav-container .uol-site-footer__nav-outer, .uol-site-footer__nav-container [class*="uol-col-"] .uol-site-footer__nav-outer, [class*="uol-col-"] .uol-homepage-content-container {
      margin: -1rem; } }
  @media (min-width: 64em) {
    [class*="uol-col-"] .uol-col-container, [class*="uol-col-"] .footer-supplement, [class*="uol-col-"] .uol-site-footer, [class*="uol-col-"] .uol-site-footer__nav-container .uol-site-footer__nav-outer, .uol-site-footer__nav-container [class*="uol-col-"] .uol-site-footer__nav-outer, [class*="uol-col-"] .uol-homepage-content-container {
      margin: -1.5rem; } }
  @media (min-width: 90em) {
    [class*="uol-col-"] .uol-col-container, [class*="uol-col-"] .footer-supplement, [class*="uol-col-"] .uol-site-footer, [class*="uol-col-"] .uol-site-footer__nav-container .uol-site-footer__nav-outer, .uol-site-footer__nav-container [class*="uol-col-"] .uol-site-footer__nav-outer, [class*="uol-col-"] .uol-homepage-content-container {
      margin: -2rem; } }

.uol-col-container--no-padding {
  padding: 0; }
  .uol-col-container--no-padding > [class^="uol-col-"],
  .uol-col-container--no-padding .uol-col,
  .uol-col-container--no-padding .uol-page-width,
  .uol-col-container--no-padding .uol-page-width--wide-content,
  .uol-col-container--no-padding .uol-page-width--x-wide-content,
  .uol-col-container--no-padding .uol-page-width--index,
  .uol-col-container--no-padding .uol-page-width--full,
  .uol-col-container--no-padding .uol-form-container--centered,
  .uol-col-container--no-padding .uol-side-nav-container--populated,
  .uol-col-container--no-padding .uol-widget--ctas,
  .uol-col-container--no-padding .uol-widget--content,
  .uol-col-container--no-padding .uol-widget--featured-content,
  .uol-col-container--no-padding .uol-widget--form,
  .uol-col-container--no-padding .uol-widget--news-events,
  .uol-col-container--no-padding .uol-widget--news-tiles,
  .uol-col-container--no-padding .uol-widget--events,
  .uol-col-container--no-padding .uol-widget--description-list,
  .uol-col-container--no-padding .uol-widget--accreditation-logos,
  .uol-col-container--no-padding .uol-widget--wide,
  .uol-col-container--no-padding .uol-article,
  .uol-col-container--no-padding .uol-site-footer__nav-container,
  .uol-col-container--no-padding .uol-site-footer__nav-container .uol-site-footer__nav,
  .uol-site-footer__nav-container .uol-col-container--no-padding .uol-site-footer__nav,
  .uol-col-container--no-padding .uol-site-footer__address-container,
  .uol-col-container--no-padding .uol-site-footer__accreditations,
  .uol-col-container--no-padding .uol-site-footer__site-information-container,
  .uol-col-container--no-padding .uol-site-footer__logo-outer,
  .uol-col-container--no-padding .uol-side-nav-container--populated + .uol-homepage-content,
  .uol-col-container--no-padding .uol-search-results__page-heading,
  .uol-col-container--no-padding .uol-search-results__content-area,
  .uol-col-container--no-padding .uol-search-results__main,
  .uol-col-container--no-padding .uol-search-result__filters,
  .uol-col-container--no-padding .uol-staff-a-z,
  .uol-col-container--no-padding .uol-page,
  .uol-col-container--no-padding .uol-page--index {
    flex-basis: 100%;
    max-width: 100%;
    padding: 0; }
    @media (min-width: 25.875em) {
      .uol-col-container--no-padding > [class^="uol-col-"],
      .uol-col-container--no-padding .uol-col,
      .uol-col-container--no-padding .uol-page-width,
      .uol-col-container--no-padding .uol-page-width--wide-content,
      .uol-col-container--no-padding .uol-page-width--x-wide-content,
      .uol-col-container--no-padding .uol-page-width--index,
      .uol-col-container--no-padding .uol-page-width--full,
      .uol-col-container--no-padding .uol-form-container--centered,
      .uol-col-container--no-padding .uol-side-nav-container--populated,
      .uol-col-container--no-padding .uol-widget--ctas,
      .uol-col-container--no-padding .uol-widget--content,
      .uol-col-container--no-padding .uol-widget--featured-content,
      .uol-col-container--no-padding .uol-widget--form,
      .uol-col-container--no-padding .uol-widget--news-events,
      .uol-col-container--no-padding .uol-widget--news-tiles,
      .uol-col-container--no-padding .uol-widget--events,
      .uol-col-container--no-padding .uol-widget--description-list,
      .uol-col-container--no-padding .uol-widget--accreditation-logos,
      .uol-col-container--no-padding .uol-widget--wide,
      .uol-col-container--no-padding .uol-article,
      .uol-col-container--no-padding .uol-site-footer__nav-container,
      .uol-col-container--no-padding .uol-site-footer__nav-container .uol-site-footer__nav,
      .uol-site-footer__nav-container .uol-col-container--no-padding .uol-site-footer__nav,
      .uol-col-container--no-padding .uol-site-footer__address-container,
      .uol-col-container--no-padding .uol-site-footer__accreditations,
      .uol-col-container--no-padding .uol-site-footer__site-information-container,
      .uol-col-container--no-padding .uol-site-footer__logo-outer,
      .uol-col-container--no-padding .uol-side-nav-container--populated + .uol-homepage-content,
      .uol-col-container--no-padding .uol-search-results__page-heading,
      .uol-col-container--no-padding .uol-search-results__content-area,
      .uol-col-container--no-padding .uol-search-results__main,
      .uol-col-container--no-padding .uol-search-result__filters,
      .uol-col-container--no-padding .uol-staff-a-z,
      .uol-col-container--no-padding .uol-page,
      .uol-col-container--no-padding .uol-page--index {
        padding: 0; } }
    @media (min-width: 64em) {
      .uol-col-container--no-padding > [class^="uol-col-"],
      .uol-col-container--no-padding .uol-col,
      .uol-col-container--no-padding .uol-page-width,
      .uol-col-container--no-padding .uol-page-width--wide-content,
      .uol-col-container--no-padding .uol-page-width--x-wide-content,
      .uol-col-container--no-padding .uol-page-width--index,
      .uol-col-container--no-padding .uol-page-width--full,
      .uol-col-container--no-padding .uol-form-container--centered,
      .uol-col-container--no-padding .uol-side-nav-container--populated,
      .uol-col-container--no-padding .uol-widget--ctas,
      .uol-col-container--no-padding .uol-widget--content,
      .uol-col-container--no-padding .uol-widget--featured-content,
      .uol-col-container--no-padding .uol-widget--form,
      .uol-col-container--no-padding .uol-widget--news-events,
      .uol-col-container--no-padding .uol-widget--news-tiles,
      .uol-col-container--no-padding .uol-widget--events,
      .uol-col-container--no-padding .uol-widget--description-list,
      .uol-col-container--no-padding .uol-widget--accreditation-logos,
      .uol-col-container--no-padding .uol-widget--wide,
      .uol-col-container--no-padding .uol-article,
      .uol-col-container--no-padding .uol-site-footer__nav-container,
      .uol-col-container--no-padding .uol-site-footer__nav-container .uol-site-footer__nav,
      .uol-site-footer__nav-container .uol-col-container--no-padding .uol-site-footer__nav,
      .uol-col-container--no-padding .uol-site-footer__address-container,
      .uol-col-container--no-padding .uol-site-footer__accreditations,
      .uol-col-container--no-padding .uol-site-footer__site-information-container,
      .uol-col-container--no-padding .uol-site-footer__logo-outer,
      .uol-col-container--no-padding .uol-side-nav-container--populated + .uol-homepage-content,
      .uol-col-container--no-padding .uol-search-results__page-heading,
      .uol-col-container--no-padding .uol-search-results__content-area,
      .uol-col-container--no-padding .uol-search-results__main,
      .uol-col-container--no-padding .uol-search-result__filters,
      .uol-col-container--no-padding .uol-staff-a-z,
      .uol-col-container--no-padding .uol-page,
      .uol-col-container--no-padding .uol-page--index {
        padding: 0; } }
    @media (min-width: 90em) {
      .uol-col-container--no-padding > [class^="uol-col-"],
      .uol-col-container--no-padding .uol-col,
      .uol-col-container--no-padding .uol-page-width,
      .uol-col-container--no-padding .uol-page-width--wide-content,
      .uol-col-container--no-padding .uol-page-width--x-wide-content,
      .uol-col-container--no-padding .uol-page-width--index,
      .uol-col-container--no-padding .uol-page-width--full,
      .uol-col-container--no-padding .uol-form-container--centered,
      .uol-col-container--no-padding .uol-side-nav-container--populated,
      .uol-col-container--no-padding .uol-widget--ctas,
      .uol-col-container--no-padding .uol-widget--content,
      .uol-col-container--no-padding .uol-widget--featured-content,
      .uol-col-container--no-padding .uol-widget--form,
      .uol-col-container--no-padding .uol-widget--news-events,
      .uol-col-container--no-padding .uol-widget--news-tiles,
      .uol-col-container--no-padding .uol-widget--events,
      .uol-col-container--no-padding .uol-widget--description-list,
      .uol-col-container--no-padding .uol-widget--accreditation-logos,
      .uol-col-container--no-padding .uol-widget--wide,
      .uol-col-container--no-padding .uol-article,
      .uol-col-container--no-padding .uol-site-footer__nav-container,
      .uol-col-container--no-padding .uol-site-footer__nav-container .uol-site-footer__nav,
      .uol-site-footer__nav-container .uol-col-container--no-padding .uol-site-footer__nav,
      .uol-col-container--no-padding .uol-site-footer__address-container,
      .uol-col-container--no-padding .uol-site-footer__accreditations,
      .uol-col-container--no-padding .uol-site-footer__site-information-container,
      .uol-col-container--no-padding .uol-site-footer__logo-outer,
      .uol-col-container--no-padding .uol-side-nav-container--populated + .uol-homepage-content,
      .uol-col-container--no-padding .uol-search-results__page-heading,
      .uol-col-container--no-padding .uol-search-results__content-area,
      .uol-col-container--no-padding .uol-search-results__main,
      .uol-col-container--no-padding .uol-search-result__filters,
      .uol-col-container--no-padding .uol-staff-a-z,
      .uol-col-container--no-padding .uol-page,
      .uol-col-container--no-padding .uol-page--index {
        padding: 0; } }

@media (min-width: 25.875em) {
  .uol-col-1 {
    flex-basis: calc(8.33333% - 1rem);
    max-width: calc(8.33333% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-1 {
    flex-basis: calc(8.33333% - 1.5rem);
    max-width: calc(8.33333% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-1 {
    flex-basis: calc(8.33333% - 2rem);
    max-width: calc(8.33333% - 2rem); } }

.uol-col-container--no-padding .uol-col-1 {
  flex-basis: 8.33333%;
  max-width: 8.33333%; }

@media (min-width: 25.875em) {
  .uol-col-2 {
    flex-basis: calc(16.66667% - 1rem);
    max-width: calc(16.66667% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-2 {
    flex-basis: calc(16.66667% - 1.5rem);
    max-width: calc(16.66667% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-2 {
    flex-basis: calc(16.66667% - 2rem);
    max-width: calc(16.66667% - 2rem); } }

.uol-col-container--no-padding .uol-col-2 {
  flex-basis: 16.66667%;
  max-width: 16.66667%; }

@media (min-width: 25.875em) {
  .uol-col-3 {
    flex-basis: calc(25% - 1rem);
    max-width: calc(25% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-3 {
    flex-basis: calc(25% - 1.5rem);
    max-width: calc(25% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-3 {
    flex-basis: calc(25% - 2rem);
    max-width: calc(25% - 2rem); } }

.uol-col-container--no-padding .uol-col-3 {
  flex-basis: 25%;
  max-width: 25%; }

@media (min-width: 25.875em) {
  .uol-col-4 {
    flex-basis: calc(33.33333% - 1rem);
    max-width: calc(33.33333% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-4 {
    flex-basis: calc(33.33333% - 1.5rem);
    max-width: calc(33.33333% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-4 {
    flex-basis: calc(33.33333% - 2rem);
    max-width: calc(33.33333% - 2rem); } }

.uol-col-container--no-padding .uol-col-4 {
  flex-basis: 33.33333%;
  max-width: 33.33333%; }

@media (min-width: 25.875em) {
  .uol-col-5 {
    flex-basis: calc(41.66667% - 1rem);
    max-width: calc(41.66667% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-5 {
    flex-basis: calc(41.66667% - 1.5rem);
    max-width: calc(41.66667% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-5 {
    flex-basis: calc(41.66667% - 2rem);
    max-width: calc(41.66667% - 2rem); } }

.uol-col-container--no-padding .uol-col-5 {
  flex-basis: 41.66667%;
  max-width: 41.66667%; }

@media (min-width: 25.875em) {
  .uol-col-6 {
    flex-basis: calc(50% - 1rem);
    max-width: calc(50% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-6 {
    flex-basis: calc(50% - 1.5rem);
    max-width: calc(50% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-6 {
    flex-basis: calc(50% - 2rem);
    max-width: calc(50% - 2rem); } }

.uol-col-container--no-padding .uol-col-6 {
  flex-basis: 50%;
  max-width: 50%; }

@media (min-width: 25.875em) {
  .uol-col-7 {
    flex-basis: calc(58.33333% - 1rem);
    max-width: calc(58.33333% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-7 {
    flex-basis: calc(58.33333% - 1.5rem);
    max-width: calc(58.33333% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-7 {
    flex-basis: calc(58.33333% - 2rem);
    max-width: calc(58.33333% - 2rem); } }

.uol-col-container--no-padding .uol-col-7 {
  flex-basis: 58.33333%;
  max-width: 58.33333%; }

@media (min-width: 25.875em) {
  .uol-col-8 {
    flex-basis: calc(66.66667% - 1rem);
    max-width: calc(66.66667% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-8 {
    flex-basis: calc(66.66667% - 1.5rem);
    max-width: calc(66.66667% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-8 {
    flex-basis: calc(66.66667% - 2rem);
    max-width: calc(66.66667% - 2rem); } }

.uol-col-container--no-padding .uol-col-8 {
  flex-basis: 66.66667%;
  max-width: 66.66667%; }

@media (min-width: 25.875em) {
  .uol-col-9 {
    flex-basis: calc(75% - 1rem);
    max-width: calc(75% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-9 {
    flex-basis: calc(75% - 1.5rem);
    max-width: calc(75% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-9 {
    flex-basis: calc(75% - 2rem);
    max-width: calc(75% - 2rem); } }

.uol-col-container--no-padding .uol-col-9 {
  flex-basis: 75%;
  max-width: 75%; }

@media (min-width: 25.875em) {
  .uol-col-10 {
    flex-basis: calc(83.33333% - 1rem);
    max-width: calc(83.33333% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-10 {
    flex-basis: calc(83.33333% - 1.5rem);
    max-width: calc(83.33333% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-10 {
    flex-basis: calc(83.33333% - 2rem);
    max-width: calc(83.33333% - 2rem); } }

.uol-col-container--no-padding .uol-col-10 {
  flex-basis: 83.33333%;
  max-width: 83.33333%; }

@media (min-width: 25.875em) {
  .uol-col-11 {
    flex-basis: calc(91.66667% - 1rem);
    max-width: calc(91.66667% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-11 {
    flex-basis: calc(91.66667% - 1.5rem);
    max-width: calc(91.66667% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-11 {
    flex-basis: calc(91.66667% - 2rem);
    max-width: calc(91.66667% - 2rem); } }

.uol-col-container--no-padding .uol-col-11 {
  flex-basis: 91.66667%;
  max-width: 91.66667%; }

@media (min-width: 25.875em) {
  .uol-col-12, .uol-search-results__page-heading, .uol-search-results__content-area, .uol-search-result__filters {
    flex-basis: calc(100% - 1rem);
    max-width: calc(100% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-12, .uol-search-results__page-heading, .uol-search-results__content-area, .uol-search-result__filters {
    flex-basis: calc(100% - 1.5rem);
    max-width: calc(100% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-12, .uol-search-results__page-heading, .uol-search-results__content-area, .uol-search-result__filters {
    flex-basis: calc(100% - 2rem);
    max-width: calc(100% - 2rem); } }

.uol-col-container--no-padding .uol-col-12, .uol-col-container--no-padding .uol-search-results__page-heading, .uol-col-container--no-padding .uol-search-results__content-area, .uol-col-container--no-padding .uol-search-result__filters {
  flex-basis: 100%;
  max-width: 100%; }

@media (min-width: 37.5em) {
  .uol-col-s-1 {
    flex-basis: calc(8.33333% - 1rem);
    max-width: calc(8.33333% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-s-1 {
    flex-basis: calc(8.33333% - 1.5rem);
    max-width: calc(8.33333% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-s-1 {
    flex-basis: calc(8.33333% - 2rem);
    max-width: calc(8.33333% - 2rem); } }

@media (min-width: 37.5em) {
  .uol-col-s-2 {
    flex-basis: calc(16.66667% - 1rem);
    max-width: calc(16.66667% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-s-2 {
    flex-basis: calc(16.66667% - 1.5rem);
    max-width: calc(16.66667% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-s-2 {
    flex-basis: calc(16.66667% - 2rem);
    max-width: calc(16.66667% - 2rem); } }

@media (min-width: 37.5em) {
  .uol-col-s-3 {
    flex-basis: calc(25% - 1rem);
    max-width: calc(25% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-s-3 {
    flex-basis: calc(25% - 1.5rem);
    max-width: calc(25% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-s-3 {
    flex-basis: calc(25% - 2rem);
    max-width: calc(25% - 2rem); } }

@media (min-width: 37.5em) {
  .uol-col-s-4 {
    flex-basis: calc(33.33333% - 1rem);
    max-width: calc(33.33333% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-s-4 {
    flex-basis: calc(33.33333% - 1.5rem);
    max-width: calc(33.33333% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-s-4 {
    flex-basis: calc(33.33333% - 2rem);
    max-width: calc(33.33333% - 2rem); } }

@media (min-width: 37.5em) {
  .uol-col-s-5 {
    flex-basis: calc(41.66667% - 1rem);
    max-width: calc(41.66667% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-s-5 {
    flex-basis: calc(41.66667% - 1.5rem);
    max-width: calc(41.66667% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-s-5 {
    flex-basis: calc(41.66667% - 2rem);
    max-width: calc(41.66667% - 2rem); } }

@media (min-width: 37.5em) {
  .uol-col-s-6, .uol-site-footer__nav-container, .uol-site-footer__address-container, .uol-site-footer__accreditations {
    flex-basis: calc(50% - 1rem);
    max-width: calc(50% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-s-6, .uol-site-footer__nav-container, .uol-site-footer__address-container, .uol-site-footer__accreditations {
    flex-basis: calc(50% - 1.5rem);
    max-width: calc(50% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-s-6, .uol-site-footer__nav-container, .uol-site-footer__address-container, .uol-site-footer__accreditations {
    flex-basis: calc(50% - 2rem);
    max-width: calc(50% - 2rem); } }

@media (min-width: 37.5em) {
  .uol-col-s-7 {
    flex-basis: calc(58.33333% - 1rem);
    max-width: calc(58.33333% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-s-7 {
    flex-basis: calc(58.33333% - 1.5rem);
    max-width: calc(58.33333% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-s-7 {
    flex-basis: calc(58.33333% - 2rem);
    max-width: calc(58.33333% - 2rem); } }

@media (min-width: 37.5em) {
  .uol-col-s-8 {
    flex-basis: calc(66.66667% - 1rem);
    max-width: calc(66.66667% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-s-8 {
    flex-basis: calc(66.66667% - 1.5rem);
    max-width: calc(66.66667% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-s-8 {
    flex-basis: calc(66.66667% - 2rem);
    max-width: calc(66.66667% - 2rem); } }

@media (min-width: 37.5em) {
  .uol-col-s-9 {
    flex-basis: calc(75% - 1rem);
    max-width: calc(75% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-s-9 {
    flex-basis: calc(75% - 1.5rem);
    max-width: calc(75% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-s-9 {
    flex-basis: calc(75% - 2rem);
    max-width: calc(75% - 2rem); } }

@media (min-width: 37.5em) {
  .uol-col-s-10 {
    flex-basis: calc(83.33333% - 1rem);
    max-width: calc(83.33333% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-s-10 {
    flex-basis: calc(83.33333% - 1.5rem);
    max-width: calc(83.33333% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-s-10 {
    flex-basis: calc(83.33333% - 2rem);
    max-width: calc(83.33333% - 2rem); } }

@media (min-width: 37.5em) {
  .uol-col-s-11 {
    flex-basis: calc(91.66667% - 1rem);
    max-width: calc(91.66667% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-s-11 {
    flex-basis: calc(91.66667% - 1.5rem);
    max-width: calc(91.66667% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-s-11 {
    flex-basis: calc(91.66667% - 2rem);
    max-width: calc(91.66667% - 2rem); } }

@media (min-width: 37.5em) {
  .uol-col-s-12, .uol-page-width--index, .uol-page-width--full, .uol-search-results__main, .uol-staff-a-z, .uol-page--index {
    flex-basis: calc(100% - 1rem);
    max-width: calc(100% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-s-12, .uol-page-width--index, .uol-page-width--full, .uol-search-results__main, .uol-staff-a-z, .uol-page--index {
    flex-basis: calc(100% - 1.5rem);
    max-width: calc(100% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-s-12, .uol-page-width--index, .uol-page-width--full, .uol-search-results__main, .uol-staff-a-z, .uol-page--index {
    flex-basis: calc(100% - 2rem);
    max-width: calc(100% - 2rem); } }

@media (min-width: 48em) {
  .uol-col-m-1 {
    flex-basis: calc(8.33333% - 1rem);
    max-width: calc(8.33333% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-m-1 {
    flex-basis: calc(8.33333% - 1.5rem);
    max-width: calc(8.33333% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-m-1 {
    flex-basis: calc(8.33333% - 2rem);
    max-width: calc(8.33333% - 2rem); } }

@media (min-width: 48em) {
  .uol-col-m-2 {
    flex-basis: calc(16.66667% - 1rem);
    max-width: calc(16.66667% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-m-2 {
    flex-basis: calc(16.66667% - 1.5rem);
    max-width: calc(16.66667% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-m-2 {
    flex-basis: calc(16.66667% - 2rem);
    max-width: calc(16.66667% - 2rem); } }

@media (min-width: 48em) {
  .uol-col-m-3 {
    flex-basis: calc(25% - 1rem);
    max-width: calc(25% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-m-3 {
    flex-basis: calc(25% - 1.5rem);
    max-width: calc(25% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-m-3 {
    flex-basis: calc(25% - 2rem);
    max-width: calc(25% - 2rem); } }

@media (min-width: 48em) {
  .uol-col-m-4 {
    flex-basis: calc(33.33333% - 1rem);
    max-width: calc(33.33333% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-m-4 {
    flex-basis: calc(33.33333% - 1.5rem);
    max-width: calc(33.33333% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-m-4 {
    flex-basis: calc(33.33333% - 2rem);
    max-width: calc(33.33333% - 2rem); } }

@media (min-width: 48em) {
  .uol-col-m-5, .uol-site-footer__logo-outer {
    flex-basis: calc(41.66667% - 1rem);
    max-width: calc(41.66667% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-m-5, .uol-site-footer__logo-outer {
    flex-basis: calc(41.66667% - 1.5rem);
    max-width: calc(41.66667% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-m-5, .uol-site-footer__logo-outer {
    flex-basis: calc(41.66667% - 2rem);
    max-width: calc(41.66667% - 2rem); } }

@media (min-width: 48em) {
  .uol-col-m-6 {
    flex-basis: calc(50% - 1rem);
    max-width: calc(50% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-m-6 {
    flex-basis: calc(50% - 1.5rem);
    max-width: calc(50% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-m-6 {
    flex-basis: calc(50% - 2rem);
    max-width: calc(50% - 2rem); } }

@media (min-width: 48em) {
  .uol-col-m-7, .uol-site-footer__site-information-container {
    flex-basis: calc(58.33333% - 1rem);
    max-width: calc(58.33333% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-m-7, .uol-site-footer__site-information-container {
    flex-basis: calc(58.33333% - 1.5rem);
    max-width: calc(58.33333% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-m-7, .uol-site-footer__site-information-container {
    flex-basis: calc(58.33333% - 2rem);
    max-width: calc(58.33333% - 2rem); } }

@media (min-width: 48em) {
  .uol-col-m-8 {
    flex-basis: calc(66.66667% - 1rem);
    max-width: calc(66.66667% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-m-8 {
    flex-basis: calc(66.66667% - 1.5rem);
    max-width: calc(66.66667% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-m-8 {
    flex-basis: calc(66.66667% - 2rem);
    max-width: calc(66.66667% - 2rem); } }

@media (min-width: 48em) {
  .uol-col-m-9 {
    flex-basis: calc(75% - 1rem);
    max-width: calc(75% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-m-9 {
    flex-basis: calc(75% - 1.5rem);
    max-width: calc(75% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-m-9 {
    flex-basis: calc(75% - 2rem);
    max-width: calc(75% - 2rem); } }

@media (min-width: 48em) {
  .uol-col-m-10, .uol-form-container--centered {
    flex-basis: calc(83.33333% - 1rem);
    max-width: calc(83.33333% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-m-10, .uol-form-container--centered {
    flex-basis: calc(83.33333% - 1.5rem);
    max-width: calc(83.33333% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-m-10, .uol-form-container--centered {
    flex-basis: calc(83.33333% - 2rem);
    max-width: calc(83.33333% - 2rem); } }

@media (min-width: 48em) {
  .uol-col-m-11 {
    flex-basis: calc(91.66667% - 1rem);
    max-width: calc(91.66667% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-m-11 {
    flex-basis: calc(91.66667% - 1.5rem);
    max-width: calc(91.66667% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-m-11 {
    flex-basis: calc(91.66667% - 2rem);
    max-width: calc(91.66667% - 2rem); } }

@media (min-width: 48em) {
  .uol-col-m-12 {
    flex-basis: calc(100% - 1rem);
    max-width: calc(100% - 1rem); } }

@media (min-width: 64em) {
  .uol-col-m-12 {
    flex-basis: calc(100% - 1.5rem);
    max-width: calc(100% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-m-12 {
    flex-basis: calc(100% - 2rem);
    max-width: calc(100% - 2rem); } }

@media (min-width: 64em) {
  .uol-col-l-1 {
    flex-basis: calc(8.33333% - 1.5rem);
    max-width: calc(8.33333% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-l-1 {
    flex-basis: calc(8.33333% - 2rem);
    max-width: calc(8.33333% - 2rem); } }

@media (min-width: 64em) {
  .uol-col-l-2 {
    flex-basis: calc(16.66667% - 1.5rem);
    max-width: calc(16.66667% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-l-2 {
    flex-basis: calc(16.66667% - 2rem);
    max-width: calc(16.66667% - 2rem); } }

@media (min-width: 64em) {
  .uol-col-l-3, .uol-article-container .uol-section-nav--relocated, .uol-side-nav-container--populated, .uol-search-result__filters {
    flex-basis: calc(25% - 1.5rem);
    max-width: calc(25% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-l-3, .uol-article-container .uol-section-nav--relocated, .uol-side-nav-container--populated, .uol-search-result__filters {
    flex-basis: calc(25% - 2rem);
    max-width: calc(25% - 2rem); } }

@media (min-width: 64em) {
  .uol-col-l-4, .uol-site-footer__address-container {
    flex-basis: calc(33.33333% - 1.5rem);
    max-width: calc(33.33333% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-l-4, .uol-site-footer__address-container {
    flex-basis: calc(33.33333% - 2rem);
    max-width: calc(33.33333% - 2rem); } }

@media (min-width: 64em) {
  .uol-col-l-5 {
    flex-basis: calc(41.66667% - 1.5rem);
    max-width: calc(41.66667% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-l-5 {
    flex-basis: calc(41.66667% - 2rem);
    max-width: calc(41.66667% - 2rem); } }

@media (min-width: 64em) {
  .uol-col-l-6, .uol-site-footer__nav-container .uol-site-footer__nav {
    flex-basis: calc(50% - 1.5rem);
    max-width: calc(50% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-l-6, .uol-site-footer__nav-container .uol-site-footer__nav {
    flex-basis: calc(50% - 2rem);
    max-width: calc(50% - 2rem); } }

@media (min-width: 64em) {
  .uol-col-l-7 {
    flex-basis: calc(58.33333% - 1.5rem);
    max-width: calc(58.33333% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-l-7 {
    flex-basis: calc(58.33333% - 2rem);
    max-width: calc(58.33333% - 2rem); } }

@media (min-width: 64em) {
  .uol-col-l-8, .uol-site-footer__nav-container {
    flex-basis: calc(66.66667% - 1.5rem);
    max-width: calc(66.66667% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-l-8, .uol-site-footer__nav-container {
    flex-basis: calc(66.66667% - 2rem);
    max-width: calc(66.66667% - 2rem); } }

@media (min-width: 64em) {
  .uol-col-l-9, .uol-page-width, .uol-page-width--wide-content, .uol-page-width--x-wide-content, .uol-page-width--index, .uol-page-width--full, .uol-article, .uol-side-nav-container--populated + .uol-homepage-content, .uol-search-results__main, .uol-staff-a-z, .uol-page, .uol-page--index {
    flex-basis: calc(75% - 1.5rem);
    max-width: calc(75% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-l-9, .uol-page-width, .uol-page-width--wide-content, .uol-page-width--x-wide-content, .uol-page-width--index, .uol-page-width--full, .uol-article, .uol-side-nav-container--populated + .uol-homepage-content, .uol-search-results__main, .uol-staff-a-z, .uol-page, .uol-page--index {
    flex-basis: calc(75% - 2rem);
    max-width: calc(75% - 2rem); } }

@media (min-width: 64em) {
  .uol-col-l-10 {
    flex-basis: calc(83.33333% - 1.5rem);
    max-width: calc(83.33333% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-l-10 {
    flex-basis: calc(83.33333% - 2rem);
    max-width: calc(83.33333% - 2rem); } }

@media (min-width: 64em) {
  .uol-col-l-11 {
    flex-basis: calc(91.66667% - 1.5rem);
    max-width: calc(91.66667% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-l-11 {
    flex-basis: calc(91.66667% - 2rem);
    max-width: calc(91.66667% - 2rem); } }

@media (min-width: 64em) {
  .uol-col-l-12, .uol-site-footer__accreditations {
    flex-basis: calc(100% - 1.5rem);
    max-width: calc(100% - 1.5rem); } }

@media (min-width: 90em) {
  .uol-col-l-12, .uol-site-footer__accreditations {
    flex-basis: calc(100% - 2rem);
    max-width: calc(100% - 2rem); } }

@media (min-width: 90em) {
  .uol-col-xl-1 {
    flex-basis: calc(8.33333% - 2rem);
    max-width: calc(8.33333% - 2rem); } }

@media (min-width: 90em) {
  .uol-col-xl-2 {
    flex-basis: calc(16.66667% - 2rem);
    max-width: calc(16.66667% - 2rem); } }

@media (min-width: 90em) {
  .uol-col-xl-3, .uol-site-footer__address-container {
    flex-basis: calc(25% - 2rem);
    max-width: calc(25% - 2rem); } }

@media (min-width: 90em) {
  .uol-col-xl-4, .uol-site-footer__nav-container .uol-site-footer__nav, .uol-site-footer__logo-outer {
    flex-basis: calc(33.33333% - 2rem);
    max-width: calc(33.33333% - 2rem); } }

@media (min-width: 90em) {
  .uol-col-xl-5 {
    flex-basis: calc(41.66667% - 2rem);
    max-width: calc(41.66667% - 2rem); } }

@media (min-width: 90em) {
  .uol-col-xl-6 {
    flex-basis: calc(50% - 2rem);
    max-width: calc(50% - 2rem); } }

@media (min-width: 90em) {
  .uol-col-xl-7 {
    flex-basis: calc(58.33333% - 2rem);
    max-width: calc(58.33333% - 2rem); } }

@media (min-width: 90em) {
  .uol-col-xl-8, .uol-form-container--centered, .uol-site-footer__site-information-container {
    flex-basis: calc(66.66667% - 2rem);
    max-width: calc(66.66667% - 2rem); } }

@media (min-width: 90em) {
  .uol-col-xl-9, .uol-site-footer__nav-container {
    flex-basis: calc(75% - 2rem);
    max-width: calc(75% - 2rem); } }

@media (min-width: 90em) {
  .uol-col-xl-10 {
    flex-basis: calc(83.33333% - 2rem);
    max-width: calc(83.33333% - 2rem); } }

@media (min-width: 90em) {
  .uol-col-xl-11 {
    flex-basis: calc(91.66667% - 2rem);
    max-width: calc(91.66667% - 2rem); } }

@media (min-width: 90em) {
  .uol-col-xl-12 {
    flex-basis: calc(100% - 2rem);
    max-width: calc(100% - 2rem); } }

.uol-example-col-container {
  border: 1px solid red;
  margin-bottom: 1rem; }
  @media (min-width: 64em) {
    .uol-example-col-container {
      margin-bottom: 1.5rem; } }
  @media (min-width: 90em) {
    .uol-example-col-container {
      margin-bottom: 2rem; } }

.uol-example-col-item {
  position: relative;
  background: rgba(193, 224, 254, 0.7);
  box-sizing: border-box;
  border: 1px solid #1a84c7;
  height: 4rem; }
  .uol-example-col-item p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center; }

.uol-text-debug p, .uol-text-debug ul, .uol-text-debug h2, .uol-text-debug h3, .uol-text-debug h4, .uol-text-debug h5, .uol-text-debug h6, .uol-text-debug table {
  border: 1px solid #ccc; }

.uol-col-debug {
  position: relative; }
  @media (min-width: 25.875em) {
    .uol-col-debug::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin-right: auto;
      margin-left: auto;
      z-index: -1000;
      pointer-events: none;
      width: calc(100% - (2 * 1rem));
      background-image: repeating-linear-gradient(to right, #fce5f0, #fce5f0 calc((100% / 12) - 1rem), transparent calc((100% / 12) - 1rem), transparent calc(100% / 12));
      background-size: calc(100% + 1rem) 100%; } }
  @media (min-width: 64em) {
    .uol-col-debug::before {
      width: calc(100% - (2 * 1.5rem));
      background-image: repeating-linear-gradient(to right, #fce5f0, #fce5f0 calc((100% / 12) - 1.5rem), transparent calc((100% / 12) - 1.5rem), transparent calc(100% / 12));
      background-size: calc(100% + 1.5rem) 100%; } }
  @media (min-width: 90em) {
    .uol-col-debug::before {
      width: calc(100% - (2 * 2rem));
      background-image: repeating-linear-gradient(to right, #fce5f0, #fce5f0 calc((100% / 12) - 2rem), transparent calc((100% / 12) - 2rem), transparent calc(100% / 12));
      background-size: calc(100% + 2rem) 100%; } }

.uol-arrow-link:hover svg, .uol-info-list__data a.uol-info-list__data__button:hover svg, .uol-arrow-link:focus svg, .uol-info-list__data a.uol-info-list__data__button:focus svg {
  right: -0.1em; }

@media (prefers-reduced-motion) {
  .uol-arrow-link:hover svg, .uol-info-list__data a.uol-info-list__data__button:hover svg, .uol-arrow-link:focus svg, .uol-info-list__data a.uol-info-list__data__button:focus svg {
    right: 0.2em; } }

.uol-arrow-link--bg-dark svg {
  fill: #ff4e36; }

.uol-arrow-link--fade-in svg {
  opacity: 0; }

.uol-arrow-link--fade-in:hover svg, .uol-arrow-link--fade-in:focus svg {
  opacity: 1; }

.uol-arrow-link__arrow-wrapper {
  position: relative;
  white-space: nowrap;
  padding-right: 1.65em; }
  .uol-arrow-link__arrow-wrapper svg {
    position: absolute;
    height: 1.2em;
    width: 1.2em;
    top: 0.1em;
    right: 0.2em;
    fill: #c70000;
    transition: all 0.3s ease 0.15s; }
    @media (-ms-high-contrast: active), (forced-colors: active) {
      .uol-arrow-link__arrow-wrapper svg {
        fill: -ms-hotlight;
        fill: linkText; } }

.uol-page-width {
  position: relative;
  flex-basis: calc(100% - 1rem);
  width: calc(100% - 1rem);
  padding: 0.5rem; }
  .uol-col-debug .uol-page-width {
    background: rgba(161, 161, 239, 0.2); }
  @media (min-width: 37.5em) {
    .uol-page-width > * {
      margin-left: calc(100% / 12 + 0.0625rem);
      margin-right: calc(100% / 12 + 0.0625rem); } }
  @media (min-width: 64em) {
    .uol-page-width > * {
      margin-left: calc(100% / 9 + 0.25rem);
      margin-right: calc(100% / 9 + 0.25rem); } }
  @media (min-width: 90em) {
    .uol-page-width > * {
      margin-right: calc((100% / 9 * 2) + 0.5rem); } }

.uol-page-width--wide-content {
  position: relative;
  flex-basis: calc(100% - 1rem);
  width: calc(100% - 1rem);
  padding: 0.5rem; }
  .uol-col-debug .uol-page-width--wide-content {
    background: rgba(161, 161, 239, 0.2); }
  @media (min-width: 37.5em) {
    .uol-page-width--wide-content > * {
      margin-left: calc(100% / 12 + 0.0625rem);
      margin-right: calc(100% / 12 + 0.0625rem); } }
  @media (min-width: 64em) {
    .uol-page-width--wide-content > * {
      margin-left: calc(100% / 9 + 0.25rem);
      margin-right: calc(100% / 9 + 0.25rem); } }
  @media (min-width: 90em) {
    .uol-page-width--wide-content > * {
      margin-right: calc((100% / 9 * 2) + 0.5rem); } }
  @media (min-width: 90em) {
    .uol-page-width--wide-content > * {
      margin-right: calc(100% / 9 + 0.25rem); } }

.uol-page-width--x-wide-content {
  position: relative;
  flex-basis: calc(100% - 1rem);
  width: calc(100% - 1rem);
  padding: 0.5rem; }
  .uol-col-debug .uol-page-width--x-wide-content {
    background: rgba(161, 161, 239, 0.2); }
  @media (min-width: 37.5em) {
    .uol-page-width--x-wide-content > * {
      margin-left: calc(100% / 12 + 0.0625rem);
      margin-right: calc(100% / 12 + 0.0625rem); } }
  @media (min-width: 64em) {
    .uol-page-width--x-wide-content > * {
      margin-left: calc(100% / 9 + 0.25rem);
      margin-right: calc(100% / 9 + 0.25rem); } }
  @media (min-width: 90em) {
    .uol-page-width--x-wide-content > * {
      margin-right: calc((100% / 9 * 2) + 0.5rem); } }
  @media (min-width: 90em) {
    .uol-page-width--x-wide-content > * {
      margin-right: 0; } }
  @media (min-width: 90em) {
    .test-side + .uol-page-width--x-wide-content > *,
    .uol-side-nav-container--populated + .uol-page-width--x-wide-content > * {
      margin-right: calc(100% / 9 + 0.25rem);
      margin-left: 0; } }

.uol-page-width--index {
  position: relative;
  flex-basis: calc(100% - 1rem);
  width: calc(100% - 1rem);
  padding: 0.5rem; }
  .uol-col-debug .uol-page-width--index {
    background: rgba(161, 161, 239, 0.2); }
  @media (min-width: 37.5em) {
    .uol-page-width--index > * {
      margin-left: calc(100% / 12 + 0.0625rem);
      margin-right: calc(100% / 12 + 0.0625rem); } }
  @media (min-width: 64em) {
    .uol-page-width--index > * {
      margin-left: calc(100% / 9 + 0.25rem);
      margin-right: calc(100% / 9 + 0.25rem); } }
  @media (min-width: 90em) {
    .uol-page-width--index > * {
      margin-right: calc((100% / 9 * 2) + 0.5rem); } }
  @media (min-width: 37.5em) {
    .uol-page-width--index > * {
      margin-left: calc(100% / 12 + 0.125rem);
      margin-right: calc(100% / 12 + 0.125rem); } }
  @media (min-width: 64em) {
    .uol-page-width--index > * {
      margin-left: 0;
      margin-right: 0; } }

.uol-page-width--full {
  position: relative;
  flex-basis: calc(100% - 1rem);
  width: calc(100% - 1rem);
  padding: 0.5rem; }
  .uol-col-debug .uol-page-width--full {
    background: rgba(161, 161, 239, 0.2); }
  @media (min-width: 37.5em) {
    .uol-page-width--full > * {
      margin-left: calc(100% / 12 + 0.0625rem);
      margin-right: calc(100% / 12 + 0.0625rem); } }
  @media (min-width: 64em) {
    .uol-page-width--full > * {
      margin-left: calc(100% / 9 + 0.25rem);
      margin-right: calc(100% / 9 + 0.25rem); } }
  @media (min-width: 90em) {
    .uol-page-width--full > * {
      margin-right: calc((100% / 9 * 2) + 0.5rem); } }
  @media (min-width: 37.5em) {
    .uol-page-width--full > * {
      margin-left: 0;
      margin-right: 0; } }

.uol-page-width--max-width, .uol-homepage-content {
  position: relative; }
  @media (min-width: 48em) {
    .uol-page-width--max-width, .uol-homepage-content {
      padding: 1rem; } }
  .uol-col-debug .uol-page-width--max-width, .uol-col-debug .uol-homepage-content {
    background: rgba(255, 255, 0, 0.5); }

@media (min-width: 64em) {
  .uol-side-nav-container--populated + .uol-article .uol-article__head,
  .uol-side-nav-container--populated + .uol-article .uol-article__content,
  .uol-side-nav-container--populated + .uol-page .uol-article__head,
  .uol-side-nav-container--populated + .uol-page .uol-article__content {
    margin-right: 0;
    margin-left: 0; } }

@media (min-width: 64em) {
  .uol-side-nav-container--populated + .uol-article {
    max-width: calc(100% / 12 * 8 - 2rem);
    flex-basis: calc(100% / 12 * 8 - 2rem); } }

@media (min-width: 90em) {
  .uol-side-nav-container--populated + .uol-page {
    max-width: calc(100% / 12 * 8 - 2rem);
    flex-basis: calc(100% / 12 * 8 - 2rem);
    padding-left: calc(100% / 12 + 1rem) !important; } }

.uol-page .uol-rich-text--with-lead > p:first-of-type, .uol-article .uol-rich-text--with-lead > p:first-of-type {
  margin-top: 1.5rem;
  margin-bottom: 1rem; }
  @media (min-width: 48em) {
    .uol-page .uol-rich-text--with-lead > p:first-of-type, .uol-article .uol-rich-text--with-lead > p:first-of-type {
      position: relative;
      margin-top: 2.5rem;
      margin-bottom: 1.5rem; }
      .uol-page .uol-rich-text--with-lead > p:first-of-type::before, .uol-article .uol-rich-text--with-lead > p:first-of-type::before {
        content: "";
        display: inline-block;
        position: absolute;
        width: calc(100% / 12 - 0.25rem);
        height: 0.25rem;
        top: 0.65em;
        left: -18px;
        transform: translateX(-100%);
        background-color: #c70000; } }
  @media (min-width: 64em) {
    .uol-page .uol-rich-text--with-lead > p:first-of-type::before, .uol-article .uol-rich-text--with-lead > p:first-of-type::before {
      width: calc(100% / 7 - 20px);
      left: -28px; } }
  @media (min-width: 90em) {
    .uol-page .uol-rich-text--with-lead > p:first-of-type::before, .uol-article .uol-rich-text--with-lead > p:first-of-type::before {
      width: calc(100% / 6 - 28px);
      left: -36px; } }

.js .uol-accordion {
  position: relative;
  border-top: 1px solid #c9c8c5;
  border-bottom: 1px solid #c9c8c5;
  opacity: 0;
  transition: opacity 0.3s ease;
  margin-top: 2rem;
  margin-bottom: 2rem; }
  @media (min-width: 48em) {
    .js .uol-accordion {
      margin-bottom: 3rem; } }
  .js .uol-accordion + .uol-accordion {
    border-top: none;
    margin-top: -2rem; }
    @media (min-width: 48em) {
      .js .uol-accordion + .uol-accordion {
        margin-top: -3rem; } }

.js .uol-accordion--group-heading {
  margin: 2rem 0 0.5rem;
  padding-bottom: 0.5rem; }
  @media (min-width: 48em) {
    .js .uol-accordion--group-heading {
      margin: 3rem 0 1rem;
      padding-bottom: 1rem; } }

.js .uol-accordion__title {
  font-weight: 600;
  margin: 0; }

.js .uol-accordion__btn {
  width: 100%;
  text-align: inherit;
  font-weight: inherit;
  background: none;
  border: none;
  padding: 1rem 2rem 1rem 0;
  color: inherit; }
  .js .uol-accordion__btn::before, .js .uol-accordion__btn::after {
    content: "";
    width: 0.7em;
    height: 2px;
    position: absolute;
    top: 1.4em;
    right: 0.5rem;
    background: #212121;
    transition: transform 0.3s ease; }
    @media (forced-colors: active) {
      .js .uol-accordion__btn::before, .js .uol-accordion__btn::after {
        border: 1px solid transparent; } }
    @media (-ms-high-contrast: active) {
      .js .uol-accordion__btn::before, .js .uol-accordion__btn::after {
        border: 1px solid WindowText; } }
  .js .uol-accordion__btn::after {
    transform: rotate(0deg); }
  .js .uol-accordion__btn:hover, .js .uol-accordion__btn:focus {
    text-decoration: underline; }
  .js .uol-accordion__btn[aria-expanded="false"]::after {
    transform: rotate(90deg); }
  .js .uol-accordion__btn[aria-expanded="true"]::after {
    transform: rotate(180deg); }

.js .uol-accordion__content {
  position: relative;
  transition: max-height 0.5s ease;
  overflow-y: hidden; }
  .js .uol-accordion__content *:last-child {
    margin-bottom: 0; }

.js .uol-accordion__content-inner {
  padding-bottom: 1.5rem; }

.uol-accreditations {
  box-sizing: border-box; }
  @media screen and (forced-colors: active) {
    .uol-accreditations {
      background-color: #838383;
      forced-color-adjust: none; } }
  @media (min-width: 48em) {
    .uol-widget__content .uol-accreditations {
      margin: 0 -0.5rem;
      display: flex;
      flex-wrap: wrap; } }
  @media (min-width: 64em) {
    .uol-widget__content .uol-accreditations {
      margin: 0 -0.75rem; } }
  @media (min-width: 90em) {
    .uol-widget__content .uol-accreditations {
      margin: 0 -1rem; } }
  .uol-site-footer .uol-accreditations {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 18rem; }

@media (min-width: 37.5em) and (max-width: 47.99em) {
  .uol-site-footer .uol-accreditations {
    width: 16.5rem; } }
    @media (min-width: 48em) {
      .uol-site-footer .uol-accreditations {
        width: 19rem; } }
    @media (min-width: 64em) {
      .uol-site-footer .uol-accreditations {
        width: 49.5rem;
        justify-content: flex-start; } }
    @media (min-width: 90em) {
      .uol-site-footer .uol-accreditations {
        margin-top: 0.5rem; } }
    @media screen and (-ms-high-contrast: active) {
      .uol-site-footer .uol-accreditations {
        -ms-high-contrast-adjust: none; }
        .no-csspositionsticky .uol-site-footer .uol-accreditations {
          background-color: #838383; } }

.uol-accreditation {
  box-sizing: border-box; }
  @media (min-width: 48em) {
    .uol-accreditation {
      width: 50%;
      padding: 0 0.5rem; } }
  @media (min-width: 64em) {
    .uol-accreditation {
      padding: 0 0.75rem; } }
  @media (min-width: 90em) {
    .uol-accreditation {
      width: 25%;
      padding: 0 1rem; } }
  @media (min-width: 48em) {
    .uol-widget__content .uol-accreditation:nth-child(n+3) {
      margin-top: -2px; } }
  @media (min-width: 90em) {
    .uol-widget__content .uol-accreditation:nth-child(n+3) {
      margin-top: 0; }
    .uol-widget__content .uol-accreditation:nth-child(n+5) {
      margin-top: -2px; } }
  @media (min-width: 90em) {
    .uol-side-nav-container--populated + .uol-homepage-content .uol-widget__content .uol-accreditation {
      width: calc(100% / 3); }
      .uol-side-nav-container--populated + .uol-homepage-content .uol-widget__content .uol-accreditation:nth-child(n+4) {
        margin-top: -2px; } }
  .uol-site-footer .uol-accreditation {
    height: 4.25rem;
    padding: 0;
    width: 8rem; }

@media (min-width: 37.5em) and (max-width: 47.99em) {
  .uol-site-footer .uol-accreditation {
    width: 7.125rem; } }
    @media (min-width: 64em) {
      .uol-site-footer .uol-accreditation {
        width: fit-content;
        margin-right: 2rem; } }
    .uol-site-footer .uol-accreditation:nth-child(-n+2) {
      margin-bottom: 1.5rem; }
      @media (min-width: 64em) {
        .uol-site-footer .uol-accreditation:nth-child(-n+2) {
          margin-bottom: 0; } }
    .uol-site-footer .uol-accreditation:nth-child(n+5) {
      margin-top: 1.5rem; }
      @media (min-width: 64em) {
        .uol-site-footer .uol-accreditation:nth-child(n+5) {
          margin-bottom: 0; } }

.uol-accreditation__link {
  display: block;
  text-align: center;
  padding: 1.5rem 3rem; }
  .uol-widget__content .uol-accreditation__link {
    display: flex;
    padding: 1.5rem 3rem;
    min-height: 60px;
    justify-content: center;
    align-items: center;
    border-top: 2px solid #c70000; }
    @media (min-width: 48em) {
      .uol-widget__content .uol-accreditation__link {
        min-height: 80px; } }
    @media (min-width: 48em) {
      .uol-widget__content .uol-accreditation__link {
        border-bottom: 2px solid #c70000; } }
    @media (min-width: 64em) {
      .uol-widget__content .uol-accreditation__link {
        padding: 1.5rem 0; } }
    .uol-widget__content .uol-accreditation__link:focus {
      background-color: #ffeded;
      outline: none; }
  .uol-widget__content .uol-accreditation:last-of-type .uol-accreditation__link {
    border-bottom: 2px solid #c70000; }
  .uol-site-footer .uol-accreditation__link {
    display: inline-block;
    height: 4.25rem;
    padding: 0; }
    .uol-site-footer .uol-accreditation__link:focus {
      outline: 1px dotted #ff4e36;
      outline-offset: 1px;
      transition: outline 0.66s ease-in; }

.uol-accreditation__logo {
  max-width: 100%; }
  .no-csspositionsticky .uol-accreditation__logo {
    height: auto;
    height: 100%;
    width: 100%; }
    @media (min-width: 37.5em) {
      .no-csspositionsticky .uol-accreditation__logo {
        width: auto;
        height: auto; } }
  .uol-widget__content .uol-accreditation__logo {
    transform: scale3d(0.92, 0.92, 0.92);
    transition: transform 0.2s ease;
    max-height: 60px;
    vertical-align: middle; }
    @media (min-width: 48em) {
      .uol-widget__content .uol-accreditation__logo {
        max-height: 80px; } }
    .uol-widget__content .uol-accreditation__logo:hover,
    .uol-accreditation__link:focus .uol-widget__content .uol-accreditation__logo {
      transform: scale3d(1, 1, 1); }
  .uol-site-footer .uol-accreditation__logo {
    vertical-align: middle;
    object-fit: contain;
    transform: translate(0, -50%);
    max-width: 8rem;
    margin: 1.5rem 0;
    height: 4.25rem;
    max-width: 8rem; }
    @media (min-width: 48em) {
      .uol-site-footer .uol-accreditation__logo {
        height: 4.675rem;
        max-width: 8.8rem; } }
    .no-csspositionsticky .uol-site-footer .uol-accreditation__logo {
      width: 100%;
      height: auto; }

.uol-article-highlight {
  font-variant-numeric: lining-nums;
  background-color: #ffeded;
  padding: 1.5rem;
  margin: 2rem 0; }
  @media (min-width: 37.5em) {
    .uol-article-highlight {
      padding: 1.5rem calc(100% / 12); } }
  @media (min-width: 48em) {
    .uol-article-highlight {
      margin: 3rem 0; } }
  @media (min-width: 64em) {
    .uol-article-highlight {
      margin: 1.5rem 0 3rem;
      padding: 1.5rem; } }

.uol-article-highlight__title {
  margin: 0 0 0.5rem; }

.uol-article-highlight__subtitle {
  font-weight: 500;
  margin: 0.5rem 0 0.75rem; }

.uol-article-highlight__paragraph {
  margin: 0.75rem 0 0; }

.uol-article-section__content a {
  text-decoration: underline;
  color: inherit; }

.uol-article-section__content .uol-publications-list__further-link {
  text-decoration: none;
  font-weight: 600; }

.uol-hero-banner {
  width: calc(100vw - 0px);
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background-color: #212121; }

.uol-hero-banner--inner {
  max-width: 91.125rem;
  margin: 0 auto;
  display: flex;
  height: auto;
  flex-direction: column-reverse;
  background: #212121;
  color: #ffffff;
  overflow: hidden;
  max-height: 592px; }
  @media (min-width: 64em) {
    .uol-hero-banner--inner {
      flex-direction: row;
      align-items: center;
      padding-right: 100px; } }
  @media (min-width: 90em) {
    .uol-hero-banner--inner {
      padding-right: 130px; } }

.uol-hero-banner__text-container {
  padding: 1.5rem 1rem 2rem; }
  @media (min-width: 48em) {
    .uol-hero-banner__text-container {
      padding: 2rem 1rem 3rem 1rem; } }
  @media (min-width: 64em) {
    .uol-hero-banner__text-container {
      width: 38%;
      padding-left: 2rem;
      padding-right: 1.5rem; } }
  @media (min-width: 103.75em) {
    .uol-hero-banner__text-container {
      padding-left: 0; } }

.uol-hero-banner__title {
  margin: 0; }

.uol-hero-banner__lead {
  margin: 0.5rem 0 0; }

@media (min-width: 64em) {
  .uol-hero-banner__img {
    width: 62%;
    padding: 39px 0 50px 100px; } }

@media (min-width: 90em) {
  .uol-hero-banner__img {
    padding: 0; } }

.uol-banner-outer {
  background: #212121;
  overflow: hidden; }

.uol-banner-container {
  box-sizing: border-box;
  max-width: 103.75rem;
  margin: 0 auto;
  position: relative;
  color: #ffffff; }
  @media (min-width: 64em) {
    .uol-banner-container {
      padding: 0 1rem; } }

@media (min-width: 64em) {
  .uol-banner-container--multiple {
    padding-bottom: 1rem; } }

.uol-banner {
  list-style: none;
  margin: 0;
  padding: 0 0 2rem;
  display: flex;
  height: auto; }

.uol-banner--multiple {
  scrollbar-color: #ff4e36 #212121;
  scrollbar-base-color: #212121;
  scrollbar-3dlight-color: #212121;
  scrollbar-track-color: #212121;
  scrollbar-face-color: #ff4e36;
  scrollbar-arrow-color: #ff4e36;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth; }
  @media (forced-colors: active) {
    .uol-banner--multiple {
      scrollbar-color: ButtonText Canvas; } }
  .uol-banner--multiple::-webkit-scrollbar {
    background-color: #212121;
    height: 1rem; }
  .uol-banner--multiple::-webkit-scrollbar-track {
    background-color: #212121; }
  .uol-banner--multiple::-webkit-scrollbar-thumb {
    background-color: #c70000;
    background-image: linear-gradient(#212121 0, #212121 0.25rem, #ff4e36 0.25rem, #ff4e36 0.75rem, #212121 0.75rem); }
    @media (forced-colors: active) {
      .uol-banner--multiple::-webkit-scrollbar-thumb {
        background: ButtonText; } }
  .uol-banner--multiple > * {
    flex: 0 0 auto; }
  .uol-banner--multiple > * + * {
    margin-left: 1rem; }
  @media (min-width: 64em) {
    .uol-banner--multiple {
      max-width: calc(100% - 5rem); } }

.uol-banner__item {
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 1rem 2rem;
  width: 100%;
  transition: opacity 0.2s ease; }
  @media (min-width: 64em) {
    .uol-banner__item {
      width: 100%;
      display: block;
      padding-bottom: 1rem; }
      .uol-banner__item::before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        padding-bottom: 33%;
        float: right; } }
  .uol-banner--multiple .uol-banner__item {
    scroll-snap-align: start;
    width: 87%; }
    @media (min-width: 48em) {
      .uol-banner--multiple .uol-banner__item {
        width: 90%; } }
    @media (min-width: 64em) {
      .uol-banner--multiple .uol-banner__item {
        width: 100%;
        display: block; } }

.uol-banner__item--unfocused {
  opacity: 0.5; }

.uol-banner__item__title {
  margin: 1.5rem 0 0.5rem; }
  @media (min-width: 37.5em) {
    .uol-banner__item__title {
      margin-top: 2rem; } }
  @media (min-width: 64em) {
    .uol-banner__item__title {
      width: 36%;
      margin-top: 3rem; } }
  @media (min-width: 90em) {
    .uol-banner__item__title {
      width: 28%;
      margin-top: 4rem; } }

.uol-banner__item__lead {
  margin: 0 0 0.5rem; }
  @media (min-width: 64em) {
    .uol-banner__item__lead {
      width: 36%; } }
  @media (min-width: 90em) {
    .uol-banner__item__lead {
      width: 28%; } }

.uol-banner__item__img {
  order: -1;
  width: calc(100% + 1rem * 2);
  margin: 0 -1rem;
  height: auto;
  min-height: 1px; }
  @media (min-width: 64em) {
    .uol-banner__item__img {
      width: 62%;
      position: absolute;
      top: 2rem;
      right: 0; } }
  @media (min-width: 90em) {
    .uol-banner__item__img {
      width: 70%;
      top: 0; } }

.uol-banner__item__link {
  display: inline-block;
  margin: 0.5rem 0 0;
  font-size: 1.125rem;
  font-weight: 500; }
  @media (min-width: 48em) {
    .uol-banner__item__link {
      font-weight: 600; } }
  @media (min-width: 64em) {
    .uol-banner__item__link {
      max-width: 36%; } }
  @media (min-width: 90em) {
    .uol-banner__item__link {
      max-width: 28%; } }
  .uol-banner__item__link::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .js .uol-banner__item__link {
    text-decoration: none; }
    .js .uol-banner__item__link:hover, .js .uol-banner__item__link:focus {
      text-decoration: underline;
      text-decoration-color: #ff4e36; }
  .uol-banner__item__link svg {
    position: relative;
    margin-top: -0.35em;
    top: 0.35em;
    left: 0;
    margin-left: 0.5em;
    fill: #ff4e36;
    transition: all 0.3s ease 0.2s; }
    @media (-ms-high-contrast: active) {
      .uol-banner__item__link svg {
        fill: windowText; } }
  .uol-banner__item__link:hover svg, .uol-banner__item__link:focus svg {
    left: 0.4em; }

.uol-banner__button {
  right: calc(13% - 0.5rem);
  transform: translateX(50%);
  transition: all 0.5s ease; }
  .js .uol-banner-container .uol-banner__button.uol-icon--icon-only {
    position: absolute; }
  .uol-banner__button:disabled {
    opacity: 0.7; }
  @media (min-width: 48em) {
    .uol-banner__button {
      right: calc(10% - 0.5rem); } }
  @media (min-width: 64em) {
    .uol-banner__button {
      right: 3.125rem; } }

.uol-banner__button--prev {
  top: 2rem;
  position: relative;
  outline: 0 dotted #197ebe;
  outline-offset: 3px;
  overflow: visible; }
  .uol-banner__button--prev::before {
    content: "";
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    border: 3px solid transparent;
    border-radius: true;
    transition: all 0.15s; }
    @media (-ms-high-contrast: active), (forced-colors: active) {
      .uol-banner__button--prev::before {
        display: none; } }
  @media (-ms-high-contrast: active), (forced-colors: active) {
    .uol-banner__button--prev:focus {
      outline-width: 3px; } }
  .uol-banner__button--prev:focus::before {
    border-color: #197ebe; }
  .uol-banner__button--prev:focus:not(:focus-visible)::before {
    border-color: transparent; }
  .uol-banner__button--prev:focus-visible::before {
    border-color: #197ebe; }
  @media (min-width: 25.875em) {
    .uol-banner__button--prev {
      top: calc(15% - 30px); } }
  @media (min-width: 37.5em) {
    .uol-banner__button--prev {
      top: calc(20% - 30px); } }
  @media (min-width: 64em) {
    .uol-banner__button--prev {
      top: 33%;
      transform: translateX(50%) translateY(-50%); } }

.uol-banner__button--next {
  top: 5.5rem;
  position: relative;
  outline: 0 dotted #197ebe;
  outline-offset: 3px;
  overflow: visible; }
  .uol-banner__button--next::before {
    content: "";
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    border: 3px solid transparent;
    border-radius: true;
    transition: all 0.15s; }
    @media (-ms-high-contrast: active), (forced-colors: active) {
      .uol-banner__button--next::before {
        display: none; } }
  @media (-ms-high-contrast: active), (forced-colors: active) {
    .uol-banner__button--next:focus {
      outline-width: 3px; } }
  .uol-banner__button--next:focus::before {
    border-color: #197ebe; }
  .uol-banner__button--next:focus:not(:focus-visible)::before {
    border-color: transparent; }
  .uol-banner__button--next:focus-visible::before {
    border-color: #197ebe; }
  @media (min-width: 25.875em) {
    .uol-banner__button--next {
      top: calc(15% + 30px); } }
  @media (min-width: 37.5em) {
    .uol-banner__button--next {
      top: calc(20% + 30px); } }
  @media (min-width: 64em) {
    .uol-banner__button--next {
      top: calc(33% + 4rem);
      transform: translateX(50%) translateY(-50%); } }

.uol-banner__counter {
  position: absolute;
  bottom: 2rem;
  left: 1rem;
  font-variant-numeric: lining-nums; }
  @media (min-width: 64em) {
    .uol-banner__counter {
      left: auto;
      bottom: auto;
      top: 20%;
      right: 3rem;
      transform: translateX(30%);
      padding-bottom: 0.2em;
      border-bottom: 1px solid #51504c; } }

.uol-banner__counter__current {
  color: #ff4e36; }

.uol-banner__counter__total::before {
  content: " / "; }

.uol-breadcrumb {
  padding: 1.5rem 1rem 0; }
  @media (min-width: 25.875em) {
    .uol-breadcrumb {
      padding: 1.5rem 1rem 0; } }
  @media (min-width: 48em) {
    .uol-breadcrumb {
      padding: 1.5rem 1rem 0; } }
  @media (min-width: 64em) {
    .uol-breadcrumb {
      padding: 2rem 0 0;
      margin: 0 1.5rem; } }
  @media (min-width: 90em) {
    .uol-breadcrumb {
      margin: 0 2rem; } }
  @media (min-width: 64em) {
    .uol-banner-outer + .uol-content-container .uol-breadcrumb {
      padding-top: 0.5rem; } }

.uol-breadcrumb__list {
  font-size: 0.875rem;
  margin: 0;
  padding: 0 0 1rem;
  list-style: none;
  border-bottom: 1px solid #c9c8c5; }
  @media (min-width: 48em) {
    .uol-breadcrumb__list {
      font-size: 0.875rem; } }
  @media (min-width: 64em) {
    .uol-breadcrumb__list {
      font-size: 1rem; } }
  @media (min-width: 48em) {
    .uol-breadcrumb__list {
      padding-bottom: 1.5rem; } }
  @media (min-width: 64em) {
    .uol-breadcrumb__list {
      padding-bottom: 2rem; } }

.uol-breadcrumb__item::before {
  content: "<";
  margin-right: 0.3em; }

@media (max-width: 47.99em) {
  .uol-breadcrumb__item {
    display: none; }
    .uol-breadcrumb__item:last-of-type {
      display: inline-block; } }

@media (min-width: 48em) {
  .uol-breadcrumb__item {
    display: inline-block; }
    .uol-breadcrumb__item::before {
      content: ">";
      margin-right: 0.3em; }
    .uol-breadcrumb__item:first-of-type::before {
      content: none; } }

.uol-breadcrumb__link {
  transition: background 0.15s ease-out, text-decoration 0.15s ease-out;
  padding: 0.5em;
  transition: text-decoration-color 0.3s ease; }
  .uol-breadcrumb__link:focus {
    color: #212529;
    background-color: #ffeded;
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-decoration-color: #ff4e36;
    text-underline-offset: 2px;
    outline: 2px dotted inherit;
    outline-offset: 1px;
    outline-color: transparent;
    background: none; }
  .uol-breadcrumb__link:focus:not(:focus-visible) {
    text-decoration: none;
    outline-color: transparent; }
  .uol-breadcrumb__link:focus-visible {
    color: #212529;
    outline-color: transparent;
    outline-offset: 1px;
    background-color: #ffeded;
    background: none; }
  .uol-breadcrumb__link:hover {
    text-decoration-color: #c70000; }
  .uol-breadcrumb__link:first-of-type {
    padding-left: 0; }

.uol-button, .js .uolcc-container .uolcc-button,
.js .uolcc-prompt-container .uolcc-button,
.js .uolcc-privacy-update-container .uolcc-button, .uol-section-nav-ctas__link {
  font-size: 1.125rem;
  background: #212121 radial-gradient(circle, transparent 1%, #212121 1%) center/15000%;
  position: relative;
  outline: 0 dotted #197ebe;
  outline-offset: 3px;
  overflow: visible;
  line-height: 1;
  box-sizing: border-box;
  min-width: 10rem;
  border: 0;
  padding: 0.8em 1.8em;
  border: 2px solid #212121;
  border-radius: 6px;
  color: #ffffff;
  background-position: center;
  text-decoration: none;
  transition: background 0.5s ease; }
  @media (min-width: 48em) {
    .uol-button, .js .uolcc-container .uolcc-button,
    .js .uolcc-prompt-container .uolcc-button,
    .js .uolcc-privacy-update-container .uolcc-button, .uol-section-nav-ctas__link {
      font-size: 1.125rem; } }
  @media (min-width: 64em) {
    .uol-button, .js .uolcc-container .uolcc-button,
    .js .uolcc-prompt-container .uolcc-button,
    .js .uolcc-privacy-update-container .uolcc-button, .uol-section-nav-ctas__link {
      font-size: 1.25rem; } }
  .uol-button::before, .js .uolcc-container .uolcc-button::before,
  .js .uolcc-prompt-container .uolcc-button::before,
  .js .uolcc-privacy-update-container .uolcc-button::before, .uol-section-nav-ctas__link::before {
    content: "";
    position: absolute;
    top: -6px;
    right: -6px;
    bottom: -6px;
    left: -6px;
    border: 3px solid transparent;
    border-radius: 9px;
    transition: all 0.15s; }
    @media (-ms-high-contrast: active), (forced-colors: active) {
      .uol-button::before, .js .uolcc-container .uolcc-button::before,
      .js .uolcc-prompt-container .uolcc-button::before,
      .js .uolcc-privacy-update-container .uolcc-button::before, .uol-section-nav-ctas__link::before {
        display: none; } }
  @media (-ms-high-contrast: active), (forced-colors: active) {
    .uol-button:focus, .js .uolcc-container .uolcc-button:focus,
    .js .uolcc-prompt-container .uolcc-button:focus,
    .js .uolcc-privacy-update-container .uolcc-button:focus, .uol-section-nav-ctas__link:focus {
      outline-width: 3px; } }
  .uol-button:focus::before, .js .uolcc-container .uolcc-button:focus::before,
  .js .uolcc-prompt-container .uolcc-button:focus::before,
  .js .uolcc-privacy-update-container .uolcc-button:focus::before, .uol-section-nav-ctas__link:focus::before {
    border-color: #197ebe; }
  .uol-button:focus:not(:focus-visible)::before, .js .uolcc-container .uolcc-button:focus:not(:focus-visible)::before,
  .js .uolcc-prompt-container .uolcc-button:focus:not(:focus-visible)::before,
  .js .uolcc-privacy-update-container .uolcc-button:focus:not(:focus-visible)::before, .uol-section-nav-ctas__link:focus:not(:focus-visible)::before {
    border-color: transparent; }
  .uol-button:focus-visible::before, .js .uolcc-container .uolcc-button:focus-visible::before,
  .js .uolcc-prompt-container .uolcc-button:focus-visible::before,
  .js .uolcc-privacy-update-container .uolcc-button:focus-visible::before, .uol-section-nav-ctas__link:focus-visible::before {
    border-color: #197ebe; }
  @media (-ms-high-contrast: active) {
    .uol-button, .js .uolcc-container .uolcc-button,
    .js .uolcc-prompt-container .uolcc-button,
    .js .uolcc-privacy-update-container .uolcc-button, .uol-section-nav-ctas__link {
      border: 1px solid WindowText; } }
  .uol-button:hover, .js .uolcc-container .uolcc-button:hover,
  .js .uolcc-prompt-container .uolcc-button:hover,
  .js .uolcc-privacy-update-container .uolcc-button:hover, .uol-section-nav-ctas__link:hover, .uol-button:active, .js .uolcc-container .uolcc-button:active,
  .js .uolcc-prompt-container .uolcc-button:active,
  .js .uolcc-privacy-update-container .uolcc-button:active, .uol-section-nav-ctas__link:active {
    text-decoration: none;
    box-shadow: 0 3px 6px 0 rgba(33, 33, 33, 0.15), 0 2px 4px 0 rgba(33, 33, 33, 0.12); }
  .uol-button:active, .js .uolcc-container .uolcc-button:active,
  .js .uolcc-prompt-container .uolcc-button:active,
  .js .uolcc-privacy-update-container .uolcc-button:active, .uol-section-nav-ctas__link:active {
    background-color: #545454;
    background-size: 100%;
    transition: background 0s; }
  .uol-button:disabled, .js .uolcc-container .uolcc-button:disabled,
  .js .uolcc-prompt-container .uolcc-button:disabled,
  .js .uolcc-privacy-update-container .uolcc-button:disabled, .uol-section-nav-ctas__link:disabled {
    color: #bcb8b3;
    background: #ddd9d5;
    border: 0.125rem solid #f0f0f0; }
    .uol-button:disabled:hover, .js .uolcc-container .uolcc-button:disabled:hover,
    .js .uolcc-prompt-container .uolcc-button:disabled:hover,
    .js .uolcc-privacy-update-container .uolcc-button:disabled:hover, .uol-section-nav-ctas__link:disabled:hover {
      box-shadow: none;
      cursor: not-allowed; }
  .js .uol-button.uol-icon, .js .uolcc-container .uol-icon.uolcc-button,
  .js .uolcc-prompt-container .uol-icon.uolcc-button,
  .js .uolcc-privacy-update-container .uol-icon.uolcc-button, .js .uol-icon.uol-section-nav-ctas__link {
    padding: 0.8em 2.2em; }
    .js .uol-button.uol-icon svg, .js .uolcc-container .uol-icon.uolcc-button svg, .js .uolcc-prompt-container .uol-icon.uolcc-button svg, .js .uolcc-privacy-update-container .uol-icon.uolcc-button svg, .js .uol-icon.uol-section-nav-ctas__link svg {
      margin-top: 0; }
  .js .uol-button.uol-icon--icon-only, .js .uolcc-container .uol-icon--icon-only.uolcc-button,
  .js .uolcc-prompt-container .uol-icon--icon-only.uolcc-button,
  .js .uolcc-privacy-update-container .uol-icon--icon-only.uolcc-button, .js .uol-icon--icon-only.uol-section-nav-ctas__link {
    position: relative;
    outline: 0 dotted #197ebe;
    outline-offset: 3px;
    overflow: visible;
    min-width: 0;
    border-radius: 50%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.81rem;
    height: 2.81rem;
    min-height: 2.81rem; }
    .js .uol-button.uol-icon--icon-only::before, .js .uolcc-container .uol-icon--icon-only.uolcc-button::before,
    .js .uolcc-prompt-container .uol-icon--icon-only.uolcc-button::before,
    .js .uolcc-privacy-update-container .uol-icon--icon-only.uolcc-button::before, .js .uol-icon--icon-only.uol-section-nav-ctas__link::before {
      content: "";
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border: 3px solid transparent;
      border-radius: 50%;
      transition: all 0.15s; }
      @media (-ms-high-contrast: active), (forced-colors: active) {
        .js .uol-button.uol-icon--icon-only::before, .js .uolcc-container .uol-icon--icon-only.uolcc-button::before,
        .js .uolcc-prompt-container .uol-icon--icon-only.uolcc-button::before,
        .js .uolcc-privacy-update-container .uol-icon--icon-only.uolcc-button::before, .js .uol-icon--icon-only.uol-section-nav-ctas__link::before {
          display: none; } }
    @media (-ms-high-contrast: active), (forced-colors: active) {
      .js .uol-button.uol-icon--icon-only:focus, .js .uolcc-container .uol-icon--icon-only.uolcc-button:focus,
      .js .uolcc-prompt-container .uol-icon--icon-only.uolcc-button:focus,
      .js .uolcc-privacy-update-container .uol-icon--icon-only.uolcc-button:focus, .js .uol-icon--icon-only.uol-section-nav-ctas__link:focus {
        outline-width: 3px; } }
    .js .uol-button.uol-icon--icon-only:focus::before, .js .uolcc-container .uol-icon--icon-only.uolcc-button:focus::before,
    .js .uolcc-prompt-container .uol-icon--icon-only.uolcc-button:focus::before,
    .js .uolcc-privacy-update-container .uol-icon--icon-only.uolcc-button:focus::before, .js .uol-icon--icon-only.uol-section-nav-ctas__link:focus::before {
      border-color: #197ebe; }
    .js .uol-button.uol-icon--icon-only:focus:not(:focus-visible)::before, .js .uolcc-container .uol-icon--icon-only.uolcc-button:focus:not(:focus-visible)::before,
    .js .uolcc-prompt-container .uol-icon--icon-only.uolcc-button:focus:not(:focus-visible)::before,
    .js .uolcc-privacy-update-container .uol-icon--icon-only.uolcc-button:focus:not(:focus-visible)::before, .js .uol-icon--icon-only.uol-section-nav-ctas__link:focus:not(:focus-visible)::before {
      border-color: transparent; }
    .js .uol-button.uol-icon--icon-only:focus-visible::before, .js .uolcc-container .uol-icon--icon-only.uolcc-button:focus-visible::before,
    .js .uolcc-prompt-container .uol-icon--icon-only.uolcc-button:focus-visible::before,
    .js .uolcc-privacy-update-container .uol-icon--icon-only.uolcc-button:focus-visible::before, .js .uol-icon--icon-only.uol-section-nav-ctas__link:focus-visible::before {
      border-color: #197ebe; }
    @media (min-width: 64em) {
      .js .uol-button.uol-icon--icon-only, .js .uolcc-container .uol-icon--icon-only.uolcc-button,
      .js .uolcc-prompt-container .uol-icon--icon-only.uolcc-button,
      .js .uolcc-privacy-update-container .uol-icon--icon-only.uolcc-button, .js .uol-icon--icon-only.uol-section-nav-ctas__link {
        width: 3.12rem;
        height: 3.12rem; } }
    .js .uol-button.uol-icon--icon-only svg, .js .uolcc-container .uol-icon--icon-only.uolcc-button svg, .js .uolcc-prompt-container .uol-icon--icon-only.uolcc-button svg, .js .uolcc-privacy-update-container .uol-icon--icon-only.uolcc-button svg, .js .uol-icon--icon-only.uol-section-nav-ctas__link svg {
      position: absolute; }
  .js .uol-button.uol-icon--icon-only--large svg, .js .uolcc-container .uol-icon--icon-only--large.uolcc-button svg, .js .uolcc-prompt-container .uol-icon--icon-only--large.uolcc-button svg, .js .uolcc-privacy-update-container .uol-icon--icon-only--large.uolcc-button svg, .js .uol-icon--icon-only--large.uol-section-nav-ctas__link svg {
    transform: scale(1.4); }

.uol-button--primary, .js .uolcc-container .uolcc-button,
.js .uolcc-prompt-container .uolcc-button,
.js .uolcc-privacy-update-container .uolcc-button {
  background: #156265 radial-gradient(circle, transparent 1%, #156265 1%) center/15000%;
  border-color: #156265; }
  .uol-button--primary:active, .js .uolcc-container .uolcc-button:active,
  .js .uolcc-prompt-container .uolcc-button:active,
  .js .uolcc-privacy-update-container .uolcc-button:active {
    background-color: #1b7f83; }

.uol-button--secondary, .js .uolcc-container .uolcc-button--secondary,
.js .uolcc-prompt-container .uolcc-button--secondary,
.js .uolcc-privacy-update-container .uolcc-button--secondary {
  background: #ffffff radial-gradient(circle, transparent 1%, #ffffff 1%) center/15000%;
  color: #156265;
  border-color: #156265; }
  .uol-button--secondary:active, .js .uolcc-container .uolcc-button--secondary:active,
  .js .uolcc-prompt-container .uolcc-button--secondary:active,
  .js .uolcc-privacy-update-container .uolcc-button--secondary:active {
    background-color: #e6e6e6; }

.uol-button--bright {
  background: #ff4e36 radial-gradient(circle, transparent 1%, #ff4e36 1%) center/15000%;
  border-color: #ff4e36; }
  .uol-button--bright:active {
    background-color: #ff6d5a; }

.uol-cards {
  list-style: none;
  margin: 0;
  padding-left: 0; }
  @media (min-width: 48em) {
    .uol-cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; } }

@media (min-width: 48em) {
  .uol-cards--featured:not(.uol-cards--count-2) .uol-cards__card:first-of-type {
    border: none; } }

@media (min-width: 48em) {
  .uol-cards--featured:not(.uol-cards--count-2) .uol-cards__card:first-of-type .uol-cards__card__link::after {
    display: none; } }

.uol-cards__card {
  position: relative;
  border: 1px solid rgba(201, 200, 197, 0.6);
  border-radius: 12px;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column; }
  @media (min-width: 48em) {
    .uol-cards__card {
      width: calc(50% - 0.5rem); } }
  @media (min-width: 64em) {
    .uol-cards__card {
      margin-bottom: 1.5rem;
      width: calc(50% - 0.75rem); } }
  @media (min-width: 90em) {
    .uol-cards__card {
      margin-bottom: 1.5rem;
      width: calc(100% / 3 - 1.33333rem); } }
  @media (min-width: 103.75em) {
    .uol-cards__card {
      margin-bottom: 2rem; } }
  @media (min-width: 64em) {
    .uol-cards--count-7:not(.uol-cards--featured) .uol-cards__card:nth-of-type(-n+4),
    .uol-cards--count-6.uol-cards--featured .uol-cards__card:nth-of-type(5),
    .uol-cards--count-6.uol-cards--featured .uol-cards__card:nth-of-type(6),
    .uol-cards--count-5.uol-cards--featured .uol-cards__card:not(:first-of-type),
    .uol-cards--count-5:not(.uol-cards--featured) .uol-cards__card:nth-of-type(-n+2),
    .uol-cards--count-4:not(.uol-cards--featured):not(.uol-cards--wide-container) .uol-cards__card,
    .uol-cards--count-3.uol-cards--featured .uol-cards__card:not(:first-of-type),
    .uol-cards--count-2 .uol-cards__card,
    .uol-cards--count-1:not(.uol-cards--featured) .uol-cards__card {
      width: calc(50% - 0.75rem); } }
  @media (min-width: 90em) {
    .uol-cards--count-7:not(.uol-cards--featured) .uol-cards__card:nth-of-type(-n+4),
    .uol-cards--count-6.uol-cards--featured .uol-cards__card:nth-of-type(5),
    .uol-cards--count-6.uol-cards--featured .uol-cards__card:nth-of-type(6),
    .uol-cards--count-5.uol-cards--featured .uol-cards__card:not(:first-of-type),
    .uol-cards--count-5:not(.uol-cards--featured) .uol-cards__card:nth-of-type(-n+2),
    .uol-cards--count-4:not(.uol-cards--featured):not(.uol-cards--wide-container) .uol-cards__card,
    .uol-cards--count-3.uol-cards--featured .uol-cards__card:not(:first-of-type),
    .uol-cards--count-2 .uol-cards__card,
    .uol-cards--count-1:not(.uol-cards--featured) .uol-cards__card {
      width: calc(50% - 1rem); } }

.uol-cards__card__image-wrapper {
  height: 0;
  padding-top: 66.66%;
  position: relative;
  border-radius: 12px 12px 0 0;
  background-image: url("../img/placeholder-uol-icon.svg");
  background-size: cover;
  background-clip: padding-box;
  overflow: hidden;
  text-align: center; }
  @media (forced-colors: active) {
    .uol-cards__card__image-wrapper {
      box-sizing: border-box;
      border: 1px solid CanvasText; } }
  @media (-ms-high-contrast: active) {
    .uol-cards__card__image-wrapper {
      box-sizing: border-box;
      border: 1px solid WindowText; } }
  @media (-ms-high-contrast: active), (forced-colors: active) {
    .uol-cards__card__image-wrapper {
      -ms-high-contrast-adjust: none;
      forced-color-adjust: none; } }

.uol-cards__card__image {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  height: 100%;
  border-radius: 11px 11px 0 0; }

.uol-cards__card__text-wrapper {
  padding: 0 1rem 1.5rem;
  color: #3b3b3b;
  background-color: #eae8e6;
  box-sizing: border-box;
  border-radius: 0 0 11px 11px;
  flex: 1 1 auto;
  order: 1;
  z-index: 1; }

.uol-cards__card__title {
  padding: 1rem 0 0.5rem 0;
  margin: 0;
  font-weight: 800;
  line-height: 1.4; }

.uol-cards__card__link {
  text-decoration: none; }
  .uol-cards__card__link::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: box-shadow 0.25s ease-in-out;
    z-index: 3;
    border-radius: 11px; }
  .uol-cards__card__link:hover::before, .uol-cards__card__link:focus-within::before {
    box-shadow: 0 15px 25px rgba(10, 2, 2, 0.15), 0 5px 10px rgba(10, 2, 2, 0.05); }
  @media (min-width: 48em) {
    .uol-cards--featured:not(.uol-cards--count-2) .uol-cards__card:first-of-type .uol-cards__card__link:hover, .uol-cards--featured:not(.uol-cards--count-2) .uol-cards__card:first-of-type .uol-cards__card__link:focus-within {
      text-decoration: underline; } }
  .uol-cards__card__link::after {
    content: "";
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    padding-bottom: 66.66%;
    background: transparent;
    border-bottom: 0.5rem solid #c70000;
    width: 1rem;
    transition: width 0.3s ease-in-out 0.1s; }
  .uol-cards__card:focus-within .uol-cards__card__link::after {
    width: 100%; }
  .uol-cards__card:hover .uol-cards__card__link::after {
    width: 100%; }

.uol-cards__card__link__video::after {
  content: none; }

@media (min-width: 48em) {
  .uol-cards--featured:not(.uol-cards--count-2) .uol-cards__card:first-of-type .uol-cards__card__link::after {
    display: none; } }

.uol-cards__card__text {
  font-size: 1.125rem;
  line-height: 1.625;
  margin: 0; }
  @media (min-width: 48em) {
    .uol-cards__card__text {
      font-size: 1rem; } }
  @media (min-width: 64em) {
    .uol-cards__card__text {
      font-size: 1.125rem; } }
  @media (min-width: 48em) {
    .uol-cards__card__text {
      line-height: 1.625; } }
  @media (min-width: 64em) {
    .uol-cards__card__text {
      line-height: 1.556; } }

@media (min-width: 48em) {
  .uol-cards--featured:not(.uol-cards--count-2) .uol-cards__card:first-of-type {
    display: block;
    width: 100%; }
    .uol-cards--featured:not(.uol-cards--count-2) .uol-cards__card:first-of-type .uol-cards__card__image-wrapper {
      float: right;
      width: calc(100% / 12 * 10 - 0.25rem);
      padding-top: calc(50% - 1rem);
      border: 1px solid rgba(201, 200, 197, 0.6);
      border-radius: 12px; }
      .uol-cards--featured:not(.uol-cards--count-2) .uol-cards__card:first-of-type .uol-cards__card__image-wrapper::before {
        display: none; }
    .uol-cards--featured:not(.uol-cards--count-2) .uol-cards__card:first-of-type .uol-cards__card__image {
      border-radius: 11px; }
    .uol-cards--featured:not(.uol-cards--count-2) .uol-cards__card:first-of-type .uol-cards__card__text-wrapper {
      position: absolute;
      bottom: 4rem;
      left: 0;
      width: calc(50% - 0.5rem);
      height: fit-content;
      padding: 1.5rem 1.5rem 2rem;
      border: 1px solid rgba(201, 200, 197, 0.6);
      border-radius: 11px; }
      .uol-cards--featured:not(.uol-cards--count-2) .uol-cards__card:first-of-type .uol-cards__card__text-wrapper .uol-cards__card__title {
        padding-top: 0; } }

@media (min-width: 64em) {
  .uol-cards--featured:not(.uol-cards--count-2) .uol-cards__card:first-of-type .uol-cards__card__image-wrapper {
    float: right;
    width: calc(80% - 0.25rem);
    padding-top: calc(50% - 2.75rem); }
    .uol-side-nav-container--populated + .uol-homepage-content .uol-cards--featured:not(.uol-cards--count-2) .uol-cards__card:first-of-type .uol-cards__card__image-wrapper {
      width: calc(100% / 9 * 7 - 0.5rem); }
  .uol-cards--featured:not(.uol-cards--count-2) .uol-cards__card:first-of-type .uol-cards__card__text-wrapper {
    width: calc(50% - 1rem); }
    .uol-side-nav-container--populated + .uol-homepage-content .uol-cards--featured:not(.uol-cards--count-2) .uol-cards__card:first-of-type .uol-cards__card__text-wrapper {
      width: calc(100% / 9 * 4 - 1rem); } }

@media (min-width: 48em) {
  .uol-cards--featured:not(.uol-cards--count-2) .uol-cards__card:first-of-type .uol-cards__card__text-wrapper {
    bottom: 2rem; }
    @supports (-webkit-line-clamp: 4) {
      .uol-cards--featured:not(.uol-cards--count-2) .uol-cards__card:first-of-type .uol-cards__card__text-wrapper {
        height: auto; } }
  .uol-cards--featured:not(.uol-cards--count-2) .uol-cards__card__text {
    overflow: hidden;
    max-height: calc(1.125em * 1.4 * 4); }
    @supports (-webkit-line-clamp: 4) {
      .uol-cards--featured:not(.uol-cards--count-2) .uol-cards__card__text {
        line-clamp: 4;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        max-height: 100%; } } }

@media (min-width: 90em) {
  .uol-cards--featured:not(.uol-cards--count-2) .uol-cards__card:first-of-type .uol-cards__card__text-wrapper {
    bottom: 4rem; }
  .uol-cards--featured:not(.uol-cards--count-2) .uol-cards__card:first-of-type .uol-cards__card__text {
    max-height: calc(1.125em * 1.4 * 6); }
    @supports (-webkit-line-clamp: 6) {
      .uol-cards--featured:not(.uol-cards--count-2) .uol-cards__card:first-of-type .uol-cards__card__text {
        line-clamp: 6;
        -webkit-line-clamp: 6; } }
  .uol-cards--featured:not(.uol-cards--count-2) .uol-cards__card:first-of-type .uol-cards__card__image-wrapper {
    width: calc(((100% + 2rem) / 12 * 8) - 2rem);
    padding-top: calc(50% - 7.5rem); }
    .uol-side-nav-container--populated + .uol-homepage-content .uol-cards--featured:not(.uol-cards--count-2) .uol-cards__card:first-of-type .uol-cards__card__image-wrapper {
      width: calc(100% / 9 * 6 - 0.5rem); }
  .uol-cards--featured:not(.uol-cards--count-2) .uol-cards__card:first-of-type .uol-cards__card__text-wrapper {
    width: calc(50% - 1rem); }
    .uol-side-nav-container--populated + .uol-homepage-content .uol-cards--featured:not(.uol-cards--count-2) .uol-cards__card:first-of-type .uol-cards__card__text-wrapper {
      left: 0;
      width: calc(100% / 9 * 4 - 1rem); } }

.uol-cards__card--without-image .uol-cards__card__text-wrapper {
  border-radius: 11px; }

.uol-cards__card--without-image::before {
  content: "";
  z-index: 2;
  position: absolute;
  top: 1rem;
  left: 1rem;
  height: 0.5rem;
  width: 1rem;
  background-color: #c70000; }
  @media (forced-colors: active) {
    .uol-cards__card--without-image::before {
      box-sizing: border-box;
      border: 0.25rem solid CanvasText; } }
  @media (-ms-high-contrast: active) {
    .uol-cards__card--without-image::before {
      box-sizing: border-box;
      border: 0.25rem solid WindowText; } }

.uol-cards__card--without-image .uol-cards__card__title {
  padding-top: 2rem; }
  .uol-cards__card--without-image .uol-cards__card__title:hover {
    text-decoration: underline;
    color: #0e0c0c; }
  .uol-cards__card--without-image .uol-cards__card__title:focus-within {
    text-decoration: underline;
    color: #0e0c0c; }

.uol-cards__card--without-image .uol-cards__card__link::after {
  display: none; }

.uol-cards__card__date {
  display: block;
  color: #636363;
  font-variant-numeric: lining-nums;
  line-height: 1.625; }
  .uol-cards__card__date + .uol-cards__card__text:first-of-type {
    margin-top: 1.5rem; }

.uol-cards__card__video-icon::before {
  content: "";
  box-sizing: border-box;
  top: -2.4rem;
  margin-top: 66.66%;
  position: absolute;
  width: 3rem;
  height: 3rem;
  display: block;
  z-index: 2;
  background-color: #c70000;
  border: 3px solid #eae8e6;
  border-radius: 50%; }

.uol-cards__card__video-icon::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  display: block;
  top: -1.5rem;
  margin-top: 66.66%;
  border-style: solid;
  border-width: 9px 0 9px 16px;
  border-color: transparent transparent transparent #fff;
  left: 2.1rem;
  z-index: 3; }

.uol-cards__card:hover .uol-cards__card__video-icon::before {
  box-shadow: 0 3px 6px 0 rgba(33, 33, 33, 0.15), 0 2px 4px 0 rgba(33, 33, 33, 0.12); }

.uol-cards__card:focus-within .uol-cards__card__video-icon::before {
  outline: 3px solid #197ebe; }

.uol-cards--image-layout .uol-cards__card--without-image::before {
  display: none; }

.uol-cards--image-layout .uol-cards__card__text-wrapper {
  border-radius: 0 0 11px 11px; }

.uol-cards--image-layout .uol-cards__card__link::after {
  display: inline-block; }

.uol-cards--image-layout .uol-cards__card__title {
  padding-top: 1rem; }

/*  ----------------------  Layout styling for News and Events cards

  - 2 layout types, with side nav and without
  - Never display more than 8 cards
  - If there is a side nav, never display more than 6
  - Viewports below 1024px, never display more than 6

  -- Both layout types
  >   Display only 6 or 3 cards below 600px - 1 column
  >   Display only 6 or 4 cards between 600px and below 1024px - 2 columns

  --- With side navigation
  >    Display either 6 or 3 cards - 3 column approach for viewports of 1024px and larger

  ---- Without side navigation
  >     Display either 8 or 4 cards - 4 column approach for viewports of 1024px and larger

  ----- Additional CSS
  >      Prevented large spaces between cards for when there are only 2 or 3 with an invisible pseudo element
         Which would have happened if there were only 2 or 3 events

  ------ Consideration
  >       If there are 3 very important events for example, and an author is asked to add a very important 4th, it won't
          display under certain scenarios, do they add 3 more just to get the 4th to show? Whether it actually displays
          or not is dependent on the viewport and whether there is a side nav
*/
@media (max-width: 89.99em) {
  .uol-widget--news-events .uol-cards__card:nth-of-type(n+7) {
    display: none; } }

.uol-widget--news-events .uol-cards__card:nth-of-type(n+9) {
  display: none; }

@media (min-width: 48em) and (max-width: 63.99em) {
  .uol-widget--news-events .uol-cards__card {
    width: calc(100% / 2 - 0.5rem); } }

@media (min-width: 64em) {
  .uol-widget--news-events .uol-cards .uol-cards__card:nth-of-type(n+1) {
    width: calc(100% / 3 - 1rem); } }

@media (min-width: 90em) {
  .uol-widget--news-events .uol-cards .uol-cards__card:nth-of-type(n+1) {
    width: calc(100% / 4 - 1.5rem);
    margin-bottom: 2rem; } }

@media (min-width: 64em) {
  .uol-widget--news-events .uol-cards--count-2,
  .uol-widget--news-events .uol-cards--count-3 {
    flex-wrap: nowrap; }
    .uol-widget--news-events .uol-cards--count-2 .uol-cards__card:not(:first-of-type),
    .uol-widget--news-events .uol-cards--count-3 .uol-cards__card:not(:first-of-type) {
      margin-left: 1.5rem; }
    .uol-widget--news-events .uol-cards--count-2::after,
    .uol-widget--news-events .uol-cards--count-3::after {
      content: "";
      flex: auto; } }

@media (min-width: 90em) {
  .uol-widget--news-events .uol-cards--count-2 .uol-cards__card:not(:first-of-type),
  .uol-widget--news-events .uol-cards--count-3 .uol-cards__card:not(:first-of-type) {
    margin-left: 2rem; } }

@media (max-width: 47.99em) {
  .uol-widget--news-events .uol-cards--count-4 .uol-cards__card:nth-of-type(n+4),
  .uol-widget--news-events .uol-cards--count-5 .uol-cards__card:nth-of-type(n+4) {
    display: none; } }

@media (min-width: 64em) and (max-width: 89.99em) {
  .uol-widget--news-events .uol-cards--count-4 .uol-cards__card:nth-of-type(n+4),
  .uol-widget--news-events .uol-cards--count-5 .uol-cards__card:nth-of-type(n+4) {
    display: none; } }

@media (min-width: 48em) {
  .uol-widget--news-events .uol-cards--count-4 .uol-cards__card:nth-of-type(n+5),
  .uol-widget--news-events .uol-cards--count-5 .uol-cards__card:nth-of-type(n+5) {
    display: none; } }

@media (min-width: 64em) and (max-width: 89.99em) {
  .uol-widget--news-events .uol-cards--count-5 .uol-cards__card:nth-of-type(n+4),
  .uol-widget--news-events .uol-cards--count-6 .uol-cards__card:nth-of-type(n+4),
  .uol-widget--news-events .uol-cards--count-7 .uol-cards__card:nth-of-type(n+4) {
    display: none; } }

@media (min-width: 90em) {
  .uol-widget--news-events .uol-cards--count-5 .uol-cards__card:nth-of-type(n+5),
  .uol-widget--news-events .uol-cards--count-6 .uol-cards__card:nth-of-type(n+5),
  .uol-widget--news-events .uol-cards--count-7 .uol-cards__card:nth-of-type(n+5) {
    display: none; } }

.uol-side-nav-container--populated ~ .uol-homepage-content .uol-widget--news-events .uol-cards__card:nth-of-type(n+7) {
  display: none; }

@media (min-width: 64em) {
  .uol-side-nav-container--populated ~ .uol-homepage-content .uol-widget--news-events .uol-cards__card {
    width: calc(100% / 2 - 0.75rem); } }

@media (min-width: 90em) {
  .uol-side-nav-container--populated ~ .uol-homepage-content .uol-widget--news-events .uol-cards__card {
    width: calc(100% / 3 - 1.333rem); } }

@media (min-width: 64em) and (max-width: 89.99em) {
  .uol-side-nav-container--populated ~ .uol-homepage-content .uol-widget--news-events .uol-cards--count-4 .uol-cards__card:nth-of-type(n+4),
  .uol-side-nav-container--populated ~ .uol-homepage-content .uol-widget--news-events .uol-cards--count-5 .uol-cards__card:nth-of-type(n+4) {
    display: flex; }
  .uol-side-nav-container--populated ~ .uol-homepage-content .uol-widget--news-events .uol-cards--count-4 .uol-cards__card:nth-of-type(n+5),
  .uol-side-nav-container--populated ~ .uol-homepage-content .uol-widget--news-events .uol-cards--count-5 .uol-cards__card:nth-of-type(n+5) {
    display: none; } }

@media (min-width: 90em) {
  .uol-side-nav-container--populated ~ .uol-homepage-content .uol-widget--news-events .uol-cards--count-4 .uol-cards__card:nth-of-type(n+4),
  .uol-side-nav-container--populated ~ .uol-homepage-content .uol-widget--news-events .uol-cards--count-5 .uol-cards__card:nth-of-type(n+4) {
    display: none; } }

@media (min-width: 64em) {
  .uol-cards--wide-container.uol-cards--count-4 .uol-cards__card,
  .uol-cards--wide-container.uol-cards--count-8 .uol-cards__card {
    width: calc(100% / 3 - 1rem); } }

@media (min-width: 90em) {
  .uol-cards--wide-container.uol-cards--count-4 .uol-cards__card,
  .uol-cards--wide-container.uol-cards--count-8 .uol-cards__card {
    width: calc(100% / 4 - 1.5rem); } }

.uol-chips {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.25rem;
  margin-right: -0.25rem; }

.uol-chips__item {
  margin: 0 6px 12px;
  list-style-type: none;
  display: inline-block; }

.uol-chips__link {
  text-decoration: none; }

.uol-chips__button {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  border: 0;
  padding: 0.5rem 0.25rem;
  background-color: #699B9D;
  color: #0e0c0c;
  border-radius: 4px;
  text-align: left;
  text-decoration: none; }
  @media (min-width: 64em) {
    .uol-chips__button {
      padding: 0.25rem; } }
  .uol-chips__button:focus {
    outline: none; }

.uol-chips__text {
  padding: 0 0.5rem 0 0.25rem;
  pointer-events: none; }

.uol-chips__delete-icon {
  height: 28px;
  margin-right: 0.25rem;
  pointer-events: none; }
  .uol-chips__delete-icon svg {
    box-sizing: border-box;
    border: 2px solid #156265;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    background-color: #ffffff;
    pointer-events: none; }
    .uol-chips__delete-icon svg path {
      fill: #156265; }
    .uol-chips__button:hover .uol-chips__delete-icon svg {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.12); }
    .uol-chips__button:focus .uol-chips__delete-icon svg {
      background-color: #1993bd;
      border-color: #ffffff;
      box-shadow: none; }
      .uol-chips__button:focus .uol-chips__delete-icon svg path {
        fill: #ffffff; }

.uol-search-results__results .uol-chips {
  padding: 1.5rem 0 2rem; }

.uol-chips-ds-container {
  background-image: linear-gradient(152deg, #eae8e6 25%, #f3f2f1 25%, #f3f2f1 50%, #eae8e6 50%, #eae8e6 75%, #f3f2f1 75%, #f3f2f1 100%);
  background-size: 213.01px 113.26px;
  border: 1px solid rgba(201, 200, 197, 0.6);
  padding: 24px;
  margin: 0 0 32px 0; }
  @media (min-width: 48em) {
    .uol-chips-ds-container {
      margin: 0 0 48px 0; } }

.js .uolcc-prompt-container,
.js .uolcc-privacy-update-container {
  background: #212121;
  border-bottom: 2px solid #51504c;
  padding: 0.75rem 0.5rem;
  color: #ffffff; }
  @media (min-width: 25.875em) {
    .js .uolcc-prompt-container,
    .js .uolcc-privacy-update-container {
      padding: 1rem; } }
  @media (min-width: 64em) {
    .js .uolcc-prompt-container,
    .js .uolcc-privacy-update-container {
      padding: 1.5rem; } }

.js .uolcc-container:focus,
.js .uolcc-prompt-container:focus,
.js .uolcc-privacy-update-container:focus {
  outline: none; }

.js .uolcc-container .uolcc-prefs-header,
.js .uolcc-container .uolcc-prefs-footer,
.js .uolcc-prompt-container .uolcc-prefs-header,
.js .uolcc-prompt-container .uolcc-prefs-footer,
.js .uolcc-privacy-update-container .uolcc-prefs-header,
.js .uolcc-privacy-update-container .uolcc-prefs-footer {
  background: #212121;
  color: #ffffff; }

.js .uolcc-container .uolcc-prefs-footer,
.js .uolcc-prompt-container .uolcc-prefs-footer,
.js .uolcc-privacy-update-container .uolcc-prefs-footer {
  box-shadow: 0 -10px 20px 0 rgba(10, 2, 2, 0.15), 0 -3px 6px 0 rgba(10, 2, 2, 0.1); }

.js .uolcc-container .uolcc-cookie-detail__button,
.js .uolcc-prompt-container .uolcc-cookie-detail__button,
.js .uolcc-privacy-update-container .uolcc-cookie-detail__button {
  color: inherit; }

.js .uolcc-container .uolcc-cookie-detail__button-title,
.js .uolcc-prompt-container .uolcc-cookie-detail__button-title,
.js .uolcc-privacy-update-container .uolcc-cookie-detail__button-title {
  font-weight: 500; }

.js .uolcc-container .uolcc-prompt-inner,
.js .uolcc-container .uolcc-privacy-update-inner,
.js .uolcc-prompt-container .uolcc-prompt-inner,
.js .uolcc-prompt-container .uolcc-privacy-update-inner,
.js .uolcc-privacy-update-container .uolcc-prompt-inner,
.js .uolcc-privacy-update-container .uolcc-privacy-update-inner {
  box-sizing: border-box;
  max-width: 101.75rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem; }
  @media (min-width: 90em) {
    .js .uolcc-container .uolcc-prompt-inner,
    .js .uolcc-container .uolcc-privacy-update-inner,
    .js .uolcc-prompt-container .uolcc-prompt-inner,
    .js .uolcc-prompt-container .uolcc-privacy-update-inner,
    .js .uolcc-privacy-update-container .uolcc-prompt-inner,
    .js .uolcc-privacy-update-container .uolcc-privacy-update-inner {
      padding: 0 1rem; } }
  .js .uolcc-container .uolcc-prompt-inner p,
  .js .uolcc-container .uolcc-privacy-update-inner p,
  .js .uolcc-prompt-container .uolcc-prompt-inner p,
  .js .uolcc-prompt-container .uolcc-privacy-update-inner p,
  .js .uolcc-privacy-update-container .uolcc-prompt-inner p,
  .js .uolcc-privacy-update-container .uolcc-privacy-update-inner p {
    max-width: 65ch; }

.js .uolcc-container .uolcc-cookie-detail__expand-icon,
.js .uolcc-prompt-container .uolcc-cookie-detail__expand-icon,
.js .uolcc-privacy-update-container .uolcc-cookie-detail__expand-icon {
  width: 1.3em;
  height: 1.3em; }

.js .uolcc-container .uolcc-button,
.js .uolcc-prompt-container .uolcc-button,
.js .uolcc-privacy-update-container .uolcc-button {
  position: relative;
  outline: 0 dotted #197ebe;
  outline-offset: 3px;
  overflow: visible;
  border: 1px solid #ffffff; }
  .js .uolcc-container .uolcc-button::before,
  .js .uolcc-prompt-container .uolcc-button::before,
  .js .uolcc-privacy-update-container .uolcc-button::before {
    content: "";
    position: absolute;
    top: -6px;
    right: -6px;
    bottom: -6px;
    left: -6px;
    border: 3px solid transparent;
    border-radius: 10px;
    transition: all 0.15s; }
    @media (-ms-high-contrast: active), (forced-colors: active) {
      .js .uolcc-container .uolcc-button::before,
      .js .uolcc-prompt-container .uolcc-button::before,
      .js .uolcc-privacy-update-container .uolcc-button::before {
        display: none; } }
  @media (-ms-high-contrast: active), (forced-colors: active) {
    .js .uolcc-container .uolcc-button:focus,
    .js .uolcc-prompt-container .uolcc-button:focus,
    .js .uolcc-privacy-update-container .uolcc-button:focus {
      outline-width: 3px; } }
  .js .uolcc-container .uolcc-button:focus::before,
  .js .uolcc-prompt-container .uolcc-button:focus::before,
  .js .uolcc-privacy-update-container .uolcc-button:focus::before {
    border-color: #197ebe; }
  .js .uolcc-container .uolcc-button:focus:not(:focus-visible)::before,
  .js .uolcc-prompt-container .uolcc-button:focus:not(:focus-visible)::before,
  .js .uolcc-privacy-update-container .uolcc-button:focus:not(:focus-visible)::before {
    border-color: transparent; }
  .js .uolcc-container .uolcc-button:focus-visible::before,
  .js .uolcc-prompt-container .uolcc-button:focus-visible::before,
  .js .uolcc-privacy-update-container .uolcc-button:focus-visible::before {
    border-color: #197ebe; }

@media (max-width: 25.865em) {
  .js .uolcc-container .uolcc-button + .uolcc-button,
  .js .uolcc-prompt-container .uolcc-button + .uolcc-button,
  .js .uolcc-privacy-update-container .uolcc-button + .uolcc-button {
    margin-top: 0.75rem; } }

@media (min-width: 25.875em) {
  .js .uolcc-container .uolcc-button + .uolcc-button,
  .js .uolcc-prompt-container .uolcc-button + .uolcc-button,
  .js .uolcc-privacy-update-container .uolcc-button + .uolcc-button {
    margin-left: 0.75rem; } }

.js #cookie-control-prompt.uolcc-prompt-container a, .js #cookie-control-prompt.uolcc-privacy-update-container a,
.js #cookie-control-privacy-update.uolcc-prompt-container a,
.js #cookie-control-privacy-update.uolcc-privacy-update-container a {
  transition: background 0.15s ease-out, text-decoration 0.15s ease-out; }
  .js #cookie-control-prompt.uolcc-prompt-container a:focus, .js #cookie-control-prompt.uolcc-privacy-update-container a:focus,
  .js #cookie-control-privacy-update.uolcc-prompt-container a:focus,
  .js #cookie-control-privacy-update.uolcc-privacy-update-container a:focus {
    color: #212529;
    background-color: #ffeded;
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-decoration-color: #ff4e36;
    text-underline-offset: 4px;
    outline: 2px dotted inherit;
    outline-offset: 1px;
    outline-color: transparent; }
  .js #cookie-control-prompt.uolcc-prompt-container a:focus:not(:focus-visible), .js #cookie-control-prompt.uolcc-privacy-update-container a:focus:not(:focus-visible),
  .js #cookie-control-privacy-update.uolcc-prompt-container a:focus:not(:focus-visible),
  .js #cookie-control-privacy-update.uolcc-privacy-update-container a:focus:not(:focus-visible) {
    text-decoration: none;
    outline-color: transparent; }
  .js #cookie-control-prompt.uolcc-prompt-container a:focus-visible, .js #cookie-control-prompt.uolcc-privacy-update-container a:focus-visible,
  .js #cookie-control-privacy-update.uolcc-prompt-container a:focus-visible,
  .js #cookie-control-privacy-update.uolcc-privacy-update-container a:focus-visible {
    color: #212529;
    outline-color: transparent;
    outline-offset: 1px;
    background-color: #ffeded; }

.uol-description-list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 0; }
  @media (min-width: 48em) {
    .uol-description-list {
      margin: 0 -0.5rem; } }
  @media (min-width: 64em) {
    .uol-description-list {
      margin: 0 -0.75rem; } }
  @media (min-width: 90em) {
    .uol-description-list {
      margin: 0 -1rem; } }

.uol-description-list__item {
  box-sizing: border-box;
  position: relative;
  flex: 0 0 100%;
  list-style: none; }
  @media (min-width: 48em) {
    .uol-description-list__item {
      flex-basis: 50%;
      padding: 0 0.5rem; } }
  @media (min-width: 64em) {
    .uol-description-list__item {
      padding: 0 0.75rem; } }
  @media (min-width: 90em) {
    .uol-description-list__item {
      flex-basis: 25%;
      padding: 0 1rem; } }
  @media (min-width: 90em) {
    .uol-side-nav-container--populated + .uol-homepage-content .uol-description-list__item {
      flex-basis: calc(100% / 3); } }

.uol-description-list__item__inner {
  box-sizing: border-box;
  padding: 1.5rem 0 2rem;
  border-color: #c9c8c5;
  border-style: solid;
  border-width: 2px 0 0;
  height: 100%; }
  .uol-description-list__item__inner:focus-within {
    background-color: #ffeded; }

.uol-description-list__item__title {
  margin: 0 0 0.5rem; }
  .uol-description-list__item__title:hover {
    text-decoration: underline; }

.uol-description-list__item__content {
  margin: 0; }

.uol-description-list__item__link {
  text-decoration: none; }
  .uol-description-list__item__link::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .uol-description-list__item__link:hover, .uol-description-list__item__link:focus {
    text-decoration: underline; }
  .uol-description-list__item__link:focus {
    text-decoration-color: #ff4e36; }

.uol-detail-nav {
  padding: 1rem 0 1rem; }
  @media (min-width: 37.5em) {
    .uol-detail-nav {
      margin-left: calc(100% / 12 + 0.0625rem);
      margin-right: calc(100% / 12 * 5 + 0.5rem); } }
  @media (min-width: 64em) {
    .uol-detail-nav {
      margin: 0; } }

@media (min-width: 64em) {
  .uol-detail-nav__title {
    margin-left: calc(100% / 12 + 0.0625rem);
    margin-right: calc(100% / 12 + 0.0625rem); } }

@media (min-width: 64em) {
  .uol-detail-nav__title {
    margin: 0; } }

.uol-detail-nav__title .uol-detail-nav__title__intro {
  display: block;
  font-size: 0.5rem;
  margin-bottom: 0.5rem; }

.uol-detail-nav__title .uol-detail-nav__title__intro {
  display: block;
  font-size: 1rem; }

.uol-detail-nav__list {
  border-left: 0.25rem solid #c70000;
  padding: 0 0 0 2.25rem;
  list-style: none; }

.uol-detail-nav__list-item {
  margin-bottom: 1.5rem; }
  .uol-detail-nav__list-item:last-child {
    margin-bottom: 0; }

.uol-detail-nav__list-link {
  text-decoration: none;
  font-size: 1.125rem; }
  .uol-detail-nav__list-link:hover, .uol-detail-nav__list-link:focus {
    outline: 2px dotted transparent;
    text-decoration: underline;
    text-underline-offset: 6px;
    text-decoration-color: #c70000;
    text-decoration-thickness: 4px; }
  .uol-detail-nav__list-link:focus-visible {
    background-color: #ffeded; }
  .uol-detail-nav__list-link[aria-current]:not(:focus) {
    color: #c70000; }
    .uol-detail-nav__list-link[aria-current]:not(:focus):not(:hover) {
      text-decoration: underline; }

.uol-detail__active-link {
  color: #c70000;
  text-decoration: underline; }

.events-cards {
  box-sizing: border-box; }
  @media (min-width: 48em) {
    .events-cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: 0 -0.5rem; } }
  @media (min-width: 64em) {
    .events-cards {
      margin: 0 -0.75rem; } }
  @media (min-width: 90em) {
    .events-cards {
      margin: 0 -1rem; } }

.event-card-outer {
  box-sizing: border-box;
  width: 100%; }
  @media (min-width: 48em) {
    .event-card-outer {
      width: 50%;
      padding: 0 0.5rem; } }
  @media (min-width: 64em) {
    .event-card-outer {
      padding: 0 0.75rem; } }
  @media (min-width: 90em) {
    .event-card-outer {
      width: 25%;
      padding: 0 1rem; } }
  @media (min-width: 64em) {
    .uol-side-nav-container--populated + .uol-homepage-content .event-card-outer {
      width: 50%; } }
  @media (min-width: 90em) {
    .uol-side-nav-container--populated + .uol-homepage-content .event-card-outer {
      width: calc(100% / 3); } }

.event-card {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  position: relative;
  box-sizing: border-box;
  border-top: 2px solid #c70000;
  padding: 1rem 0 2rem 0;
  height: 100%; }
  .event-card:focus-within {
    background-color: #ffeded; }

.event-card__title {
  width: 100%;
  margin: 0.5rem 0 0.25rem; }

.event-card__link {
  text-decoration: none; }
  .event-card__link::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .event-card:focus-within .event-card__link, .event-card__link:hover, .event-card__link:focus {
    text-decoration: underline;
    text-decoration-color: #ff4e36; }

.event-card__dates {
  order: -1;
  margin-left: 2.25rem;
  margin-top: 0.75rem; }
  @media (max-width: 48em) {
    .event-card__dates {
      width: 100%; } }
  @media (min-width: 48em) {
    .event-card__dates {
      margin-left: 3rem;
      margin-top: 1.5rem; } }
  @media (min-width: 64em) {
    .event-card__dates {
      margin-left: 3.5rem;
      margin-top: 1.875rem; } }

.event-card__dates,
.event-card__time {
  font-weight: 500;
  font-variant-numeric: lining-nums; }

.event-card__time {
  width: 100%; }

.event-card__date__end-date {
  white-space: nowrap; }

.event-card__date__start-date__day {
  position: absolute;
  left: 0;
  top: 1rem;
  font-variant-numeric: lining-nums; }

.uol-facts-figures-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box; }
  .uol-facts-figures-container > * {
    box-sizing: border-box; }

.uol-facts-figures {
  margin: 0;
  text-align: center; }
  @media (max-width: 37.49em) {
    .uol-facts-figures {
      margin-bottom: 1rem; } }
  .uol-facts-figures-container .uol-facts-figures {
    margin: 2rem 0;
    padding: 0 1rem;
    flex-grow: 1;
    flex-basis: 100%; }
    @media (max-width: 47.99em) {
      .uol-facts-figures-container .uol-facts-figures {
        position: relative; }
        .uol-facts-figures-container .uol-facts-figures::after {
          content: "";
          position: absolute;
          display: block;
          bottom: -2rem;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          width: 60%;
          max-width: 11rem;
          height: 4px;
          background: #eae8e6; } }
    @media (min-width: 48em) {
      .uol-facts-figures-container .uol-facts-figures {
        margin: 2rem 0;
        padding: 0.5rem 1.5rem; } }

@media (min-width: 48em) and (max-width: 89.99em) {
  .uol-facts-figures-container .uol-facts-figures {
    flex-basis: calc(50% - 3rem); }
    .uol-facts-figures-container .uol-facts-figures:nth-child(odd) {
      border-right: 4px solid #eae8e6; }
    .uol-facts-figures-container .uol-facts-figures:nth-child(odd):last-of-type {
      border-right: none; }
    .no-csspositionsticky .uol-facts-figures-container .uol-facts-figures {
      flex-basis: 33%; } }
    @media (min-width: 90em) {
      .uol-facts-figures-container .uol-facts-figures {
        padding: 0.5rem 1.5rem;
        flex-basis: calc(100% / 3 - 3rem); }
        .uol-facts-figures-container .uol-facts-figures:nth-child(3n-2), .uol-facts-figures-container .uol-facts-figures:nth-child(3n-1) {
          border-right: 4px solid #eae8e6; }
          .uol-facts-figures-container .uol-facts-figures:nth-child(3n-2):last-of-type, .uol-facts-figures-container .uol-facts-figures:nth-child(3n-1):last-of-type {
            border-right: none; }
        .no-csspositionsticky .uol-facts-figures-container .uol-facts-figures {
          flex-basis: 25%; } }

.uol-facts-figures__headline {
  margin-top: 0;
  margin-bottom: 1rem; }

.uol-facts-figures__headline__1 {
  font-size: 2.25rem;
  line-height: 2.75rem;
  display: block;
  margin-bottom: 0.75rem;
  font-weight: 800;
  color: #c70000; }
  @media (min-width: 48em) {
    .uol-facts-figures__headline__1 {
      font-size: 2.75rem; } }
  @media (min-width: 48em) {
    .uol-facts-figures__headline__1 {
      line-height: 3rem; } }
  .uol-tiles .uol-facts-figures__headline__1 {
    color: #ff4e36; }

.uol-facts-figures__headline__1__emphasis {
  font-size: 2.75rem;
  line-height: 2.75rem; }
  @media (min-width: 48em) {
    .uol-facts-figures__headline__1__emphasis {
      font-size: 4.25rem; } }
  @media (min-width: 48em) {
    .uol-facts-figures__headline__1__emphasis {
      line-height: 3rem; } }

.uol-facts-figures__headline__2 {
  font-size: 1.125rem;
  line-height: 1.5rem; }
  @media (min-width: 48em) {
    .uol-facts-figures__headline__2 {
      font-size: 1.125rem; } }
  @media (min-width: 64em) {
    .uol-facts-figures__headline__2 {
      font-size: 1.25rem; } }
  @media (min-width: 48em) {
    .uol-facts-figures__headline__2 {
      line-height: 1.5rem; } }

.uol-facts-figures__cite {
  font-size: 1rem;
  line-height: 1.375rem;
  font-style: normal; }
  @media (min-width: 48em) {
    .uol-facts-figures__cite {
      font-size: 1rem; } }
  @media (min-width: 64em) {
    .uol-facts-figures__cite {
      font-size: 1.125rem; } }
  @media (min-width: 48em) {
    .uol-facts-figures__cite {
      line-height: 1.375rem; } }
  @media (min-width: 64em) {
    .uol-facts-figures__cite {
      line-height: 1.75rem; } }

.uol-facts-figures__link {
  transition: background 0.15s ease-out, text-decoration 0.15s ease-out;
  color: inherit; }
  .uol-facts-figures__link:focus {
    color: #212529;
    background-color: #ffeded;
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-decoration-color: #ff4e36;
    text-underline-offset: 3px;
    outline: 2px dotted inherit;
    outline-offset: 1px;
    outline-color: transparent; }
  .uol-facts-figures__link:focus:not(:focus-visible) {
    text-decoration: none;
    outline-color: transparent; }
  .uol-facts-figures__link:focus-visible {
    color: #212529;
    outline-color: transparent;
    outline-offset: 1px;
    background-color: #ffeded; }
  .uol-facts-figures__link:hover {
    text-decoration-color: #c70000; }

.uol-filters-panel {
  box-sizing: border-box;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  width: 100%;
  box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0), 0 5px 10px 0 rgba(0, 0, 0, 0);
  transition: all 0.7s ease;
  opacity: 1;
  z-index: 1000; }
  .uol-filters-panel .uol-form__custom__legend {
    margin-bottom: 0.25rem; }
  .uol-filters-panel .uol-form__input-label__hint {
    padding-bottom: 0.75rem; }
  @media (max-width: 63.99em) {
    .js .uol-filters-panel {
      padding: 1rem 1rem 1.5rem;
      position: fixed;
      transform: translateX(-100%);
      overflow-y: auto; } }
  @media (prefers-reduced-motion) {
    .uol-filters-panel {
      transition: none; } }
  @media (min-width: 48em) {
    .js .uol-filters-panel {
      width: calc((100% - 1rem) / 12 * 9); } }
  @media (min-width: 64em) {
    .js .uol-filters-panel {
      position: relative;
      width: auto;
      transform: translateX(0); } }
  .uol-filters-panel .uol-form__input-container {
    padding-bottom: 0.75rem; }
    @media (min-width: 64em) {
      .uol-filters-panel .uol-form__input-container {
        padding-bottom: calc(0.75rem + 0.5rem); } }
    .uol-filters-panel .uol-form__input-container:after {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      bottom: -1rem;
      left: 0;
      height: 2px;
      background: #c9c8c5; }
  .uol-filters-panel .uol-form__custom-fieldset {
    margin-bottom: 0; }
    .uol-filters-panel .uol-form__custom-fieldset > *:last-child {
      margin-bottom: 0; }

.js .uol-filters-panel--open {
  transform: translateX(0);
  box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.15), 0 5px 10px 0 rgba(0, 0, 0, 0.5); }

@media (min-width: 64em) {
  .uol-filters-panel--open {
    box-shadow: none; } }

.uol-filters-panel--open .uol-form__input-wrapper__num-of-selected-message {
  top: 11px; }

#filters-close-button {
  position: absolute;
  top: 1rem;
  right: 1rem; }
  @media (min-width: 64em) {
    #filters-close-button {
      display: none; } }

@media (min-width: 64em) {
  #show_search_results {
    display: none; } }

#clear_filter_panel {
  padding: 0.8em 2.1em; }

.uol-filters-panel__title {
  font-weight: 600;
  margin-top: 0; }

.uol-filters-panel__footer button {
  display: block;
  width: auto;
  margin-bottom: 1.5rem; }
  .uol-filters-panel__footer button:first-of-type {
    margin-top: 1rem; }
  .uol-filters-panel__footer button:last-of-type {
    margin-bottom: 0; }

.uol-filters-panel__overlay {
  position: fixed;
  top: -100%;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(10, 2, 2, 0);
  transition: background 0.7s ease; }
  .uol-filters-panel--open + .uol-filters-panel__overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(10, 2, 2, 0.78);
    z-index: 999; }

.uol-filters-panel .uol-form__input-container--small {
  max-width: none; }

.uol-filters-panel .uol-form__input--checkbox-custom svg {
  height: 20px;
  width: 20px; }

.uol-filters-panel--open .uol-form__input--checkbox-custom svg {
  height: 36px;
  width: 36px; }

.uol-footer-contact {
  font-style: normal;
  font-variant-numeric: lining-nums; }

.uol-footer-contact__name {
  display: block;
  position: relative;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1.5rem; }
  .uol-footer-contact__name::before {
    content: "";
    position: absolute;
    display: inline-block;
    top: -1rem;
    left: 0;
    width: 1rem;
    height: 0.25rem;
    background-color: #ff4e36; }

.uol-footer-contact__address {
  padding: 0 0 1rem; }

.uol-footer-contact__address__item {
  display: block; }

.uol-footer-contact__telephone-email-container {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 2rem; }
  .uol-footer-contact__telephone-email-container::before {
    content: "";
    position: absolute;
    display: inline-block;
    top: -1rem;
    left: 0;
    width: 1rem;
    height: 0.25rem;
    background-color: #ff4e36; }

.uol-footer-contact__telephone-email-data {
  margin-left: 0; }

.uol-footer-contact__telephone,
.uol-footer-contact__email {
  display: inline-block;
  color: inherit;
  text-decoration: none; }

.footer-site-information {
  background: inherit; }

.footer-site-information__list {
  list-style: none;
  padding: 0;
  line-height: 1.5;
  font-variant-numeric: lining-nums; }

.footer-site-information__item {
  text-decoration: none;
  margin-bottom: 0.5rem; }
  @media (min-width: 48em) {
    .footer-site-information__item {
      display: inline;
      padding-right: 1.5rem;
      margin-bottom: 0; } }

.footer-site-information__link {
  color: inherit;
  text-decoration: none;
  white-space: nowrap; }

.uol-footer-social-media__item {
  display: inline-block;
  margin-right: 1.5rem;
  margin-bottom: 0.75rem;
  margin-left: -0.25rem;
  text-decoration: none;
  padding: 0.25rem; }
  .uol-footer-social-media__item:last-of-type {
    margin-right: 0; }
  .uol-footer-social-media__item svg path {
    fill: #cac2ba;
    transition: fill 0.3s ease; }
  .uol-footer-social-media__item:hover svg path, .uol-footer-social-media__item:focus svg path {
    fill: #ff4e36; }

.footer-supplement {
  font-weight: 600;
  background-color: #dedede;
  color: #212529;
  text-decoration: none;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 1rem; }
  .footer-supplement:hover, .footer-supplement:focus {
    text-decoration: underline;
    text-decoration-color: #c70000;
    text-decoration-thickness: 1.5px; }
  .footer-supplement .footer-supplement__icon {
    height: 25px;
    width: 25px;
    margin-right: 0.5rem; }
    .footer-supplement .footer-supplement__icon svg {
      width: 100% !important;
      height: 100% !important; }

.uol-form-container--centered {
  margin: 0 auto; }

.uol-form__container {
  border: 1px solid #c9c8c5;
  border-radius: 6px;
  margin-bottom: 2rem; }
  .uol-form__container.uol-form-container--centered {
    padding: 0; }
  .uol-homepage-content .uol-form__container {
    margin: 0 0 calc(2rem - 1rem) 0;
    width: calc(100% - 2px); }
    @media (min-width: 48em) {
      .uol-homepage-content .uol-form__container {
        margin: 0 0 calc(4rem - 2rem) 0; } }
    @media (min-width: 64em) {
      .uol-homepage-content .uol-form__container {
        margin: 0 0 calc(5rem - 2rem) 0; } }
  @media (min-width: 64em) {
    .uol-side-nav-container--populated + .uol-homepage-content .uol-form__container .uol-form__inner-wrapper {
      flex-basis: 100%; } }
  @media (min-width: 90em) {
    .uol-side-nav-container--populated + .uol-homepage-content .uol-form__container .uol-form__inner-wrapper {
      flex-basis: 55.555%; } }
  @media (min-width: 90em) {
    .uol-side-nav-container--populated + .uol-homepage-content .uol-form__container .uol-form {
      margin-right: 2rem; } }
  .uol-side-nav-container--populated + .uol-homepage-content .uol-form__container .uol-form__img-wrapper {
    display: none; }
    @media (min-width: 90em) {
      .uol-side-nav-container--populated + .uol-homepage-content .uol-form__container .uol-form__img-wrapper {
        display: inline-flex;
        flex-basis: 44.444%; } }

.uol-form__inner-wrapper {
  padding: 1.5rem 1rem 2rem;
  background-color: #eae8e6; }
  @media (min-width: 64em) {
    .uol-form__inner-wrapper {
      flex-basis: 58.333%;
      padding: 1.5rem 1.5rem 2.5rem; } }
  @media (min-width: 90em) {
    .uol-form__inner-wrapper {
      flex-basis: 50%;
      padding: 2.5rem 2rem; } }

.uol-form__title {
  color: #212529;
  font-size: 2rem;
  line-height: 1.25;
  font-family: freight-text-pro, Georgia, Cambria, "Times New Roman", Times, serif;
  margin: 0;
  padding-bottom: 0.5rem; }
  .uol-form__title + .uol-form {
    padding-top: 0.5rem; }
  @media (min-width: 48em) {
    .uol-form__title {
      font-size: 2.25rem;
      line-height: 1.333; } }
  @media (min-width: 64em) {
    .uol-form__title {
      font-size: 2.625rem;
      line-height: 1.238; } }

.uol-form__lead {
  display: block;
  color: #212529;
  font-size: 1.125rem;
  line-height: 1.556;
  font-family: "San Francisco", Roboto, "Helvetica Neue", Helvetica, "Noto Sans", "Segoe UI", Arial, sans-serif;
  margin: 0 0 2rem;
  font-weight: normal; }
  @media (min-width: 48em) {
    .uol-form__lead {
      max-width: 32rem; } }
  @media (min-width: 64em) {
    .uol-form__lead {
      font-size: 1.25rem;
      max-width: 41rem; } }

.uol-form {
  flex-direction: row; }
  @media (min-width: 48em) {
    .uol-form.uol-form--button-inline {
      display: flex; } }

@media (min-width: 64em) {
  .uol-form__container--with-image {
    display: flex; } }

.uol-form__img-wrapper {
  background-color: #eae8e6;
  position: relative;
  display: none;
  z-index: -2; }
  @media (min-width: 64em) {
    .uol-form__img-wrapper {
      display: inline-flex;
      flex-basis: 41.666%; } }
  @media (min-width: 90em) {
    .uol-form__img-wrapper {
      flex-basis: 50%; } }

.uol-form__img {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1; }

.uol-form--button-inline .uol-form__inputs-wrapper {
  flex: 1; }

.uol-form--button-inline .uol-form__input-container {
  margin-bottom: 0; }

.uol-form--button-inline .uol-form__button-wrapper {
  align-self: flex-end; }
  .uol-form--button-inline .uol-form__button-wrapper .uol-button, .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-container .uolcc-button, .js .uolcc-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button,
  .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-prompt-container .uolcc-button, .js .uolcc-prompt-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button,
  .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-privacy-update-container .uolcc-button, .js .uolcc-privacy-update-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button, .uol-form--button-inline .uol-form__button-wrapper .uol-section-nav-ctas__link {
    position: relative;
    outline: 0 dotted #197ebe;
    outline-offset: 3px;
    overflow: visible; }
    .uol-form--button-inline .uol-form__button-wrapper .uol-button::before, .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-container .uolcc-button::before, .js .uolcc-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button::before,
    .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-prompt-container .uolcc-button::before, .js .uolcc-prompt-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button::before,
    .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-privacy-update-container .uolcc-button::before, .js .uolcc-privacy-update-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button::before, .uol-form--button-inline .uol-form__button-wrapper .uol-section-nav-ctas__link::before {
      content: "";
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border: 3px solid transparent;
      border-radius: inherit;
      transition: all 0.15s; }
      @media (-ms-high-contrast: active), (forced-colors: active) {
        .uol-form--button-inline .uol-form__button-wrapper .uol-button::before, .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-container .uolcc-button::before, .js .uolcc-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button::before,
        .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-prompt-container .uolcc-button::before, .js .uolcc-prompt-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button::before,
        .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-privacy-update-container .uolcc-button::before, .js .uolcc-privacy-update-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button::before, .uol-form--button-inline .uol-form__button-wrapper .uol-section-nav-ctas__link::before {
          display: none; } }
    @media (-ms-high-contrast: active), (forced-colors: active) {
      .uol-form--button-inline .uol-form__button-wrapper .uol-button:focus, .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-container .uolcc-button:focus, .js .uolcc-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button:focus,
      .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-prompt-container .uolcc-button:focus, .js .uolcc-prompt-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button:focus,
      .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-privacy-update-container .uolcc-button:focus, .js .uolcc-privacy-update-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button:focus, .uol-form--button-inline .uol-form__button-wrapper .uol-section-nav-ctas__link:focus {
        outline-width: 3px; } }
    .uol-form--button-inline .uol-form__button-wrapper .uol-button:focus::before, .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-container .uolcc-button:focus::before, .js .uolcc-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button:focus::before,
    .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-prompt-container .uolcc-button:focus::before, .js .uolcc-prompt-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button:focus::before,
    .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-privacy-update-container .uolcc-button:focus::before, .js .uolcc-privacy-update-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button:focus::before, .uol-form--button-inline .uol-form__button-wrapper .uol-section-nav-ctas__link:focus::before {
      border-color: #197ebe; }
    .uol-form--button-inline .uol-form__button-wrapper .uol-button:focus:not(:focus-visible)::before, .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-container .uolcc-button:focus:not(:focus-visible)::before, .js .uolcc-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button:focus:not(:focus-visible)::before,
    .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-prompt-container .uolcc-button:focus:not(:focus-visible)::before, .js .uolcc-prompt-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button:focus:not(:focus-visible)::before,
    .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-privacy-update-container .uolcc-button:focus:not(:focus-visible)::before, .js .uolcc-privacy-update-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button:focus:not(:focus-visible)::before, .uol-form--button-inline .uol-form__button-wrapper .uol-section-nav-ctas__link:focus:not(:focus-visible)::before {
      border-color: transparent; }
    .uol-form--button-inline .uol-form__button-wrapper .uol-button:focus-visible::before, .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-container .uolcc-button:focus-visible::before, .js .uolcc-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button:focus-visible::before,
    .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-prompt-container .uolcc-button:focus-visible::before, .js .uolcc-prompt-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button:focus-visible::before,
    .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-privacy-update-container .uolcc-button:focus-visible::before, .js .uolcc-privacy-update-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button:focus-visible::before, .uol-form--button-inline .uol-form__button-wrapper .uol-section-nav-ctas__link:focus-visible::before {
      border-color: #197ebe; }
  @media (min-width: 48em) {
    .uol-form--button-inline .uol-form__button-wrapper {
      padding-left: 1rem; } }
  @media (min-width: 64em) {
    .uol-form--button-inline .uol-form__button-wrapper {
      padding-left: 1.5rem; } }
  @media (min-width: 90em) {
    .uol-form--button-inline .uol-form__button-wrapper {
      padding-left: 2rem; } }
  .uol-form--button-inline .uol-form__button-wrapper [class^="uol-button"] {
    width: 100%;
    height: 3.125rem;
    line-height: 0.75; }

@media (min-width: 37.5em) {
  .uol-form--button-block .uol-form__button-wrapper {
    display: inline-block;
    width: initial; } }

.uol-form--button-block .uol-form__button-wrapper .uol-button, .uol-form--button-block .uol-form__button-wrapper .js .uolcc-container .uolcc-button, .js .uolcc-container .uol-form--button-block .uol-form__button-wrapper .uolcc-button,
.uol-form--button-block .uol-form__button-wrapper .js .uolcc-prompt-container .uolcc-button, .js .uolcc-prompt-container .uol-form--button-block .uol-form__button-wrapper .uolcc-button,
.uol-form--button-block .uol-form__button-wrapper .js .uolcc-privacy-update-container .uolcc-button, .js .uolcc-privacy-update-container .uol-form--button-block .uol-form__button-wrapper .uolcc-button, .uol-form--button-block .uol-form__button-wrapper .uol-section-nav-ctas__link {
  width: 100%; }

.uol-form__additional-content {
  padding: 0;
  margin: 0; }
  .uol-form__additional-content a {
    transition: background 0.15s ease-out, text-decoration 0.15s ease-out; }
    .uol-form__additional-content a:focus {
      color: #212529;
      background-color: #ffeded;
      text-decoration: underline;
      text-decoration-thickness: 3px;
      text-decoration-color: #ff4e36;
      text-underline-offset: 4px;
      outline: 2px dotted inherit;
      outline-offset: 1px;
      outline-color: transparent; }
    .uol-form__additional-content a:focus:not(:focus-visible) {
      text-decoration: none;
      outline-color: transparent; }
    .uol-form__additional-content a:focus-visible {
      color: #212529;
      outline-color: transparent;
      outline-offset: 1px;
      background-color: #ffeded; }

.uol-rich-text .uol-form__additional-content--before {
  margin: 1rem 0; }
  .uol-rich-text .uol-form__additional-content--before > * {
    margin-bottom: 1rem; }
  .uol-rich-text .uol-form__additional-content--before > *:last-child {
    margin-bottom: 2rem; }

.uol-rich-text .uol-form__additional-content--after {
  margin: 2rem 0 0; }
  .uol-rich-text .uol-form__additional-content--after > * {
    margin-bottom: 1rem; }
  .uol-rich-text .uol-form__additional-content--after > *:last-child {
    margin-bottom: 0; }

.uol-form-container--overflow {
  overflow: visible !important; }
  .uol-form-container--overflow .uol-widget__content {
    overflow: visible  !important; }

.uol-form__input--character-count {
  padding-top: 0.75rem;
  font-weight: 500;
  margin: 0;
  user-select: none;
  font-variant-numeric: lining-nums; }
  @media (min-width: 48em) {
    .uol-form__input--character-count {
      font-weight: 400; } }
  .uol-form__input--character-count[data-field-invalid="true"] {
    color: #bd2f19; }

.uol-form-input__excess-text {
  background-color: #f1a498;
  font-style: normal; }

.uol-form__input__error, .uol-form__form-error {
  font-size: 1rem;
  display: block;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
  padding: 0.5rem 1rem 0.5rem 44px;
  color: #ffffff;
  background: url("../img/alert-circle-outline.svg") no-repeat 13px 11px;
  background-color: #d4351c;
  border-radius: 6px;
  font-weight: 500;
  max-width: 25.5rem;
  line-height: 1.667;
  font-variant-numeric: lining-nums; }
  @media (min-width: 48em) {
    .uol-form__input__error, .uol-form__form-error {
      font-size: 1rem; } }
  @media (min-width: 64em) {
    .uol-form__input__error, .uol-form__form-error {
      font-size: 1.125rem; } }
  .uol-form__input__error a, .uol-form__form-error a {
    transition: background 0.15s ease-out, text-decoration 0.15s ease-out;
    padding: 0 1px;
    margin: 0 -1px;
    cursor: pointer; }
    .uol-form__input__error a:focus, .uol-form__form-error a:focus {
      color: #bd2f19;
      background-color: #ffeded;
      text-decoration: underline;
      text-decoration-thickness: 3px;
      text-decoration-color: #212121;
      text-underline-offset: 3px;
      outline: 2px dotted inherit;
      outline-offset: 1px;
      outline-color: transparent; }
    .uol-form__input__error a:focus:not(:focus-visible), .uol-form__form-error a:focus:not(:focus-visible) {
      text-decoration: none;
      outline-color: transparent; }
    .uol-form__input__error a:focus-visible, .uol-form__form-error a:focus-visible {
      color: #bd2f19;
      outline-color: transparent;
      outline-offset: 1px;
      background-color: #ffeded; }
    .uol-form__input__error a:hover, .uol-form__form-error a:hover {
      text-decoration: none; }
  @media (forced-colors: active) {
    .uol-form__input__error, .uol-form__form-error {
      border: 1px solid CanvasText; } }
  @media (-ms-high-contrast: active) {
    .uol-form__input__error, .uol-form__form-error {
      border: 1px solid WindowText; } }
  [role="search"] .uol-form__input__error, [role="search"] .uol-form__form-error {
    max-width: 100%; }
  .uol-form__input--teaxtarea-label .uol-form__input__error, .uol-form__input--teaxtarea-label .uol-form__form-error {
    max-width: 100%; }
    @media (min-width: 64.01em) {
      .uol-form__input--teaxtarea-label .uol-form__input__error, .uol-form__input--teaxtarea-label .uol-form__form-error {
        max-width: 46.125rem; } }
  .uol-form__custom-fieldset .uol-form__input__error, .uol-form__custom-fieldset .uol-form__form-error {
    max-width: 27.375rem;
    margin-bottom: 1rem; }
  @media (min-width: 48.01em) {
    .uol-form__input__error, .uol-form__form-error {
      font-weight: 400; } }
  @media (min-width: 64.01em) {
    .uol-form__input__error, .uol-form__form-error {
      line-height: 1.556; } }

.uol-form__form-error {
  position: relative;
  max-width: 27.375rem;
  border-radius: 6px;
  margin-bottom: 2.5rem; }
  .uol-form__form-error[data-form-error="true"] {
    position: relative;
    outline: 0 dotted #197ebe;
    outline-offset: 3px;
    overflow: visible; }
    .uol-form__form-error[data-form-error="true"]::before {
      content: "";
      position: absolute;
      top: -5px;
      right: -5px;
      bottom: -5px;
      left: -5px;
      border: 3px solid transparent;
      border-radius: false;
      transition: all 0.15s; }
      @media (-ms-high-contrast: active), (forced-colors: active) {
        .uol-form__form-error[data-form-error="true"]::before {
          display: none; } }
    @media (-ms-high-contrast: active), (forced-colors: active) {
      .uol-form__form-error[data-form-error="true"]:focus {
        outline-width: 3px; } }
    .uol-form__form-error[data-form-error="true"]:focus::before {
      border-color: #197ebe; }
    .uol-form__form-error[data-form-error="true"]:focus:not(:focus-visible)::before {
      border-color: transparent; }
    .uol-form__form-error[data-form-error="true"]:focus-visible::before {
      border-color: #197ebe;
      box-shadow: inset 0px 0px 0px 2px rgba(0, 0, 0, 0.65); }
  .uol-form__form-error p {
    margin: 0;
    padding: 0; }

.uol-form__form-error__text {
  position: relative;
  z-index: 3; }

.uol-datepicker-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  background-color: #0a0202;
  opacity: 0.78;
  animation: backgroundFade 0.15s ease-in-out; }
  .uol-datepicker-background.uol-datepicker-background--fade-out {
    animation: backgroundFade 0.15s ease-in-out;
    animation-direction: reverse; }

@media (min-width: 25.875em) {
  .uol-datepicker__controls-wrapper {
    display: flex; } }

.uol-datepicker__input-wrapper {
  position: relative;
  width: 100%; }
  .uol-datepicker__input-wrapper::before {
    content: "";
    position: absolute;
    bottom: 1px;
    left: 1px;
    border-bottom: 4px solid #197ebe;
    border-radius: 0 0 4px 4px;
    width: 0;
    z-index: 12;
    transition: width 0.25s ease-in; }
    @media (min-width: 25.875em) {
      .uol-datepicker__input-wrapper::before {
        bottom: 1px; } }
  .uol-datepicker__input-wrapper:focus-within::before {
    width: 100%; }
  [data-invalid] .uol-datepicker__input-wrapper::before {
    border-bottom-color: #d4351c; }

.uol-datepicker__unified-input-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%; }
  @media (min-width: 37.5em) {
    .uol-datepicker__unified-input-wrapper {
      width: 22.8125rem; } }
  [data-invalid] .uol-datepicker__unified-input-wrapper .uol-form__input__error, [data-invalid] .uol-datepicker__unified-input-wrapper .uol-form__form-error {
    flex-basis: auto;
    order: 2; }
    @media (max-width: 37.49em) {
      [data-invalid] .uol-datepicker__unified-input-wrapper .uol-form__input__error, [data-invalid] .uol-datepicker__unified-input-wrapper .uol-form__form-error {
        max-width: 100%; } }

.uol-datepicker__input-group {
  position: relative;
  width: 100%; }
  @media (max-width: 37.49em) {
    .uol-datepicker__input-group .uol-form__input__error, .uol-datepicker__input-group .uol-form__form-error {
      max-width: 100%; } }
  @media (min-width: 37.5em) {
    .uol-datepicker__input-group {
      width: 22.8125rem; } }

.uol-datepicker__labels-wrapper {
  display: flex; }
  [data-invalid] .uol-datepicker__labels-wrapper {
    order: 1; }

.uol-datepicker__range-label {
  padding-bottom: 0.75rem; }

.uol-datepicker__range-label--start {
  flex-basis: 8.5rem; }
  @media (min-width: 25.875em) {
    .uol-datepicker__range-label--start {
      flex-basis: 9rem; } }

.uol-datepicker__range-label--end {
  padding-left: 0.25rem; }

.uol-datepicker__unified-input {
  position: relative;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  border: 1px solid #51504c;
  border-radius: 6px; }
  [data-invalid] .uol-datepicker__unified-input {
    order: 3;
    border-color: #d4351c;
    border-width: 2px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.12); }
  .no-csspositionsticky .uol-datepicker__unified-input {
    height: 3.125rem; }
  @media (min-width: 37.5em) {
    .uol-datepicker__unified-input {
      width: 19.8125rem;
      border-right: none;
      border-radius: 6px 0 0 6px; } }
  .no-js .uol-datepicker__unified-input {
    border-right: 1px solid #51504c;
    border-radius: 6px; }

.uol-datepicker__input-wrapper--start,
.uol-datepicker__input-wrapper--end {
  position: relative; }
  .uol-datepicker__input-wrapper--start::before,
  .uol-datepicker__input-wrapper--end::before {
    content: "";
    position: absolute;
    bottom: -1px;
    width: 0;
    left: 1px;
    border-top: 4px solid transparent;
    border-radius: 0 0 0 3px;
    transition: width 0.25s ease-in; }
  .uol-datepicker__input-wrapper--start:focus-within::before,
  .uol-datepicker__input-wrapper--end:focus-within::before {
    border-color: #197ebe;
    width: 100%; }
    [data-invalid] .uol-datepicker__input-wrapper--start:focus-within::before, [data-invalid]
    .uol-datepicker__input-wrapper--end:focus-within::before {
      border-color: #d4351c; }

.uol-datepicker__input-wrapper--start::after {
  content: "-";
  position: absolute;
  color: #212529;
  bottom: 50%;
  transform: translateY(50%);
  right: 0;
  font-size: 1.25rem; }

.uol-datepicker__input-wrapper--end {
  flex-grow: 1; }

.uol-datepicker__input {
  font-size: 1rem;
  line-height: 1.25;
  box-sizing: border-box;
  border: 0;
  padding-left: 0.75rem;
  height: 3.125rem;
  width: 100%;
  font-variant-numeric: lining-nums;
  outline: transparent; }
  @media (min-width: 48em) {
    .uol-datepicker__input {
      font-size: 1.125rem; } }
  @media (min-width: 64em) {
    .uol-datepicker__input {
      font-size: 1.25rem; } }
  @media (min-width: 48em) {
    .uol-datepicker__input {
      line-height: 1.25; } }
  @media (min-width: 64em) {
    .uol-datepicker__input {
      line-height: 1.1; } }
  .uol-datepicker__input::placeholder {
    color: #3b3b3b; }
  [data-single-selection] .uol-datepicker__input {
    border: 1px solid #51504c;
    border-radius: 6px; }
    @media (min-width: 25.875em) {
      [data-single-selection] .uol-datepicker__input {
        border-right: none;
        border-radius: 6px 0 0 6px; } }
    .no-js [data-single-selection] .uol-datepicker__input {
      border: 1px solid #51504c;
      border-radius: 6px; }
  [data-single-selection][data-invalid] .uol-datepicker__input {
    border-width: 2px;
    border-color: #d4351c;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.12); }
  [data-range-selection] .uol-datepicker__input {
    height: calc(3.125rem - 2px); }
  [data-range-selection][data-invalid] .uol-datepicker__input {
    height: calc(3.125rem - 4px); }

[data-range-selection] .uol-datepicker__input--start {
  border-radius: 6px 0 0 6px;
  width: 8.5rem; }

.no-csspositionsticky .uol-datepicker__input--start:focus, .no-csspositionsticky
.uol-datepicker__input--end:focus {
  outline: 3px solid #197ebe;
  background-color: #eae8e6; }

.no-csspositionsticky .uol-datepicker__input--start::-ms-clear, .no-csspositionsticky
.uol-datepicker__input--end::-ms-clear {
  display: none; }

.uol-datepicker__input--end {
  border-radius: 0 6px 6px 0; }
  @media (min-width: 25.875em) {
    .uol-datepicker__input--end {
      border-radius: 0; } }
  .no-js .uol-datepicker__input--end {
    border-radius: 0 6px 6px 0; }

.uol-datepicker__toggle-btn {
  margin-top: 0.5rem;
  padding: 0.75rem 1rem;
  min-width: 0;
  max-width: 8.5rem; }
  .uol-datepicker__toggle-btn > svg {
    height: 1.25rem;
    width: 1.25rem;
    fill: #156265; }
    @media (-ms-high-contrast: active) {
      .uol-datepicker__toggle-btn > svg {
        fill: ButtonText; } }
    @media (forced-colors: active) {
      .uol-datepicker__toggle-btn > svg {
        fill: currentColor; } }
  [data-range-selection][data-invalid] .uol-datepicker__toggle-btn {
    order: 4; }
  @media (max-width: 25.865em) {
    .uol-datepicker__toggle-btn {
      position: relative; }
      .uol-datepicker__toggle-btn::before {
        content: "";
        position: absolute;
        top: -6px;
        right: -6px;
        bottom: -6px;
        left: -6px;
        border: 3px solid transparent;
        border-radius: inherit;
        transition: all 0.15s; }
      .uol-datepicker__toggle-btn:focus::before {
        border-color: #197ebe; } }
  @media (min-width: 25.875em) {
    .uol-datepicker__toggle-btn {
      background: #ffffff radial-gradient(circle, transparent 1%, #ffffff 1%) center/15000%;
      align-self: flex-end;
      margin-top: 0;
      border-radius: 0 6px 6px 0;
      padding: 0;
      height: 3.125rem;
      min-width: 3rem;
      outline: transparent; }
      [data-range-selection] .uol-datepicker__toggle-btn {
        position: absolute;
        bottom: 0; }
      [data-invalid] .uol-datepicker__toggle-btn {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.12); }
      .no-csspositionsticky .uol-datepicker__toggle-btn {
        right: 0; }
      .uol-datepicker__toggle-btn:focus {
        background-color: #197ebe;
        border-color: #197ebe; }
        .uol-datepicker__toggle-btn:focus > svg {
          fill: #ffffff; } }

.uol-datepicker__btn-label {
  vertical-align: text-top; }
  @media (min-width: 25.875em) {
    .uol-datepicker__btn-label {
      display: none; } }

.uol-datepicker {
  position: fixed;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  margin-top: 0.15em;
  border-radius: 8px;
  padding: 1.5rem 1rem;
  max-height: 100%;
  width: 20rem;
  z-index: 12;
  overflow: auto;
  font-variant-numeric: lining-nums;
  transform: translate(-50%, -50%);
  opacity: 0;
  background-color: #ffffff;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.15), 0 5px 10px rgba(0, 0, 0, 0.5), 0 5px 10px rgba(10, 2, 2, 0.05); }

@media (min-width: 25.875em) and (max-width: 63.99em) {
  .uol-datepicker {
    width: 24.625rem; } }
  @media (min-width: 64em) {
    .uol-datepicker {
      font-family: freight-sans-pro, "San Francisco", Roboto, "Helvetica Neue", Helvetica, "Noto Sans", "Segoe UI", Arial, sans-serif; } }
  @media (forced-colors: active), (-ms-high-contrast: active) {
    .uol-datepicker {
      border: 1px solid ButtonText; } }

.uol-datepicker__header {
  border-radius: 8px 8px 0 0; }

.uol-datepicker__toggle-container {
  position: relative;
  display: flex;
  border: none;
  border-radius: 3px;
  padding: 0;
  background-color: #eae8e6; }
  @media (forced-colors: active), (-ms-high-contrast: active) {
    .uol-datepicker__toggle-container {
      border: 1px solid ButtonText; } }
  .uol-datepicker__toggle-container::before, .uol-datepicker__toggle-container::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    margin: 2px;
    border-radius: 3px;
    transition: all 200ms ease-in; }
  .uol-datepicker__toggle-container::before {
    top: 0;
    left: 0;
    border: 2px solid #156265;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.12);
    height: calc(100% - 4px);
    width: calc(50% - 2px);
    background: radial-gradient(circle, #ffffff 50%, #e6e6e6 90%); }
  .uol-datepicker__toggle-container::after {
    top: 4px;
    left: 12px;
    border: 2px solid transparent;
    height: calc(100% - 12px);
    width: calc(50% - 10px); }
    @media (forced-colors: active), (-ms-high-contrast: active) {
      .uol-datepicker__toggle-container::after {
        display: none; } }

[data-toggle="start"]::before {
  left: 2px; }

[data-toggle="start"]::after {
  left: 6px; }

[data-toggle="start"]:focus-within::after {
  border-color: #197ebe; }

[data-toggle="end"]::before {
  transform: translateX(100%); }

[data-toggle="end"]::after {
  right: 4px;
  transform: translateX(100%); }

[data-toggle="end"]:focus-within::after {
  border-color: #197ebe; }

.uol-datepicker__toggle-item {
  position: relative;
  width: 50%; }

.uol-datepicker__toggle-radio {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.0001;
  color: #212529;
  cursor: pointer; }
  .uol-datepicker__toggle-radio:checked + .uol-datepicker__toggle-label .uol-datepicker__toggle-label--lower {
    color: #156265; }

.uol-datepicker__toggle-label {
  font-size: 1rem;
  display: block;
  border-radius: 6px;
  padding: calc(1rem + 2px) 1rem;
  font-weight: 500; }
  @media (min-width: 48em) {
    .uol-datepicker__toggle-label {
      font-size: 1rem; } }
  @media (min-width: 64em) {
    .uol-datepicker__toggle-label {
      font-size: 1.125rem; } }

.uol-datepicker__toggle-label--upper,
.uol-datepicker__toggle-label--lower {
  display: block;
  line-height: 1.2; }

.uol-datepicker__toggle-label--lower {
  padding-top: 0.5rem;
  color: #3b3b3b; }

.uol-datepicker__nav {
  display: flex;
  justify-content: center;
  padding: 1.5rem 0.25rem 0.75rem; }
  .uol-datepicker-container[data-single-selection] .uol-datepicker__nav {
    padding-top: 0.5rem; }

.uol-datepicker__grid-title {
  font-size: 1rem;
  display: inline-block;
  width: calc(18rem / 7 * 3.5);
  margin: 0;
  padding: 0;
  font-weight: 500;
  line-height: 1.625;
  text-align: center;
  color: #3b3b3b; }
  @media (min-width: 48em) {
    .uol-datepicker__grid-title {
      font-size: 1rem; } }
  @media (min-width: 64em) {
    .uol-datepicker__grid-title {
      font-size: 1.125rem; } }

@media (min-width: 25.875em) and (max-width: 63.99em) {
  .uol-datepicker__grid-title {
    width: calc(21.625rem / 7 * 3.25);
    line-height: 2; } }
  @media (min-width: 64em) {
    .uol-datepicker__grid-title {
      line-height: 1.3; } }

.uol-datepicker__prev-year,
.uol-datepicker__prev-month,
.uol-datepicker__next-month,
.uol-datepicker__next-year {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
  background: none;
  color: #212121;
  outline: transparent; }

@media (min-width: 25.875em) and (max-width: 63.99em) {
  .uol-datepicker__prev-year,
  .uol-datepicker__prev-month,
  .uol-datepicker__next-month,
  .uol-datepicker__next-year {
    height: 2rem;
    width: 2rem; } }
  .uol-datepicker__prev-year path,
  .uol-datepicker__prev-month path,
  .uol-datepicker__next-month path,
  .uol-datepicker__next-year path {
    height: 1.5rem;
    width: 1.5rem; }

@media (min-width: 25.875em) and (max-width: 63.99em) {
  .uol-datepicker__prev-year path,
  .uol-datepicker__prev-month path,
  .uol-datepicker__next-month path,
  .uol-datepicker__next-year path {
    height: 2rem;
    width: 2rem; } }
  @media (forced-colors: active), (-ms-high-contrast: active) {
    .uol-datepicker__prev-year,
    .uol-datepicker__prev-month,
    .uol-datepicker__next-month,
    .uol-datepicker__next-year {
      color: ButtonText; } }
  .uol-datepicker__prev-year:focus::before, .uol-datepicker__prev-year:hover::before,
  .uol-datepicker__prev-month:focus::before,
  .uol-datepicker__prev-month:hover::before,
  .uol-datepicker__next-month:focus::before,
  .uol-datepicker__next-month:hover::before,
  .uol-datepicker__next-year:focus::before,
  .uol-datepicker__next-year:hover::before {
    box-sizing: border-box;
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    height: 1.75rem;
    width: 1.75rem;
    border: 2px solid #197ebe;
    border-radius: 3rem; }

@media (min-width: 25.875em) and (max-width: 63.99em) {
  .uol-datepicker__prev-year:focus::before, .uol-datepicker__prev-year:hover::before,
  .uol-datepicker__prev-month:focus::before,
  .uol-datepicker__prev-month:hover::before,
  .uol-datepicker__next-month:focus::before,
  .uol-datepicker__next-month:hover::before,
  .uol-datepicker__next-year:focus::before,
  .uol-datepicker__next-year:hover::before {
    height: 2.25rem;
    width: 2.25rem; } }

.uol-datepicker__prev-year {
  margin-right: 1rem; }

.uol-datepicker__next-year {
  margin-left: 1rem; }

.uol-datepicker__grid {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0 4px;
  width: 100%; }

@media (min-width: 25.875em) and (max-width: 63.99em) {
  .uol-datepicker__grid {
    padding: 0 0.5rem; } }

.uol-datepicker__header-cell {
  font-size: 1rem;
  height: 2.375rem;
  line-height: 1.625;
  font-weight: normal;
  text-align: center;
  color: #3b3b3b; }
  @media (min-width: 48em) {
    .uol-datepicker__header-cell {
      font-size: 1rem; } }
  @media (min-width: 64em) {
    .uol-datepicker__header-cell {
      font-size: 1.125rem; } }

.uol-datepicker__row--blank {
  visibility: hidden; }

.uol-datepicker__cell {
  font-size: 1rem;
  position: relative;
  box-sizing: border-box;
  margin: 0;
  border: none;
  width: 2.575rem;
  height: 2.575rem;
  line-height: inherit;
  text-align: center;
  cursor: pointer;
  outline: transparent; }
  @media (min-width: 48em) {
    .uol-datepicker__cell {
      font-size: 1rem; } }
  @media (min-width: 64em) {
    .uol-datepicker__cell {
      font-size: 1.125rem; } }
  .uol-datepicker__cell:not(.uol-datepicker__cell--unavailable) {
    font-weight: 600; }
  @media (-ms-high-contrast: active) {
    .uol-datepicker__cell[tabindex="0"]:focus {
      border: 1px solid ButtonText; } }
  @media (forced-colors: active) {
    .uol-datepicker__cell[tabindex="0"]:focus {
      border: 1px solid currentColor; } }
  .uol-datepicker__cell::before, .uol-datepicker__cell::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    border: 2px solid transparent;
    border-radius: 3rem;
    z-index: 11; }
    @media (forced-colors: active), (-ms-high-contrast: active) {
      .uol-datepicker__cell::before, .uol-datepicker__cell::after {
        display: none; } }
  .uol-datepicker__cell::before {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%; }
  .uol-datepicker__cell::after {
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    height: calc(100% - 4px);
    width: calc(100% - 4px); }
  .uol-datepicker__cell > span[aria-hidden="true"] {
    position: relative;
    display: inline-block;
    height: 100%;
    width: 100%;
    line-height: 2.45;
    z-index: 12;
    color: #212529;
    pointer-events: none; }

@media (min-width: 25.875em) and (max-width: 63.99em) {
  .uol-datepicker__cell > span[aria-hidden="true"] {
    line-height: 2.965; } }
    @media (min-width: 64em) {
      .uol-datepicker__cell > span[aria-hidden="true"] {
        line-height: 2.1; } }
  .uol-datepicker__cell:hover:not([data-selected]), .uol-datepicker__cell[tabindex="0"]:not([data-selected]) {
    color: #156265; }
    @media (-ms-high-contrast: active) {
      .uol-datepicker__cell:hover:not([data-selected]), .uol-datepicker__cell[tabindex="0"]:not([data-selected]) {
        outline: 2px dotted ButtonText; } }
    @media (forced-colors: active) {
      .uol-datepicker__cell:hover:not([data-selected]), .uol-datepicker__cell[tabindex="0"]:not([data-selected]) {
        outline: 2px dotted currentColor; } }
    .uol-datepicker__cell:hover:not([data-selected])::before, .uol-datepicker__cell[tabindex="0"]:not([data-selected])::before {
      background-color: #ffffff;
      border-color: #156265; }
  .uol-datepicker__cell.uol-datepicker__cell--has-focus[tabindex="0"]:not([data-selected])::before {
    background-color: #699A9C; }
  .uol-datepicker__cell.uol-datepicker__cell--has-focus[tabindex="0"]:not([data-selected])::after {
    border-color: #ffffff; }
  .uol-datepicker__cell.uol-datepicker__cell--has-focus[tabindex="0"][data-selected]::after {
    border-color: #ffffff; }
  .uol-datepicker__cell[data-selected-start]:not([data-single-day]) {
    background: linear-gradient(to right, #ffffff 0%, #ffffff 50%, #699A9C 50%, #699A9C 50%); }
    @media (-ms-high-contrast: active) {
      .uol-datepicker__cell[data-selected-start]:not([data-single-day]) {
        border: 2px solid ButtonText;
        border-radius: 1rem 0 0 1rem; } }
    @media (forced-colors: active) {
      .uol-datepicker__cell[data-selected-start]:not([data-single-day]) {
        border: 2px solid currentColor;
        border-radius: 1rem 0 0 1rem; } }
  .uol-datepicker__cell[data-selected-end]:not([data-single-day]), .uol-datepicker__cell.uol-datepicker__cell--end-range {
    background: linear-gradient(to right, #699A9C 0%, #699A9C 50%, #ffffff 50%, #ffffff 100%); }
    @media (-ms-high-contrast: active) {
      .uol-datepicker__cell[data-selected-end]:not([data-single-day]), .uol-datepicker__cell.uol-datepicker__cell--end-range {
        border: 2px solid ButtonText;
        border-radius: 0 1rem 1rem 0; } }
    @media (forced-colors: active) {
      .uol-datepicker__cell[data-selected-end]:not([data-single-day]), .uol-datepicker__cell.uol-datepicker__cell--end-range {
        border: 2px solid currentColor;
        border-radius: 0 1rem 1rem 0; } }
  .uol-datepicker__cell.uol-datepicker__cell--end-range:not([data-selected]).uol-datepicker__cell--has-focus::before {
    background-color: #699A9C; }
  .uol-datepicker__cell.uol-datepicker__cell--end-range:not([data-selected]).uol-datepicker__cell--has-focus::after {
    border-color: #ffffff; }
  .uol-datepicker__cell[data-single-day] {
    background-color: #ffffff; }
  @media (-ms-high-contrast: active) {
    .uol-datepicker__cell[data-single-day], .uol-datepicker__cell[data-selected-date] {
      border: 2px solid ButtonText;
      border-radius: 1rem; } }
  @media (forced-colors: active) {
    .uol-datepicker__cell[data-single-day], .uol-datepicker__cell[data-selected-date] {
      border: 2px solid currentColor;
      border-radius: 1rem; } }
  .uol-datepicker__cell[data-selected-date] span[aria-hidden="true"], .uol-datepicker__cell[data-single-day] span[aria-hidden="true"], .uol-datepicker__cell[data-selected-start] span[aria-hidden="true"], .uol-datepicker__cell[data-selected-end] span[aria-hidden="true"] {
    color: #ffffff; }
  .uol-datepicker__cell[data-selected-date]::before, .uol-datepicker__cell[data-single-day]::before, .uol-datepicker__cell[data-selected-start]::before, .uol-datepicker__cell[data-selected-end]::before {
    background-color: #156265; }
  .uol-datepicker__cell[data-selected-date]:focus::after, .uol-datepicker__cell[data-single-day]:focus::after, .uol-datepicker__cell[data-selected-start]:focus::after, .uol-datepicker__cell[data-selected-end]:focus::after {
    border-color: #ffffff; }
  .uol-datepicker__cell.uol-datepicker__cell--in-range, .uol-datepicker__cell.uol-datepicker__cell--passthrough {
    color: #0e0c0c;
    background-color: #699A9C; }
    .uol-datepicker__cell.uol-datepicker__cell--in-range > span[aria-hidden="true"]::before, .uol-datepicker__cell.uol-datepicker__cell--passthrough > span[aria-hidden="true"]::before {
      background-color: #ffffff; }
    .uol-datepicker__cell.uol-datepicker__cell--in-range > span[aria-hidden="true"], .uol-datepicker__cell.uol-datepicker__cell--passthrough > span[aria-hidden="true"] {
      color: #0a0202; }
      .uol-datepicker__cell.uol-datepicker__cell--in-range > span[aria-hidden="true"]::after, .uol-datepicker__cell.uol-datepicker__cell--passthrough > span[aria-hidden="true"]::after {
        background-color: #0a0202; }
    .uol-datepicker__cell.uol-datepicker__cell--in-range[tabindex="0"]::before, .uol-datepicker__cell.uol-datepicker__cell--in-range:focus::before, .uol-datepicker__cell.uol-datepicker__cell--passthrough[tabindex="0"]::before, .uol-datepicker__cell.uol-datepicker__cell--passthrough:focus::before {
      background-color: #ffffff;
      border-color: #699A9C; }
    .uol-datepicker__cell.uol-datepicker__cell--in-range[tabindex="0"] > span[aria-hidden="true"]::before, .uol-datepicker__cell.uol-datepicker__cell--in-range:focus > span[aria-hidden="true"]::before, .uol-datepicker__cell.uol-datepicker__cell--passthrough[tabindex="0"] > span[aria-hidden="true"]::before, .uol-datepicker__cell.uol-datepicker__cell--passthrough:focus > span[aria-hidden="true"]::before {
      background-color: #156265; }
    .uol-datepicker__cell.uol-datepicker__cell--in-range.uol-datepicker__cell--has-focus[tabindex="0"]::before, .uol-datepicker__cell.uol-datepicker__cell--passthrough.uol-datepicker__cell--has-focus[tabindex="0"]::before {
      border-color: #ffffff; }
    .uol-datepicker__cell.uol-datepicker__cell--in-range.uol-datepicker__cell--has-focus[tabindex="0"]::after, .uol-datepicker__cell.uol-datepicker__cell--passthrough.uol-datepicker__cell--has-focus[tabindex="0"]::after {
      border-color: #156265; }

.uol-datepicker__cell--empty {
  cursor: not-allowed;
  height: calc(18rem / 7); }

@media (min-width: 25.875em) and (max-width: 63.99em) {
  .uol-datepicker__cell--empty {
    height: calc(21.625rem / 7); } }
  .uol-datepicker__cell--empty::before, .uol-datepicker__cell--empty::after {
    display: none; }

.uol-datepicker__cell--current-day span[aria-hidden="true"]::before {
  content: "";
  position: absolute;
  bottom: 6px;
  left: 50%;
  height: 4px;
  width: 4px;
  transform: translateX(-2px);
  background-color: #156265; }
  @media (-ms-high-contrast: active) {
    .uol-datepicker__cell--current-day span[aria-hidden="true"]::before {
      background-color: ButtonText; } }
  @media (forced-colors: active) {
    .uol-datepicker__cell--current-day span[aria-hidden="true"]::before {
      background-color: currentColor; } }

@media (min-width: 25.875em) and (max-width: 63.99em) {
  .uol-datepicker__cell--current-day span[aria-hidden="true"]::before {
    bottom: 8px; } }

.uol-datepicker__cell--current-day[tabindex="0"]:not([data-selected]) span[aria-hidden="true"]::before {
  background-color: #156265; }

.uol-datepicker__cell--current-day[data-selected] > span[aria-hidden="true"]::before, .uol-datepicker__cell--current-day.uol-datepicker__cell--has-focus[tabindex="0"] > span[aria-hidden="true"]::before {
  background-color: #ffffff; }

.uol-datepicker__cell--unavailable[aria-disabled="true"] {
  cursor: not-allowed; }
  .uol-datepicker__cell--unavailable[aria-disabled="true"][tabindex="0"]::before {
    border-color: #d4351c; }
  .uol-datepicker__cell--unavailable[aria-disabled="true"].uol-datepicker__cell--has-focus[tabindex="0"] [aria-hidden="true"] {
    color: #ffffff; }
    .uol-datepicker__cell--unavailable[aria-disabled="true"].uol-datepicker__cell--has-focus[tabindex="0"] [aria-hidden="true"]::after {
      background-color: #ffffff; }
  .uol-datepicker__cell--unavailable[aria-disabled="true"].uol-datepicker__cell--has-focus[tabindex="0"]::before {
    background-color: #d4351c; }
  .uol-datepicker__cell--unavailable[aria-disabled="true"] [aria-hidden="true"] {
    color: #636363; }
    .uol-datepicker__cell--unavailable[aria-disabled="true"] [aria-hidden="true"]::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      height: 100%;
      width: 2px;
      transform: translate(-50%, -50%) rotate(-45deg);
      background-color: #636363; }
      @media (-ms-high-contrast: active) {
        .uol-datepicker__cell--unavailable[aria-disabled="true"] [aria-hidden="true"]::after {
          background-color: ButtonText; } }
      @media (forced-colors: active) {
        .uol-datepicker__cell--unavailable[aria-disabled="true"] [aria-hidden="true"]::after {
          background-color: CanvasText; } }
  .uol-datepicker__cell--unavailable[aria-disabled="true"].uol-datepicker__cell--in-range.uol-datepicker__cell--has-focus[tabindex="0"]::after, .uol-datepicker__cell--unavailable[aria-disabled="true"].uol-datepicker__cell--passthrough.uol-datepicker__cell--has-focus[tabindex="0"]::after {
    border-color: #d4351c; }
  .uol-datepicker__cell--unavailable[aria-disabled="true"].uol-datepicker__cell--in-range.uol-datepicker__cell--has-focus[tabindex="0"] > [aria-hidden="true"], .uol-datepicker__cell--unavailable[aria-disabled="true"].uol-datepicker__cell--passthrough.uol-datepicker__cell--has-focus[tabindex="0"] > [aria-hidden="true"] {
    color: #ffffff; }
    .uol-datepicker__cell--unavailable[aria-disabled="true"].uol-datepicker__cell--in-range.uol-datepicker__cell--has-focus[tabindex="0"] > [aria-hidden="true"]::after, .uol-datepicker__cell--unavailable[aria-disabled="true"].uol-datepicker__cell--passthrough.uol-datepicker__cell--has-focus[tabindex="0"] > [aria-hidden="true"]::after {
      background-color: #ffffff; }
  .uol-datepicker__cell--unavailable[aria-disabled="true"].uol-datepicker__cell--in-range > [aria-hidden="true"], .uol-datepicker__cell--unavailable[aria-disabled="true"].uol-datepicker__cell--passthrough > [aria-hidden="true"] {
    color: #0a0202; }
    .uol-datepicker__cell--unavailable[aria-disabled="true"].uol-datepicker__cell--in-range > [aria-hidden="true"]::after, .uol-datepicker__cell--unavailable[aria-disabled="true"].uol-datepicker__cell--passthrough > [aria-hidden="true"]::after {
      background-color: #0a0202; }
  .uol-datepicker__cell--unavailable[aria-disabled="true"].uol-datepicker__cell--in-range:focus > [aria-hidden="true"], .uol-datepicker__cell--unavailable[aria-disabled="true"].uol-datepicker__cell--passthrough:focus > [aria-hidden="true"] {
    color: #6e6b6b; }
    .uol-datepicker__cell--unavailable[aria-disabled="true"].uol-datepicker__cell--in-range:focus > [aria-hidden="true"]::after, .uol-datepicker__cell--unavailable[aria-disabled="true"].uol-datepicker__cell--passthrough:focus > [aria-hidden="true"]::after {
      background-color: #6e6b6b; }
  .uol-datepicker__cell--unavailable[aria-disabled="true"]:focus::before, .uol-datepicker__cell--unavailable[aria-disabled="true"]:hover::before {
    border-color: #d4351c; }
  .uol-datepicker__cell--unavailable[aria-disabled="true"][data-selected-start] > [aria-hidden="true"]::after, .uol-datepicker__cell--unavailable[aria-disabled="true"][data-selected-end] > [aria-hidden="true"]::after, .uol-datepicker__cell--unavailable[aria-disabled="true"][data-single-day] > [aria-hidden="true"]::after, .uol-datepicker__cell--unavailable[aria-disabled="true"][data-selected-date] > [aria-hidden="true"]::after {
    background-color: #ffffff; }
    @media (forced-colors: active) {
      .uol-datepicker__cell--unavailable[aria-disabled="true"][data-selected-start] > [aria-hidden="true"]::after, .uol-datepicker__cell--unavailable[aria-disabled="true"][data-selected-end] > [aria-hidden="true"]::after, .uol-datepicker__cell--unavailable[aria-disabled="true"][data-single-day] > [aria-hidden="true"]::after, .uol-datepicker__cell--unavailable[aria-disabled="true"][data-selected-date] > [aria-hidden="true"]::after {
        background-color: CanvasText; } }
  .uol-datepicker__cell--unavailable[aria-disabled="true"][data-selected-start]::before, .uol-datepicker__cell--unavailable[aria-disabled="true"][data-selected-end]::before, .uol-datepicker__cell--unavailable[aria-disabled="true"][data-single-day]::before, .uol-datepicker__cell--unavailable[aria-disabled="true"][data-selected-date]::before {
    background-color: #d4351c; }
  .uol-datepicker__cell--unavailable[aria-disabled="true"][data-selected-start]:focus::after, .uol-datepicker__cell--unavailable[aria-disabled="true"][data-selected-end]:focus::after, .uol-datepicker__cell--unavailable[aria-disabled="true"][data-single-day]:focus::after, .uol-datepicker__cell--unavailable[aria-disabled="true"][data-selected-date]:focus::after {
    border-color: #ffffff; }

.uol-datepicker__footer {
  display: flex;
  justify-content: flex-end;
  margin: 0.75rem 0 0.5rem;
  padding: 1rem 0 0; }

.uol-datepicker__cancel-btn,
.uol-datepicker__confirm-btn {
  position: relative;
  outline: 0 dotted #197ebe;
  outline-offset: 3px;
  overflow: visible;
  font-size: 1rem;
  border-radius: 4px;
  padding: 1rem;
  max-height: 3.125rem;
  min-width: 0;
  max-width: 7.75rem;
  width: 7.75rem; }
  .uol-datepicker__cancel-btn::before,
  .uol-datepicker__confirm-btn::before {
    content: "";
    position: absolute;
    top: -6px;
    right: -6px;
    bottom: -6px;
    left: -6px;
    border: 3px solid transparent;
    border-radius: inherit;
    transition: all 0.15s; }
    @media (-ms-high-contrast: active), (forced-colors: active) {
      .uol-datepicker__cancel-btn::before,
      .uol-datepicker__confirm-btn::before {
        display: none; } }
  @media (-ms-high-contrast: active), (forced-colors: active) {
    .uol-datepicker__cancel-btn:focus,
    .uol-datepicker__confirm-btn:focus {
      outline-width: 3px; } }
  .uol-datepicker__cancel-btn:focus::before,
  .uol-datepicker__confirm-btn:focus::before {
    border-color: #197ebe; }
  .uol-datepicker__cancel-btn:focus:not(:focus-visible)::before,
  .uol-datepicker__confirm-btn:focus:not(:focus-visible)::before {
    border-color: transparent; }
  .uol-datepicker__cancel-btn:focus-visible::before,
  .uol-datepicker__confirm-btn:focus-visible::before {
    border-color: #197ebe; }
  @media (min-width: 48em) {
    .uol-datepicker__cancel-btn,
    .uol-datepicker__confirm-btn {
      font-size: 1rem; } }
  @media (min-width: 64em) {
    .uol-datepicker__cancel-btn,
    .uol-datepicker__confirm-btn {
      font-size: 1.125rem; } }
  @media (min-width: 64em) {
    .uol-datepicker__cancel-btn,
    .uol-datepicker__confirm-btn {
      padding: calc(1rem - 2px); } }

.uol-datepicker__cancel-btn {
  margin-right: 0.75rem; }

.uol-datepicker--fade-in {
  animation: fadeIn 0.2s ease-in-out;
  animation-fill-mode: forwards; }

.uol-datepicker--fade-out {
  animation: fadeOut 0.2s ease-in-out;
  animation-fill-mode: backwards; }

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: visible; }
  100% {
    opacity: 1; } }

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible; }
  99% {
    visibility: visible; }
  100% {
    opacity: 0;
    visibility: hidden; } }

@keyframes backgroundFade {
  0% {
    opacity: 0; }
  100% {
    opacity: 0.78; } }

.uol-typeahead {
  background: #ffffff; }
  .uol-typeahead[type="text"] {
    padding-right: 2rem; }
  .uol-typeahead ~ svg {
    transition: transform 200ms ease-in-out; }
  .uol-typeahead[aria-expanded="true"] {
    border-radius: 6px 6px 0 0; }
    .uol-typeahead[aria-expanded="true"] ~ .uol-form__input__chevron {
      cursor: pointer;
      pointer-events: auto;
      transform: rotate(180deg); }
  .uol-typeahead[aria-expanded="false"] {
    border-radius: 6px; }
    .uol-typeahead[aria-expanded="false"] ~ svg {
      transform: rotate(0); }

.uol-typeahead__list {
  box-sizing: border-box;
  position: absolute;
  top: 1.85rem;
  left: 0;
  border: 1px solid #51504c;
  padding: 0;
  width: 100%;
  max-height: 12rem;
  list-style-type: none;
  z-index: 11;
  overflow-y: auto;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.12);
  cursor: pointer; }
  .uol-typeahead__list[data-panel-shown="false"] {
    visibility: hidden; }
  .uol-typeahead__list[data-panel-shown="true"] {
    visibility: visible; }
  @media (min-width: 48em) {
    .uol-typeahead__list {
      max-height: 22rem; } }
  @media (min-width: 64em) {
    .uol-typeahead__list {
      max-height: 24rem; } }

.uol-typeahead__item {
  position: relative;
  display: flex;
  padding: 0 2.875rem 0 0.75rem;
  min-height: 2.875rem;
  align-items: center;
  font-family: "San Francisco", Roboto, "Helvetica Neue", Helvetica, "Noto Sans", "Segoe UI", Arial, sans-serif;
  font-weight: normal;
  background: inherit;
  color: #212529; }
  .uol-typeahead__item[data-item-active] {
    background-color: #ABC6C7; }
  .uol-typeahead__list--multi-select .uol-typeahead__item::before {
    content: "";
    position: relative;
    margin-right: 0.5rem;
    min-width: 1.75rem;
    height: 1.75rem;
    width: 1.75rem;
    border: 2px solid #156265;
    background-color: #ffffff;
    z-index: 2;
    pointer-events: none; }
  .uol-typeahead__list--multi-select .uol-typeahead__item[aria-selected="true"] > svg {
    fill: #156265;
    color: #156265; }
  .uol-typeahead__item:hover {
    background-color: #699B9D;
    cursor: pointer; }
  .uol-typeahead__item[aria-selected="true"] > svg {
    position: relative;
    top: -1px;
    left: 0.5rem;
    fill: #0a0202;
    color: #0a0202;
    pointer-events: none; }
    .uol-typeahead__list--multi-select .uol-typeahead__item[aria-selected="true"] > svg {
      position: absolute;
      z-index: 3;
      left: 1rem;
      top: 50%;
      transform: translateY(-50%); }
    @media screen and (forced-colors: active) {
      .uol-typeahead__item[aria-selected="true"] > svg {
        fill: CanvasText; } }
  .uol-typeahead__item[data-filter-hidden] {
    display: none; }
  @media (min-width: 64em) {
    .uol-typeahead__item {
      font-family: freight-sans-pro, "San Francisco", Roboto, "Helvetica Neue", Helvetica, "Noto Sans", "Segoe UI", Arial, sans-serif; } }

.js .uol-form__input--select[hidden] {
  display: none; }

.uol-chips--inInput {
  padding-top: 0.75rem;
  padding-left: 6px;
  border: 1px solid #51504c;
  border-bottom: none;
  border-radius: 6px;
  margin: 0 0 -8px 0;
  background-color: #fff; }
  @media (min-width: 37.5em) {
    .uol-chips--inInput {
      max-width: calc(27.375rem - 8px); } }

.uol-typeahead--panelOpenChips.uol-typeahead {
  border-top: 0;
  border-radius: 0; }

.uol-typeahead--panelOpenNoChips.uol-typeahead {
  border-radius: 6px 6px 0 0; }

.uol-typeahead--panelOpenNoChips::before {
  border-radius: 0; }

.uol-typeahead--panelClosedChips.uol-typeahead {
  border-top: 0;
  border-radius: 0 0 6px 6px; }

.uol-typeahead--panelClosedChips::before {
  border-radius: 0; }

.uol-typeahead--panelClosedNoChips.uol-typeahead {
  border-radius: 6px 6px 6px 6px; }

.uol-typeahead__input--no-chips--panel-open {
  padding-top: 50px !important;
  height: 100px !important; }

.uol-typeahead__input--no-chips--panel-closed {
  padding-top: 50px !important;
  height: 50px !important; }

.uol-typeahead__select--no-chips--panel-open {
  top: 80px; }

.uol-typeahead__select--no-chips--panel-closed {
  top: 30px; }

.uol-typeahead__count-num--no-chips--panel-open {
  border-bottom: 1px solid #51504c;
  padding-bottom: 8px; }

.uol-typeahead__count-num--no-chips--panel-closed {
  border-bottom: none;
  padding-bottom: 0; }

.uol-form__input-wrapper__num-of-selected-message {
  display: block;
  width: calc(100% - 0.75rem);
  padding-left: 0.75rem;
  position: absolute;
  left: 0;
  top: 12px;
  color: #3b3b3b;
  pointer-events: none; }
  @media (min-width: 48em) {
    .uol-form__input-wrapper__num-of-selected-message {
      top: 10px; } }
  @media (min-width: 64em) {
    .uol-form__input-wrapper__num-of-selected-message {
      top: 8px; } }

.uol-form__input-wrapper--search-typeahead .uol-form__input--select {
  height: 44px;
  padding-bottom: 0; }
  @media (max-width: 48em) {
    .uol-form__input-wrapper--search-typeahead .uol-form__input--select {
      margin-bottom: 2rem; } }

.uol-form__input-container {
  /**
   * Styling specific to all inputs that take text characters as input
  */
  color: #212529;
  font-size: 1.125rem;
  font-variant-numeric: lining-nums;
  margin-bottom: 2rem; }
  .uol-form__input-container:not(.uol-form__input-container--multiple) {
    position: relative; }
  @media (min-width: 64em) {
    .uol-form__input-container {
      margin-bottom: 2.5rem; } }
  @media (min-width: 48em) {
    .uol-form__input-container {
      flex-wrap: nowrap;
      border-radius: 12px; } }
  @media (min-width: 64em) {
    .uol-form__input-container {
      border-radius: 12px;
      font-size: 1.25rem; } }
  @media (min-width: 37.5em) {
    .uol-form__input-container[data-typeahead] {
      max-width: 27.375rem;
      min-width: 27.375rem; } }

.uol-form__input-label, .uol-datepicker__range-label {
  display: block;
  font-weight: 600; }

.uol-form__input-label__text {
  display: block;
  max-width: 27.375rem; }

.uol-form__input-label__hint {
  display: block;
  font-weight: 400;
  max-width: 27.375rem; }

.uol-form__input-label__text + .uol-form__input-label__hint {
  padding-top: 0.25rem; }

.uol-form__selected-items {
  display: none; }

.uol-form__input__requirements {
  max-width: 47.5rem;
  font-weight: 400;
  display: block;
  margin-top: 0.25rem; }
  .uol-form__input__requirements > span {
    display: block;
    position: relative; }
    .uol-form__input__requirements > span::before {
      content: "•";
      position: relative;
      padding: 0 0.75rem 0 0.5rem;
      font-size: 1rem;
      top: -0.1rem; }
    .uol-form__input__requirements > span:last-of-type {
      margin-bottom: 0.75rem; }

.uol-form__input-wrapper {
  display: inline-block;
  width: 100%;
  position: relative; }
  @media (min-width: 37.5em) {
    .uol-form__input-wrapper:not(.uol-form__input-wrapper--search):not(.uol-form__input-wrapper--file) {
      max-width: 27.375rem; } }
  .uol-form__input-wrapper svg {
    fill: windowText;
    position: absolute;
    top: 0.9rem;
    left: 0.9rem; }
  .uol-form__input-wrapper::before {
    content: "";
    position: absolute;
    bottom: 1px;
    left: 1px;
    border-bottom: 4px solid #197ebe;
    width: 0;
    border-radius: 0 0 4px 4px;
    transition: width 0.25s ease-in; }
    [data-typeahead] .uol-form__input-wrapper::before {
      border-radius: 0; }
  .uol-form__input-wrapper.uol-form__input-wrapper--typeahead--open::before {
    border-radius: 0; }
  .uol-form__input-wrapper:focus-within::before {
    width: calc(100% - 2px); }
  .uol-form__input-wrapper[data-field-invalid="true"]::before {
    border-bottom-color: #d4351c; }

@media (max-width: 47.99em) {
  .uol-form__input-wrapper--search::before {
    margin-bottom: 2rem; } }

@media (max-width: 47.99em) {
  .uol-form__input-wrapper--search {
    margin-bottom: 0; }
    .uol-form__input-wrapper--search .uol-form__input--search, .uol-form__input-wrapper--search .uol-typeahead {
      margin-bottom: 2rem; } }

.uol-form__input-wrapper--panelOpen::before {
  border-radius: 0; }

.uol-form__input-container--x-small .uol-form__input__error, .uol-form__input-container--x-small .uol-form__form-error,
.uol-form__input-container--x-small [class].uol-form__input-wrapper,
.uol-form__input-container--x-small .uol-form__input {
  max-width: 100%; }
  @media (min-width: 25.875em) {
    .uol-form__input-container--x-small .uol-form__input__error, .uol-form__input-container--x-small .uol-form__form-error,
    .uol-form__input-container--x-small [class].uol-form__input-wrapper,
    .uol-form__input-container--x-small .uol-form__input {
      max-width: 18.25rem; } }

.uol-form__input-container--x-small .uol-form__input-label__hint {
  max-width: 27.375rem; }

.uol-form__input-container--small .uol-form__input__error, .uol-form__input-container--small .uol-form__form-error,
.uol-form__input-container--small [class].uol-form__input-wrapper,
.uol-form__input-container--small .uol-form__input {
  max-width: 100%; }
  @media (min-width: 37.5em) {
    .uol-form__input-container--small .uol-form__input__error, .uol-form__input-container--small .uol-form__form-error,
    .uol-form__input-container--small [class].uol-form__input-wrapper,
    .uol-form__input-container--small .uol-form__input {
      max-width: 22.8125rem; } }

.uol-form__input-container--small .uol-form__input-label__hint {
  max-width: 27.375rem; }

.uol-form__input-container--medium .uol-form__input__error, .uol-form__input-container--medium .uol-form__form-error,
.uol-form__input-container--medium [class].uol-form__input-wrapper,
.uol-form__input-container--medium .uol-form__input {
  max-width: 100%; }
  @media (min-width: 37.5em) {
    .uol-form__input-container--medium .uol-form__input__error, .uol-form__input-container--medium .uol-form__form-error,
    .uol-form__input-container--medium [class].uol-form__input-wrapper,
    .uol-form__input-container--medium .uol-form__input {
      max-width: 27.375rem; } }

.uol-form__input-container--medium .uol-form__input-label__hint {
  max-width: 27.375rem; }

.uol-form__input-container--large .uol-form__input__error, .uol-form__input-container--large .uol-form__form-error,
.uol-form__input-container--large [class].uol-form__input-wrapper,
.uol-form__input-container--large .uol-form__input {
  max-width: 100%; }
  @media (min-width: 25.875em) {
    .uol-form__input-container--large .uol-form__input__error, .uol-form__input-container--large .uol-form__form-error,
    .uol-form__input-container--large [class].uol-form__input-wrapper,
    .uol-form__input-container--large .uol-form__input {
      max-width: 46.125rem; } }

.uol-form__input-container--large .uol-form__input-label__hint {
  max-width: 27.375rem; }

.uol-form__input {
  line-height: 1.25;
  box-sizing: border-box;
  display: block;
  border-radius: 6px;
  width: 100%;
  border: 1px solid #51504c;
  padding: 0 0.75rem;
  background-color: #ffffff;
  font-variant-numeric: lining-nums;
  background-color: #ffffff; }
  @media (min-width: 48em) {
    .uol-form__input {
      line-height: 1.25; } }
  @media (min-width: 64em) {
    .uol-form__input {
      line-height: 1.1; } }
  .uol-form__input:not(.uol-form__input--textarea):not(.uol-form__input--file):not(.uol-form__input--select[multiple]) {
    height: 3.125rem; }
  .uol-form__input[type="search"] {
    -webkit-appearance: none;
    appearance: none;
    box-sizing: border-box; }
  .uol-form__input:focus {
    outline: 3px dotted transparent; }
  .no-csspositionsticky .uol-form__input:focus {
    outline: 4px solid #1a84c7; }
  .uol-form__input::placeholder {
    color: #212529; }
  .uol-form__input[aria-invalid="true"] {
    border: 2px solid #d4351c;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.12); }

/**
   * Styling for textarea component
   */
.uol-form__input--textarea-wrapper {
  position: relative;
  z-index: 2; }
  .uol-form__input--textarea-wrapper::before {
    content: "";
    position: absolute;
    box-sizing: border-box;
    top: -5px;
    right: 0;
    bottom: 0;
    left: -5px;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    border: 3px solid transparent;
    border-radius: 8px;
    z-index: 3; }
    @media (min-width: 64em) {
      .uol-form__input--textarea-wrapper::before {
        max-width: calc(46.125rem + 10px); } }
    @media (forced-colors: active) {
      .uol-form__input--textarea-wrapper::before {
        border: none; } }
    @media (-ms-high-contrast: active) {
      .uol-form__input--textarea-wrapper::before {
        border: none; } }
  .uol-form__input--textarea-wrapper:focus-within::before {
    border-color: #197ebe; }
  .uol-form__input--textarea-wrapper[data-field-invalid="true"]:focus-within::before {
    border-color: #d4351c; }
  .uol-form__input--textarea-wrapper[data-field-invalid="true"] .uol-form__input--textarea {
    caret-color: #d4351c; }

.uol-form__input--textarea {
  position: relative;
  min-height: 3.125rem;
  max-height: 57.5rem;
  box-sizing: border-box;
  resize: vertical;
  z-index: 3;
  height: 11.875rem;
  line-height: 1.556;
  padding: 0.75rem; }
  @media (min-width: 25.875em) {
    .uol-form__input--textarea {
      height: 13.625rem; } }
  @media (min-width: 64em) {
    .uol-form__input--textarea {
      line-height: 1.6;
      max-width: 46.125rem; } }
  @media (min-width: 37.5em) {
    .uol-form__input--textarea[data-textarea-height="small"] {
      height: 6.625rem; } }
  @media (min-width: 64em) {
    .uol-form__input--textarea[data-textarea-height="small"] {
      height: 7.25rem; } }
  @media (min-width: 37.5em) {
    .uol-form__input--textarea[data-textarea-height="medium"] {
      height: 8.375rem; } }
  @media (min-width: 64em) {
    .uol-form__input--textarea[data-textarea-height="medium"] {
      height: 9.25rem; } }
  @media (min-width: 37.5em) {
    .uol-form__input--textarea[data-textarea-height="large"] {
      height: 14.625rem; } }
  @media (min-width: 64em) {
    .uol-form__input--textarea[data-textarea-height="large"] {
      height: 15.25rem; } }

/**
   * Styling specific to inputs that have an icon
   */
.uol-form__input-wrapper--with-icon svg {
  fill: windowText;
  position: absolute;
  top: 0.9rem;
  left: 0.9rem; }

@media (max-width: 47.99em) {
  .uol-form__input-wrapper--search-typeahead::before {
    margin-bottom: 32px; } }

@media (max-width: 47.99em) {
  .uol-form__input-wrapper--search-typeahead::before {
    margin-bottom: 32px; } }

.uol-form__input-wrapper--with-icon .uol-form__input {
  padding-left: 2.875rem; }

/**
   * Styling for search inputs
   */
@media (min-width: 48em) {
  .uol-form__input--search, .uol-form__input-wrapper--search {
    margin-right: 1rem; } }

@media (min-width: 64em) {
  .uol-form__input--search, .uol-form__input-wrapper--search {
    margin-right: 1.5rem; } }

@media (min-width: 90em) {
  .uol-form__input--search, .uol-form__input-wrapper--search {
    margin-right: 2rem; } }

.uol-form__input-wrapper--with-icon .uol-form__input {
  padding-left: 2.875rem; }

.uol-form__input-wrapper--with-icon .uol-form__input {
  padding-left: 2.875rem; }

.uol-form__additional-content {
  padding-top: 0.75rem; }
  .uol-form__additional-content a {
    transition: background 0.15s ease-out, text-decoration 0.15s ease-out; }
    .uol-form__additional-content a:focus {
      color: #212529;
      background-color: #ffeded;
      text-decoration: underline;
      text-decoration-thickness: 3px;
      text-decoration-color: #ff4e36;
      text-underline-offset: 4px;
      outline: 2px dotted inherit;
      outline-offset: 1px;
      outline-color: transparent; }
    .uol-form__additional-content a:focus:not(:focus-visible) {
      text-decoration: none;
      outline-color: transparent; }
    .uol-form__additional-content a:focus-visible {
      color: #212529;
      outline-color: transparent;
      outline-offset: 1px;
      background-color: #ffeded; }

/**
   * Password field with Toggle password functionality
   */
input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none; }

.uol-form__input--password {
  width: calc(100% - 3.875rem);
  border-radius: 6px 0 0 6px;
  border-right: none; }
  .no-js .uol-form__input--password {
    border-radius: 6px;
    border: 1px solid #51504c;
    width: 100%; }

.uol-form__input--password-toggle {
  background: #ffffff radial-gradient(circle, transparent 1%, #ffffff 1%) center/15000%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding: 0 0.75rem;
  right: 0;
  top: 0;
  height: 100%;
  border: 2px solid #156265;
  border-radius: 0 6px 6px 0;
  transition: box-shadow 0.25s ease-in, background 0.5s ease;
  min-width: 4rem;
  outline: 0 dotted transparent;
  outline-offset: 3px; }
  .uol-form__input--password-toggle:focus {
    background-color: #197ebe;
    border-color: #197ebe; }
    .uol-form__input--password-toggle:focus[data-password-visible="true"]::before {
      border-bottom: 4px solid #ffffff;
      border-top: 2px solid #197ebe; }
    .uol-form__input--password-toggle:focus > svg {
      color: #ffffff;
      fill: #ffffff; }
    @media (forced-colors: active) {
      .uol-form__input--password-toggle:focus {
        outline-color: LinkText;
        outline-width: 3px; } }
    @media (-ms-high-contrast: active) {
      .uol-form__input--password-toggle:focus {
        outline-color: -ms-hotlight;
        outline-width: 3px; } }
  .uol-form__input--password-toggle::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 0;
    transform-origin: left top;
    border-bottom: 4px solid #156265;
    border-top: 2px solid #ffffff;
    transform: rotate(45deg) translate(-50%, -50%);
    transition: width 0.25s ease-in; }
  .uol-form__input--password-toggle[data-password-visible="true"]::before {
    width: 2.25rem; }
  .uol-form__input--password-toggle:hover {
    box-shadow: inset 0 0 0 1px #156265; }
  .uol-form__input--password-toggle:active {
    background-color: #e6e6e6; }
  .uol-form__input--password-toggle > svg {
    fill: #156265;
    height: 1.875rem;
    position: initial;
    margin-top: 0.125rem; }
    @media (forced-colors: active) {
      .uol-form__input--password-toggle > svg {
        fill: LinkText; } }
    @media (-ms-high-contrast: active) {
      .uol-form__input--password-toggle > svg {
        fill: -ms-hotlight; } }
  .no-js .uol-form__input--password-toggle {
    display: none; }

/**
    * Grouped inputs that are short fields displayed inline, DOB, date etc
    */
.uol-form__input--inline-wrapper {
  display: flex; }
  .uol-form__input-container .uol-form__input--inline-wrapper {
    position: static; }

.uol-form__input--inline-field-wrapper {
  position: relative; }
  .uol-form__input--inline-field-wrapper::before {
    content: "";
    position: absolute;
    bottom: -4.5px;
    left: -4px;
    box-sizing: border-box;
    border-radius: 10px;
    border: 3px solid transparent;
    width: calc(100% + 9px);
    height: calc(3.125rem + 10px);
    z-index: 0;
    background-color: transparent;
    transition: border-color 0.25s ease-in; }
    @media (min-width: 48em) {
      .uol-form__input--inline-field-wrapper::before {
        bottom: -4.5px; } }
    @media (min-width: 64em) {
      .uol-form__input--inline-field-wrapper::before {
        bottom: -5px; } }
    @media (forced-colors: active) {
      .uol-form__input--inline-field-wrapper::before {
        border-color: Canvas; } }
    @media (-ms-high-contrast: active) {
      .uol-form__input--inline-field-wrapper::before {
        border-color: Window; } }
  .uol-form__input--inline-field-wrapper:focus-within::before {
    border-color: #197ebe; }
  .uol-form__input--inline-field-wrapper:focus-within[data-field-invalid="true"]::before {
    border-color: #d4351c; }
  .uol-form__input--inline-field-wrapper:not(:last-of-type) {
    margin-right: 1rem; }
  .uol-form__input--inline-field-wrapper :first-of-type {
    margin-left: 1px; }
  .uol-form__input--inline-field-wrapper:not(:first-of-type) {
    margin-left: -1.5px; }

.uol-form__input--inline-field-wrapper .uol-form__input-label, .uol-form__input--inline-field-wrapper .uol-datepicker__range-label {
  font-weight: 500;
  color: #0e0c0c;
  padding-bottom: 0.5rem; }

.uol-form__input--inline-field {
  position: relative;
  z-index: 3;
  box-sizing: border-box; }
  .uol-form__input--inline-field[maxlength="2"] {
    width: 3.375rem; }
  .uol-form__input--inline-field[maxlength="3"] {
    width: 4rem; }
  .uol-form__input--inline-field[maxlength="4"] {
    width: 4.625rem; }
  .uol-form__input--inline-field[aria-invalid="true"] {
    border: 2px solid #d4351c;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.12); }

.uol-form__input--fieldset {
  border: 0;
  padding: 0;
  font-size: 2rem;
  display: block;
  margin: 2.5rem 0 -2rem; }
  @media (min-width: 64em) {
    .uol-form__input--fieldset {
      margin: 3rem 0 -2.5rem; } }

.uol-form__input--legend {
  border-top: 1px solid #979797;
  margin-bottom: 1.5rem;
  display: block;
  width: 100%; }

.uol-form__input--legend-title {
  font-family: freight-text-pro, Georgia, Cambria, "Times New Roman", Times, serif;
  margin: 1.5rem 0 0.5rem;
  color: #212529; }
  @media (min-width: 48em) {
    .uol-form__input--legend-title {
      font-size: 2rem; } }

.uol-form__input--legend-subtitle {
  color: #212529;
  font-weight: normal;
  vertical-align: top;
  font-size: 1.125rem;
  line-height: 1.556;
  font-family: "San Francisco", Roboto, "Helvetica Neue", Helvetica, "Noto Sans", "Segoe UI", Arial, sans-serif;
  display: inline-block; }
  @media (min-width: 64em) {
    .uol-form__input--legend-subtitle {
      font-size: 1.25rem; } }

.uol-form__input--time {
  -webkit-appearance: none;
  appearance: none;
  line-height: 2.75;
  background-color: #ffffff;
  color: #212529;
  text-align: left; }
  .uol-form__input--time[value=""] {
    -webkit-text-fill-color: #636363; }
  .uol-form__input--time:valid {
    -webkit-text-fill-color: #212529; }
  .uol-form__input--time::-webkit-calendar-picker-indicator {
    filter: brightness(0) saturate(100%) invert(29%) sepia(34%) saturate(975%) hue-rotate(133deg) brightness(94%) contrast(87%);
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer; }
    @media (forced-colors: active) {
      .uol-form__input--time::-webkit-calendar-picker-indicator {
        filter: none; } }
    @media (-ms-high-contrast: active) {
      .uol-form__input--time::-webkit-calendar-picker-indicator {
        filter: none; } }
  .uol-form__input--time::-webkit-date-and-time-value {
    -webkit-appearance: none;
    line-height: 3.1;
    text-align: left; }
    @media (min-width: 37.5em) {
      .uol-form__input--time::-webkit-date-and-time-value {
        line-height: 2.75; } }
    @media (min-width: 48em) {
      .uol-form__input--time::-webkit-date-and-time-value {
        line-height: 2.5; } }
  @media (min-width: 64em) {
    .uol-form__input--time::-webkit-datetime-edit {
      line-height: 2.3; } }
  .uol-form__input--time::-webkit-datetime-edit-hour-field, .uol-form__input--time::-webkit-datetime-edit-minute-field {
    padding: 0; }
    .uol-form__input--time::-webkit-datetime-edit-hour-field:focus, .uol-form__input--time::-webkit-datetime-edit-minute-field:focus {
      padding-bottom: 1px;
      border-radius: 0;
      background: #197ebe;
      -webkit-text-fill-color: #ffffff; }

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  line-height: 1; }

/*
  --- Below contains all of the styling for our non-text input elements, controls and grouping elements ---

  - Custom radio buttons
  - Custom checkboxes
  - Toggle password visibility
  - Select input
  - Custom number spinner (currently shelved)
  */
/**
   * Custom fieldset styling
   */
.uol-form__custom-fieldset {
  border: none;
  padding: 0;
  margin-bottom: 2rem;
  width: 100%; }

[role="radiogroup"] {
  margin-bottom: 2rem; }
  @media (min-width: 37.5em) {
    [role="radiogroup"] {
      margin-bottom: 1rem; } }
  @media (min-width: 48em) {
    [role="radiogroup"] {
      margin-bottom: 2rem; } }

.uol-form__custom__legend {
  display: block;
  margin: 0 0 0.75rem;
  font-weight: 600; }

.uol-form__input--radio-wrapper {
  position: relative;
  margin-bottom: 1rem;
  min-height: 2.625rem;
  max-width: 27.375rem; }

.uol-form__input--radio,
.uol-form__input--custom-radio {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  height: 2.625rem;
  width: 2.625rem;
  border-radius: 50%;
  cursor: pointer;
  transition: box-shadow 0.25s ease-in, border-color 0.25s ease-in;
  background-color: #ffffff; }

.uol-form__input--radio {
  opacity: 0.000001;
  z-index: 2;
  overflow: hidden; }
  .uol-form__input--radio:hover ~ .uol-form__input--custom-radio {
    border-radius: 50%;
    border-color: #156265;
    box-shadow: inset 0 0 0 1px #156265; }
  .uol-form__input--radio:hover ~ .uol-form__input--radio__label {
    color: #0e0c0c; }
  .uol-form__input--radio:checked ~ .uol-form__input--custom-radio {
    border-color: #156265;
    box-shadow: inset 0 0 0 1px #156265; }
    .uol-form__input--radio:checked ~ .uol-form__input--custom-radio svg {
      fill: #156265;
      transition: fill 0.25s ease-in; }
      @media (forced-colors: active) {
        .uol-form__input--radio:checked ~ .uol-form__input--custom-radio svg {
          fill: LinkText; } }
      @media (-ms-high-contrast: active) {
        .uol-form__input--radio:checked ~ .uol-form__input--custom-radio svg {
          fill: -ms-hotlight; } }

.uol-form__input--radio__label {
  display: inline-block;
  margin-left: calc(2.625rem + 0.5rem);
  cursor: pointer;
  padding: 0.25rem 0;
  color: #212529;
  line-height: 1.556;
  font-weight: 400;
  font-size: 1.125rem; }
  @media (min-width: 64em) {
    .uol-form__input--radio__label {
      font-size: 1.25rem; } }

.uol-form__input--custom-radio {
  display: inline-block;
  border: 2px solid #156265; }
  .uol-form__input--custom-radio > svg {
    margin: 50% 50%;
    transform: translate(-50%, -50%);
    fill: rgba(21, 98, 101, 0);
    overflow: visible; }

/**
    * Styling specific to checkboxes
    */
.uol-form__input--checkbox-wrapper {
  position: relative;
  min-height: 2.625rem;
  margin-bottom: 1rem;
  transition: box-shadow 0.25s ease-in;
  max-width: 27.375rem; }
  .uol-form__input--checkbox-wrapper:hover .uol-form__input--checkbox-custom {
    box-shadow: inset 0 0 0 1px #156265; }
  .uol-form__input--checkbox-wrapper:hover ~ .uol-form__input--checkbox-label {
    color: #0e0c0c; }

.uol-form__input--checkbox-link a {
  transition: background 0.15s ease-out, text-decoration 0.15s ease-out;
  display: inline-block;
  margin-bottom: 1rem; }
  .uol-form__input--checkbox-link a:focus {
    color: #212529;
    background-color: #ffeded;
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-decoration-color: #ff4e36;
    text-underline-offset: 4px;
    outline: 2px dotted inherit;
    outline-offset: 1px;
    outline-color: transparent; }
  .uol-form__input--checkbox-link a:focus:not(:focus-visible) {
    text-decoration: none;
    outline-color: transparent; }
  .uol-form__input--checkbox-link a:focus-visible {
    color: #212529;
    outline-color: transparent;
    outline-offset: 1px;
    background-color: #ffeded; }

.uol-form__input--checkbox,
.uol-form__input--checkbox-custom {
  height: 2.625rem;
  width: 2.625rem;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  box-sizing: border-box; }

.uol-form__input--checkbox-label {
  padding: 0.5rem 0 0.5rem 3.25rem;
  cursor: pointer;
  display: inline-block;
  line-height: 1.556;
  font-weight: 400; }
  @media (min-width: 64em) {
    .uol-form__input--checkbox-label {
      padding: 0.35rem 0 0.35rem 3.25rem;
      font-size: 1.25rem; } }

.uol-form__input--checkbox {
  opacity: 0.00001;
  z-index: 1; }
  .uol-form__input--checkbox:focus ~ .uol-form__input--checkbox-custom {
    box-shadow: inset 0 0 0 1px #156265; }
  .uol-form__input--checkbox:focus ~ .uol-form__input--checkbox-label {
    color: #0e0c0c; }
  .uol-form__input--checkbox[aria-invalid="true"]:focus ~ .uol-form__input--checkbox-custom, .uol-form__input--checkbox[aria-invalid="true"]:hover ~ .uol-form__input--checkbox-custom {
    box-shadow: inset 0 0 0 1px #d4351c; }

.uol-form__input--checkbox-custom {
  border: 2px solid #156265;
  background-color: #ffffff; }
  .uol-form__input--checkbox-custom path {
    fill: transparent;
    height: 2.25rem;
    width: 2.25rem;
    position: initial;
    margin: auto;
    transition: color 0.25s ease-in; }

@media (forced-colors: active) {
  .uol-form__input--checkbox:focus ~ .uol-form__input--checkbox-custom {
    outline: 3px solid LinkText;
    outline-offset: 3px; } }

@media (-ms-high-contrast: active) {
  .uol-form__input--checkbox:focus ~ .uol-form__input--checkbox-custom {
    outline: 4px solid -ms-hotlight; } }

.uol-form__input--checkbox[aria-invalid="true"] ~ .uol-form__input--checkbox-custom {
  border-color: #d4351c; }

.uol-form__input--checkbox:checked ~ .uol-form__input--checkbox-custom path {
  fill: #156265; }
  @media (-ms-high-contrast: active) {
    .uol-form__input--checkbox:checked ~ .uol-form__input--checkbox-custom path {
      fill: WindowText; } }
  @media (forced-colors: active) {
    .uol-form__input--checkbox:checked ~ .uol-form__input--checkbox-custom path {
      fill: LinkText; } }

.uol-form__input--select {
  -webkit-appearance: none;
  appearance: none;
  background-color: #ffffff;
  color: #212529; }
  .uol-form__input--select + svg {
    right: 0.75rem;
    left: unset;
    stroke: WindowText;
    pointer-events: none; }
    .uol-form__input--select + svg path {
      fill: WindowText; }
    .no-csspositionsticky .uol-form__input--select + svg {
      display: none !important; }
  @media (min-width: 64em) {
    .uol-form__input--select {
      line-height: 1.25; } }
  .no-js .uol-form__input--select[multiple], .uol-form__input--select[multiple][data-native-select] {
    min-height: 10rem;
    padding: 0; }
    .no-js .uol-form__input--select[multiple] option, .uol-form__input--select[multiple][data-native-select] option {
      padding: 0.5em 0.75em; }
  .uol-form__input--select[multiple] + svg {
    display: none; }
  .uol-form__input--select.uol-form__input--select--typeahead-hidden + svg {
    display: block; }

.uol-form__input--file {
  padding: 0;
  border: none;
  max-width: 25.5rem; }
  .uol-form__input--file:focus::file-selector-button, .uol-form__input--file:focus::-webkit-file-upload-button {
    border: 2px solid #045ccc; }
  .uol-form__input--file::file-selector-button, .uol-form__input--file::-webkit-file-upload-button {
    font-size: 1.125rem;
    background: #156265 radial-gradient(circle, transparent 1%, #156265 1%) center/15000%;
    line-height: 1;
    box-sizing: border-box;
    min-width: 10rem;
    border: 0.125rem solid #156265;
    padding: 0.8em 1.8em;
    border-radius: 6px;
    color: #ffffff;
    background-position: center;
    text-decoration: none;
    transition: background 0.5s ease;
    font-family: inherit;
    margin-right: 0.75rem; }
    @media (min-width: 48em) {
      .uol-form__input--file::file-selector-button, .uol-form__input--file::-webkit-file-upload-button {
        font-size: 1.125rem; } }
    @media (min-width: 64em) {
      .uol-form__input--file::file-selector-button, .uol-form__input--file::-webkit-file-upload-button {
        font-size: 1.25rem; } }
    @media (min-width: 64em) {
      .uol-form__input--file::file-selector-button, .uol-form__input--file::-webkit-file-upload-button {
        padding-bottom: 11px; } }
    @media (-ms-high-contrast: active) {
      .uol-form__input--file::file-selector-button, .uol-form__input--file::-webkit-file-upload-button {
        border: 1px solid WindowText; } }
    .uol-form__input--file::file-selector-button:hover, .uol-form__input--file::file-selector-button:active, .uol-form__input--file::-webkit-file-upload-button:hover, .uol-form__input--file::-webkit-file-upload-button:active {
      text-decoration: none;
      box-shadow: 0 3px 6px 0 rgba(33, 33, 33, 0.15), 0 2px 4px 0 rgba(33, 33, 33, 0.12); }
    .uol-form__input--file::file-selector-button:active, .uol-form__input--file::-webkit-file-upload-button:active {
      background-color: #1b7f83;
      background-size: 100%;
      transition: background 0s; }
    .uol-form__input--file::file-selector-button:disabled, .uol-form__input--file::-webkit-file-upload-button:disabled {
      color: #b7bec5;
      background: #f0f0f0;
      border: 0.125rem solid #f0f0f0; }
      .uol-form__input--file::file-selector-button:disabled:hover, .uol-form__input--file::-webkit-file-upload-button:disabled:hover {
        box-shadow: none;
        cursor: not-allowed; }
    .js .uol-form__input--file::file-selector-button.uol-icon, .js .uol-form__input--file::-webkit-file-upload-button.uol-icon {
      padding: 0.8em 2.2em; }
      .js .uol-form__input--file::file-selector-button.uol-icon svg, .js .uol-form__input--file::-webkit-file-upload-button.uol-icon svg {
        margin-top: 0; }
    .js .uol-form__input--file::file-selector-button.uol-icon--icon-only, .js .uol-form__input--file::-webkit-file-upload-button.uol-icon--icon-only {
      min-width: 0;
      border-radius: 50%;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.81rem;
      height: 2.81rem;
      min-height: 2.81rem; }
      @media (min-width: 64em) {
        .js .uol-form__input--file::file-selector-button.uol-icon--icon-only, .js .uol-form__input--file::-webkit-file-upload-button.uol-icon--icon-only {
          width: 3.12rem;
          height: 3.12rem; } }
    .js .uol-form__input--file::file-selector-button.uol-icon--icon-only--large svg, .js .uol-form__input--file::-webkit-file-upload-button.uol-icon--icon-only--large svg {
      transform: scale(1.4); }

.uol-form__input-wrapper--file::before {
  content: none; }

.uol-form__files-list {
  list-style: none;
  margin: 0.75rem 0;
  padding: 0;
  max-width: 27.375rem; }
  @media (min-width: 64em) {
    .uol-form__files-list {
      margin: 0.75rem 0 1.5rem; } }

.uol-form__files-list__item {
  position: relative;
  padding: 0.75rem 3rem 0.75rem 0;
  border-bottom: 1px solid #51504c; }
  .uol-form__files-list__item .uol-form__files-list__item__name {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .uol-form__files-list__item .uol-form__files-list__item__btn-delete {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2em;
    height: 2em;
    background: transparent;
    border: none;
    border: 2px solid transparent;
    border-radius: 50%; }
    .uol-form__files-list__item .uol-form__files-list__item__btn-delete:hover {
      border-color: #156265; }
    .uol-form__files-list__item .uol-form__files-list__item__btn-delete svg {
      position: absolute;
      width: 1.25em;
      height: 1.25em;
      top: initial;
      left: initial; }

@media (min-width: 64em) {
  .uol-form__input-container--small .uol-form__input--checkbox-wrapper {
    min-height: 0;
    margin-bottom: 0.75rem; }
    .uol-form__input-container--small .uol-form__input--checkbox-wrapper .uol-form__input--checkbox,
    .uol-form__input-container--small .uol-form__input--checkbox-wrapper .uol-form__input--checkbox-custom {
      top: 0.075em;
      height: 1.5rem;
      width: 1.5rem; }
    .uol-form__input-container--small .uol-form__input--checkbox-wrapper .uol-form__input--checkbox-label {
      padding: 0 0 0 30px;
      line-height: 1; }
  .uol-form__input-container--small .uol-form__input--radio-wrapper {
    margin-bottom: 0.5rem;
    min-height: 0; }
    .uol-form__input-container--small .uol-form__input--radio-wrapper .uol-form__input--radio,
    .uol-form__input-container--small .uol-form__input--radio-wrapper .uol-form__input--custom-radio {
      height: 1.5rem;
      width: 1.5rem; }
      .uol-form__input-container--small .uol-form__input--radio-wrapper .uol-form__input--radio svg,
      .uol-form__input-container--small .uol-form__input--radio-wrapper .uol-form__input--custom-radio svg {
        transform: translate(-25%, -25%); }
        .uol-form__input-container--small .uol-form__input--radio-wrapper .uol-form__input--radio svg circle,
        .uol-form__input-container--small .uol-form__input--radio-wrapper .uol-form__input--custom-radio svg circle {
          cx: 6px;
          cy: 6px;
          r: 6px; }
    .uol-form__input-container--small .uol-form__input--radio-wrapper .uol-form__input--custom-radio {
      top: 0.15em; }
    .uol-form__input-container--small .uol-form__input--radio-wrapper .uol-form__input--radio__label {
      margin-left: 2rem;
      padding: 0; } }

@media (min-width: 37.5em) {
  .uol-form__inputs-wrapper--inline {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row; }
    .uol-form__inputs-wrapper--inline .uol-form__input--radio-wrapper {
      flex-shrink: 0;
      margin-right: 2rem; } }

.uol-form-container--centered {
  margin: 0 auto; }

.uol-form__container {
  border: 1px solid #c9c8c5;
  border-radius: 6px;
  margin-bottom: 2rem; }
  .uol-form__container.uol-form-container--centered {
    padding: 0; }
  @media (min-width: 64em) {
    .uol-side-nav-container--populated + .uol-homepage-content .uol-form__container .uol-form__inner-wrapper {
      flex-basis: 100%; } }
  @media (min-width: 90em) {
    .uol-side-nav-container--populated + .uol-homepage-content .uol-form__container .uol-form__inner-wrapper {
      flex-basis: 55.555%; } }
  @media (min-width: 90em) {
    .uol-side-nav-container--populated + .uol-homepage-content .uol-form__container .uol-form {
      margin-right: 2rem; } }
  .uol-side-nav-container--populated + .uol-homepage-content .uol-form__container .uol-form__img-wrapper {
    display: none; }
    @media (min-width: 90em) {
      .uol-side-nav-container--populated + .uol-homepage-content .uol-form__container .uol-form__img-wrapper {
        display: inline-flex;
        flex-basis: 44.444%; } }

.uol-form__inner-wrapper {
  padding: 1.5rem 1rem 2rem;
  background-color: #eae8e6;
  /*
  Note:
  As element uses typography rich text, each paragraph element has spacing underneath
  Here, the element is at the bottom of the form so we force last paragraph element
  to have zero spacing
  */ }
  @media (min-width: 64em) {
    .uol-form__inner-wrapper {
      flex-basis: 58.333%;
      padding: 2.5rem 2rem; } }
  @media (min-width: 90em) {
    .uol-form__inner-wrapper {
      flex-basis: 50%; } }
  .uol-form__inner-wrapper p:last-child {
    margin-bottom: 0 !important; }

/*
Note:
Fix so blue line is in correct place for form group inputs
*/
.uol-form__input-group--inline .uol-form__input-wrapper:before {
  bottom: 1px; }

.uol-form__title {
  color: #212529;
  font-size: 2rem;
  line-height: 1.25;
  font-family: freight-text-pro, Georgia, Cambria, "Times New Roman", Times, serif;
  margin: 0;
  padding-bottom: 0.5rem; }
  .uol-form__title + .uol-form {
    padding-top: 0.5rem; }
  @media (min-width: 48em) {
    .uol-form__title {
      font-size: 2.25rem;
      line-height: 1.333; } }
  @media (min-width: 64em) {
    .uol-form__title {
      font-size: 2.625rem;
      line-height: 1.238; } }

.uol-form__lead {
  display: block;
  color: #212529;
  font-size: 1.125rem;
  line-height: 1.556;
  font-family: "San Francisco", Roboto, "Helvetica Neue", Helvetica, "Noto Sans", "Segoe UI", Arial, sans-serif;
  margin: 0 0 2rem;
  font-weight: normal; }
  @media (min-width: 48em) {
    .uol-form__lead {
      max-width: 32rem; } }
  @media (min-width: 64em) {
    .uol-form__lead {
      font-size: 1.25rem;
      max-width: 41rem; } }

.uol-form {
  flex-direction: row; }
  @media (min-width: 48em) {
    .uol-form .uol-form--button-inline {
      display: flex; } }
  .uol-form .uol-form__input-group {
    display: flex;
    flex-wrap: wrap; }
    @media (min-width: 48em) {
      .uol-form .uol-form__input-group {
        column-gap: 1rem; } }
    @media (min-width: 64em) {
      .uol-form .uol-form__input-group {
        column-gap: 1.5rem; } }
    @media (min-width: 90em) {
      .uol-form .uol-form__input-group {
        column-gap: 2rem; } }
    .uol-form .uol-form__input-group .uol-form__input-container {
      width: 100%; }
      @media (min-width: 48em) {
        .uol-form .uol-form__input-group .uol-form__input-container {
          width: calc(50% - 0.5rem); } }
      @media (min-width: 64em) {
        .uol-form .uol-form__input-group .uol-form__input-container {
          width: calc(50% - 0.75rem); } }
      @media (min-width: 64em) {
        .uol-form .uol-form__input-group .uol-form__input-container {
          width: calc(50% - 1rem); } }
      .uol-form .uol-form__input-group .uol-form__input-container .uol-form__input-wrapper {
        max-width: none; }
  .uol-form .uol-form__input-group--inline {
    display: flex;
    flex-direction: column; }
    @media (min-width: 48em) {
      .uol-form .uol-form__input-group--inline {
        flex-direction: row; } }

@media (min-width: 64em) {
  .uol-form__container--with-image {
    display: flex; } }

.uol-form__img-wrapper {
  background-color: #eae8e6;
  position: relative;
  display: none;
  z-index: -2;
  overflow: hidden; }
  @media (min-width: 64em) {
    .uol-form__img-wrapper {
      display: inline-flex;
      flex-basis: 41.666%; } }
  @media (min-width: 90em) {
    .uol-form__img-wrapper {
      flex-basis: 50%; } }

.uol-form__img {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1; }

.uol-form--button-inline .uol-form__inputs-wrapper {
  flex: 1; }

.uol-form--button-inline .uol-form__input-container {
  margin-bottom: 0; }

.uol-form--button-inline .uol-form__button-wrapper {
  align-self: flex-end; }
  .uol-form--button-inline .uol-form__button-wrapper .uol-button, .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-container .uolcc-button, .js .uolcc-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button,
  .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-prompt-container .uolcc-button, .js .uolcc-prompt-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button,
  .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-privacy-update-container .uolcc-button, .js .uolcc-privacy-update-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button, .uol-form--button-inline .uol-form__button-wrapper .uol-section-nav-ctas__link {
    position: relative;
    outline: 0 dotted #197ebe;
    outline-offset: 3px;
    overflow: visible; }
    .uol-form--button-inline .uol-form__button-wrapper .uol-button::before, .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-container .uolcc-button::before, .js .uolcc-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button::before,
    .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-prompt-container .uolcc-button::before, .js .uolcc-prompt-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button::before,
    .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-privacy-update-container .uolcc-button::before, .js .uolcc-privacy-update-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button::before, .uol-form--button-inline .uol-form__button-wrapper .uol-section-nav-ctas__link::before {
      content: "";
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border: 3px solid transparent;
      border-radius: inherit;
      transition: all 0.15s; }
      @media (-ms-high-contrast: active), (forced-colors: active) {
        .uol-form--button-inline .uol-form__button-wrapper .uol-button::before, .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-container .uolcc-button::before, .js .uolcc-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button::before,
        .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-prompt-container .uolcc-button::before, .js .uolcc-prompt-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button::before,
        .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-privacy-update-container .uolcc-button::before, .js .uolcc-privacy-update-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button::before, .uol-form--button-inline .uol-form__button-wrapper .uol-section-nav-ctas__link::before {
          display: none; } }
    @media (-ms-high-contrast: active), (forced-colors: active) {
      .uol-form--button-inline .uol-form__button-wrapper .uol-button:focus, .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-container .uolcc-button:focus, .js .uolcc-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button:focus,
      .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-prompt-container .uolcc-button:focus, .js .uolcc-prompt-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button:focus,
      .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-privacy-update-container .uolcc-button:focus, .js .uolcc-privacy-update-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button:focus, .uol-form--button-inline .uol-form__button-wrapper .uol-section-nav-ctas__link:focus {
        outline-width: 3px; } }
    .uol-form--button-inline .uol-form__button-wrapper .uol-button:focus::before, .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-container .uolcc-button:focus::before, .js .uolcc-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button:focus::before,
    .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-prompt-container .uolcc-button:focus::before, .js .uolcc-prompt-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button:focus::before,
    .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-privacy-update-container .uolcc-button:focus::before, .js .uolcc-privacy-update-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button:focus::before, .uol-form--button-inline .uol-form__button-wrapper .uol-section-nav-ctas__link:focus::before {
      border-color: #197ebe; }
    .uol-form--button-inline .uol-form__button-wrapper .uol-button:focus:not(:focus-visible)::before, .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-container .uolcc-button:focus:not(:focus-visible)::before, .js .uolcc-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button:focus:not(:focus-visible)::before,
    .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-prompt-container .uolcc-button:focus:not(:focus-visible)::before, .js .uolcc-prompt-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button:focus:not(:focus-visible)::before,
    .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-privacy-update-container .uolcc-button:focus:not(:focus-visible)::before, .js .uolcc-privacy-update-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button:focus:not(:focus-visible)::before, .uol-form--button-inline .uol-form__button-wrapper .uol-section-nav-ctas__link:focus:not(:focus-visible)::before {
      border-color: transparent; }
    .uol-form--button-inline .uol-form__button-wrapper .uol-button:focus-visible::before, .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-container .uolcc-button:focus-visible::before, .js .uolcc-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button:focus-visible::before,
    .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-prompt-container .uolcc-button:focus-visible::before, .js .uolcc-prompt-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button:focus-visible::before,
    .uol-form--button-inline .uol-form__button-wrapper .js .uolcc-privacy-update-container .uolcc-button:focus-visible::before, .js .uolcc-privacy-update-container .uol-form--button-inline .uol-form__button-wrapper .uolcc-button:focus-visible::before, .uol-form--button-inline .uol-form__button-wrapper .uol-section-nav-ctas__link:focus-visible::before {
      border-color: #197ebe; }
  @media (min-width: 48em) {
    .uol-form--button-inline .uol-form__button-wrapper {
      padding-left: 1rem; } }
  @media (min-width: 64em) {
    .uol-form--button-inline .uol-form__button-wrapper {
      padding-left: 1.5rem; } }
  @media (min-width: 90em) {
    .uol-form--button-inline .uol-form__button-wrapper {
      padding-left: 2rem; } }
  .uol-form--button-inline .uol-form__button-wrapper [class^="uol-button"] {
    width: 100%;
    height: 3.125rem;
    line-height: 0.75; }
    @media (min-width: 37.5em) {
      .uol-form--button-inline .uol-form__button-wrapper [class^="uol-button"] {
        width: inherit; } }

@media (min-width: 37.5em) {
  .uol-form--button-block .uol-form__button-wrapper {
    display: inline-block;
    width: initial; } }

.uol-form--button-block .uol-form__button-wrapper .uol-button, .uol-form--button-block .uol-form__button-wrapper .js .uolcc-container .uolcc-button, .js .uolcc-container .uol-form--button-block .uol-form__button-wrapper .uolcc-button,
.uol-form--button-block .uol-form__button-wrapper .js .uolcc-prompt-container .uolcc-button, .js .uolcc-prompt-container .uol-form--button-block .uol-form__button-wrapper .uolcc-button,
.uol-form--button-block .uol-form__button-wrapper .js .uolcc-privacy-update-container .uolcc-button, .js .uolcc-privacy-update-container .uol-form--button-block .uol-form__button-wrapper .uolcc-button, .uol-form--button-block .uol-form__button-wrapper .uol-section-nav-ctas__link {
  width: 100%; }

.uol-form__additional-content {
  padding: 0;
  margin: 0; }
  .uol-form__additional-content a {
    transition: background 0.15s ease-out, text-decoration 0.15s ease-out; }
    .uol-form__additional-content a:focus {
      color: #212529;
      background-color: #ffeded;
      text-decoration: underline;
      text-decoration-thickness: 3px;
      text-decoration-color: #ff4e36;
      text-underline-offset: 4px;
      outline: 2px dotted inherit;
      outline-offset: 1px;
      outline-color: transparent; }
    .uol-form__additional-content a:focus:not(:focus-visible) {
      text-decoration: none;
      outline-color: transparent; }
    .uol-form__additional-content a:focus-visible {
      color: #212529;
      outline-color: transparent;
      outline-offset: 1px;
      background-color: #ffeded; }

.uol-rich-text .uol-form__additional-content--before {
  margin: 1rem 0; }
  .uol-rich-text .uol-form__additional-content--before > * {
    margin-bottom: 1rem; }
  .uol-rich-text .uol-form__additional-content--before > *:last-child {
    margin-bottom: 2rem; }

.uol-rich-text .uol-form__additional-content--after {
  margin: 2rem 0 0; }
  .uol-rich-text .uol-form__additional-content--after > * {
    margin-bottom: 1rem; }
  .uol-rich-text .uol-form__additional-content--after > *:last-child {
    margin-bottom: 0; }

@media (min-width: 48em) {
  .uol-form__inner-wrapper .uol-form__custom-fieldset {
    width: calc(100% + 160px + 16px); } }

@media (min-width: 64em) {
  .uol-form__inner-wrapper .uol-form__custom-fieldset {
    width: calc(100% + 160px + 24px); } }

.uol-form__inner-wrapper .uol-form__custom__legend {
  margin: 0 0 0.75rem; }

.uol-form__inner-wrapper .uol-form__input-label, .uol-form__inner-wrapper .uol-datepicker__range-label {
  padding-bottom: 0.75rem; }

@keyframes info-text-fade {
  0% {
    display: none;
    opacity: 0; }
  1% {
    display: block;
    opacity: 0; }
  30% {
    display: block;
    opacity: 0; }
  100% {
    display: block;
    opacity: 1; } }

.uol-gallery-modal {
  position: absolute;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 99.9);
  left: 0;
  top: 0;
  background: #0a0202;
  z-index: 10;
  color: #ffffff; }

.js .uol-gallery-modal .uol-gallery-modal__button-close {
  position: absolute;
  top: 1rem;
  right: 0.75rem;
  z-index: 2; }

@media (orientation: portrait) and (min-width: 25.875em) {
  .js .uol-gallery-modal .uol-gallery-modal__button-close {
    right: 1.5rem; } }
  @media (orientation: landscape) {
    .js .uol-gallery-modal .uol-gallery-modal__button-close {
      top: 0.5rem;
      right: 38.5px;
      transform: translateX(50%); } }
  @media (orientation: landscape) and (min-width: 37.5em) {
    .js .uol-gallery-modal .uol-gallery-modal__button-close {
      top: 1.5rem; } }
  .js .uol-gallery-modal .uol-gallery-modal__button-close svg path {
    fill: #ffffff; }
    @media (-ms-high-contrast: active), (forced-colors: active) {
      .js .uol-gallery-modal .uol-gallery-modal__button-close svg path {
        fill: ButtonText; } }

.uol-gallery-modal__track {
  margin: 0;
  padding: 0;
  height: calc(var(--vh, 1vh) * 99.9);
  width: 100vw;
  list-style: none;
  scroll-snap-type: x mandatory;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  overflow-x: auto;
  -ms-overflow-style: none; }
  .uol-gallery-modal__track::-webkit-scrollbar {
    display: none; }

.uol-gallery-modal__track--smooth {
  scroll-behavior: smooth; }

.uol-gallery-modal__item {
  position: relative;
  box-sizing: border-box;
  display: flex;
  width: 100vw;
  height: 100%;
  scroll-snap-align: start;
  background: #212121; }
  @media (orientation: portrait) {
    .uol-gallery-modal__item {
      margin-top: 77px;
      height: calc(100% - 77px); } }

.uol-gallery-modal__info-container {
  box-sizing: border-box;
  background: rgba(10, 2, 2, 0.92);
  z-index: 1;
  transition: all 0.3s ease; }
  @media (orientation: landscape) {
    .uol-gallery-modal__info-container {
      position: absolute;
      width: 77px;
      height: 100%;
      border-right: 3px solid #c9c8c5;
      display: flex; }
      .uol-gallery-modal__info-container::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: calc(77px - 3px);
        background-color: #0a0202; } }
  @media (orientation: portrait) {
    .uol-gallery-modal__info-container {
      position: absolute;
      bottom: 0;
      width: 100%;
      max-height: 100%;
      overflow-y: hidden;
      border-top: 3px solid #c9c8c5;
      padding: 1rem 1.5rem; } }

@media (orientation: portrait) {
  .uol-gallery-modal__info-container--open {
    padding-bottom: 2rem; } }

@media (orientation: landscape) {
  .uol-gallery-modal__info-container--open {
    width: calc(100vw - (77px * 1.5)); } }
  @media (orientation: landscape) and (min-width: 37.5em) {
    .uol-gallery-modal__info-container--open {
      width: calc(80vw - (77px)); } }
  @media (orientation: landscape) and (min-width: 48em) {
    .uol-gallery-modal__info-container--open {
      width: calc(70vw - (77px)); } }
  @media (orientation: landscape) and (min-width: 64em) {
    .uol-gallery-modal__info-container--open {
      width: calc(60vw - (77px)); } }
  @media (orientation: landscape) and (min-width: 90em) {
    .uol-gallery-modal__info-container--open {
      width: calc(50vw - (77px)); } }

@media (orientation: landscape) {
  .uol-gallery-modal__button-info {
    position: absolute;
    left: 1rem;
    top: 0.5rem; } }
  @media (orientation: landscape) and (min-width: 37.5em) {
    .uol-gallery-modal__button-info {
      top: 1.5rem; } }
  @media (orientation: landscape) and (min-width: 64em) {
    .uol-gallery-modal__button-info {
      left: 0.75rem; } }

.uol-gallery-modal__info-container--open .uol-gallery-modal__button-info {
  border: 2px solid #ffffff; }

.uol-gallery-modal__info {
  scrollbar-color: #ff4e36 #212121;
  scrollbar-base-color: #212121;
  scrollbar-3dlight-color: #212121;
  scrollbar-track-color: #212121;
  scrollbar-face-color: #ff4e36;
  scrollbar-arrow-color: #ff4e36;
  display: none;
  box-sizing: border-box;
  overflow-y: auto; }
  @media (forced-colors: active) {
    .uol-gallery-modal__info {
      scrollbar-color: ButtonText Canvas; } }
  .uol-gallery-modal__info::-webkit-scrollbar {
    background-color: #212121;
    width: 1rem; }
  .uol-gallery-modal__info::-webkit-scrollbar-track {
    background-color: #212121; }
  .uol-gallery-modal__info::-webkit-scrollbar-thumb {
    background-color: #c70000;
    background-image: linear-gradient(90deg, #212121 0, #212121 0.25rem, #ff4e36 0.25rem, #ff4e36 0.75rem, #212121 0.75rem); }
    @media (forced-colors: active) {
      .uol-gallery-modal__info::-webkit-scrollbar-thumb {
        background: ButtonText; } }
  @media (orientation: portrait) {
    .uol-gallery-modal__info {
      margin-top: 1rem;
      max-height: calc(var(--vh, 1vh) * 100 - (77px * 2) - 2rem);
      padding-right: 1rem; } }
  @media (orientation: landscape) {
    .uol-gallery-modal__info {
      box-sizing: border-box;
      flex-basis: calc(100% - 77px);
      margin: 1.5rem 0.5rem 0 auto;
      padding-right: 0.5rem;
      padding-left: 1rem; } }
  .uol-gallery-modal__button-info[aria-expanded="true"] + .uol-gallery-modal__info {
    display: block; }
    @media (orientation: landscape) {
      .uol-gallery-modal__button-info[aria-expanded="true"] + .uol-gallery-modal__info {
        animation: info-text-fade 0.7s ease;
        animation-fill-mode: both; } }

.uol-gallery-modal__info__title {
  margin-top: 0;
  margin-bottom: 1rem; }
  @media (orientation: landscape) {
    .uol-gallery-modal__info__title {
      margin-top: 1rem; } }
  @media (orientation: landscape) and (min-width: 48em) {
    .uol-gallery-modal__info__title {
      margin-top: 0.5rem; } }

.uol-gallery-modal__figure {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center; }
  @media (orientation: landscape) {
    .uol-gallery-modal__figure {
      height: 100%;
      width: calc(100% - 77px); }
      .uol-gallery-modal__info-container + .uol-gallery-modal__figure {
        margin-left: 77px;
        width: calc(100% - (77px * 2)); } }
  @media (orientation: portrait) {
    .uol-gallery-modal__item--has-info .uol-gallery-modal__figure {
      height: calc(100% - 77px - 3px); } }

.uol-gallery-modal__image-container {
  position: absolute;
  top: 50%;
  height: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }
  .uol-gallery-modal__image-container img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; }

.uol-gallery-modal__image-container--video {
  top: auto;
  transform: none;
  position: relative;
  aspect-ratio: 16/9;
  overflow: hidden; }
  .uol-gallery-modal__image-container--video iframe {
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.uol-gallery-modal__image-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem 1rem;
  background: rgba(33, 33, 33, 0.88);
  color: #ffffff; }
  @media (orientation: landscape) {
    .uol-gallery-modal__figure--video .uol-gallery-modal__image-caption {
      display: none; } }

.uol-gallery-modal__nav-container {
  box-sizing: border-box;
  position: fixed;
  background: #0a0202;
  display: flex;
  z-index: 1; }
  @media (orientation: landscape) {
    .uol-gallery-modal__nav-container {
      top: 0;
      right: 0;
      width: 77px;
      flex-direction: column;
      height: 100%;
      padding: 3.31rem 0.75rem 0; } }
  @media (orientation: landscape) and (min-width: 37.5em) {
    .uol-gallery-modal__nav-container {
      padding: 4.31rem 0.75rem 0; } }
  @media (orientation: landscape) and (min-width: 64em) {
    .uol-gallery-modal__nav-container {
      padding: 4.62rem 0.75rem 0; } }
  @media (orientation: landscape) {
      .uol-gallery-modal__nav-container button {
        margin-bottom: 0.5rem;
        margin-left: auto;
        margin-right: auto; } }
    @media (orientation: landscape) and (min-width: 37.5em) {
      .uol-gallery-modal__nav-container button {
        margin-bottom: 1.5rem;
        padding: 5rem 0.75rem 1rem; } }
  @media (orientation: portrait) {
    .uol-gallery-modal__nav-container {
      top: 0;
      height: 77px;
      width: 100%;
      justify-content: center;
      align-items: center; } }

.uol-gallery-modal__progress {
  font-variant-numeric: lining-nums; }
  @media (orientation: landscape) {
    .uol-gallery-modal__progress {
      order: -1;
      text-align: center;
      margin: 0.5rem 0; } }
  @media (orientation: landscape) and (min-width: 37.5em) {
    .uol-gallery-modal__progress {
      margin: 1.5rem 0; } }
  @media (orientation: portrait) {
    .uol-gallery-modal__progress {
      margin: 0 1.5rem; } }
  @media (orientation: portrait) and (min-width: 25.875em) {
    .uol-gallery-modal__progress {
      margin: 0 3rem; } }
  @media (orientation: portrait) and (min-width: 37.5em) {
    .uol-gallery-modal__progress {
      margin: 0 4rem; } }

.uol-gallery-modal__progress__current,
.uol-gallery-modal__progress__total {
  position: relative;
  top: 0.05em; }

.uol-gallery-modal__progress__current {
  color: #ff4e36; }

@keyframes skeletonBg {
  0% {
    background-color: #eae8e6; }
  50% {
    background-color: rgba(234, 232, 230, 0.2); }
  100% {
    background-color: #eae8e6; } }

@keyframes galleryImageFadeIn {
  0% {
    display: none;
    opacity: 0; }
  1% {
    display: block; }
  100% {
    display: block;
    opacity: 1; } }

.uol-gallery-container {
  width: 100%;
  overflow-x: hidden; }

.uol-gallery {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem; }
  @media (min-width: 64em) {
    .uol-gallery {
      margin-left: -0.75rem;
      margin-right: -0.75rem; } }
  @media (min-width: 90em) {
    .uol-gallery {
      margin-left: -1rem;
      margin-right: -1rem; } }
  .uol-rich-text .uol-gallery {
    max-width: none; }

.uol-gallery__item {
  box-sizing: border-box; }
  .js .uol-gallery__item {
    flex-basis: calc(50% - 1rem);
    margin: 0 0.5rem 1rem; }
    @media (min-width: 64em) {
      .js .uol-gallery__item {
        flex-basis: calc(50% - 1.5rem);
        margin: 0 0.75rem 1rem; } }
    @media (min-width: 90em) {
      .js .uol-gallery__item {
        flex-basis: calc(50% - 2rem);
        margin: 0 1rem 1.5rem; } }
  .uol-gallery__item:first-of-type {
    flex-basis: 100%; }
  .uol-gallery--count-2 .uol-gallery__item:first-of-type,
  .uol-gallery--count-4 .uol-gallery__item:first-of-type {
    flex-basis: calc(50% - 1rem);
    margin: 0 0.5rem 1rem; }
    @media (min-width: 64em) {
      .uol-gallery--count-2 .uol-gallery__item:first-of-type,
      .uol-gallery--count-4 .uol-gallery__item:first-of-type {
        flex-basis: calc(50% - 1.5rem);
        margin: 0 0.75rem 1rem; } }
    @media (min-width: 90em) {
      .uol-gallery--count-2 .uol-gallery__item:first-of-type,
      .uol-gallery--count-4 .uol-gallery__item:first-of-type {
        flex-basis: calc(50% - 2rem);
        margin: 0 1rem 1.5rem; } }
  .js .uol-gallery__item:nth-of-type(5) ~ .uol-gallery__item {
    display: none; }

.uol-gallery__item--video .uol-gallery__figure {
  display: none; }
  .js .uol-gallery__item--video .uol-gallery__figure {
    display: block; }

.uol-rich-text .uol-gallery__figure {
  margin: 0; }

.uol-gallery__image-caption {
  color: #3b3b3b;
  padding-top: 0.5rem; }
  @media (min-width: 64em) {
    .uol-gallery__image-caption {
      padding-top: 0.75rem; } }

.uol-gallery__image-container {
  font-size: 0;
  position: relative;
  overflow: hidden; }
  .uol-gallery__image-container::before {
    content: "";
    display: block;
    width: 0;
    padding-bottom: 66.6%; }
  .uol-gallery__image-container img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover; }
  .uol-gallery__item:first-of-type .uol-gallery__image-container {
    position: relative;
    overflow: hidden; }
    .uol-gallery__item:first-of-type .uol-gallery__image-container::before {
      content: "";
      display: block;
      width: 0;
      padding-bottom: 50%; }
    .uol-gallery__item:first-of-type .uol-gallery__image-container img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      height: auto;
      min-width: 100%;
      min-height: 100%;
      max-height: 100%;
      object-fit: cover; }
  .uol-gallery--count-2 .uol-gallery__item:nth-of-type(2) .uol-gallery__image-container {
    position: relative;
    overflow: hidden; }
    .uol-gallery--count-2 .uol-gallery__item:nth-of-type(2) .uol-gallery__image-container::before {
      content: "";
      display: block;
      width: 0;
      padding-bottom: 50%; }
    .uol-gallery--count-2 .uol-gallery__item:nth-of-type(2) .uol-gallery__image-container img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      height: auto;
      min-width: 100%;
      min-height: 100%;
      max-height: 100%;
      object-fit: cover; }
  .uol-gallery--count-4 .uol-gallery__item:first-of-type .uol-gallery__image-container {
    position: relative;
    overflow: hidden; }
    .uol-gallery--count-4 .uol-gallery__item:first-of-type .uol-gallery__image-container::before {
      content: "";
      display: block;
      width: 0;
      padding-bottom: 66.6%; }
    .uol-gallery--count-4 .uol-gallery__item:first-of-type .uol-gallery__image-container img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      height: auto;
      min-width: 100%;
      min-height: 100%;
      max-height: 100%;
      object-fit: cover; }
  .uol-gallery__item--video .uol-gallery__image-container {
    outline: 1px solid rgba(106, 100, 93, 0.7);
    outline-offset: -1px;
    background-color: #eae8e6;
    animation-name: skeletonBg;
    animation-duration: 2.49s;
    animation-iteration-count: 2; }
    .uol-gallery__item--video .uol-gallery__image-container img {
      opacity: 0; }
  .uol-gallery__item--video[aria-busy=false] .uol-gallery__image-container {
    outline: none; }
    .uol-gallery__item--video[aria-busy=false] .uol-gallery__image-container img {
      opacity: 0;
      display: block;
      animation: galleryImageFadeIn 1s ease-out forwards; }
  .uol-gallery__item--video:nth-of-type(5n + 1) .uol-gallery__image-container {
    animation-delay: 0.2s; }
  .uol-gallery__item--video:nth-of-type(5n + 2) .uol-gallery__image-container {
    animation-delay: 0.4s; }
  .uol-gallery__item--video:nth-of-type(5n + 3) .uol-gallery__image-container {
    animation-delay: 0.6s; }
  .uol-gallery__item--video:nth-of-type(5n + 4) .uol-gallery__image-container {
    animation-delay: 0.8s; }
  .uol-gallery__item--video:nth-of-type(5n + 5) .uol-gallery__image-container {
    animation-delay: 1s; }

.js .uol-gallery__item__content {
  display: none; }

.uol-gallery__button {
  background: rgba(33, 33, 33, 0.75);
  border: none; }

.uol-gallery__button--open-item {
  position: relative;
  outline: 0 dotted #197ebe;
  outline-offset: 3px;
  overflow: visible;
  position: absolute;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  left: 0.5rem;
  bottom: 0.5rem;
  transition: all 0.15s; }
  .uol-gallery__button--open-item::before {
    content: "";
    position: absolute;
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 3px solid transparent;
    border-radius: false;
    transition: all 0.15s; }
    @media (-ms-high-contrast: active), (forced-colors: active) {
      .uol-gallery__button--open-item::before {
        display: none; } }
  @media (-ms-high-contrast: active), (forced-colors: active) {
    .uol-gallery__button--open-item:focus {
      outline-width: 3px; } }
  .uol-gallery__button--open-item:focus::before {
    border-color: #197ebe; }
  .uol-gallery__button--open-item:focus:not(:focus-visible)::before {
    border-color: transparent; }
  .uol-gallery__button--open-item:focus-visible::before {
    border-color: #197ebe;
    box-shadow: inset 0px 0px 0px 2px rgba(0, 0, 0, 0.65); }
  .uol-gallery__button--open-item:hover, .uol-gallery__button--open-item:focus {
    background: #212121;
    color: #ff4e36; }
  @media (min-width: 48em) {
    .uol-gallery__button--open-item {
      left: 1.5rem;
      bottom: 1.5rem; } }
  .js .uol-gallery__item .uol-gallery__button--open-item {
    position: absolute; }

.uol-gallery__button--with-count {
  position: relative;
  outline: 0 dotted #197ebe;
  outline-offset: 3px;
  overflow: visible;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(33, 33, 33, 0.65);
  color: #ffffff;
  z-index: 2;
  text-decoration-color: #ff4e36;
  transition: all 0.15s; }
  .uol-gallery__button--with-count::before {
    content: "";
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    border: 3px solid transparent;
    border-radius: false;
    transition: all 0.15s; }
    @media (-ms-high-contrast: active), (forced-colors: active) {
      .uol-gallery__button--with-count::before {
        display: none; } }
  @media (-ms-high-contrast: active), (forced-colors: active) {
    .uol-gallery__button--with-count:focus {
      outline-width: 3px; } }
  .uol-gallery__button--with-count:focus::before {
    border-color: #197ebe; }
  .uol-gallery__button--with-count:focus:not(:focus-visible)::before {
    border-color: transparent; }
  .uol-gallery__button--with-count:focus-visible::before {
    border-color: #197ebe;
    box-shadow: inset 0px 0px 0px 2px rgba(0, 0, 0, 0.65); }
  .uol-gallery__button--with-count:hover, .uol-gallery__button--with-count:focus {
    background: rgba(33, 33, 33, 0.75);
    text-decoration: underline;
    text-decoration-color: #ff4e36; }
    @media (-ms-high-contrast: active), (forced-colors: active) {
      .uol-gallery__button--with-count:hover, .uol-gallery__button--with-count:focus {
        text-decoration-color: ButtonText;
        border: 2px solid ButtonText; } }

.js .uol-gallery__item--video-play-icon .uol-button.uol-icon--icon-only, .js .uol-gallery__item--video-play-icon .uolcc-container .uol-icon--icon-only.uolcc-button, .js .uolcc-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button,
.js .uol-gallery__item--video-play-icon .uolcc-prompt-container .uol-icon--icon-only.uolcc-button, .js .uolcc-prompt-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button,
.js .uol-gallery__item--video-play-icon .uolcc-privacy-update-container .uol-icon--icon-only.uolcc-button, .js .uolcc-privacy-update-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button, .js .uol-gallery__item--video-play-icon .uol-icon--icon-only.uol-section-nav-ctas__link {
  background: #c70000;
  border: 2px solid #ffffff;
  border-radius: 50%;
  color: #ffffff;
  aspect-ratio: 1/1;
  width: 4rem;
  height: 4rem;
  left: 1.5rem;
  bottom: 1.5rem; }
  .js .uol-gallery__item--video-play-icon .uol-button.uol-icon--icon-only svg, .js .uol-gallery__item--video-play-icon .uolcc-container .uol-icon--icon-only.uolcc-button svg, .js .uolcc-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button svg, .js .uol-gallery__item--video-play-icon .uolcc-prompt-container .uol-icon--icon-only.uolcc-button svg, .js .uolcc-prompt-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button svg, .js .uol-gallery__item--video-play-icon .uolcc-privacy-update-container .uol-icon--icon-only.uolcc-button svg, .js .uolcc-privacy-update-container .uol-gallery__item--video-play-icon .uol-icon--icon-only.uolcc-button svg, .js .uol-gallery__item--video-play-icon .uol-icon--icon-only.uol-section-nav-ctas__link svg {
    width: 3rem;
    height: 3rem; }

.uol-global-marketing-banner {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 1rem;
  border-radius: 5px;
  background: #212121;
  color: #ffffff; }
  @media (min-width: 64em) {
    .uol-global-marketing-banner {
      padding: 1rem 1.5rem; } }
  .uol-global-marketing-banner:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.12); }
  .uol-global-marketing-banner:focus-within .uol-global-marketing-banner__link, .uol-global-marketing-banner:hover .uol-global-marketing-banner__link {
    text-decoration: underline;
    text-decoration-color: #ff4e36; }

.uol-global-marketing-banner__title {
  margin: 0 0 0.25rem 0; }

.uol-global-marketing-banner__description {
  margin: 0 0 0.25rem 0; }
  @media (min-width: 48em) {
    .uol-global-marketing-banner__description {
      font-weight: 500; } }
  @media (min-width: 64em) {
    .uol-global-marketing-banner__description {
      margin: 0 0 0.0625rem 0; } }

.uol-global-marketing-banner__link {
  display: inline-block;
  font-weight: 600;
  text-decoration: none;
  margin: 0; }
  .uol-global-marketing-banner__link svg {
    fill: #ff4e36; }
  .uol-global-marketing-banner__link::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

.uol-global-masthead-outer {
  background: #0a0202; }

.uol-global-masthead {
  position: relative;
  background: #0a0202;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: #ffffff;
  z-index: 2; }
  @media (-ms-high-contrast: active) {
    .uol-global-masthead {
      border-bottom: 1px solid windowText; } }
  .js .uol-global-masthead {
    min-height: 49px; }
    @media (min-width: 64em) {
      .js .uol-global-masthead {
        flex-wrap: nowrap; } }

.uol-global-masthead__inner {
  position: relative;
  background: inherit; }
  @media (max-width: 47.99em) {
    .uol-global-masthead__inner {
      z-index: 2;
      flex-grow: 2;
      max-width: 4rem; } }
  @media (min-width: 48em) {
    .uol-global-masthead__inner {
      width: 20rem; } }
  @media (max-width: 47.99em) {
    .uol-global-masthead--quicklinks-expanded .uol-global-masthead__inner {
      position: absolute !important;
      clip: rect(1px, 1px, 1px, 1px);
      height: 1px;
      width: 1px;
      overflow: hidden;
      left: -9999px; } }

.uol-global-masthead__home {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 0.25rem 1rem 0;
  overflow: hidden;
  position: relative; }
  @media (min-width: 48em) {
    .uol-global-masthead__home {
      width: 286px; } }
  @media (min-width: 64em) {
    .uol-global-masthead__home {
      margin-left: 1.5rem; } }
  @media (min-width: 90em) {
    .uol-global-masthead__home {
      margin-left: 2rem; } }
  .uol-global-masthead__home .uol-logo-uol-inline .uol-logo-uol-inline__img {
    fill: #ffffff; }
    @media screen and (-ms-high-contrast: active) {
      .uol-global-masthead__home .uol-logo-uol-inline .uol-logo-uol-inline__img {
        fill: windowText; } }
  .uol-global-masthead__home:focus {
    outline: 2px solid #ff4e36;
    outline-offset: 1px; }
  .uol-global-masthead__home:focus:not(:focus-visible) {
    outline-width: 0; }
  .uol-global-masthead__home:focus-visible {
    outline: 2px solid #ff4e36;
    outline-offset: 1px; }

.uol-global-masthead__search-toggle {
  position: relative;
  outline: 0 dotted #197ebe;
  outline-offset: 3px;
  overflow: visible;
  border: none;
  background: transparent;
  margin: 0 1rem 0 0;
  padding: 0.5rem 0.5rem 0.25rem; }
  .uol-global-masthead__search-toggle svg path,
  .uol-global-masthead__search-toggle svg polygon {
    fill: #ffffff;
    transition: fill 0.3s ease; }
    @media (-ms-high-contrast: active) {
      .uol-global-masthead__search-toggle svg path,
      .uol-global-masthead__search-toggle svg polygon {
        fill: ButtonText; } }
  .uol-global-masthead__search-toggle:focus {
    outline: 2px dotted transparent; }
  .uol-global-masthead__search-toggle:hover svg path,
  .uol-global-masthead__search-toggle:hover svg polygon, .uol-global-masthead__search-toggle:focus svg path,
  .uol-global-masthead__search-toggle:focus svg polygon {
    fill: #ff4e36; }
    @media (-ms-high-contrast: active) {
      .uol-global-masthead__search-toggle:hover svg path,
      .uol-global-masthead__search-toggle:hover svg polygon, .uol-global-masthead__search-toggle:focus svg path,
      .uol-global-masthead__search-toggle:focus svg polygon {
        fill: -ms-hotlight;
        fill: LinkText; } }
  .uol-global-masthead__search-toggle::before {
    content: "";
    position: absolute;
    top: 7px;
    right: 7px;
    bottom: 7px;
    left: 7px;
    border: 3px solid transparent;
    border-radius: inherit;
    transition: all 0.15s; }
    @media (-ms-high-contrast: active), (forced-colors: active) {
      .uol-global-masthead__search-toggle::before {
        display: none; } }
  @media (-ms-high-contrast: active), (forced-colors: active) {
    .uol-global-masthead__search-toggle:focus {
      outline-width: 3px; } }
  .uol-global-masthead__search-toggle:focus::before {
    border-color: #197ebe; }
  .uol-global-masthead__search-toggle:focus:not(:focus-visible)::before {
    border-color: transparent; }
  .uol-global-masthead__search-toggle:focus-visible::before {
    border-color: #197ebe; }
  .uol-global-masthead__search-toggle::before {
    bottom: 8px; }
  .uol-global-masthead__search-toggle[aria-expanded="true"] {
    background: #212121; }
  @media (max-width: 47.99em) {
    .uol-global-masthead--quicklinks-expanded .uol-global-masthead__search-toggle {
      position: absolute !important;
      clip: rect(1px, 1px, 1px, 1px);
      height: 1px;
      width: 1px;
      overflow: hidden;
      left: -9999px; } }

.uol-global-masthead__search-form {
  position: relative;
  display: flex; }
  .uol-global-masthead__search-form input[type=search] {
    -webkit-appearance: none; }
  .uol-global-masthead__search-form::before {
    content: "";
    position: absolute;
    left: 1rem;
    bottom: 0.75rem;
    width: 0;
    border-bottom: 6px solid #ff4e36;
    transition: width 0.5s ease 0.1s; }
  .uol-global-masthead__search-form:focus-within::before {
    width: calc(100% - 2rem); }
  @media (min-width: 64em) {
    .uol-global-masthead__search-form::before {
      left: 0;
      bottom: 4px; }
    .uol-global-masthead__search-form:focus-within::before {
      width: 100%; } }
  @media (max-width: 63.99em) {
    .uol-global-masthead__search-form {
      visibility: hidden;
      width: 100%;
      padding: 1rem;
      height: 45px;
      background: #212121;
      transition: margin 0.3s ease, background 0.1s ease 0.3s; } }
  @media (max-width: 63.99em) and (prefers-reduced-motion) {
    .uol-global-masthead__search-form {
      transition: none; } }
  @media (min-width: 64em) {
    .uol-global-masthead__search-form {
      visibility: visible;
      width: 376px;
      margin-right: 1rem; } }
  @media (max-width: 63.99em) {
    .js .uol-global-masthead__search-form {
      margin-top: -85px;
      z-index: -1; } }
  @media (min-width: 64em) {
    .js .uol-global-masthead__search-form {
      visibility: visible; } }
  @media (max-width: 63.99em) {
    .no-js .uol-global-masthead__search-form {
      width: 100%;
      visibility: visible; } }
  .uol-global-masthead__search-form[hidden] {
    top: 0;
    background-color: #0a0202;
    visibility: hidden;
    transition: top 0.5s ease, background 0s ease 0s; }
    @media (prefers-reduced-motion) {
      .uol-global-masthead__search-form[hidden] {
        transition: none; } }
  @media (min-width: 64em) {
    .uol-global-masthead__search-form:focus-within::after {
      background: transparent; } }

.uol-global-masthead__search-form--expanded {
  background-color: #212121;
  visibility: visible; }
  .js .uol-global-masthead__search-form--expanded {
    margin-top: 0; }

.uol-global-masthead__search-input {
  flex: 1;
  background: transparent;
  border: none;
  border-radius: 0;
  color: #ffffff;
  padding: 6px;
  border-bottom: 1px solid #ffffff;
  appearance: none; }
  .no-csspositionsticky .uol-global-masthead__search-input:focus {
    border-bottom-color: #ff4e36; }
  .uol-global-masthead__search-inputinput[type=search] {
    appearance: none; }
  .uol-global-masthead__search-input::placeholder {
    color: #999999;
    font-size: 1.125rem; }
  @media (min-width: 64em) {
    .uol-global-masthead__search-input {
      margin: 6px 0; } }
  .uol-global-masthead__search-input:focus {
    outline: 2px dotted transparent; }
    @media (max-width: 47.99em) {
      .uol-global-masthead__search-input:focus {
        outline: 2px dotted transparent; } }

.uol-global-masthead__search-submit {
  position: relative;
  outline: 0 dotted #197ebe;
  outline-offset: 3px;
  overflow: visible;
  position: absolute;
  right: 1rem;
  top: 1rem;
  background: transparent;
  border: none;
  color: #ffffff;
  margin: 0 0.25rem 0 0.5rem;
  padding: 0.25rem 0.5rem 0; }
  .uol-global-masthead__search-submit svg path,
  .uol-global-masthead__search-submit svg polygon {
    fill: #ffffff;
    transition: fill 0.3s ease; }
    @media (-ms-high-contrast: active) {
      .uol-global-masthead__search-submit svg path,
      .uol-global-masthead__search-submit svg polygon {
        fill: ButtonText; } }
  .uol-global-masthead__search-submit:focus {
    outline: 2px dotted transparent; }
  .uol-global-masthead__search-submit:hover svg path,
  .uol-global-masthead__search-submit:hover svg polygon, .uol-global-masthead__search-submit:focus svg path,
  .uol-global-masthead__search-submit:focus svg polygon {
    fill: #ff4e36; }
    @media (-ms-high-contrast: active) {
      .uol-global-masthead__search-submit:hover svg path,
      .uol-global-masthead__search-submit:hover svg polygon, .uol-global-masthead__search-submit:focus svg path,
      .uol-global-masthead__search-submit:focus svg polygon {
        fill: -ms-hotlight;
        fill: LinkText; } }
  .uol-global-masthead__search-submit::before {
    content: "";
    position: absolute;
    top: 6px;
    right: 6px;
    bottom: 6px;
    left: 6px;
    border: 3px solid transparent;
    border-radius: inherit;
    transition: all 0.15s; }
    @media (-ms-high-contrast: active), (forced-colors: active) {
      .uol-global-masthead__search-submit::before {
        display: none; } }
  @media (-ms-high-contrast: active), (forced-colors: active) {
    .uol-global-masthead__search-submit:focus {
      outline-width: 3px; } }
  .uol-global-masthead__search-submit:focus::before {
    border-color: #197ebe; }
  .uol-global-masthead__search-submit:focus:not(:focus-visible)::before {
    border-color: transparent; }
  .uol-global-masthead__search-submit:focus-visible::before {
    border-color: #197ebe; }
  .uol-global-masthead__search-submit::before {
    top: 0.3rem;
    bottom: 0.5625rem;
    border-width: 1px;
    border-radius: 2px; }
  @media (min-width: 64em) {
    .uol-global-masthead__search-submit {
      top: 8px;
      right: 0; } }

.uol-quicklinks {
  position: relative;
  flex-grow: 2; }
  .no-js .uol-quicklinks {
    display: none; }
  @media (max-width: 47.99em) {
    .uol-quicklinks {
      width: calc(100% - 136px);
      background: #0a0202; }
      .uol-global-masthead--quicklinks-expanded .uol-quicklinks {
        width: auto; } }
  @media (min-width: 48em) {
    .uol-quicklinks {
      margin-right: 1.5rem; } }
  @media (min-width: 64em) {
    .uol-quicklinks {
      max-width: calc(100% - 400px - 20rem); } }

.uol-quicklinks__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  text-align: left; }

.uol-quicklinks__button {
  position: relative;
  margin: 0;
  white-space: nowrap;
  background: transparent;
  color: #fffbf5;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  padding: 0.9em 0.5em; }
  .uol-quicklinks__button:focus {
    outline: 2px dotted transparent;
    outline-offset: -6px; }
  @media (max-width: 47.99em) {
    .uol-quicklinks__button[aria-expanded="true"] {
      font-size: 1.75rem;
      line-height: 2.25rem;
      display: block;
      text-align: left;
      width: 100vw;
      border-bottom: 2px solid #ff4e36;
      margin-top: -0.5rem;
      padding: 1.312rem 3rem 1.312rem 1rem; } }
  @media (max-width: 47.99em) and (min-width: 48em) {
    .uol-quicklinks__button[aria-expanded="true"] {
      font-size: 2.25rem; } }
  @media (max-width: 47.99em) and (min-width: 64em) {
    .uol-quicklinks__button[aria-expanded="true"] {
      font-size: 3rem; } }
  @media (max-width: 47.99em) and (min-width: 48em) {
    .uol-quicklinks__button[aria-expanded="true"] {
      line-height: 3rem; } }
  @media (max-width: 47.99em) and (min-width: 64em) {
    .uol-quicklinks__button[aria-expanded="true"] {
      line-height: 3.5rem; } }
  .uol-quicklinks__button::after {
    content: "";
    position: absolute;
    bottom: 0.2em;
    left: 0.2em;
    right: calc(100% - 0.2em);
    height: 0.375rem;
    background-color: rgba(255, 78, 54, 0);
    transition: right 0.4s ease 0.1s; }
    @media (min-width: 48em) {
      .uol-quicklinks__button::after {
        height: 0.5rem; } }
    @media (-ms-high-contrast: active), (forced-colors: active) {
      .uol-quicklinks__button::after {
        background-color: WindowText;
        background-color: CanvasText; } }
  .uol-quicklinks__button:hover::after, .uol-quicklinks__button:focus::after {
    right: 0.2em;
    background-color: #ff4e36; }
  .uol-quicklinks__button[aria-expanded="true"]::before {
    content: "";
    position: absolute;
    right: 0.3rem;
    bottom: 0.6rem;
    width: 0;
    height: 0;
    border: 0.5rem solid transparent;
    border-top: none;
    border-bottom-color: #ffffff;
    filter: drop-shadow(0 -0.05rem 0.05rem rgba(33, 33, 33, 0.1));
    z-index: 2; }
    @media (max-width: 48em) {
      .uol-quicklinks__button[aria-expanded="true"]::before {
        display: none; } }
  @media (max-width: 48em) {
    .uol-quicklinks__button[aria-expanded="true"]::after {
      display: none; }
    .uol-quicklinks__button[aria-expanded="true"]:focus {
      text-decoration: underline; }
    .uol-quicklinks__button[aria-expanded="true"]:focus:not(:focus-visible) {
      text-decoration: none; }
    .uol-quicklinks__button[aria-expanded="true"]:focus-visible {
      outline-color: transparent;
      text-decoration: underline;
      text-underline-offset: 4px;
      text-decoration-thickness: 0.5rem;
      text-decoration-color: #ff4e36; } }

.uol-quicklinks__button__icon {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-left: 0.2em;
  margin-right: -0.2em; }
  .uol-quicklinks__button__icon::before, .uol-quicklinks__button__icon::after {
    content: "";
    display: block;
    position: absolute;
    width: 0.5em;
    height: 0.13em;
    background: #fffbf5;
    bottom: 20%; }
    @media (-ms-high-contrast: active) {
      .uol-quicklinks__button__icon::before, .uol-quicklinks__button__icon::after {
        background: ButtonText; } }
  .uol-quicklinks__button__icon::before {
    left: 50%;
    transform: translateX(-76%) rotate(45deg); }
  .uol-quicklinks__button__icon::after {
    right: 50%;
    transform: translateX(76%) rotate(-45deg); }
  @media (max-width: 47.99em) {
    .uol-quicklinks__group--expanded .uol-quicklinks__button__icon {
      position: absolute;
      padding: 1rem;
      right: 0.75rem;
      top: 33%;
      transform: translateY(-50%); } }
  .uol-quicklinks__group--expanded .uol-quicklinks__button__icon::before {
    transform: translateX(-76%) rotate(315deg); }
  .uol-quicklinks__group--expanded .uol-quicklinks__button__icon::after {
    transform: translateX(76%) rotate(45deg); }

.uol-quicklinks__group {
  position: relative; }
  @media (min-width: 48em) {
    .uol-quicklinks__group {
      padding-right: 1.5rem; } }
  .uol-quicklinks__group--more .uol-quicklinks__group {
    font-weight: 600;
    padding-left: 1rem; }
    @media (min-width: 48em) {
      .uol-quicklinks__group--more .uol-quicklinks__group {
        width: 50%; } }

@media (max-width: 47.99em) {
  .uol-quicklinks__group--sibling-expanded {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    width: 1px;
    overflow: hidden;
    left: -9999px; } }

.uol-quicklinks__group__list,
.uol-quicklinks__more-list {
  font-size: 1.75rem;
  display: none;
  padding: 1rem;
  list-style: none;
  background: #212121;
  color: #ffffff; }
  @media (min-width: 48em) {
    .uol-quicklinks__group__list,
    .uol-quicklinks__more-list {
      position: absolute;
      top: calc(100% - 0.75rem);
      right: 0;
      box-sizing: border-box;
      width: 375px;
      max-width: 100vw;
      background: #ffffff;
      font-weight: 600;
      color: #212529;
      border: 1px solid rgba(201, 200, 197, 0.2);
      border-radius: 0.75rem;
      padding-top: 1rem;
      z-index: 1;
      transform: translateX(3rem);
      box-shadow: 0 15px 24px 0 rgba(10, 2, 2, 0.15), 0 5px 10px 0 rgba(33, 33, 33, 0.5); } }
  @media (min-width: 64em) {
    .uol-quicklinks__group__list,
    .uol-quicklinks__more-list {
      right: auto;
      left: 0;
      transform: translateX(-3rem); }
      .uol-quicklinks__group__list::before,
      .uol-quicklinks__more-list::before {
        right: auto;
        left: 3.25rem; } }
  .uol-quicklinks__button[aria-expanded="true"] + .uol-quicklinks__group__list, .uol-quicklinks__button[aria-expanded="true"] +
  .uol-quicklinks__more-list {
    display: block; }

.uol-quicklinks__group--more .uol-quicklinks__group__list {
  margin-left: -1rem; }

.uol-quicklinks__more-list .uol-quicklinks__group__list {
  display: block; }
  @media (min-width: 48em) {
    .uol-quicklinks__more-list .uol-quicklinks__group__list {
      position: relative;
      width: auto;
      top: auto;
      left: auto;
      right: 0;
      padding-top: 0;
      padding-bottom: 0;
      transform: none;
      box-shadow: none;
      border: 0; } }

@media (min-width: 64em) {
  .uol-quicklinks__more-list {
    width: 766px;
    transform: translateX(-40%);
    padding-top: 2rem; }
    .uol-quicklinks__button[aria-expanded="true"] + .uol-quicklinks__more-list {
      display: flex; } }

.uol-quicklinks__group__item {
  padding: 0; }
  .uol-quicklinks__group--more .uol-quicklinks__group__item {
    padding-left: 0;
    margin-left: -1rem;
    margin-right: -1rem; }

.uol-quicklinks__group__link {
  position: relative;
  display: block;
  padding: 1rem;
  font-size: 1.125rem;
  text-decoration: none;
  border-radius: 6px;
  transition: background 0.3s ease; }
  @media (min-width: 48em) {
    .uol-quicklinks__group__link {
      color: #3b3b3b; } }
  .uol-quicklinks__group__link svg {
    position: relative;
    margin-top: -0.35em;
    top: 0.35em;
    left: 0;
    margin-left: 0.5em;
    fill: rgba(255, 78, 54, 0);
    opacity: 0;
    transition: all 0.3s ease 0.2s; }
    @media (-ms-high-contrast: active) {
      .uol-quicklinks__group__link svg {
        fill: windowText; } }
  .uol-quicklinks__group__link:hover, .uol-quicklinks__group__link:focus {
    background: #0a0202;
    text-decoration: underline; }
    @media (min-width: 48em) {
      .uol-quicklinks__group__link:hover, .uol-quicklinks__group__link:focus {
        background: #efeae6;
        color: #0e0c0c; } }
    .uol-quicklinks__group__link:hover svg, .uol-quicklinks__group__link:focus svg {
      left: 0.4em;
      opacity: 1;
      fill: #ff4e36; }
      @media (-ms-high-contrast: active) {
        .uol-quicklinks__group__link:hover svg, .uol-quicklinks__group__link:focus svg {
          fill: windowText; } }

.uol-header-local-masthead {
  font-size: 2.125rem;
  line-height: 1.556;
  padding: 1rem;
  background-color: #ffffff; }
  @media (min-width: 48em) {
    .uol-header-local-masthead {
      font-size: 1.25rem; } }
  @media (min-width: 48em) {
    .uol-header-local-masthead {
      line-height: 1.6; } }
  @media (min-width: 48em) {
    .uol-header-local-masthead {
      padding: 0.75rem 1rem; } }
  @media (min-width: 64em) {
    .uol-header-local-masthead {
      padding: 0.75rem 1.5rem; } }
  @media (min-width: 90em) {
    .uol-header-local-masthead {
      padding: 0.75rem 2rem; } }
  .uol-global-masthead-outer--with-local-navigation .uol-header-local-masthead {
    border-bottom: 1px solid #c9c8c5; }

.uol-global-masthead-outer--with-local-navigation + .uol-content-container.uol-header-local-navigation-wrapper {
  margin-top: 0; }
  .uol-global-masthead-outer--with-local-navigation + .uol-content-container.uol-header-local-navigation-wrapper.uol-header-local-navigation-wrapper--scrollable {
    border-top: none; }
  @media (min-width: 64em) {
    .uol-global-masthead-outer--with-local-navigation + .uol-content-container.uol-header-local-navigation-wrapper {
      margin-top: none; } }

.uol-header-local-masthead__inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  max-width: 99.75rem; }

.uol-header-local-masthead__link {
  transition: background 0.15s ease-out, text-decoration 0.15s ease-out;
  font-weight: 600;
  text-decoration: underline;
  text-underline-offset: 1px;
  color: #212529; }
  .uol-header-local-masthead__link:focus {
    color: #212529;
    background-color: #ffeded;
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-decoration-color: #ff4e36;
    text-underline-offset: 1px;
    outline: 2px dotted inherit;
    outline-offset: 1px;
    outline-color: transparent; }
  .uol-header-local-masthead__link:focus:not(:focus-visible) {
    text-decoration: none;
    outline-color: transparent; }
  .uol-header-local-masthead__link:focus-visible {
    color: #212529;
    outline-color: transparent;
    outline-offset: 1px;
    background-color: #ffeded; }
  @media (min-width: 48em) {
    .uol-header-local-masthead__link {
      font-weight: 600; } }
  .uol-header-local-masthead__link:hover {
    text-decoration: underline;
    text-decoration-color: #c70000;
    text-decoration-thickness: 2px;
    transition: text-decoration-color 0s; }
    .no-csspositionsticky .uol-header-local-masthead__link:hover {
      text-decoration: none; }

.uol-header-local-masthead__link--parent-site {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 8px; }
  @media (min-width: 48em) {
    .uol-header-local-masthead__link--parent-site {
      font-size: 1rem; } }
  @media (min-width: 48em) {
    .uol-header-local-masthead__link--parent-site {
      line-height: 1.25rem; } }
  @media (min-width: 48em) {
    .uol-header-local-masthead__link--parent-site {
      margin-bottom: 6px; } }

.uol-header-local-masthead__link--title {
  line-height: 1.75rem; }
  @media (min-width: 48em) {
    .uol-header-local-masthead__link--title {
      line-height: 2rem; } }

.no-wrap {
  white-space: nowrap; }

.uol-header-local-navigation-wrapper {
  max-width: 101.75rem; }
  .no-js .uol-header-local-navigation-wrapper {
    margin: 1rem 0;
    padding-bottom: 1rem; }
  .js .uol-header-local-navigation-wrapper {
    position: relative;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-top: 0; }
    @media (min-width: 64em) {
      .js .uol-header-local-navigation-wrapper {
        margin-top: 2rem;
        padding-left: 0.5rem; } }
    @media (min-width: 90em) {
      .js .uol-header-local-navigation-wrapper {
        padding-left: 1rem; } }
    @media (min-width: 103.75em) {
      .js .uol-header-local-navigation-wrapper {
        padding-left: 0; } }

.uol-header-local-navigation-wrapper--scrollable {
  border-top: 1px solid #c9c8c5; }

@media (max-width: 47.99em) {
  .uol-header-local-navigation-wrapper--sub-expanded {
    padding-bottom: 0; } }

.js .uol-header-local-navigation {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scroll-snap-type: x proximity;
  -ms-overflow-style: none;
  scrollbar-width: none; }
  .js .uol-header-local-navigation::-webkit-scrollbar {
    display: none; }

.js .uol-header-local-navigation--scrollable {
  margin-left: 2rem;
  margin-right: 2rem; }

@media (max-width: 47.99em) {
  .uol-header-local-navigation-wrapper--sub-expanded .uol-header-local-navigation--scrollable {
    margin-left: 0;
    margin-right: 0; } }

.uol-header-local-navigation__button-scroll {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 3rem;
  height: 100%;
  background: rgba(255, 255, 255, 0.96);
  border: none;
  transition: opacity 0.3s ease; }
  .uol-header-local-navigation__button-scroll::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border: 7px solid transparent; }
    @media (-ms-high-contrast: active), (forced-colors: active) {
      .uol-header-local-navigation__button-scroll::after {
        -ms-high-contrast-adjust: none;
        forced-color-adjust: CanvasText; } }
  .uol-header-local-navigation__button-scroll[disabled] {
    opacity: 0;
    cursor: default;
    z-index: -1; }
  @media (max-width: 47.99em) {
    .uol-header-local-navigation-wrapper--sub-expanded .uol-header-local-navigation__button-scroll {
      display: none; } }

.uol-header-local-navigation__button-scroll--left {
  left: 0;
  border-right: 1px solid #c9c8c5; }
  .uol-header-local-navigation__button-scroll--left::after {
    left: 0.75rem;
    border-right-color: #ff4e36; }

.uol-header-local-navigation__button-scroll--right {
  right: 0;
  border-left: 1px solid #c9c8c5; }
  .uol-header-local-navigation__button-scroll--right::after {
    right: 0.75rem;
    border-left-color: #ff4e36; }

.uol-header-local-navigation__item {
  font-weight: 600; }
  @media (max-width: 47.99em) {
    .uol-header-local-navigation__item {
      font-weight: 500;
      -webkit-box-sizing: border-box;
      box-sizing: border-box; } }
  .js .uol-header-local-navigation__sub-nav .uol-header-local-navigation__item {
    padding: 0.5rem 1rem; }

@media (max-width: 47.99em) {
  .uol-header-local-navigation__item--sibling-open {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    width: 1px;
    overflow: hidden;
    left: -9999px; } }

.js .uol-header-local-navigation__item--cta {
  background: #212121;
  margin-left: -1px;
  margin-right: -1px;
  margin-bottom: -1px;
  border: 1px solid #212121; }
  @media (min-width: 48em) {
    .js .uol-header-local-navigation__item--cta:last-of-type {
      border-radius: 0 0 0.75rem 0.75rem; } }

.js .uol-header-local-navigation__sub-nav .uol-header-local-navigation__item--cta {
  margin-top: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 0.5rem; }
  .js .uol-header-local-navigation__sub-nav .uol-header-local-navigation__item--cta ~ .uol-header-local-navigation__item--cta {
    margin-top: 0;
    padding-top: 0; }
  .js .uol-header-local-navigation__sub-nav .uol-header-local-navigation__item--cta:last-of-type {
    padding-bottom: 2rem; }

.no-js .uol-header-local-navigation__link:hover, .no-js .uol-header-local-navigation__link:focus {
  text-decoration: underline; }

.js .uol-header-local-navigation__link {
  position: relative;
  display: block;
  color: #3b3b3b;
  text-decoration: none;
  white-space: nowrap;
  padding: 0.5rem 1rem 0.75rem;
  transition: color 0.4s ease 0.1s; }
  .js .uol-header-local-navigation__link::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: calc(1rem - 2px);
    right: calc(100% - 1rem + 2px);
    height: 0.5rem;
    background-color: #ffffff;
    transition: right 0.4s ease 0.1s; }
  .js .uol-header-local-navigation__link:focus {
    outline: 2px dotted transparent; }
  .js .uol-header-local-navigation__link:hover::after, .js .uol-header-local-navigation__link:focus::after {
    right: calc(1rem - 2px);
    background-color: #c70000; }
    @media (-ms-high-contrast: active), (forced-colors: active) {
      .js .uol-header-local-navigation__link:hover::after, .js .uol-header-local-navigation__link:focus::after {
        background-color: WindowText;
        background-color: CanvasText; } }
  .js .uol-header-local-navigation__link:hover, .js .uol-header-local-navigation__link:focus {
    color: #0e0c0c; }
  .js .uol-header-local-navigation__link[aria-current] {
    color: #c70000; }

.js .uol-header-local-navigation__sub-nav .uol-header-local-navigation__link {
  background: #ffffff;
  color: #3b3b3b;
  white-space: normal;
  transition: background 0.5s ease;
  border-radius: 6px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  .js .uol-header-local-navigation__sub-nav .uol-header-local-navigation__link:hover, .js .uol-header-local-navigation__sub-nav .uol-header-local-navigation__link:focus {
    background-color: #efeae6;
    color: #212529;
    text-decoration: underline; }
  .js .uol-header-local-navigation__sub-nav .uol-header-local-navigation__link[aria-current] {
    color: #c70000; }
  .js .uol-header-local-navigation__sub-nav .uol-header-local-navigation__link::after {
    content: none; }
  .js .uol-header-local-navigation__sub-nav .uol-header-local-navigation__link svg {
    position: relative;
    top: 0.35em;
    left: 0;
    margin-left: 0.5em;
    fill: rgba(199, 0, 0, 0);
    transition: all 0.3s ease 0.2s; }
  .js .uol-header-local-navigation__sub-nav .uol-header-local-navigation__link:hover svg, .js .uol-header-local-navigation__sub-nav .uol-header-local-navigation__link:focus svg {
    left: 0.4em;
    fill: #c70000; }
    @media (-ms-high-contrast: active) {
      .js .uol-header-local-navigation__sub-nav .uol-header-local-navigation__link:hover svg, .js .uol-header-local-navigation__sub-nav .uol-header-local-navigation__link:focus svg {
        fill: windowText; } }

.js .uol-header-local-navigation__item--cta .uol-header-local-navigation__link {
  background: inherit;
  color: #ffffff;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem; }
  @media (min-width: 48em) {
    .js .uol-header-local-navigation__item--cta .uol-header-local-navigation__link {
      font-size: 18px; } }
  .js .uol-header-local-navigation__item--cta .uol-header-local-navigation__link:hover, .js .uol-header-local-navigation__item--cta .uol-header-local-navigation__link:focus {
    background-color: #0a0202;
    color: #ffffff; }
  .js .uol-header-local-navigation__item--cta .uol-header-local-navigation__link[aria-current] {
    color: #ff4e36; }
  .js .uol-header-local-navigation__item--cta .uol-header-local-navigation__link svg {
    fill: #ff4e36; }
    @media (-ms-high-contrast: active) {
      .js .uol-header-local-navigation__item--cta .uol-header-local-navigation__link svg {
        fill: windowText; } }
  .js .uol-header-local-navigation__item--cta .uol-header-local-navigation__link:hover svg, .js .uol-header-local-navigation__item--cta .uol-header-local-navigation__link:focus svg {
    left: 0.4em;
    fill: #ff4e36; }
    @media (-ms-high-contrast: active) {
      .js .uol-header-local-navigation__item--cta .uol-header-local-navigation__link:hover svg, .js .uol-header-local-navigation__item--cta .uol-header-local-navigation__link:focus svg {
        fill: windowText; } }

.uol-header-local-navigation__subnav-button {
  position: relative;
  font-weight: inherit;
  white-space: nowrap;
  background: transparent;
  border: none;
  margin: 0;
  padding: 0.5rem 1rem 0.75rem;
  appearance: none;
  color: #3b3b3b;
  transition: color 0.4s ease 0.1s; }
  .uol-header-local-navigation__subnav-button::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: calc(1rem - 2px);
    right: calc(100% - 1rem + 2px);
    height: 0.5rem;
    background-color: #ffffff;
    transition: right 0.4s ease 0.1s; }
  .uol-header-local-navigation__subnav-button:focus {
    outline: 2px dotted transparent; }
  .uol-header-local-navigation__subnav-button:hover::after, .uol-header-local-navigation__subnav-button:focus::after {
    right: calc(1rem - 2px);
    background-color: #c70000; }
    @media (-ms-high-contrast: active), (forced-colors: active) {
      .uol-header-local-navigation__subnav-button:hover::after, .uol-header-local-navigation__subnav-button:focus::after {
        background-color: WindowText;
        background-color: CanvasText; } }
  @media (max-width: 48em) {
    .uol-header-local-navigation__subnav-button[aria-expanded="true"]:focus {
      text-decoration: underline; }
    .uol-header-local-navigation__subnav-button[aria-expanded="true"]:focus:not(:focus-visible) {
      text-decoration: none; }
    .uol-header-local-navigation__subnav-button[aria-expanded="true"]:focus-visible {
      outline-color: transparent;
      text-decoration: underline;
      text-underline-offset: 4px;
      text-decoration-thickness: 0.5rem;
      text-decoration-color: #ff4e36; } }
  .uol-header-local-navigation__subnav-button:focus {
    outline-offset: -4px; }
  .uol-header-local-navigation__subnav-button:hover, .uol-header-local-navigation__subnav-button:focus {
    color: #0e0c0c; }
  .uol-header-local-navigation__subnav-button[aria-expanded="true"] {
    color: #0e0c0c; }
    @media (min-width: 48em) {
      .uol-header-local-navigation__subnav-button[aria-expanded="true"]::before {
        content: "";
        position: absolute;
        right: 0;
        transform: translateX(-70%);
        bottom: 0.375rem;
        width: 0;
        height: 0;
        border: 0.625rem solid transparent;
        border-top: none;
        border-bottom-color: #ffffff;
        filter: drop-shadow(0 -0.05rem 0.05rem rgba(33, 33, 33, 0.1));
        z-index: 4; } }
  .uol-header-local-navigation__subnav-button .uol-header-local-navigation__button__icon {
    position: relative;
    display: inline-block;
    width: 1.5rem;
    height: 1rem;
    margin-left: 0.2em;
    margin-right: -0.2em; }
    .uol-header-local-navigation__subnav-button .uol-header-local-navigation__button__icon::before, .uol-header-local-navigation__subnav-button .uol-header-local-navigation__button__icon::after {
      content: "";
      display: block;
      position: absolute;
      width: 0.5em;
      height: 0.13em;
      background: #212121;
      bottom: 20%; }
      @media (-ms-high-contrast: active) {
        .uol-header-local-navigation__subnav-button .uol-header-local-navigation__button__icon::before, .uol-header-local-navigation__subnav-button .uol-header-local-navigation__button__icon::after {
          background: ButtonText; } }
    .uol-header-local-navigation__subnav-button .uol-header-local-navigation__button__icon::before {
      left: 50%;
      transform: translateX(-76%) rotate(45deg); }
    .uol-header-local-navigation__subnav-button .uol-header-local-navigation__button__icon::after {
      right: 50%;
      transform: translateX(76%) rotate(-45deg); }
  @media (max-width: 47.99em) {
    .uol-header-local-navigation__subnav-button[aria-expanded="true"] {
      font-size: 1.75rem;
      line-height: 2.25rem;
      display: block;
      text-align: left;
      width: 100vw;
      border-bottom: 2px solid #c9c8c5; } }
  @media (max-width: 47.99em) and (min-width: 48em) {
    .uol-header-local-navigation__subnav-button[aria-expanded="true"] {
      font-size: 2.25rem; } }
  @media (max-width: 47.99em) and (min-width: 64em) {
    .uol-header-local-navigation__subnav-button[aria-expanded="true"] {
      font-size: 3rem; } }
  @media (max-width: 47.99em) and (min-width: 48em) {
    .uol-header-local-navigation__subnav-button[aria-expanded="true"] {
      line-height: 3rem; } }
  @media (max-width: 47.99em) and (min-width: 64em) {
    .uol-header-local-navigation__subnav-button[aria-expanded="true"] {
      line-height: 3.5rem; } }
  @media (max-width: 47.99em) and (max-width: 47.99em) {
    .uol-header-local-navigation__subnav-button[aria-expanded="true"] {
      margin-top: -0.5rem;
      padding: 1.312rem 3rem 1.312rem 1rem; } }
  @media (max-width: 47.99em) {
      .uol-header-local-navigation__subnav-button[aria-expanded="true"]::after {
        content: none; } }
  @media (max-width: 47.99em) and (min-width: 48em) {
    .uol-header-local-navigation__subnav-button[aria-expanded="true"]::before {
      content: "";
      width: 30px;
      height: 30px;
      background: red;
      position: absolute; } }
  .uol-header-local-navigation__subnav-button[aria-expanded="true"] .uol-header-local-navigation__button__icon::before {
    transform: translateX(-76%) rotate(315deg); }
  .uol-header-local-navigation__subnav-button[aria-expanded="true"] .uol-header-local-navigation__button__icon::after {
    transform: translateX(76%) rotate(45deg); }
  @media (max-width: 47.99em) {
    .uol-header-local-navigation__subnav-button[aria-expanded="true"] .uol-header-local-navigation__button__icon {
      position: absolute;
      padding: 1rem;
      right: 0.75rem;
      top: 50%;
      transform: translateY(-50%); }
      .uol-header-local-navigation__subnav-button[aria-expanded="true"] .uol-header-local-navigation__button__icon::before {
        bottom: 50%; }
      .uol-header-local-navigation__subnav-button[aria-expanded="true"] .uol-header-local-navigation__button__icon::after {
        bottom: 50%; } }

.js .uol-header-local-navigation__sub-nav {
  padding: 0;
  list-style: none;
  display: none;
  box-shadow: 0 15px 24px 0 rgba(10, 2, 2, 0.15), 0 5px 10px 0 rgba(33, 33, 33, 0.5), 0 5px 15px 2px rgba(255, 255, 255, 0.25); }
  @media (max-width: 47.99em) {
    .js .uol-header-local-navigation__sub-nav {
      padding-top: 1rem;
      background: #ffffff;
      width: 100vw;
      overflow-y: auto;
      overflow: hidden; } }
  @media (max-width: 47.99em) and (-ms-high-contrast: active) {
    .js .uol-header-local-navigation__sub-nav {
      border-bottom: 1px solid windowText; } }
  @media (min-width: 48em) {
    .js .uol-header-local-navigation__sub-nav {
      position: absolute;
      top: calc(100% - 1rem);
      box-sizing: border-box;
      width: 375px;
      background: #ffffff;
      border: 1px solid rgba(201, 200, 197, 0.2);
      border-radius: 0.75rem;
      padding-top: 0.25rem;
      z-index: 1001;
      transform: translateX(-1rem); } }

.uol-header {
  box-shadow: 0 10px 20px 0 rgba(10, 2, 2, 0.15), 0 3px 6px 0 rgba(10, 2, 2, 0.1); }

.uol-highlight-block {
  border-radius: 6px;
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background: #eae8e6; }
  @media (min-width: 64em) {
    .uol-highlight-block {
      border-radius: 12px;
      padding: 2rem 1.5rem; } }
  @media (min-width: 90em) {
    .uol-highlight-block {
      padding: 2rem; } }
  .uol-highlight-block .uol-rich-text :last-child {
    margin-bottom: 0; }

.js .uol-icon {
  position: relative;
  display: inline-flex; }
  .js .uol-icon svg {
    display: block;
    width: 1em;
    height: 1em;
    margin-top: .2em;
    margin-right: 0.4em;
    margin-left: -0.4em; }
    .js .uol-icon svg path {
      fill: currentColor; }
      @media (-ms-high-contrast: active) {
        .js .uol-icon svg path {
          fill: buttonText; } }

.js .uol-icon--position-after {
  flex-direction: row-reverse; }
  .js .uol-icon--position-after svg {
    margin-right: -0.4em;
    margin-left: 0.4em; }

.js .uol-icon--icon-only svg {
  margin: 0; }

.uol-iframe {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #51504C;
  aspect-ratio: 2 / 1; }

.uol-search-results .uol-iframe {
  aspect-ratio: 1 / 1;
  margin: 0.5rem 0.5rem 9px;
  border: 1px solid #C9C8C5; }
  @media (max-width: 47.99em) {
    .uol-search-results .uol-iframe {
      margin-bottom: 25px; } }

@media (min-width: 37.5em) and (max-width: 63.99em) {
  .uol-search-results .uol-iframe {
    height: 382px;
    width: 100%; } }
  @media (max-width: 360px) {
    .uol-search-results .uol-iframe {
      width: 95%; } }
  @media (min-width: 48em) {
    .uol-search-results .uol-iframe {
      height: 382px;
      margin-bottom: 1.5rem; } }
  @media (min-width: 64em) {
    .uol-search-results .uol-iframe {
      margin: 0.25rem 0.75rem 3rem; } }

.uol-article .uol-iframe + .uol-search-result__filters {
  margin-bottom: 2rem !important; }
  @media (min-width: 48em) {
    .uol-article .uol-iframe + .uol-search-result__filters {
      margin-bottom: 3rem !important; } }

.uol-article--form-map .uol-iframe {
  margin-bottom: 2rem !important; }
  @media (min-width: 48em) {
    .uol-article--form-map .uol-iframe {
      margin-bottom: 3rem !important; } }

.fluid-width-video-wrapper iframe {
  border: none; }

.uol-image-pair {
  display: flex;
  column-gap: 1rem; }
  @media (min-width: 64em) {
    .uol-image-pair {
      column-gap: 1.5rem; } }
  @media (min-width: 90em) {
    .uol-image-pair {
      column-gap: 2rem; } }
  .uol-image-pair .uol-image-pair__container {
    flex: 50%;
    margin: 0; }
  .uol-image-pair .uol-image-pair__image--single {
    width: 100%;
    aspect-ratio: 2 / 1;
    object-fit: cover; }
  .uol-image-pair .uol-image-pair__image {
    width: 100%;
    display: block; }
  .uol-image-pair .uol-image-pair__caption {
    color: #636363;
    margin-top: 0.5rem;
    padding-top: 0; }

.uol-featured-image {
  position: relative;
  display: flex;
  flex-direction: column; }
  @media (min-width: 25.875em) {
    .uol-featured-image {
      margin-bottom: 2rem; } }
  @media (min-width: 64em) {
    .uol-featured-image {
      margin-bottom: 3rem; } }
  .no-csspositionsticky .uol-featured-image {
    display: block; }
  .uol-widget__content .uol-featured-image {
    margin-bottom: 1rem; }

.uol-featured-image__img, .uol-article .uol-featured-image img {
  width: 100%;
  height: auto; }

.uol-featured-image__caption, .uol-article .uol-featured-image__caption,
.uol-article figcaption {
  margin: 0;
  padding: 0.5rem 0 0;
  color: #636363; }

/*
FOLLOWING RULE TO REMOVE
Once spacing work integrated in to 1.0.15 remove the following rule
It's necessary for the 1.0.14.1 patch as featured image added as new addition
*/
@media (max-width: 25.865em) {
  .uol-page .uol-featured-image {
    margin-bottom: 2rem; } }

.uol-in-page-navigation {
  background-color: #ffeded;
  max-width: 47.5rem;
  margin-top: 1.5rem; }
  .uol-in-page-navigation:not(.uol-in-page-navigation--with-accordion) {
    padding: 1rem;
    border-radius: 12px;
    margin-bottom: 1.5rem; }
  @media (max-width: 37.49em) {
    .uol-in-page-navigation {
      margin-left: -0.25rem;
      margin-right: -0.25rem; } }
  @media (min-width: 48em) {
    .uol-in-page-navigation {
      margin-top: 2rem;
      margin-bottom: 2rem; } }
  @media (min-width: 64em) {
    .uol-in-page-navigation {
      margin-top: 3rem;
      margin-bottom: 3rem; } }
  .uol-in-page-navigation.uol-in-page-navigation--with-accordion .uol-accordion {
    border-top-color: #51504c;
    border-bottom-color: #51504c; }
  .uol-in-page-navigation.uol-in-page-navigation--with-accordion .uol-accordion__title {
    padding: 0 1rem; }
  .uol-in-page-navigation.uol-in-page-navigation--with-accordion .uol-accordion__btn,
  .uol-in-page-navigation.uol-in-page-navigation--with-accordion .uol-accordion__btn[aria-expanded="true"] {
    box-sizing: border-box;
    text-decoration: none;
    color: #212529;
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    width: calc(100% + 2rem); }
    .uol-in-page-navigation.uol-in-page-navigation--with-accordion .uol-accordion__btn:hover, .uol-in-page-navigation.uol-in-page-navigation--with-accordion .uol-accordion__btn:focus,
    .uol-in-page-navigation.uol-in-page-navigation--with-accordion .uol-accordion__btn[aria-expanded="true"]:hover,
    .uol-in-page-navigation.uol-in-page-navigation--with-accordion .uol-accordion__btn[aria-expanded="true"]:focus {
      text-decoration: none; }
  .uol-in-page-navigation.uol-in-page-navigation--with-accordion .uol-accordion__content {
    padding: 0 1rem 2rem; }
  .uol-in-page-navigation .uol-in-page-navigation__title {
    font-size: 1.125rem;
    margin: 0;
    color: #212529; }
    @media (min-width: 48em) {
      .uol-in-page-navigation .uol-in-page-navigation__title {
        font-size: 1.25rem; } }
    @media (min-width: 64em) {
      .uol-in-page-navigation .uol-in-page-navigation__title {
        font-size: 1.25rem; } }
  .uol-in-page-navigation ul {
    padding: 0 1rem;
    border-radius: 12px;
    margin: 0.5rem 0 1rem; }
  .uol-in-page-navigation li {
    padding: 0 0 0.75rem 0.75rem;
    position: relative; }
    .uol-in-page-navigation li::marker {
      content: "—";
      position: absolute;
      color: #51504c;
      font-size: 1rem; }
    .uol-in-page-navigation li:last-of-type {
      padding-bottom: 0; }
    .uol-in-page-navigation li a {
      font-size: 1rem;
      font-family: freight-text-pro, Georgia, Cambria, "Times New Roman", Times, serif;
      line-height: 1.625;
      color: #3b3b3b; }
      @media (min-width: 48em) {
        .uol-in-page-navigation li a {
          font-size: 1rem; } }
      @media (min-width: 64em) {
        .uol-in-page-navigation li a {
          font-size: 1.125rem; } }
      .uol-in-page-navigation li a:hover, .uol-in-page-navigation li a:focus {
        text-decoration-color: #c70000; }
      @media (min-width: 48em) {
        .uol-in-page-navigation li a {
          line-height: 1.556; } }
      .uol-in-page-navigation li a[aria-current="page"] {
        color: #0e0c0c;
        font-weight: 600; }

.in-text-icons {
  padding: 0 !important;
  /*
  grip gap for each view same as gap between other components
  column width 50% - half of grip gap
  */ }
  @media (min-width: 48em) {
    .in-text-icons {
      display: grid;
      columns: 2;
      grid-template-columns: calc(50% - 0.5rem) calc(50% - 0.5rem);
      grid-gap: 0 1rem; } }
  @media (min-width: 64em) {
    .in-text-icons {
      grid-template-columns: calc(50% - 0.75rem) calc(50% - 0.75rem);
      grid-gap: 0 1.5rem; } }
  @media (min-width: 90em) {
    .in-text-icons {
      grid-template-columns: calc(50% - 1rem) calc(50% - 1rem);
      grid-gap: 0 2rem; } }

.in-text-icon {
  display: grid !important;
  grid-template-columns: auto 1fr;
  grid-gap: 0.75rem;
  align-items: center;
  margin-bottom: 20px !important; }
  .in-text-icon svg {
    display: block;
    height: 2rem !important;
    width: 2rem !important;
    color: #c70000;
    margin: 0 !important; }
  .in-text-icon .uol-icon__label {
    line-height: 1.75rem;
    display: block;
    break-inside: avoid-column;
    margin-right: 48px; }

.uol-rich-text .in-text-icons {
  margin-top: 16px !important;
  margin-bottom: 0px !important; }
  @media (min-width: 48em) {
    .uol-rich-text .in-text-icons {
      margin-top: 24px !important;
      margin-bottom: -20px !important; } }
  .uol-rich-text .in-text-icons svg {
    margin-right: 0.75rem; }

.uol-rich-text p + .in-text-icons {
  margin-top: -8px !important; }
  @media (min-width: 48em) {
    .uol-rich-text p + .in-text-icons {
      margin-top: 0 !important; } }

.uol-index-table-caption {
  color: #0e0c0c;
  margin: 1rem 0 0;
  padding: 1rem; }
  @media (max-width: 47.99em) {
    .uol-index-table-caption {
      border: 1px solid #c9c8c5;
      border-radius: 6px 6px 0 0; } }

.uol-index-table--results-container {
  padding: 1rem; }
  @media (min-width: 48.01em) {
    .uol-index-table--results-container {
      text-align: right; } }

.uol-index-table--results {
  font-size: 1rem; }

.uol-index-table {
  border-collapse: unset;
  margin-bottom: 1rem;
  border: 1px solid #c9c8c5;
  font-variant-numeric: lining-nums; }
  @media (min-width: 48em) {
    .uol-index-table {
      margin: 1rem;
      position: relative;
      border-radius: 12px; } }
  .uol-index-table thead {
    border-radius: 6px 6px 0 0; }
    @media (min-width: 48em) {
      .uol-index-table thead {
        z-index: 2;
        border-radius: 0; } }
  .uol-index-table th,
  .uol-index-table td {
    vertical-align: top; }
    @media (min-width: 48em) {
      .uol-index-table th,
      .uol-index-table td {
        padding: 1rem; } }
  .uol-index-table th {
    font-weight: 500; }
    @media (min-width: 48em) {
      .uol-index-table th {
        text-align: left;
        position: sticky;
        top: 0;
        padding-bottom: 20px;
        background: #ffffff;
        z-index: 2; }
        .uol-index-table th:first-of-type {
          border-radius: 12px 0 0 0; }
        .uol-index-table th:last-of-type {
          border-radius: 0 12px 0 0; }
        .csspositionsticky .uol-index-table th::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          bottom: -4px;
          left: 0;
          right: 0;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.4) 4px, rgba(0, 0, 0, 0) 4px);
          opacity: 0;
          transition: opacity 0.7s ease; }
        .uol-index-table th.uol-index-table__th--numeric {
          text-align: right; } }
  @media (min-width: 48em) and (min-width: 48em) {
    .uol-index-table th.uol-index-table__th--image-column {
      padding-left: 6.5rem; } }
  @media (min-width: 48em) {
    .uol-index-table .thead--stuck th::before {
      opacity: 1; }
    .uol-index-table .thead--stuck th:first-of-type, .uol-index-table .thead--stuck th:last-of-type {
      border-radius: 0; } }
  .uol-index-table td {
    color: #212529; }
    .uol-index-table td a {
      color: #212529;
      text-decoration: underline; }
      .uol-index-table td a:hover, .uol-index-table td a:focus {
        text-decoration-color: #c70000; }
  .uol-index-table tbody {
    line-height: 1.5; }
    @media (min-width: 48em) {
      .uol-index-table tbody {
        z-index: 1; } }
    .uol-index-table tbody tr {
      transition: all 0.3s ease; }
      @media (min-width: 48em) {
        .uol-index-table tbody tr:nth-child(odd) {
          background: #f3f2f1; }
        .uol-index-table tbody tr:last-of-type td:first-of-type {
          border-radius: 0 0 0 12px; }
        .uol-index-table tbody tr:last-of-type td:last-of-type {
          border-radius: 0 0 12px 0; } }
    @media (min-width: 48em) {
      .uol-index-table tbody td {
        border-bottom: 1px solid #c9c8c5; } }
    .uol-index-table tbody tr:last-of-type td {
      border-bottom: none; }
  @media (min-width: 48em) {
    .uol-index-table .uol-index-table__td--type-numeric {
      text-align: right; } }
  .uol-index-table .uol-index-table__td--type-img {
    position: relative;
    margin-top: -1rem;
    margin-left: -1rem;
    margin-right: -1rem;
    padding: 1rem 1rem 1rem 6.5rem; }
    .uol-index-table .uol-index-table__td--type-img::before {
      content: "";
      float: left;
      overflow: hidden;
      height: 4rem;
      font-size: 0; }
  .uol-index-table .uol-index-table__td__img {
    position: absolute;
    top: 8px;
    left: 8px;
    display: block;
    width: 80px;
    height: 80px;
    background-color: #ffffff;
    border-radius: 50%;
    overflow: hidden; }
    @media (min-width: 48em) {
      .uol-index-table .uol-index-table__td__img {
        width: 64px;
        height: 64px;
        top: 16px; } }
    .uol-index-table .uol-index-table__td__img::before, .uol-index-table .uol-index-table__td__img::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: inherit;
      height: inherit;
      border-radius: inherit; }
    .uol-index-table .uol-index-table__td__img::before {
      background: 50% / cover;
      background-image: inherit; }
    .uol-index-table .uol-index-table__td__img::after {
      box-shadow: inset 0 2px 5px rgba(33, 33, 33, 0.16); }
  .uol-index-table .uol-index-table__td__img--profile {
    background: #ffffff url("../img/avatar-missing.png"); }
  .uol-index-table .uol-index-table__sort-select {
    display: block;
    font-weight: 600;
    width: 100%;
    margin: 0;
    padding: 1rem;
    border-color: #51504c;
    border-radius: 0; }
    @supports (-webkit-appearance: none) {
      .uol-index-table .uol-index-table__sort-select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url("../img/sort.svg") no-repeat right 1rem top 50%; } }
    @media (-ms-high-contrast: active) {
      .uol-index-table .uol-index-table__sort-select {
        -webkit-appearance: menulist;
        -moz-appearance: menulist;
        appearance: menulist;
        background: initial; } }
    @media (min-width: 48em) {
      .uol-index-table .uol-index-table__sort-select {
        display: none; } }
  .uol-index-table .uol-index-table__th-inner {
    display: flex; }
  .uol-index-table .uol-index-table__btn-sort {
    position: relative;
    width: 25px;
    height: 24px;
    background: transparent;
    border: none; }
    @media (max-width: 47.99em) {
      .uol-index-table .uol-index-table__btn-sort {
        display: none; } }
    .uol-index-table .uol-index-table__btn-sort::before, .uol-index-table .uol-index-table__btn-sort::after {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      left: 8px;
      border: 0.35rem solid transparent;
      border-bottom: none;
      border-top: 0.4rem solid #0a0202; }
      @media (forced-colors: active) {
        .uol-index-table .uol-index-table__btn-sort::before, .uol-index-table .uol-index-table__btn-sort::after {
          border: 0.35rem solid canvas;
          border-top: 0.4rem solid #0a0202; } }
      @media (-ms-high-contrast: active) {
        .uol-index-table .uol-index-table__btn-sort::before, .uol-index-table .uol-index-table__btn-sort::after {
          border: 0.35rem solid window;
          border-top: 0.4rem solid windowText; } }
    .uol-index-table .uol-index-table__btn-sort::before {
      transform: rotate(180deg) translateY(120%); }
    .uol-index-table .uol-index-table__btn-sort::after {
      transform: translateY(20%); }
    .uol-index-table .uol-index-table__btn-sort.sorted--asc::after {
      opacity: 0; }
    .uol-index-table .uol-index-table__btn-sort.sorted--desc::before {
      opacity: 0; }

.uol-index-table--stacked {
  margin: 0 0 1rem;
  border-radius: 12px; }
  @media (max-width: 47.99em) {
    .uol-index-table--stacked {
      display: block;
      border-collapse: separate;
      border-radius: 0 0 6px 6px; }
      .uol-index-table--stacked thead {
        position: absolute !important;
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        width: 1px;
        overflow: hidden;
        left: -9999px; }
      .uol-index-table--stacked tbody {
        display: block; }
        .uol-index-table--stacked tbody tr {
          display: block;
          margin-bottom: 0; }
        .uol-index-table--stacked tbody td {
          display: block;
          padding: 1rem;
          color: #0e0c0c; }
          .uol-index-table--stacked tbody td:first-of-type {
            background: #f3f2f1; }
          .uol-index-table--stacked tbody td:not(:first-child):not(:last-child) {
            border-bottom: 1px solid #c9c8c5; }
        .uol-index-table--stacked tbody .uol-index-table__td--type-img {
          position: relative;
          background: #ddcfc0;
          padding: 1.5rem 1rem 1.5rem 7rem;
          margin: 0; }
        .uol-index-table--stacked tbody .uol-index-table__td__img {
          position: absolute;
          top: 1rem;
          left: 1rem;
          display: block;
          width: 5rem;
          height: 5rem;
          background-color: #ffffff;
          border-radius: 50%;
          overflow: hidden; }
          .uol-index-table--stacked tbody .uol-index-table__td__img::before, .uol-index-table--stacked tbody .uol-index-table__td__img::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: inherit;
            height: inherit;
            border-radius: inherit; }
          .uol-index-table--stacked tbody .uol-index-table__td__img::before {
            background: 50% / cover;
            background-image: inherit; }
          .uol-index-table--stacked tbody .uol-index-table__td__img::after {
            box-shadow: inset 0 2px 5px rgba(33, 33, 33, 0.16); }
          .uol-index-table--stacked tbody .uol-index-table__td__img img {
            width: 100%;
            height: auto; } }

@media (max-width: 47.99em) {
  .uol-index-table--search-results {
    border-radius: 6px; }
    .uol-index-table--search-results .uol-index-table__sort-select {
      border-radius: 6px 6px 0 0; } }

.uol-index-table__pseudo-th {
  display: block;
  font-weight: 600;
  color: #212529;
  margin-bottom: 0.25rem; }
  @media (min-width: 48em) {
    .uol-index-table__pseudo-th {
      display: none; } }

.uol-info-list-container {
  display: block;
  width: 100%; }

.uol-info-list {
  overflow: auto; }

@media (min-width: 48em) {
  .uol-info-list--inline {
    display: flex;
    flex-wrap: wrap; } }

.uol-info-list__group {
  clear: both;
  margin-bottom: 0.75rem;
  overflow: auto; }
  @media (min-width: 48em) {
    .uol-info-list--inline .uol-info-list__group {
      margin-right: 1em; } }
  .uol-people-profile__card .uol-info-list__group {
    margin-bottom: 0; }

.uol-info-list__group:last-child {
  margin-bottom: 0; }

.uol-info-list__term {
  float: left;
  font-weight: 700;
  margin-right: 0.2em; }
  .uol-info-list__term::after {
    content: ":\00a0"; }

.uol-info-list__data {
  float: left;
  margin: 0;
  font-variant-numeric: lining-nums; }
  .uol-info-list__data::after {
    content: ",\00a0";
    display: inline-block;
    margin-left: -0.2em; }
  .uol-info-list__data:last-of-type::after {
    content: none; }
  .uol-info-list__data a {
    transition: background 0.15s ease-out, text-decoration 0.15s ease-out;
    text-decoration: underline;
    color: inherit;
    transition: all 0.3s ease; }
    .uol-info-list__data a:focus {
      color: #212529;
      background-color: #ffeded;
      text-decoration: underline;
      text-decoration-thickness: 3px;
      text-decoration-color: #ff4e36;
      text-underline-offset: 2px;
      outline: 2px dotted inherit;
      outline-offset: 1px;
      outline-color: transparent; }
    .uol-info-list__data a:focus:not(:focus-visible) {
      text-decoration: none;
      outline-color: transparent; }
    .uol-info-list__data a:focus-visible {
      color: #212529;
      outline-color: transparent;
      outline-offset: 1px;
      background-color: #ffeded; }
    .uol-info-list__data a:hover {
      text-decoration-color: #c70000; }

.uol-info-list__data--date {
  font-weight: 800;
  display: block;
  float: none;
  border-top: 3px solid #c70000;
  padding-top: 1.5rem;
  margin-bottom: 0;
  font-variant-numeric: normal; }
  @media (min-width: 48em) {
    .uol-info-list__data--date {
      margin-bottom: 0.5rem;
      padding-top: 2rem; } }

.uol-key-facts {
  background-color: #ABC6C7;
  /*
  TODO: width fix for fitting in to course pages incorporating scroll bar
  Do more robust solution whereby scrollbar width is calculated via using client width
  */
  width: 100%;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50%;
  margin-right: -50%;
  margin-bottom: 3rem; }
  @media (min-width: 64em) {
    .uol-key-facts {
      width: calc(100vw - 9px);
      position: relative;
      margin-left: -50vw;
      margin-right: -50vw; } }

.uol-key-facts__list {
  font-variant-numeric: lining-nums;
  padding: 0;
  margin: 1.5rem 0.5rem;
  width: 100%; }

@media (min-width: 37.5em) and (max-width: 63.99em) {
  .uol-key-facts__list {
    columns: 2;
    column-gap: 1rem;
    margin: 1.5rem calc((1 / 12) * 100% + 0.5rem); } }
  @media (min-width: 64em) {
    .uol-key-facts__list {
      columns: 3;
      margin: 1rem 0.75rem;
      column-gap: 1.5rem; } }
  @media (min-width: 90em) {
    .uol-key-facts__list {
      margin: 2.25rem calc((1 / 12) * 100% + 1rem) 2.25rem 1rem;
      column-gap: calc((100% + 2rem) / 11 + 2rem); } }

.uol-key-facts__group {
  break-inside: avoid;
  margin-bottom: 1rem; }
  @media (min-width: 64em) {
    .uol-key-facts__group {
      margin: 0;
      padding: 0.75rem 0; } }

.uol-key-facts__term {
  font-weight: 600;
  color: #0e0c0c; }

.uol-key-facts__data {
  margin-left: 0; }

[class*=uol-col-] .uol-col-container--key-facts {
  margin: 0; }

@media (min-width: 64em) {
  .uol-col-container--key-facts {
    padding: 1rem 1.5rem; } }

.uol-link-button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }
  @media (min-width: 48em) {
    .uol-link-button-container {
      flex-direction: row; } }

.uol-link-button {
  display: inline-block;
  min-width: 0;
  margin-right: 1.5rem;
  margin-bottom: 2rem; }
  @media (min-width: 48em) {
    .uol-link-button {
      margin-right: 1.5rem;
      margin-bottom: 3rem; } }
  @media (min-width: 90em) {
    .uol-link-button {
      margin-right: 2rem; } }
  .uol-link-button:first-child {
    margin-bottom: 2rem; }
    @media (min-width: 48em) {
      .uol-link-button:first-child {
        margin-bottom: 0; } }
  .uol-link-button:last-child {
    margin-bottom: 0; }

.uol-linked-list {
  padding: 0;
  margin: 0 1rem 0 0;
  list-style-type: none; }
  @media (min-width: 48em) {
    .uol-linked-list {
      column-count: 2;
      column-gap: 1.75rem; } }
  @media (min-width: 64em) {
    .uol-linked-list {
      column-gap: 2.25rem; } }
  @media (max-width: 89.99em) {
    .uol-linked-list {
      max-width: 60rem; } }
  @media (min-width: 90em) {
    .uol-linked-list {
      column-count: 4;
      column-gap: 2.75rem; }
      .uol-side-nav-container--populated + .uol-homepage-content .uol-linked-list {
        column-count: 3; } }

.uol-article .uol-linked-list {
  column-count: 1;
  padding-left: 0; }

@media (min-width: 48em) {
  .uol-article p + .uol-linked-list {
    padding-top: 0.5rem; } }

.uol-widget--linked-list {
  max-width: 100%; }
  .uol-widget--linked-list .uol-widget__head {
    padding-bottom: 0; }
    @media (min-width: 48em) {
      .uol-widget--linked-list .uol-widget__head {
        padding-bottom: 0.5rem; } }
    @media (min-width: 64em) {
      .uol-widget--linked-list .uol-widget__head {
        padding-bottom: 1rem; } }
  .uol-widget--linked-list .uol-widget__content {
    margin-top: 0.75rem; }

.uol-linked-list__item {
  font-size: 1.125rem;
  box-sizing: border-box;
  color: #212529;
  break-inside: avoid;
  padding: 0.25rem 0;
  display: block;
  width: 100%; }
  @media (min-width: 48em) {
    .uol-linked-list__item {
      font-size: 1.25rem; } }

.uol-linked-list__link {
  line-height: 1.778;
  margin-left: 1rem;
  padding: 0.375rem 0;
  display: inline-block;
  position: relative; }
  @media (min-width: 48em) {
    .uol-linked-list__link {
      line-height: 1.6; } }
  @media (min-width: 64em) {
    .uol-linked-list__link {
      margin-left: 1rem; } }
  .uol-linked-list__link::before {
    content: "";
    position: absolute;
    height: 4px;
    width: 4px;
    background-color: #c70000;
    left: -14px;
    top: 1.2rem; }
    @media (-ms-high-contrast: active), (forced-colors: active) {
      .uol-linked-list__link::before {
        background-color: currentColor; } }
  .uol-linked-list__link:hover {
    text-decoration: underline;
    text-decoration-color: #ff4e36;
    text-decoration-thickness: 3px; }
  .uol-linked-list__link:focus {
    outline-color: transparent;
    background: #ffeded;
    text-underline-offset: 2px;
    text-decoration-thickness: 3px;
    text-decoration-color: #ff4e36; }
  .no-csspositionsticky .uol-linked-list__link:hover, .no-csspositionsticky .uol-linked-list__link:focus {
    text-decoration: none;
    border-bottom: 3px solid #ff4e36; }

.uol-list-featured {
  list-style-type: none;
  margin: 0;
  padding: 0;
  max-width: 52rem; }

.uol-list-featured__item {
  font-size: 1.125rem;
  line-height: 1.556;
  position: relative;
  margin-bottom: 1rem;
  padding: 0.5rem 0 0.5rem 2rem; }
  @media (min-width: 48em) {
    .uol-list-featured__item {
      font-size: 1.125rem; } }
  @media (min-width: 64em) {
    .uol-list-featured__item {
      font-size: 1.25rem; } }
  @media (min-width: 48em) {
    .uol-list-featured__item {
      line-height: 1.6; } }
  @media (min-width: 64em) {
    .uol-list-featured__item {
      margin-bottom: 1.5rem; } }
  @media (min-width: 90em) {
    .uol-list-featured__item {
      margin-bottom: 2rem; } }
  .uol-list-featured__item::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 1rem;
    background-color: #f3f2f1;
    z-index: -1; }
  .uol-list-featured__item a {
    transition: background 0.15s ease-out, text-decoration 0.15s ease-out;
    padding: 0 1px;
    margin: 0 -1px; }
    .uol-list-featured__item a:focus {
      color: #212529;
      background-color: #ffeded;
      text-decoration: underline;
      text-decoration-thickness: 3px;
      text-decoration-color: #ff4e36;
      text-underline-offset: 2px;
      outline: 2px dotted inherit;
      outline-offset: 1px;
      outline-color: transparent; }
    .uol-list-featured__item a:focus:not(:focus-visible) {
      text-decoration: none;
      outline-color: transparent; }
    .uol-list-featured__item a:focus-visible {
      color: #212529;
      outline-color: transparent;
      outline-offset: 1px;
      background-color: #ffeded; }
    .uol-list-featured__item a:hover {
      text-decoration-color: #ff4e36;
      text-decoration-thickness: 3px;
      text-underline-offset: 2px;
      transition: all 0s; }
    .no-csspositionsticky .uol-list-featured__item a:hover {
      text-decoration: none;
      border-bottom: 3px solid #ff4e36; }

.uol-list-featured__item--ordered {
  padding: 1rem 0 1rem 3.5rem;
  counter-increment: list-number;
  font-variant-numeric: lining-nums; }
  .uol-list-featured__item--ordered::before {
    font-size: 1.75rem;
    content: counter(list-number) ".";
    position: absolute;
    left: 1rem;
    font-family: freight-text-pro, Georgia, Cambria, "Times New Roman", Times, serif;
    color: #212121;
    top: 1rem;
    line-height: 1;
    font-weight: 800; }
    @media (min-width: 48em) {
      .uol-list-featured__item--ordered::before {
        font-size: 2rem;
        top: 50%;
        transform: translateY(-50%); } }
    @media (min-width: 64em) {
      .uol-list-featured__item--ordered::before {
        font-size: 2.1875rem; } }
  .uol-list-featured__item--ordered::after {
    width: 1.75rem; }

.nav-list-group__title {
  position: relative;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1.5rem; }
  .nav-list-group__title::before {
    content: "";
    position: absolute;
    display: inline-block;
    top: -1rem;
    left: 0;
    width: 1rem;
    height: 0.25rem;
    background-color: #ff4e36; }

.nav-list-group__list {
  list-style: none;
  padding: 0 0 2rem;
  margin-bottom: 0; }

.nav-list-group__item {
  margin-bottom: 0.5rem; }

.nav-list-group__link {
  color: inherit;
  text-decoration: none; }

.uol-logo-group {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 1.5rem; }

.uol-logo-group-item {
  width: 100%; }
  .uol-logo-group-item:not(:last-child) {
    margin-bottom: 1.5rem; }
  @media (min-width: 25.875em) {
    .uol-logo-group-item {
      width: calc((100% - 1rem) / 2);
      margin-right: 1rem; }
      .uol-logo-group-item:nth-child(2n+2) {
        margin-right: 0; } }
  @media (min-width: 48em) {
    .uol-logo-group-item {
      width: calc((100% - 2 * 1rem) / 3);
      margin-right: 1rem; }
      .uol-logo-group-item:nth-child(2n+2) {
        margin-right: 1rem; }
      .uol-logo-group-item:nth-child(3n+3) {
        margin-right: 0; } }
  @media (min-width: 64em) {
    .uol-logo-group-item {
      width: calc((100% - 2 * 1.5rem) / 3);
      margin-right: 1.5rem; }
      .uol-logo-group-item:nth-child(2n+2) {
        margin-right: 1.5rem; } }
  @media (min-width: 90em) {
    .uol-logo-group-item {
      width: calc((100% - 2 * 2rem) / 3);
      margin-right: 2rem; }
      .uol-logo-group-item:nth-child(2n+2) {
        margin-right: 2rem; }
      .uol-logo-group-item:not(:last-child) {
        margin-bottom: 2rem; } }

.uol-logo-group-item__container {
  border: 1px solid #c9c8c5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 144px;
  margin-bottom: 0.25rem; }

.uol-logo-group-item__logo {
  margin: 0 auto;
  max-height: 96px;
  max-width: 226px; }
  @media (min-width: 25.875em) {
    .uol-logo-group-item__logo {
      max-width: 152px; } }
  @media (min-width: 37.5em) {
    .uol-logo-group-item__logo {
      max-width: 163px; } }
  @media (min-width: 48em) {
    .uol-logo-group-item__logo {
      max-width: 161px; } }
  @media (min-width: 64em) {
    .uol-logo-group-item__logo {
      max-width: 168px; } }
  @media (min-width: 90em) {
    .uol-logo-group-item__logo {
      max-width: 171px; } }
  @media (min-width: 103.75em) {
    .uol-logo-group-item__logo {
      max-width: 176px; } }

.uol-logo-group-item__link {
  transition: background 0.15s ease-out, text-decoration 0.15s ease-out;
  margin-bottom: 1.5rem; }
  .uol-logo-group-item__link:focus {
    color: #212529;
    background-color: #ffeded;
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-decoration-color: #ff4e36;
    text-underline-offset: 3px;
    outline: 2px dotted inherit;
    outline-offset: 1px;
    outline-color: transparent; }
  .uol-logo-group-item__link:focus:not(:focus-visible) {
    text-decoration: none;
    outline-color: transparent; }
  .uol-logo-group-item__link:focus-visible {
    color: #212529;
    outline-color: transparent;
    outline-offset: 1px;
    background-color: #ffeded; }
  .uol-logo-group-item__link:hover {
    text-decoration-color: #ff4e36; }

.uol-logo-uol-inline .uol-logo-uol-inline__img {
  fill: #212121; }
  @media screen and (-ms-high-contrast: active) {
    .uol-logo-uol-inline .uol-logo-uol-inline__img {
      fill: windowText; } }

.uol-multilink-results {
  list-style: none;
  margin-bottom: 1rem;
  padding: 1.5rem 1rem;
  border: 1px solid #c9c8c5;
  border-radius: 6px;
  background-color: #f3f2f1;
  font-variant-numeric: lining-nums;
  color: #212529;
  overflow: auto; }
  @media (min-width: 48em) {
    .uol-multilink-results {
      padding: 1.5rem 1rem; } }
  @media (min-width: 64em) {
    .uol-multilink-results {
      padding: 1.5rem; } }
  .uol-multilink-results .uol-info-list {
    margin: 0.5rem 0 0 0; }

.uol-multilink-results__alongside-image {
  max-width: 784px; }

.uol-multilink-results__has-image {
  margin-top: 1rem;
  margin-left: 1rem; }
  @media (min-width: 48em) {
    .uol-multilink-results__has-image {
      margin-top: 0;
      margin-left: 1rem; } }
  @media (min-width: 64em) {
    .uol-multilink-results__has-image {
      margin-left: 1.5rem; } }
  @media (min-width: 90em) {
    .uol-multilink-results__has-image {
      margin-left: 2rem; } }

.uol-multilink-results__image-titles {
  display: flex; }

.uol-multilink-results__image {
  border-radius: 50%;
  width: 116px;
  height: 116px;
  margin-bottom: 0.5rem; }
  @media (min-width: 48em) {
    .uol-multilink-results__image {
      width: 156px;
      height: 156px; } }
  @media (min-width: 64em) {
    .uol-multilink-results__image {
      width: 161px;
      height: 161px; } }
  @media (min-width: 90em) {
    .uol-multilink-results__image {
      width: 179px;
      height: 179px; } }
  @media (min-width: 103.75em) {
    .uol-multilink-results__image {
      width: 215px;
      height: 215px; } }

.uol-multilink-results__name {
  font-size: 1.375rem;
  font-family: freight-text-pro, Georgia, Cambria, "Times New Roman", Times, serif;
  margin: 0; }
  @media (min-width: 48em) {
    .uol-multilink-results__name {
      font-size: 1.75rem; } }
  .uol-multilink-results__name a {
    text-decoration: none; }
    .uol-multilink-results__name a:hover, .uol-multilink-results__name a:focus {
      text-decoration: underline;
      outline: 3px dotted transparent; }

.uol-multilink-results__position {
  display: block;
  font-family: freight-text-pro, Georgia, Cambria, "Times New Roman", Times, serif;
  color: #c70000;
  margin-top: 0.5rem; }

.uol-multilink-results--further {
  margin-bottom: 0.5rem; }

.uol-multilink-results__paragraph {
  margin: 0.5rem 0 0; }

.uol-status-message:last-child {
  margin-bottom: -0.5rem; }

.uol-info-list--inline .uol-info-list__group:not(:last-child) {
  margin-right: 0.75rem;
  margin-bottom: 0.5rem; }

@media (min-width: 48em) {
  .uol-info-list--inline {
    margin-bottom: -0.5rem !important; } }

.uol-multilink-results__footer {
  padding: 1rem 0 0; }
  .uol-multilink-results__footer:before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #c9c8c5; }

.uol-multilink-results__footer__messages {
  display: block;
  padding-top: 1.5rem; }
  .js .uol-multilink-results__footer__messages svg {
    height: 1.5em;
    width: 1.5em;
    min-width: 1.5em;
    margin-top: 0;
    margin-left: 0; }

.uol-multilink-results__footer__actions {
  margin-bottom: -1.5rem;
  padding-top: 1.5rem; }
  .uol-multilink-results__footer__actions .uol-button, .uol-multilink-results__footer__actions .js .uolcc-container .uolcc-button, .js .uolcc-container .uol-multilink-results__footer__actions .uolcc-button,
  .uol-multilink-results__footer__actions .js .uolcc-prompt-container .uolcc-button, .js .uolcc-prompt-container .uol-multilink-results__footer__actions .uolcc-button,
  .uol-multilink-results__footer__actions .js .uolcc-privacy-update-container .uolcc-button, .js .uolcc-privacy-update-container .uol-multilink-results__footer__actions .uolcc-button, .uol-multilink-results__footer__actions .uol-section-nav-ctas__link {
    margin: 0 1rem 1.5rem 0; }

.uol-multilink-results-items {
  padding: 0;
  margin: 0; }

.uol-navigation-cards {
  display: flex;
  flex-wrap: wrap; }
  .uol-navigation-cards > * {
    text-decoration: none; }
  @media (min-width: 48em) {
    .uol-navigation-cards {
      gap: 0 1rem; } }
  @media (min-width: 64em) {
    .uol-navigation-cards {
      gap: 0 1.5rem; } }
  @media (min-width: 90em) {
    .uol-navigation-cards {
      gap: 0 2rem; } }

.uol-navigation-card {
  box-sizing: border-box;
  min-height: 338px;
  padding: 2rem 1rem;
  flex: 100%;
  background-color: #f3f2f1;
  position: relative;
  border: 1px solid #c9c8c5;
  margin-bottom: 1rem; }
  @media (min-width: 64em) {
    .uol-navigation-card {
      margin-bottom: 1.5rem; } }
  @media (min-width: 90em) {
    .uol-navigation-card {
      margin-bottom: 2rem; } }
  .uol-navigation-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: box-shadow 0.25s ease-in-out;
    z-index: 3; }
  .uol-navigation-card:hover::before {
    box-shadow: 0px 13px 15px rgba(0, 0, 0, 0.25); }
  @media (min-width: 48em) {
    .uol-navigation-card {
      flex: calc(50% - 8px);
      max-width: calc(50% - 8px); } }
  @media (min-width: 64em) {
    .uol-navigation-card {
      flex: calc((100% + 24px) / 3 - 24px);
      max-width: calc((100% + 24px) / 3 - 24px); } }
  @media (min-width: 90em) {
    .uol-navigation-card {
      flex: calc((100% + 32px) / 4 - 32px);
      max-width: calc((100% + 32px) / 4 - 32px); } }
  .uol-navigation-card::after {
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 0.5rem solid #c70000;
    transform: scaleX(0);
    transition: transform .3s ease-in-out;
    transform-origin: 0 50%; }
  .uol-navigation-card:hover:after, .uol-navigation-card:focus-within:after {
    transform: scaleX(1);
    outline: none; }
  @media (min-width: 64em) {
    .uol-side-nav-container--populated + .uol-homepage-content .uol-navigation-card {
      flex: calc((100% + 24px) / 2 - 24px);
      max-width: calc((100% + 24px) / 2 - 24px); } }
  @media (min-width: 90em) {
    .uol-side-nav-container--populated + .uol-homepage-content .uol-navigation-card {
      flex: calc((100% + 32px) / 3 - 32px);
      max-width: calc((100% + 32px) / 3 - 32px); } }

.uol-navigation-card__title {
  margin: 0 0 0.5rem; }

.uol-navigation-card__description {
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-top: 0;
  margin-bottom: 0;
  color: #212529;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; }
  @media (min-width: 48em) {
    .uol-navigation-card__description {
      font-size: 1.25rem; } }
  @media (min-width: 64em) {
    .uol-navigation-card__description {
      font-size: 1.25rem; } }
  @media (min-width: 48em) {
    .uol-navigation-card__description {
      line-height: 1.75rem; } }
  @media (min-width: 64em) {
    .uol-navigation-card__description {
      line-height: 2rem; } }
  @media (min-width: 48em) {
    .uol-navigation-card__description {
      font-size: 1rem;
      line-height: 1.5rem; } }
  @media (min-width: 48em) and (min-width: 48em) {
    .uol-navigation-card__description {
      font-size: 1rem; } }
  @media (min-width: 48em) and (min-width: 64em) {
    .uol-navigation-card__description {
      font-size: 1.125rem; } }
  @media (min-width: 48em) and (min-width: 48em) {
    .uol-navigation-card__description {
      line-height: 1.625rem; } }
  @media (min-width: 48em) and (min-width: 64em) {
    .uol-navigation-card__description {
      line-height: 1.75rem; } }

.uol-news-tiles {
  display: flex;
  flex-wrap: wrap; }

.uol-news-tile {
  border: 1px solid #fff;
  position: relative;
  box-sizing: border-box;
  background-color: #6e6b6b;
  color: #ffffff;
  width: 100%;
  min-height: 12rem; }
  @media (min-width: 25.875em) {
    .uol-news-tile {
      min-height: 13rem; } }
  @media (min-width: 37.5em) {
    .uol-news-tile {
      min-height: 18.75rem;
      width: 50%; }
      .uol-news-tile:first-of-type {
        width: 100%; } }
  @media (min-width: 64em) {
    .uol-news-tile {
      min-height: 21.5rem;
      width: calc(100% / 3); }
      .uol-news-tile:first-of-type {
        width: calc(100% / 3 * 2); } }
  @media (min-width: 90em) {
    .uol-news-tile {
      min-height: 22.5rem;
      width: 25%; }
      .uol-news-tile:first-of-type {
        width: 50%; } }
  .uol-news-tile:nth-of-type(2) {
    background-color: #c70000; }
    .uol-news-tile:nth-of-type(2) .uol-news-tile__img {
      display: none; }
  .uol-news-tile:nth-of-type(3) {
    background-color: #5f5754; }
  .uol-news-tile:nth-of-type(5) {
    background-color: #6a645d; }
  .uol-news-tile:nth-of-type(4) {
    background-color: #910000; }
    .uol-news-tile:nth-of-type(4) .uol-news-tile__img {
      display: none; }
  .uol-news-tile:nth-of-type(7) {
    background-color: #212121; }
    .uol-news-tile:nth-of-type(7) .uol-news-tile__img {
      display: none; }
  .uol-news-tile::after {
    border: 4px solid rgba(255, 78, 54, 0); }
  .uol-news-tile:focus-within::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-color: #ff4e36; }

.uol-news-tile__inner {
  z-index: 8;
  position: relative;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 4rem 1rem 1rem 1rem;
  box-sizing: border-box; }
  @media (min-width: 37.5em) {
    .uol-news-tile__inner {
      padding-top: 128px; } }
  .uol-news-tile:hover .uol-news-tile__inner::before,
  .uol-news-tile:focus-within .uol-news-tile__inner::before {
    background-color: #ff4e36; }
  .uol-news-tile:nth-of-type(2) .uol-news-tile__inner::before,
  .uol-news-tile:nth-of-type(4) .uol-news-tile__inner::before,
  .uol-news-tile:nth-of-type(7) .uol-news-tile__inner::before {
    background-color: #ffffff; }

.uol-news-tile__content::before {
  content: "";
  position: relative;
  display: inline-block;
  top: -0.75rem;
  left: 0;
  width: 1.5rem;
  height: 0.5rem;
  background-color: #ffffff;
  transition: background 0.3s ease; }
  @media (min-width: 48em) {
    .uol-news-tile__content::before {
      width: 2rem; } }
  .uol-news-tile:focus-within .uol-news-tile__content::before,
  .uol-news-tile:hover .uol-news-tile__content::before {
    background-color: #ff4e36; }
  .uol-news-tile:nth-of-type(2) .uol-news-tile__content::before,
  .uol-news-tile:nth-of-type(4) .uol-news-tile__content::before,
  .uol-news-tile:nth-of-type(7) .uol-news-tile__content::before,
  .uol-news-tiles--no-img:focus-within .uol-news-tile__content::before,
  .uol-news-tiles--no-img:hover .uol-news-tile__content::before {
    background-color: #FFF; }

.uol-news-tile__title {
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 0;
  font-variant-numeric: lining-nums; }
  @media (min-width: 48em) {
    .uol-news-tile__title {
      font-size: 1.5rem; } }
  @media (min-width: 64em) {
    .uol-news-tile__title {
      font-size: 1.75rem; } }
  @media (min-width: 48em) {
    .uol-news-tile__title {
      line-height: 2rem; } }
  @media (min-width: 64em) {
    .uol-news-tile__title {
      line-height: 2.375rem; } }

.uol-news-tile__link {
  color: inherit;
  text-decoration: none;
  background: rgba(10, 2, 2, 0.6);
  box-shadow: -0.5rem 0 0 0 rgba(10, 2, 2, 0.6), 0.5rem 0 0 0 rgba(10, 2, 2, 0.6);
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone; }
  .uol-news-tile__link::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .uol-news-tile__link:hover, .uol-news-tile__link:focus {
    text-decoration: underline;
    text-decoration-color: #ff4e36; }
  .uol-news-tile:nth-of-type(2) .uol-news-tile__link,
  .uol-news-tile:nth-of-type(4) .uol-news-tile__link,
  .uol-news-tile:nth-of-type(7) .uol-news-tile__link,
  .uol-news-tiles--no-img .uol-news-tile__link {
    background: transparent;
    box-shadow: none; }
    .uol-news-tile:nth-of-type(2) .uol-news-tile__link:hover, .uol-news-tile:nth-of-type(2) .uol-news-tile__link:focus,
    .uol-news-tile:nth-of-type(4) .uol-news-tile__link:hover,
    .uol-news-tile:nth-of-type(4) .uol-news-tile__link:focus,
    .uol-news-tile:nth-of-type(7) .uol-news-tile__link:hover,
    .uol-news-tile:nth-of-type(7) .uol-news-tile__link:focus,
    .uol-news-tiles--no-img .uol-news-tile__link:hover,
    .uol-news-tiles--no-img .uol-news-tile__link:focus {
      text-decoration-color: #ffffff; }

.uol-news-tile__meta {
  margin: 0.75rem 0 0; }

.uol-news-tile__meta__data {
  display: inline;
  margin: 0; }
  .uol-news-tile__meta__data + .uol-news-tile__meta__data::before {
    content: ",";
    position: relative;
    left: -0.2em; }

.uol-news-tile__meta__data--date {
  display: inline-block;
  font-variant-numeric: lining-nums;
  background: rgba(10, 2, 2, 0.6);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: -0.5rem;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone; }
  .uol-news-tile:nth-of-type(2) .uol-news-tile__meta__data--date,
  .uol-news-tile:nth-of-type(4) .uol-news-tile__meta__data--date,
  .uol-news-tile:nth-of-type(7) .uol-news-tile__meta__data--date,
  .uol-news-tiles--no-img .uol-news-tile__meta__data--date {
    background: transparent; }

.uol-news-tile__meta__link {
  color: inherit;
  text-decoration: none; }
  .uol-news-tile__meta__link:hover, .uol-news-tile__meta__link:focus {
    text-decoration: underline; }

.uol-news-tile__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: 50%;
  background-size: cover; }
  @media (-ms-high-contrast: active), (forced-colors: active) {
    .uol-news-tile__img {
      -ms-high-contrast-adjust: none;
      forced-color-adjust: CanvasText; } }
  .uol-news-tile__img::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(33, 33, 33, 0.1);
    transition: background 0.3s ease; }
    .uol-news-tile:hover .uol-news-tile__img::after,
    .uol-news-tile:focus-within .uol-news-tile__img::after {
      background-color: rgba(33, 33, 33, 0); }

@media (min-width: 37.5em) {
  .uol-news-tiles--set-of-four .uol-news-tile {
    width: calc(100% / 2); } }

@media (min-width: 64em) {
  .uol-news-tiles--set-of-four .uol-news-tile {
    width: calc(100% / 3); } }

@media (min-width: 90em) {
  .uol-news-tiles--set-of-four .uol-news-tile {
    width: calc(100% / 4); } }

.page-heading {
  margin-bottom: 2rem; }
  @media (min-width: 48em) {
    .page-heading {
      margin-bottom: 3rem; } }
  .uol-article--profile .page-heading {
    margin-bottom: 0; }

.page-heading__title__main {
  position: relative;
  margin: 24px 0 0; }
  @media (min-width: 48em) {
    .page-heading__title__main {
      margin: 40px 0 0; } }
  @media (min-width: 64em) {
    .page-heading__title__main {
      margin: 48px 0 0; } }
  @media (min-width: 90em) {
    .page-heading__title__main {
      margin: 48px 0 0; } }

.page-heading__title {
  position: relative;
  margin: 24px 0 0; }
  @media (min-width: 48em) {
    .page-heading__title {
      margin: 40px 0 0; } }
  @media (min-width: 64em) {
    .page-heading__title {
      margin: 48px 0 0; } }
  @media (min-width: 90em) {
    .page-heading__title {
      margin: 48px 0 0; } }
  .page-heading__title::before {
    content: "";
    position: absolute;
    display: inline-block;
    top: -16px;
    left: 0;
    width: 1.5rem;
    height: 0.5rem;
    background-color: #c70000; }
    @media (min-width: 48em) {
      .page-heading__title::before {
        top: -24px;
        width: 2rem; } }
    @media (min-width: 64em) {
      .page-heading__title::before {
        top: -24px;
        width: 2rem; } }
    @media (min-width: 90em) {
      .page-heading__title::before {
        top: -24px; } }
  .page-heading__title.page-heading__title--subtitle {
    display: block;
    padding-top: 0.5rem;
    font-weight: 800; }
    .page-heading__title.page-heading__title--subtitle::before {
      content: none; }

.page-heading__title--position {
  margin-top: 0.5rem;
  display: block;
  font-weight: 800;
  color: #c70000; }

.page-heading__meta {
  margin-top: 0.5rem;
  margin-bottom: 0; }
  @media (min-width: 48em) {
    .page-heading__meta {
      margin-top: 1rem; } }

.page-heading__meta__group {
  margin-bottom: 0.25rem; }

.page-heading__meta__data {
  display: inline-block;
  margin: 0;
  color: #636363;
  font-variant-numeric: lining-nums; }
  .uol-article--profile .page-heading__meta__data,
  .uol-article--person-insight .page-heading__meta__data,
  .uol-article--blog .page-heading__meta__data {
    font-weight: 800;
    color: #c70000; }

.page-heading__meta__data__link {
  color: #c70000; }
  .page-heading__meta__data__link::after {
    content: ",";
    position: relative;
    left: -0.3em; }
  .page-heading__meta__data:last-of-type .page-heading__meta__data__link::after {
    content: none; }

.uol-page-nav {
  display: block;
  max-width: 47.5rem;
  margin: 2rem 0; }
  @media (min-width: 48em) {
    .uol-page-nav {
      display: flex;
      margin: 4rem 0; } }
  .uol-course__content .uol-page-nav {
    max-width: none; }

.uol-page-nav__item {
  transition: background 0.15s ease-out, text-decoration 0.15s ease-out;
  box-sizing: border-box;
  display: block;
  text-decoration: none;
  border: solid #51504c;
  border-width: 1px 0;
  padding: 1.5rem 1rem; }
  .uol-page-nav__item:focus {
    color: #212529;
    background-color: #ffeded;
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-decoration-color: #ff4e36;
    text-underline-offset: 4px;
    outline: 2px dotted inherit;
    outline-offset: 1px;
    outline-color: transparent; }
  .uol-page-nav__item:focus:not(:focus-visible) {
    text-decoration: none;
    outline-color: transparent; }
  .uol-page-nav__item:focus-visible {
    color: #212529;
    outline-color: transparent;
    outline-offset: 1px;
    background-color: #ffeded; }
  .uol-page-nav__item:focus {
    text-decoration: none; }
  @media (max-width: 47.99em) {
    .uol-page-nav__item + .uol-page-nav__item {
      border-top: none; } }
  @media (min-width: 48em) {
    .uol-page-nav__item + .uol-page-nav__item {
      margin-left: 1rem; } }
  @media (min-width: 64em) {
    .uol-page-nav__item + .uol-page-nav__item {
      margin-left: 1.5rem; } }
  @media (min-width: 90em) {
    .uol-page-nav__item + .uol-page-nav__item {
      margin-left: 2rem; } }
  @media (min-width: 48em) {
    .uol-page-nav__item {
      flex-basis: calc(50% - 0.5rem); } }
  @media (min-width: 64em) {
    .uol-page-nav__item {
      flex-basis: calc(50% - 0.75rem); } }
  @media (min-width: 90em) {
    .uol-page-nav__item {
      flex-basis: calc(50% - 1rem); } }

@media (min-width: 48em) {
  .uol-page-nav__item--next {
    text-align: right;
    margin-left: auto; } }

.uol-page-nav__item__identifier {
  display: block;
  color: #0e0c0c; }
  .uol-page-nav__item:focus .uol-page-nav__item__identifier {
    text-decoration: none; }

.uol-page-nav__item__title {
  display: block; }
  .uol-page-nav__item:hover .uol-page-nav__item__title,
  .uol-page-nav__item:focus .uol-page-nav__item__title {
    text-underline-offset: 4px;
    text-decoration: underline; }
  .uol-page-nav__item:focus .uol-page-nav__item__title {
    text-decoration-color: #ff4e36; }

.uol-pagination {
  text-align: center;
  font-variant-numeric: lining-nums;
  padding-bottom: 1rem;
  border-bottom: 1px solid #c9c8c5; }

.uol-pagination__list {
  margin: 1rem 0 0.75rem;
  padding: 0;
  list-style: none; }

.uol-pagination__item {
  display: inline-block;
  margin: 0 0.125rem; }
  @media (max-width: 37.49em) {
    .uol-pagination__item.uol-pagination__item--numeric {
      display: none; } }
  .uol-pagination__item.uol-pagination__item--numeric .uol-button:focus, .uol-pagination__item.uol-pagination__item--numeric .js .uolcc-container .uolcc-button:focus, .js .uolcc-container .uol-pagination__item.uol-pagination__item--numeric .uolcc-button:focus,
  .uol-pagination__item.uol-pagination__item--numeric .js .uolcc-prompt-container .uolcc-button:focus, .js .uolcc-prompt-container .uol-pagination__item.uol-pagination__item--numeric .uolcc-button:focus,
  .uol-pagination__item.uol-pagination__item--numeric .js .uolcc-privacy-update-container .uolcc-button:focus, .js .uolcc-privacy-update-container .uol-pagination__item.uol-pagination__item--numeric .uolcc-button:focus, .uol-pagination__item.uol-pagination__item--numeric .uol-section-nav-ctas__link:focus {
    box-shadow: 0px 0px 0px 2px #ffffff, 0px 0px 0px 5px #197ebe;
    outline: 3px dotted transparent; }
  .uol-pagination__item.uol-pagination__item--numeric .uol-button:focus:not(:focus-visible), .uol-pagination__item.uol-pagination__item--numeric .js .uolcc-container .uolcc-button:focus:not(:focus-visible), .js .uolcc-container .uol-pagination__item.uol-pagination__item--numeric .uolcc-button:focus:not(:focus-visible),
  .uol-pagination__item.uol-pagination__item--numeric .js .uolcc-prompt-container .uolcc-button:focus:not(:focus-visible), .js .uolcc-prompt-container .uol-pagination__item.uol-pagination__item--numeric .uolcc-button:focus:not(:focus-visible),
  .uol-pagination__item.uol-pagination__item--numeric .js .uolcc-privacy-update-container .uolcc-button:focus:not(:focus-visible), .js .uolcc-privacy-update-container .uol-pagination__item.uol-pagination__item--numeric .uolcc-button:focus:not(:focus-visible), .uol-pagination__item.uol-pagination__item--numeric .uol-section-nav-ctas__link:focus:not(:focus-visible) {
    box-shadow: none; }
  .uol-pagination__item.uol-pagination__item--numeric .uol-button:focus-visible, .uol-pagination__item.uol-pagination__item--numeric .js .uolcc-container .uolcc-button:focus-visible, .js .uolcc-container .uol-pagination__item.uol-pagination__item--numeric .uolcc-button:focus-visible,
  .uol-pagination__item.uol-pagination__item--numeric .js .uolcc-prompt-container .uolcc-button:focus-visible, .js .uolcc-prompt-container .uol-pagination__item.uol-pagination__item--numeric .uolcc-button:focus-visible,
  .uol-pagination__item.uol-pagination__item--numeric .js .uolcc-privacy-update-container .uolcc-button:focus-visible, .js .uolcc-privacy-update-container .uol-pagination__item.uol-pagination__item--numeric .uolcc-button:focus-visible, .uol-pagination__item.uol-pagination__item--numeric .uol-section-nav-ctas__link:focus-visible {
    box-shadow: 0px 0px 0px 2px #ffffff, 0px 0px 0px 5px #197ebe; }
  .uol-pagination__item.uol-pagination__item--numeric.uol-pagination__item--current {
    display: inline-block; }
  .uol-pagination__item .uol-button--primary, .uol-pagination__item .js .uolcc-container .uolcc-button, .js .uolcc-container .uol-pagination__item .uolcc-button,
  .uol-pagination__item .js .uolcc-prompt-container .uolcc-button, .js .uolcc-prompt-container .uol-pagination__item .uolcc-button,
  .uol-pagination__item .js .uolcc-privacy-update-container .uolcc-button, .js .uolcc-privacy-update-container .uol-pagination__item .uolcc-button {
    position: relative;
    outline: 0 dotted #197ebe;
    outline-offset: 3px;
    overflow: visible; }
    .uol-pagination__item .uol-button--primary::before, .uol-pagination__item .js .uolcc-container .uolcc-button::before, .js .uolcc-container .uol-pagination__item .uolcc-button::before,
    .uol-pagination__item .js .uolcc-prompt-container .uolcc-button::before, .js .uolcc-prompt-container .uol-pagination__item .uolcc-button::before,
    .uol-pagination__item .js .uolcc-privacy-update-container .uolcc-button::before, .js .uolcc-privacy-update-container .uol-pagination__item .uolcc-button::before {
      content: "";
      position: absolute;
      top: -7px;
      right: -7px;
      bottom: -7px;
      left: -7px;
      border: 3px solid transparent;
      border-radius: inherit;
      transition: all 0.15s; }
      @media (-ms-high-contrast: active), (forced-colors: active) {
        .uol-pagination__item .uol-button--primary::before, .uol-pagination__item .js .uolcc-container .uolcc-button::before, .js .uolcc-container .uol-pagination__item .uolcc-button::before,
        .uol-pagination__item .js .uolcc-prompt-container .uolcc-button::before, .js .uolcc-prompt-container .uol-pagination__item .uolcc-button::before,
        .uol-pagination__item .js .uolcc-privacy-update-container .uolcc-button::before, .js .uolcc-privacy-update-container .uol-pagination__item .uolcc-button::before {
          display: none; } }
    @media (-ms-high-contrast: active), (forced-colors: active) {
      .uol-pagination__item .uol-button--primary:focus, .uol-pagination__item .js .uolcc-container .uolcc-button:focus, .js .uolcc-container .uol-pagination__item .uolcc-button:focus,
      .uol-pagination__item .js .uolcc-prompt-container .uolcc-button:focus, .js .uolcc-prompt-container .uol-pagination__item .uolcc-button:focus,
      .uol-pagination__item .js .uolcc-privacy-update-container .uolcc-button:focus, .js .uolcc-privacy-update-container .uol-pagination__item .uolcc-button:focus {
        outline-width: 3px; } }
    .uol-pagination__item .uol-button--primary:focus::before, .uol-pagination__item .js .uolcc-container .uolcc-button:focus::before, .js .uolcc-container .uol-pagination__item .uolcc-button:focus::before,
    .uol-pagination__item .js .uolcc-prompt-container .uolcc-button:focus::before, .js .uolcc-prompt-container .uol-pagination__item .uolcc-button:focus::before,
    .uol-pagination__item .js .uolcc-privacy-update-container .uolcc-button:focus::before, .js .uolcc-privacy-update-container .uol-pagination__item .uolcc-button:focus::before {
      border-color: #197ebe; }
    .uol-pagination__item .uol-button--primary:focus:not(:focus-visible)::before, .uol-pagination__item .js .uolcc-container .uolcc-button:focus:not(:focus-visible)::before, .js .uolcc-container .uol-pagination__item .uolcc-button:focus:not(:focus-visible)::before,
    .uol-pagination__item .js .uolcc-prompt-container .uolcc-button:focus:not(:focus-visible)::before, .js .uolcc-prompt-container .uol-pagination__item .uolcc-button:focus:not(:focus-visible)::before,
    .uol-pagination__item .js .uolcc-privacy-update-container .uolcc-button:focus:not(:focus-visible)::before, .js .uolcc-privacy-update-container .uol-pagination__item .uolcc-button:focus:not(:focus-visible)::before {
      border-color: transparent; }
    .uol-pagination__item .uol-button--primary:focus-visible::before, .uol-pagination__item .js .uolcc-container .uolcc-button:focus-visible::before, .js .uolcc-container .uol-pagination__item .uolcc-button:focus-visible::before,
    .uol-pagination__item .js .uolcc-prompt-container .uolcc-button:focus-visible::before, .js .uolcc-prompt-container .uol-pagination__item .uolcc-button:focus-visible::before,
    .uol-pagination__item .js .uolcc-privacy-update-container .uolcc-button:focus-visible::before, .js .uolcc-privacy-update-container .uol-pagination__item .uolcc-button:focus-visible::before {
      border-color: #197ebe; }
  @media (min-width: 25.875em) {
    .uol-pagination__item {
      margin: 0 0.25rem; } }

@media (max-width: 37.49em) {
  .js .uol-pagination__link {
    display: none; } }

.js .uol-pagination__link[aria-current] {
  display: inline-flex;
  background: transparent;
  color: #212529;
  border-color: transparent; }
  .js .uol-pagination__link[aria-current]:hover, .js .uol-pagination__link[aria-current]:focus {
    box-shadow: none; }
  .js .uol-pagination__link[aria-current]:focus {
    box-shadow: 0px 0px 0px 2px #ffffff, 0px 0px 0px 5px #197ebe;
    outline: 3px dotted transparent; }
  .js .uol-pagination__link[aria-current]:focus:not(:focus-visible) {
    box-shadow: none; }
  .js .uol-pagination__link[aria-current]:focus-visible {
    box-shadow: 0px 0px 0px 2px #ffffff, 0px 0px 0px 5px #197ebe; }
  @media (forced-colors: active) {
    .js .uol-pagination__link[aria-current] {
      border-color: Canvas;
      color: ButtonText; } }
  @media (-ms-high-contrast: active) {
    .js .uol-pagination__link[aria-current] {
      border-color: Window; } }

.js .uol-pagination__link::after {
  content: attr(data-number);
  display: block;
  width: 100%;
  height: 1em;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%); }

.uol-people-profile__card {
  position: relative;
  list-style: none;
  margin-bottom: 1rem;
  padding: 1.5rem 1rem 2rem;
  border: 1px solid #c9c8c5;
  border-radius: 6px;
  background-color: #f3f2f1;
  font-variant-numeric: lining-nums;
  color: #212529;
  overflow: auto; }
  .uol-people-profile__card:last-child {
    margin-bottom: 2rem; }
  @media (max-width: 319px) {
    .uol-people-profile__card {
      display: flex;
      flex-wrap: wrap; } }
  @media (min-width: 48em) {
    .uol-people-profile__card {
      padding: 1.5rem 1rem 2rem; } }
  @media (min-width: 64em) {
    .uol-people-profile__card {
      margin-bottom: 1.5rem;
      padding: 1.5rem 1.5rem 2rem; }
      .uol-people-profile__card:last-child {
        margin-bottom: 3rem; } }
  @media (min-width: 90em) {
    .uol-people-profile__card {
      margin-bottom: 2rem;
      padding: 1.5rem 2rem 2rem; } }
  .uol-people-profile__card .uol-info-list {
    margin: 0 0 0.5rem; }
  @media (max-width: 47.99em) {
    .uol-people-profile__card .uol-info-list-container {
      clear: both; } }
  .uol-people-profile__card .uol-info-list-container:last-of-type .uol-info-list {
    margin-bottom: 0; }
  .uol-people-profile__card .uol-info-list__group {
    margin-bottom: 0.5rem; }
    .uol-people-profile__card .uol-info-list__group:last-of-type {
      margin-bottom: 0; }

.uol-people-profile__card--image-layout .uol-info-list {
  width: 100%; }
  @media (min-width: 25.875em) {
    .uol-people-profile__card--image-layout .uol-info-list {
      clear: both; } }
  @media (min-width: 48em) {
    .uol-people-profile__card--image-layout .uol-info-list {
      clear: none;
      float: right;
      width: calc(100% - 10.75rem); } }
  @media (min-width: 64em) {
    .uol-people-profile__card--image-layout .uol-info-list {
      width: calc(100% - 9.375rem); } }
  @media (min-width: 90em) {
    .uol-people-profile__card--image-layout .uol-info-list {
      width: calc(100% - 12.75rem); } }

.uol-people-profile__name {
  margin: 0 0 0.25rem;
  font-family: freight-text-pro, Georgia, Cambria, "Times New Roman", Times, serif; }
  .uol-people-profile__card--image-layout .uol-people-profile__name {
    float: right; }
    @media (max-width: 319px) {
      .uol-people-profile__card--image-layout .uol-people-profile__name {
        width: 100%;
        margin-top: 1rem; } }
    @media (min-width: 320px) {
      .uol-people-profile__card--image-layout .uol-people-profile__name {
        margin-top: 1rem;
        width: calc(100% - 6.75rem); } }
    @media (min-width: 25.875em) {
      .uol-people-profile__card--image-layout .uol-people-profile__name {
        margin-top: 0;
        width: calc(100% - 8.25rem); } }
    @media (min-width: 48em) {
      .uol-people-profile__card--image-layout .uol-people-profile__name {
        width: calc(100% - 10.75rem); } }
    @media (min-width: 64em) {
      .uol-people-profile__card--image-layout .uol-people-profile__name {
        width: calc(100% - 9.375rem); } }
    @media (min-width: 90em) {
      .uol-people-profile__card--image-layout .uol-people-profile__name {
        width: calc(100% - 12.75rem); } }
  .uol-people-profile__name a {
    text-decoration: none; }
    .uol-people-profile__name a:hover, .uol-people-profile__name a:focus {
      text-decoration: underline;
      outline: 3px dotted transparent; }

.uol-people-profile__img {
  border-radius: 50%;
  width: 5.75rem;
  height: 5.75rem; }
  @media (max-width: 25.865em) {
    .uol-people-profile__img {
      order: -1;
      margin-bottom: 0.5rem; } }
  @media (min-width: 25.875em) {
    .uol-people-profile__img {
      float: left;
      width: 7.25rem;
      height: 7.25rem;
      margin-bottom: 1rem; } }
  @media (min-width: 48em) {
    .uol-people-profile__img {
      width: 9.75rem;
      height: 9.75rem; } }
  @media (min-width: 64em) {
    .uol-people-profile__img {
      width: 7.875rem;
      height: 7.875rem; } }
  @media (min-width: 90em) {
    .uol-people-profile__img {
      left: 2rem;
      width: 10.75rem;
      height: 10.75rem;
      margin-bottom: 0; } }

.uol-people-profile__position {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-family: freight-text-pro, Georgia, Cambria, "Times New Roman", Times, serif;
  color: #c70000; }
  @media (min-width: 25.875em) {
    .uol-people-profile__card--image-layout .uol-people-profile__position {
      float: right;
      width: calc(100% - 8.25rem); } }
  @media (min-width: 48em) {
    .uol-people-profile__card--image-layout .uol-people-profile__position {
      width: calc(100% - 10.75rem); } }
  @media (min-width: 64em) {
    .uol-people-profile__card--image-layout .uol-people-profile__position {
      width: calc(100% - 9.375rem); } }
  @media (min-width: 90em) {
    .uol-people-profile__card--image-layout .uol-people-profile__position {
      width: calc(100% - 12.75rem); } }

.uol-people-profile__footer {
  clear: both;
  padding: 1rem 0 0; }
  .uol-people-profile__footer:before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #c9c8c5; }

.uol-people-profile__footer__messages {
  display: block;
  padding-top: 1rem;
  margin-bottom: -1rem; }
  .js .uol-people-profile__footer__messages svg {
    height: 1.5em;
    width: 1.5em;
    min-width: 1.5em;
    margin-top: 0; }
  .uol-people-profile__footer__messages + .uol-people-profile__footer__actions {
    margin-top: 1rem; }

.uol-people-profile__footer__actions {
  margin-bottom: -1.5rem;
  padding-top: 1rem; }
  .uol-people-profile__footer__actions .uol-button, .uol-people-profile__footer__actions .js .uolcc-container .uolcc-button, .js .uolcc-container .uol-people-profile__footer__actions .uolcc-button,
  .uol-people-profile__footer__actions .js .uolcc-prompt-container .uolcc-button, .js .uolcc-prompt-container .uol-people-profile__footer__actions .uolcc-button,
  .uol-people-profile__footer__actions .js .uolcc-privacy-update-container .uolcc-button, .js .uolcc-privacy-update-container .uol-people-profile__footer__actions .uolcc-button, .uol-people-profile__footer__actions .uol-section-nav-ctas__link {
    margin: 0 1rem 1rem 0; }

.uol-people-profile-cards {
  padding: 0;
  margin: 0; }

.uol-profile-image {
  position: relative; }

.uol-profile-image__img {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  border-radius: 50%;
  background: 50% / cover; }
  @media (-ms-high-contrast: active), (forced-colors: active) {
    .uol-profile-image__img {
      -ms-high-contrast-adjust: none;
      forced-color-adjust: none; } }
  .uol-profile-image__img::before, .uol-profile-image__img::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit; }
  .uol-profile-image__img::before {
    background: 50% / cover;
    background-image: inherit; }
  .uol-profile-image__img::after {
    box-shadow: inset 0 2px 4px rgba(33, 33, 33, 0.2); }

.uol-profile-supplement {
  padding: 3rem 1rem; }
  @media (min-width: 48em) {
    .uol-profile-supplement {
      padding: 4rem 1rem; } }
  @media (min-width: 64em) {
    .uol-profile-supplement {
      padding: 5rem 1.5rem;
      display: flex;
      flex-wrap: wrap; } }
  .uol-article-container .uol-profile-supplement,
  .uol-course-container .uol-profile-supplement {
    margin: 0 0 1rem;
    padding: 0; }
  .uol-profile-supplement footer {
    padding-top: 0.5rem; }
    @media (min-width: 48em) {
      .uol-profile-supplement footer {
        padding-top: 1rem; } }

.uol-profile-supplement__title {
  position: relative;
  margin: 1.5rem 0 2rem;
  overflow-wrap: break-word; }
  @media (min-width: 90em) {
    .uol-profile-supplement__title {
      flex-basis: 100%; } }
  .uol-profile-supplement__title::before {
    content: "";
    position: absolute;
    display: inline-block;
    top: -1rem;
    left: 0;
    width: 1rem;
    height: 0.5rem;
    background-color: #c70000; }
    @media (min-width: 48em) {
      .uol-profile-supplement__title::before {
        width: 1.5rem; } }
    @media (min-width: 64em) {
      .uol-profile-supplement__title::before {
        width: 2rem;
        top: -1.5rem; } }

.uol-profile-supplement__body {
  position: relative; }
  @media (min-width: 64em) {
    .uol-profile-supplement__body {
      display: flex; }
      .uol-profile-supplement__body::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 6.5rem;
        background: #910000;
        border-radius: 6px 0 0 6px; } }

.uol-profile-supplement__image-container {
  box-sizing: border-box;
  position: relative;
  width: calc((100% + 1rem) / 12 * 5 - 1rem);
  min-width: 150px;
  padding: 1rem 0 1rem 2rem; }
  @media (min-width: 48em) {
    .uol-profile-supplement__image-container {
      width: calc((100% + 1rem) / 12 * 4 - 1rem); } }
  @media (min-width: 64em) {
    .uol-profile-supplement__image-container {
      width: initial;
      flex-basis: calc((100% + 1.5rem) / 9 * 3 - 1.5rem);
      padding: 1rem 0 1rem 1.5rem; } }
  @media (max-width: 63.99em) {
    .uol-profile-supplement__image-container::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: calc(50% - 1rem);
      background: #910000;
      border-radius: 6px 0 0 6px;
      z-index: -1; } }

.js .uol-profile-supplement__video-icon {
  position: absolute !important;
  right: 0;
  bottom: 0.25rem;
  width: 30%;
  background: #ff4e36;
  border: 3px solid #ffffff;
  border-radius: 50%;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1; }
  @media (min-width: 25.875em) {
    .js .uol-profile-supplement__video-icon {
      bottom: 0.5rem;
      width: 30%; } }
  @media (min-width: 37.5em) {
    .js .uol-profile-supplement__video-icon {
      width: 25%; } }
  @media (min-width: 48em) {
    .js .uol-profile-supplement__video-icon {
      bottom: 1rem; } }
  @media (min-width: 64em) {
    .js .uol-profile-supplement__video-icon {
      bottom: 10%; } }
  @media (min-width: 90em) {
    .js .uol-profile-supplement__video-icon {
      width: 20%;
      bottom: 2rem; } }
  .js .uol-profile-supplement__video-icon svg {
    width: 80% !important;
    height: 80% !important;
    position: absolute; }

.uol-typography-blockquote {
  padding: 0;
  margin-bottom: 2.75rem; }
  @media (min-width: 48em) {
    .uol-typography-blockquote {
      margin-bottom: 3.75rem; } }
  .uol-typography-blockquote p {
    margin-bottom: 1rem; }
  .uol-typography-blockquote::before {
    top: -1.5rem;
    left: -1.5rem; }

.uol-profile-supplement__text-container {
  margin: 3rem calc((100% + 1rem) / 12) 2rem; }
  @media (min-width: 64em) {
    .uol-profile-supplement__text-container {
      margin: 0;
      box-sizing: border-box;
      padding: 2rem 0 2rem 2rem;
      flex-basis: calc((100% + 1.5rem) / 9 * 6); } }

.uol-profile-supplement__link {
  display: block;
  font-weight: 600;
  text-decoration: none; }
  .uol-profile-supplement__link:hover, .uol-profile-supplement__link:focus {
    text-decoration: underline; }
  .uol-profile-supplement__link::before {
    content: "";
    display: block;
    position: relative;
    height: 0.25rem;
    top: -1rem;
    background: #eae8e6; }
  .uol-profile-supplement__link::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

.uol-publications-list {
  margin-bottom: 2rem; }
  @media (min-width: 48em) {
    .uol-publications-list {
      margin-bottom: 3rem; } }
  .uol-publications-list .uol-info-list {
    margin: 0; }
  .uol-publications-list .uol-info-list__data {
    float: none; }
  .uol-publications-list .uol-info-list__group {
    margin-top: 0.75rem; }

.uol-publications-list__publication-title {
  font-weight: 600;
  color: #910000; }

.uol-publications-list__content-group {
  margin-top: 0.75rem; }

.uol-publications-list__title {
  font-weight: 600; }

.uol-publications-list__category {
  font-weight: 600;
  color: #00323d; }

.uol-publications-list__link-container {
  margin-top: 0.75rem; }

.uol-publications-list__link-outer {
  margin-top: 0.75rem; }

.uol-publications-list__link {
  transition: background 0.15s ease-out, text-decoration 0.15s ease-out;
  display: inline-block;
  margin-top: 0; }
  .uol-publications-list__link:focus {
    color: #212529;
    background-color: #ffeded;
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-decoration-color: #ff4e36;
    text-underline-offset: 2px;
    outline: 2px dotted inherit;
    outline-offset: 1px;
    outline-color: transparent; }
  .uol-publications-list__link:focus:not(:focus-visible) {
    text-decoration: none;
    outline-color: transparent; }
  .uol-publications-list__link:focus-visible {
    color: #212529;
    outline-color: transparent;
    outline-offset: 1px;
    background-color: #ffeded; }
  .uol-publications-list__link:hover {
    text-decoration-color: #c70000; }

.uol-publications-list__further-link {
  transition: background 0.15s ease-out, text-decoration 0.15s ease-out;
  display: inline-block;
  margin-top: 0; }
  .uol-publications-list__further-link:focus {
    color: #212529;
    background-color: #ffeded;
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-decoration-color: #ff4e36;
    text-underline-offset: 2px;
    outline: 2px dotted inherit;
    outline-offset: 1px;
    outline-color: transparent; }
  .uol-publications-list__further-link:focus:not(:focus-visible) {
    text-decoration: none;
    outline-color: transparent; }
  .uol-publications-list__further-link:focus-visible {
    color: #212529;
    outline-color: transparent;
    outline-offset: 1px;
    background-color: #ffeded; }
  .uol-publications-list__further-link:hover {
    text-decoration: underline; }
  @media (min-width: 64em) {
    .uol-publications-list__further-link {
      margin-top: 0; } }

.uol-results-count__title {
  font-size: 1.125rem;
  font-weight: 500;
  font-variant-numeric: lining-nums;
  text-align: left; }
  @media (min-width: 48em) {
    .uol-results-count__title {
      font-size: 1.25rem; } }

.uol-results-items {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.uol-results-items__item {
  box-sizing: border-box;
  margin-bottom: 1rem;
  box-shadow: 0;
  transition: box-shadow 0.25s ease-in-out; }
  @media (min-width: 64em) {
    .uol-results-items__item {
      margin-bottom: 1.5rem; } }
  @media (min-width: 90em) {
    .uol-results-items__item {
      margin-bottom: 2rem; } }

.uol-results-items__item__featured {
  color: #ffffff;
  display: flex;
  border-bottom: 2px solid #212121; }
  @media (min-width: 48em) {
    .uol-results-items__item__featured {
      flex-direction: row; } }

.uol-results-items__item__featured__text {
  padding: 0.5rem 1rem;
  background: #212121;
  color: #ffffff; }

.uol-results-items__item__content-wrapper {
  border: 1px solid rgba(201, 200, 197, 0.6);
  border-top: 0;
  transition: box-shadow 0.25s ease-in-out; }
  .uol-results-items__item__content-wrapper:hover, .uol-results-items__item__content-wrapper:focus-within {
    box-shadow: 0 15px 25px rgba(10, 2, 2, 0.15), 0 5px 10px rgba(10, 2, 2, 0.05); }
  @media (min-width: 48em) {
    .uol-results-items__item__content-wrapper {
      border: 1px solid rgba(201, 200, 197, 0.6);
      display: flex;
      gap: 0 16px;
      background: #f3f2f1;
      padding: 1.5rem 1rem 1rem 1rem;
      box-shadow: 0;
      transition: box-shadow 0.25s ease-in-out;
      position: relative; } }
  @media (min-width: 64em) {
    .uol-results-items__item__content-wrapper {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      gap: 0 24px;
      padding-bottom: 0.875rem; } }
  @media (min-width: 90em) {
    .uol-results-items__item__content-wrapper {
      gap: 0 32px; } }

.uol-results-items__item__text-container {
  background: #f3f2f1;
  padding: 1.5rem 1rem 1rem 1rem; }
  @media (max-width: 48em) {
    .uol-results-items__item__text-container {
      position: relative; } }
  @media (min-width: 48em) {
    .uol-results-items__item__text-container {
      padding-top: 0;
      border: 0;
      background: none;
      padding: 0;
      margin: 0;
      flex-basis: 100%; } }

@media (min-width: 37.5em) {
  .uol-results-items__item__text-container__inner {
    max-width: 470px; } }

@media (min-width: 48em) {
  .uol-results-items__item__text-container__inner {
    max-width: 610px; } }

@media (min-width: 64em) {
  .uol-results-items__item__text-container__inner {
    max-width: 564px; } }

@media (min-width: 90em) {
  .uol-results-items__item__text-container__inner {
    max-width: 674px; } }

@media (min-width: 103.75em) {
  .uol-results-items__item__text-container__inner {
    max-width: 784px; } }

.uol-results-items__item__title {
  margin: 0 0 0.5rem; }

.uol-results-items__item__link {
  /*
      Incorrect heading sizes in DS. Hard code these for now.
      */
  font-size: 1.375rem;
  text-decoration: none; }
  @media (min-width: 48em) {
    .uol-results-items__item__link {
      font-size: 1.75rem; } }
  .uol-results-items__item__link::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .uol-results-items__item__link:hover, .uol-results-items__item__link:focus {
    text-decoration: underline; }

.uol-results-items__item__highlight {
  margin-top: 0;
  margin-bottom: 0.5rem;
  display: block;
  color: #c70000; }

.uol-results-items__item__meta {
  font-weight: 500;
  margin: 0 0 0.5rem; }
  .uol-results-items__item__meta:last-child {
    margin-bottom: 1rem; }

.uol-results-items__item__meta__group {
  margin-bottom: 0.5rem;
  line-height: 1.5rem; }

.uol-results-items__item__meta + p {
  margin-top: 0px; }

.uol-results-items__item__meta__label {
  display: inline;
  font-weight: 600; }
  .uol-results-items__item__meta__label::after {
    content: ":"; }

.uol-results-items__item__meta__data {
  margin: 0;
  display: inline; }
  .uol-results-items__item__meta__data::after {
    content: "";
    display: inline-block;
    position: relative;
    width: 1px;
    height: 0.8em;
    background-color: #c70000;
    margin-left: 0.3em;
    margin-right: 0.15em;
    top: 0.1em; }
  .uol-results-items__item__meta__data:last-of-type::after {
    content: none; }

.uol-results-items__item__meta__group--has-additional .uol-results-items__item__meta__data::after {
  content: none; }

.uol-results-items__item__meta__group--has-additional .uol-results-items__item__meta__additional-data {
  margin: 0.5rem 0 0 0; }

@media (min-width: 48em) {
  .uol-results-items__item__image-container {
    max-width: 220px;
    flex-basis: 100%; } }

@media (min-width: 64em) {
  .uol-results-items__item__image-container {
    max-width: 290px; } }

@media (min-width: 103.75em) {
  .uol-results-items__item__image-container {
    max-width: 344px; } }

.uol-results-items__item__img {
  display: block;
  width: 100%;
  height: auto; }
  @media (min-width: 48em) {
    .uol-results-items__item__img {
      margin-bottom: 16px; } }
  @media (min-width: 64em) {
    .uol-results-items__item__img {
      margin-bottom: 18px; } }
  .uol-results-items__item__img:hover, .uol-results-items__item__img:focus {
    cursor: pointer; }

.uol-results-items__item__summary,
.uol-results-items__item__introduction {
  margin-top: 0; }

.uol-results-items__item__introduction {
  margin-bottom: 0.5rem; }

/*
Below CSS needed to make results items in course pages look as they should
TODO: replace course content results items with new component
*/
.uol-course__content .uol-results-items .uol-results-items__item__title {
  margin-top: 0; }

.uol-course__content .uol-results-items .uol-results-items__item__meta {
  font-size: 16px; }
  @media (min-width: 64em) {
    .uol-course__content .uol-results-items .uol-results-items__item__meta {
      font-size: 18px; } }

.uol-course__content .uol-results-items .uol-results-items__item__meta__label {
  font-weight: 600; }

.uol-course__content .uol-results-items .uol-results-items__item__content-wrapper {
  border: 1px solid rgba(201, 200, 197, 0.6);
  padding: 0; }

.uol-course__content .uol-results-items .uol-results-items__item__text-container {
  padding: 0.75rem 1rem 0.25rem; }
  @media (min-width: 64em) {
    .uol-course__content .uol-results-items .uol-results-items__item__text-container {
      padding: 0.75rem 1.5rem 0.5rem; } }

.uol-course__content .uol-results-items .uol-results-items__item {
  padding: 0; }

.uol-section-nav-ctas {
  font-family: freight-sans-pro, "San Francisco", Roboto, "Helvetica Neue", Helvetica, "Noto Sans", "Segoe UI", Arial, sans-serif;
  border-top: 4px solid #c9c8c5;
  margin: 2rem 1rem; }
  @media (min-width: 48em) {
    .uol-section-nav-ctas {
      margin: 3rem 1rem 3rem; } }
  @media (min-width: 64em) {
    .uol-section-nav-ctas {
      margin: 3rem 0; } }

.uol-section-nav-ctas__title {
  font-size: 1.125rem;
  color: #0a0202;
  margin-bottom: 0;
  font-weight: 600; }

.uol-section-nav-ctas__links {
  margin: 0;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: start;
  align-content: start; }

@media (min-width: 25.875em) and (max-width: 63.99em) {
  .uol-section-nav-ctas__links {
    margin-right: -16px; } }
  @media (max-width: 63.99em) {
    .uol-section-nav-ctas__links {
      flex-direction: row; } }

.uol-section-nav-ctas__link {
  position: relative;
  outline: 0 dotted #197ebe;
  outline-offset: 3px;
  overflow: visible;
  margin-bottom: 2rem;
  padding: 1.125rem 1.375rem 1.4125rem;
  width: 100%; }
  .uol-section-nav-ctas__link::before {
    content: "";
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    border: 3px solid transparent;
    border-radius: inherit;
    transition: all 0.15s; }
    @media (-ms-high-contrast: active), (forced-colors: active) {
      .uol-section-nav-ctas__link::before {
        display: none; } }
  @media (-ms-high-contrast: active), (forced-colors: active) {
    .uol-section-nav-ctas__link:focus {
      outline-width: 3px; } }
  .uol-section-nav-ctas__link:focus::before {
    border-color: #197ebe; }
  .uol-section-nav-ctas__link:focus:not(:focus-visible)::before {
    border-color: transparent; }
  .uol-section-nav-ctas__link:focus-visible::before {
    border-color: #197ebe; }
  @media (min-width: 25.875em) {
    .uol-section-nav-ctas__link {
      width: unset;
      margin: 2rem 2rem 0 0; } }

.uol-section-nav-related-content {
  margin: 2rem 1rem;
  border-top: 4px solid #c9c8c5;
  font-size: 1.125rem; }
  @media (min-width: 48em) {
    .uol-section-nav-related-content {
      margin: 3rem 1rem; } }
  @media (min-width: 25.875em) {
    .uol-section-nav-related-content {
      margin-left: 1rem;
      margin-right: 1rem; } }
  @media (min-width: 48em) {
    .uol-section-nav-related-content {
      margin-left: 0;
      margin-right: 0; } }

.uol-section-nav-related-content__title {
  margin: 0.75rem 0 2rem;
  font-weight: 600;
  font-size: 1.125rem; }

@media (min-width: 37.5em) and (max-width: 63.99em) {
  .uol-section-nav-related-content__list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between; } }

.uol-section-nav-related__item {
  box-sizing: border-box;
  padding: 1.5rem 1.5rem 2rem;
  margin-bottom: 1rem;
  border: 2px solid #c9c8c5;
  border-radius: 10px;
  line-height: 1.556; }

@media (min-width: 37.5em) and (max-width: 63.99em) {
  .uol-section-nav-related__item {
    flex-basis: calc(50% - 0.5rem); } }
  @media (min-width: 64em) {
    .uol-section-nav-related__item {
      margin-bottom: 2rem; } }

.uol-section-nav-related-content__item__title {
  margin: 0 0 0.5rem;
  line-height: 1.5;
  color: #212529;
  font-weight: 600;
  font-size: 1.25rem; }

.uol-section-nav-related-content__item__title__link {
  text-decoration: none; }
  .uol-section-nav-related-content__item__title__link svg {
    display: inline-block;
    fill: #c70000;
    position: relative;
    top: 0.3rem;
    left: 0;
    margin-left: 0.5em;
    transition: left 0.3s ease 0.2s; }
    @media (forced-colors: active) {
      .uol-section-nav-related-content__item__title__link svg {
        fill: WindowText; } }
    @media (-ms-high-contrast: active) {
      .uol-section-nav-related-content__item__title__link svg {
        fill: CanvasText; } }
  .uol-section-nav-related-content__item__title__link:hover {
    text-decoration: underline; }
    .uol-section-nav-related-content__item__title__link:hover svg {
      left: 0.4em; }

.uol-section-nav-related-content__item__text {
  margin: 0;
  color: #3b3b3b;
  font-size: 1.125rem; }

.uol-section-nav-related-content__item__contact-wrapper {
  font-variant-numeric: lining-nums; }
  .uol-section-nav-related-content__item__contact-wrapper a {
    display: flex; }
    .uol-section-nav-related-content__item__contact-wrapper a span {
      width: calc(100% - 35px);
      overflow-wrap: break-word;
      padding-top: 3px; }
  .uol-section-nav-related-content__item__contact-wrapper:not(:last-of-type) {
    padding-bottom: 1rem; }
  .uol-section-nav-related-content__item__text + .uol-section-nav-related-content__item__contact-wrapper {
    margin-top: 0.5rem; }
  .uol-section-nav-related-content__item__contact-wrapper svg {
    fill: #5f5754;
    margin-right: 0.5rem;
    position: relative;
    top: 0.35rem; }
    @media (forced-colors: active) {
      .uol-section-nav-related-content__item__contact-wrapper svg {
        fill: WindowText; } }
    @media (-ms-high-contrast: active) {
      .uol-section-nav-related-content__item__contact-wrapper svg {
        fill: CanvasText; } }
  .uol-section-nav-related-content__item__contact-wrapper a:hover {
    text-decoration-color: #c70000; }

.uol-section-nav-related-content--coursefinder {
  margin-top: -1.75rem; }

@media (min-width: 48em) and (max-width: 63.99em) {
  .uol-section-nav-related-content--coursefinder {
    margin-top: -0.563rem;
    margin-left: calc((100% / 12) + 0.9rem);
    margin-right: calc((100% / 12) + 0.9rem); } }
  @media (min-width: 64em) {
    .uol-section-nav-related-content--coursefinder {
      margin-top: 0; } }

.uol-content-switch-from-side-to-main {
  margin-left: 1rem;
  margin-right: 1rem; }
  @media (min-width: 64em) {
    .uol-content-switch-from-side-to-main {
      margin-left: 0;
      margin-right: 0; } }

.uol-section-nav {
  margin: 0; }
  @media (min-width: 48em) {
    .uol-section-nav {
      margin: 0; } }
  @media (min-width: 64em) {
    .uol-section-nav {
      margin: 1rem 0 0; } }
  @media (min-width: 64em) {
    .uol-header .js .uol-section-nav {
      display: none;
      padding-left: 0.5rem; } }

@media (min-width: 64em) {
  .js .uol-section-nav--relocated {
    display: block; } }

.uol-article-container .uol-section-nav--relocated {
  padding-left: 0.5rem; }

@media (max-width: 63.99em) {
  .uol-section-nav__title {
    margin: 0; } }

@media (min-width: 64em) {
  .uol-section-nav__title {
    margin-top: 0.5rem;
    margin-bottom: 1rem; } }

.uol-section-nav__title__intro {
  display: none; }
  @media (min-width: 64em) {
    .uol-section-nav__title__intro {
      display: block;
      font-size: 1rem; } }

.uol-section-nav__title__text {
  display: none; }
  @media (min-width: 64em) {
    .uol-section-nav__title__text {
      display: block;
      font-size: 1.5rem; } }

.uol-section-nav__toggle {
  box-sizing: border-box;
  width: 100%;
  padding: 1rem;
  background: #c70000;
  color: #ffffff;
  font-size: 1.125rem;
  text-align: left;
  border: none;
  box-shadow: 0 10px 20px 0 rgba(10, 2, 2, 0.15), 0 3px 6px 0 rgba(10, 2, 2, 0.1); }
  .uol-section-nav__toggle[aria-expanded="true"] {
    box-shadow: none; }
  @media (min-width: 64em) {
    .uol-section-nav__toggle {
      display: none; } }

.uol-section-nav__toggle__icon {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 2px;
  top: -6px;
  margin-right: 1rem;
  background: #ffffff;
  transition: background 0.3s ease; }
  .uol-section-nav__toggle__icon::before, .uol-section-nav__toggle__icon::after {
    content: "";
    display: block;
    position: absolute;
    width: inherit;
    height: inherit;
    background: #ffffff;
    transition: all 0.3s ease; }
  .uol-section-nav__toggle__icon::before {
    top: -6px; }
  .uol-section-nav__toggle__icon::after {
    top: 6px; }
  .uol-section-nav__toggle[aria-expanded="true"] .uol-section-nav__toggle__icon {
    background: transparent; }
    .uol-section-nav__toggle[aria-expanded="true"] .uol-section-nav__toggle__icon::before {
      top: 0;
      transform: rotate(-45deg); }
    .uol-section-nav__toggle[aria-expanded="true"] .uol-section-nav__toggle__icon::after {
      top: 0;
      transform: rotate(45deg); }

.js .uol-section-nav__list {
  font-size: 1.125rem;
  list-style: none; }
  @media (min-width: 48em) {
    .js .uol-section-nav__list {
      font-size: 1.25rem; } }
  @media (max-width: 63.99em) {
    .js .uol-section-nav__list {
      margin: 0;
      padding: 0.5rem 1rem 2rem 3rem;
      box-shadow: 0 10px 20px 0 rgba(10, 2, 2, 0.15), 0 3px 6px 0 rgba(10, 2, 2, 0.1);
      display: none; } }
  @media (min-width: 64em) {
    .js .uol-section-nav__list {
      border-left: 0.25rem solid #c70000;
      padding-left: 2rem; } }

.js .uol-section-nav__list--expanded {
  display: block; }

.js .uol-section-nav__sublist {
  list-style: none;
  padding: 0 0 0 1.5rem;
  display: none; }

button[aria-expanded="true"] + .uol-section-nav__sublist {
  display: block; }

.no-js .uol-section-nav__item__label {
  margin: 0; }

.js .uol-section-nav__link, .js
.uol-section-nav__branch-toggle {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  color: #212529;
  border: none;
  padding: 0.75rem 0.25rem 0.5rem;
  margin-left: -0.25rem;
  text-align: left;
  text-decoration: none;
  transition: background 0.15s ease-in; }
  .js .uol-section-nav__link:hover, .js .uol-section-nav__link:focus, .js
  .uol-section-nav__branch-toggle:hover, .js
  .uol-section-nav__branch-toggle:focus {
    outline: 2px dotted transparent;
    text-decoration: underline;
    text-underline-offset: 6px;
    text-decoration-color: #c70000;
    text-decoration-thickness: 4px; }
  .js .uol-section-nav__link:focus-visible, .js
  .uol-section-nav__branch-toggle:focus-visible {
    background-color: #ffeded; }
  .js .uol-section-nav__link[aria-current]:not(:focus), .js
  .uol-section-nav__branch-toggle[aria-current]:not(:focus) {
    color: #c70000; }
    .js .uol-section-nav__link[aria-current]:not(:focus):not(:hover), .js
    .uol-section-nav__branch-toggle[aria-current]:not(:focus):not(:hover) {
      text-decoration: underline; }

.uol-section-nav__branch-toggle {
  overflow: visible; }
  .uol-section-nav__branch-toggle svg {
    position: absolute;
    left: -2rem;
    top: 0.75rem;
    transition: transform 0.1s ease;
    fill: #0e0c0c; }
  .uol-section-nav__branch-toggle[aria-expanded="true"] svg {
    transform: rotate(90deg) translateX(6.25%); }

.uol-side-nav-container {
  display: none; }

.uol-side-nav-container--populated {
  display: block; }

.uol-skip-link {
  font-size: 1rem;
  line-height: 1.556;
  position: absolute;
  white-space: nowrap;
  left: 1rem;
  top: 0;
  transform: translateY(-100%);
  padding: 0.5rem 1rem;
  text-decoration: none;
  outline: 2px dotted transparent;
  background-color: #e76f16;
  color: #0e0c0c;
  border-bottom: 2px solid #212121;
  z-index: 2;
  transition: all 0.2s; }
  @media (min-width: 48em) {
    .uol-skip-link {
      font-size: 1.125rem; } }
  @media (min-width: 64em) {
    .uol-skip-link {
      font-size: 1.25rem; } }
  @media (min-width: 48em) {
    .uol-skip-link {
      line-height: 1.556; } }
  @media (min-width: 64em) {
    .uol-skip-link {
      line-height: 1.6; } }
  @media (min-width: 64em) {
    .uol-skip-link {
      padding: 0.5rem 1.5rem;
      left: 1.5rem; } }
  @media (min-width: 90em) {
    .uol-skip-link {
      left: 2rem; } }
  @media (prefers-reduced-motion) {
    .uol-skip-link {
      transition: none; } }

.uol-skip-link:focus {
  transform: translateY(0);
  top: 1rem; }
  @media (min-width: 64em) {
    .uol-skip-link:focus {
      top: 1.5rem; } }

.uol-status-message {
  display: block;
  color: #0e0c0c; }
  .uol-status-message + .uol-status-message {
    margin-top: 0.5rem; }
  .uol-status-message strong {
    font-weight: 500; }
  .uol-status-message svg path {
    fill: #0a0202; }
    @media screen and (forced-colors: active) {
      .uol-status-message svg path {
        fill: CanvasText; } }
  .uol-status-message svg + .uol-icon__label {
    margin-left: 0.25rem; }

.uol-tiles {
  display: flex;
  flex-wrap: wrap; }

.uol-tile {
  position: relative;
  color: #ffffff;
  width: 100%;
  min-height: 11.2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  background-clip: padding-box;
  margin: 0 0 0.5rem; }
  @media (min-width: 25.875em) {
    .uol-tile {
      min-height: 13rem; } }
  @media (min-width: 37.5em) {
    .uol-tile {
      min-height: 18.75rem; } }
  @media (min-width: 48em) {
    .uol-tile {
      margin: 0;
      border: 0.25rem solid #ffffff;
      min-height: 16rem;
      width: calc(100% / 3 * 2); } }
  @media (min-width: 64em) {
    .uol-tile {
      min-height: 17rem; } }
  @media (min-width: 90em) {
    .uol-tile {
      width: 50%;
      min-height: 20rem; } }

@media (min-width: 48em) {
  .uol-tile--small,
  .uol-tile--facts {
    width: calc(100% / 3); } }

@media (min-width: 90em) {
  .uol-tile--small,
  .uol-tile--facts {
    width: 25%; } }

.csspositionsticky .uol-tile--img {
  background-color: #212121; }

.uol-tile--no-img {
  background-color: #212121; }
  .uol-tile--no-img:nth-of-type(6n+2) {
    background: #910000; }
  .uol-tile--no-img:nth-of-type(6n+3) {
    background: #c70000; }
  .uol-tile--no-img:nth-of-type(6n+4) {
    background: #0a0202; }
  .uol-tile--no-img:nth-of-type(6n+5) {
    background: #5f5754; }
  .uol-tile--no-img:nth-of-type(6n+6) {
    background: #00323d; }
  @media (max-width: 47.99em) {
    .uol-tile--no-img {
      min-height: 7rem; } }
  .uol-tile--no-img:hover::after, .uol-tile--no-img:focus-within::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 4px solid #ff4e36; }
  .uol-tile--no-img.uol-tile--facts:hover::after, .uol-tile--no-img.uol-tile--facts:focus-within::after {
    content: none; }

.uol-tile--profile {
  justify-content: flex-start;
  background: #e9e1d8;
  color: #212529;
  transition: background 0.3s ease; }
  .uol-tile--profile.uol-tile--no-img {
    background: #e9e1d8; }
  @media (min-width: 48em) {
    .uol-tile--profile {
      width: 100%; } }
  @media (min-width: 90em) {
    .uol-tile--profile {
      width: 75%; } }

.uol-tile__inner {
  box-sizing: border-box;
  padding: 1rem;
  z-index: 1; }
  @media (max-width: 25.865em) {
    .uol-tile__inner {
      padding: 0.5rem; } }
  @media (min-width: 48em) {
    .uol-tile__inner {
      padding: 1.5rem; } }
  @media (max-width: 25.865em) {
    .uol-tile--img .uol-tile__inner {
      padding: 0;
      display: flex;
      flex-direction: column-reverse; } }
  @media (-ms-high-contrast: active), (forced-colors: active) {
    .uol-tile--img .uol-tile__inner {
      -ms-high-contrast-adjust: none;
      forced-color-adjust: CanvasText; } }
  @media (min-width: 48em) {
    .uol-tile--profile .uol-tile__inner {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: calc(100% / 3 * 2); } }

.uol-tile--img .uol-tile__text-container {
  text-shadow: 0 0 50px #0a0202;
  background: #212121;
  padding: 0.5rem; }
  @media (max-width: 25.865em) {
    .uol-tile--img .uol-tile__text-container {
      border: solid #0a0202;
      border-width: 0 4px 4px 4px;
      padding: 0.25rem; } }
  @media (min-width: 25.875em) {
    .uol-tile--img .uol-tile__text-container {
      background: rgba(33, 33, 33, 0.8);
      margin-left: -0.5rem;
      margin-right: -0.5rem;
      margin-bottom: -0.5rem; } }
  @media (min-width: 48em) {
    .uol-tile--img .uol-tile__text-container {
      margin-left: -1rem;
      margin-right: -1rem;
      margin-bottom: -1rem; } }

@media (max-width: 25.865em) {
  .uol-tile--img:hover .uol-tile__text-container {
    border-color: #ff4e36; } }

@media (max-width: 47.99em) {
  .uol-tile--small.uol-tile--video .uol-tile__text-container,
  .uol-tile--no-img.uol-tile--video .uol-tile__text-container {
    margin-right: 3rem; } }

.uol-tile__title {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 800;
  margin: 0; }
  @media (min-width: 48em) {
    .uol-tile__title {
      font-size: 1.5rem; } }
  @media (min-width: 64em) {
    .uol-tile__title {
      font-size: 1.75rem; } }
  @media (min-width: 48em) {
    .uol-tile__title {
      line-height: 2.25rem; } }
  @media (min-width: 64em) {
    .uol-tile__title {
      line-height: 2.375rem; } }

.uol-tile__subtitle {
  display: none;
  margin: 0.5rem 0 0; }
  @media (min-width: 48em) {
    .uol-tile__subtitle {
      display: block;
      margin-bottom: 0.75rem; } }

.uol-tile__icon {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0; }

.uol-tile:focus-within .uol-tile__icon--video::after,
.uol-tile:hover .uol-tile__icon--video::after {
  transform: scale(1.2);
  transition: transform 0.5s ease; }

.uol-tile__link {
  text-decoration: none;
  color: inherit;
  z-index: 4; }
  .uol-tile__link:hover, .uol-tile__link:focus {
    text-decoration: underline;
    text-decoration-color: #ff4e36; }
    .uol-tile--no-img .uol-tile__link:hover, .uol-tile--no-img .uol-tile__link:focus {
      text-decoration-color: #ffffff; }
  .uol-tile__link::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

.uol-tile__img {
  background-position: 50%;
  background-size: cover;
  z-index: -1; }
  @media (max-width: 25.865em) {
    .uol-tile__img {
      display: block;
      padding-bottom: 50%; } }
  @media (min-width: 25.875em) {
    .uol-tile__img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; } }
  .uol-tile__img::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1; }
  .uol-tile--img:hover .uol-tile__img::after,
  .uol-tile--img:focus-within .uol-tile__img::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 4px solid #ff4e36;
    z-index: 1; }

.uol-tile__profile-image {
  position: relative;
  width: 8rem;
  height: 8rem;
  background-position: 50%;
  background-size: cover;
  margin: 0.75rem 1rem;
  border-radius: 4px;
  box-sizing: border-box; }
  @media (-ms-high-contrast: active), (forced-colors: active) {
    .uol-tile__profile-image {
      -ms-high-contrast-adjust: none;
      forced-color-adjust: none; } }
  @media (min-width: 25.875em) {
    .uol-tile__profile-image {
      margin-left: 2rem; } }
  @media (min-width: 37.5em) {
    .uol-tile__profile-image {
      margin: -3.7rem 3rem 0.75rem auto; } }
  @media (min-width: 48em) {
    .uol-tile__profile-image {
      margin: 0;
      position: absolute;
      height: initial;
      top: 0;
      right: 0;
      bottom: 0;
      width: calc(100% / 3 - 1px);
      border-radius: 0; }
      .uol-tile:hover .uol-tile__profile-image::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: solid #ff4e36;
        border-width: 4px 4px 4px 0; } }
  .uol-tile__profile-image::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.4);
    border-radius: inherit; }

.uol-tile__profile-blockquote {
  margin: 0;
  padding: 2rem 0 0 1rem;
  display: flex;
  flex-direction: column; }
  @media (min-width: 25.875em) {
    .uol-tile__profile-blockquote {
      padding-left: 2rem; } }
  @media (min-width: 37.5em) {
    .uol-tile__profile-blockquote {
      padding-left: 3rem;
      padding-right: 2rem; } }
  @media (min-width: 48em) {
    .uol-tile__profile-blockquote {
      padding-left: 1rem; } }
  @media (min-width: 64em) {
    .uol-tile__profile-blockquote {
      padding-left: 0; } }

.uol-tile__profile-quote::before {
  content: "\201C";
  font-size: 600%;
  height: 1ex;
  display: block;
  position: absolute;
  top: -0.22em;
  left: 0.15em;
  color: #ff4e36; }
  @media (-ms-high-contrast: active), (forced-colors: active) {
    .uol-tile__profile-quote::before {
      forced-color-adjust: none; } }
  @media (min-width: 37.5em) {
    .uol-tile__profile-quote::before {
      left: 0.4em; } }
  @media (min-width: 48em) {
    .uol-tile__profile-quote::before {
      left: 0.16em; } }

.uol-tile__profile-quote p {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-family: freight-text-pro, Georgia, Cambria, "Times New Roman", Times, serif; }
  .uol-tile__profile-quote p:first-of-type {
    margin-top: 0; }
  .uol-tile__profile-quote p:last-of-type {
    margin-bottom: 0; }

.uol-tile__profile-detail {
  margin-bottom: 0;
  padding-left: 1rem; }
  @media (min-width: 25.875em) {
    .uol-tile__profile-detail {
      padding-left: 2rem; } }
  @media (min-width: 37.5em) {
    .uol-tile__profile-detail {
      padding-left: 3rem;
      max-width: calc(100% - 14rem); } }
  @media (min-width: 48em) {
    .uol-tile__profile-detail {
      max-width: none;
      padding-left: 1rem; } }
  @media (min-width: 64em) {
    .uol-tile__profile-detail {
      padding-left: 0; } }

.uol-tile__profile-name {
  display: block;
  font-weight: 800;
  color: #636363; }

.uol-tile__profile-role,
.uol-tile__profile-position {
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #636363; }

.uol-tile__profile-role + .uol-tile__profile-position::before {
  content: "";
  display: inline-block;
  height: 1.2em;
  width: 1px;
  position: relative;
  top: 0.28em;
  background: #ff4e36;
  margin-left: 0.2em;
  margin-right: 0.35em; }

.uol-tile__profile-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background: rgba(10, 2, 2, 0); }
  @media (-ms-high-contrast: active) {
    .uol-tile__profile-link {
      background: none; } }
  .uol-tile__profile-link:focus::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 4px solid #ff4e36;
    z-index: 1; }

.uol-tile--facts {
  background: #212121;
  text-align: center; }
  .uol-tile--facts.uol-tile--no-img {
    background: #212121; }
  .uol-tile--facts .uol-tile__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center; }

.uol-widget-container {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  margin-bottom: 1rem;
  width: 100%; }
  .uol-col-debug .uol-widget-container {
    background: rgba(255, 105, 180, 0.3); }
  .uol-widget-container:last-of-type {
    margin-bottom: 3rem; }
    @media (min-width: 48em) {
      .uol-widget-container:last-of-type {
        margin-bottom: 5rem; } }
    @media (min-width: 64em) {
      .uol-widget-container:last-of-type {
        margin-bottom: 6rem; } }
  @media (min-width: 64em) {
    .uol-side-nav-container--populated + .uol-homepage-content .uol-widget-container {
      padding-left: 0;
      padding-right: 0; } }
  @media (min-width: 90em) {
    .uol-side-nav-container--populated + .uol-homepage-content .uol-widget-container {
      padding-left: 0.25rem;
      padding-right: 0.25rem; } }

.uol-widget {
  position: relative;
  margin-top: 1rem;
  padding: 0 !important;
  flex-basis: 100% !important; }
  @media (max-width: 63.99em) {
    .uol-widget {
      max-width: none; } }
  @media (min-width: 64em) {
    .uol-widget {
      margin-top: 3rem; } }

.uol-widget--cards {
  margin-top: 0;
  width: 100%; }

.uol-widget--ctas,
.uol-widget--content,
.uol-widget--featured-content,
.uol-widget--form,
.uol-widget--news-events,
.uol-widget--news-tiles,
.uol-widget--events,
.uol-widget--description-list,
.uol-widget--accreditation-logos,
.uol-widget--wide {
  max-width: none; }

@media (max-width: 47.99em) {
  .uol-widget--ctas {
    padding-top: 3rem !important; } }

@media (max-width: 63.99em) {
  .uol-widget--featured-content {
    border-bottom: 0.5rem solid #ffeded;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem; } }

@media (min-width: 64em) {
  .uol-widget--featured-content {
    display: flex;
    flex: 2 1 auto;
    flex-basis: auto;
    align-items: flex-start; } }

.uol-widget--ctas,
.uol-widget--form {
  margin-top: 0;
  padding-bottom: 0; }

.uol-side-nav-container--populated + .uol-homepage-content .uol-widget {
  flex-basis: 100%;
  max-width: none;
  padding: 0 !important; }

.uol-widget__head {
  position: relative;
  box-sizing: border-box;
  padding-bottom: 1.5rem;
  display: flex;
  flex-direction: column; }
  .no-csspositionsticky .uol-widget__head {
    padding: 3px; }
  @media (min-width: 48em) {
    .uol-widget__head {
      margin: 0; } }
  @media (min-width: 64em) {
    .no-csspositionsticky .uol-widget__head {
      padding: 0; } }
  @media (min-width: 90em) {
    .uol-widget__head {
      margin: 0; } }
  .uol-widget__head > * {
    max-width: 52rem; }
  @media (min-width: 64em) {
    .uol-widget--featured-content .uol-widget__head {
      padding: 1rem 1rem 3rem 0; }
      .uol-widget--featured-content .uol-widget__head::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 4rem;
        background: #ffeded;
        border-radius: 0 0 0 6px;
        z-index: -1; } }
  .uol-widget--news-events .uol-widget__head {
    padding-bottom: 0; }
  .uol-widget--list-featured .uol-widget__head {
    padding-bottom: 1rem; }
    @media (min-width: 48em) {
      .uol-widget--list-featured .uol-widget__head {
        padding-bottom: 1.5rem; } }
    @media (min-width: 64em) {
      .uol-widget--list-featured .uol-widget__head {
        padding-bottom: 2rem; } }

.uol-widget__title {
  position: relative;
  margin: 2rem 0 0;
  overflow-wrap: break-word; }
  @media (max-width: 63.99em) {
    .uol-widget__title {
      margin-top: 3rem; } }
  .uol-widget__title::before {
    content: "";
    position: absolute;
    display: inline-block;
    top: -1rem;
    left: 0;
    width: 1rem;
    height: 0.5rem;
    background-color: #c70000; }
    @media (min-width: 48em) {
      .uol-widget__title::before {
        width: 1.5rem; } }
    @media (min-width: 64em) {
      .uol-widget__title::before {
        width: 2rem;
        top: -1.5rem; } }
  .uol-widget--featured-content .uol-widget__title {
    max-width: none;
    margin: 0; }
    @media (max-width: 63.99em) {
      .uol-widget--featured-content .uol-widget__title {
        border-radius: 6px 6px 0 0;
        padding: 3rem 1rem 2rem;
        background: #ffeded; }
        .uol-widget--featured-content .uol-widget__title::before {
          top: 2rem;
          left: 1rem; } }
    @media (min-width: 64em) {
      .uol-widget--featured-content .uol-widget__title {
        margin-top: 0.5rem; } }

.uol-widget__lead {
  padding-top: 0.5rem; }
  @media (min-width: 64em) {
    .uol-widget__lead {
      padding-top: 1rem; } }
  @media (max-width: 63.99em) {
    .uol-widget--featured-content .uol-widget__lead {
      padding-left: 1rem;
      padding-right: 1rem; } }
  @media (max-width: 63.99em) {
    .uol-widget--featured-content .uol-widget__lead {
      padding-top: 0.5rem; } }

.uol-widget__head p,
.uol-widget__lead p {
  font-size: 1.125rem; }
  @media (min-width: 48em) {
    .uol-widget__head p,
    .uol-widget__lead p {
      font-size: 1.375rem; } }
  @media (min-width: 64em) {
    .uol-widget__head p,
    .uol-widget__lead p {
      font-size: 1.5rem; } }

.uol-widget__head p:first-of-type,
.uol-widget__lead p:first-of-type {
  margin-top: 1rem; }

.uol-widget__head:last-child,
.uol-widget__head p:last-of-type,
.uol-widget__lead:last-child,
.uol-widget__lead p:last-of-type {
  margin-bottom: 0; }

.uol-widget__content {
  margin: 1rem 0 0;
  clear: both; }
  .uol-widget--cards .uol-widget__content {
    overflow-x: initial; }
  @media (min-width: 48em) {
    .uol-widget__content {
      margin: 1rem 0 0; } }
  @media (min-width: 64em) {
    .uol-widget--featured-content .uol-widget__content {
      margin-top: 0;
      width: calc(100% / 3 * 2);
      padding: 0 2rem 1rem;
      border-right: 4px solid #ffeded; }
      .uol-side-nav-container--populated + .uol-homepage-content .uol-widget--featured-content .uol-widget__content {
        width: 60%; } }
  .uol-widget--list-featured .uol-widget__content {
    margin-top: 0; }
  .uol-widget--form .uol-widget__content {
    margin-top: 0.5rem; }

@media (max-width: 63.99em) {
  .uol-widget__content__text {
    padding-left: 1rem;
    padding-right: 1rem; } }

.uol-widget__link {
  transition: background 0.15s ease-out, text-decoration 0.15s ease-out;
  align-self: flex-start;
  font-size: 1.25rem;
  font-weight: 500;
  margin-top: 1rem;
  padding-right: 0.2em;
  color: inherit;
  text-decoration: none;
  text-decoration-color: rgba(255, 255, 255, 0);
  transition: text-decoration-color 0.3s ease; }
  .uol-widget__link:focus {
    color: #212529;
    background-color: #ffeded;
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-decoration-color: #ff4e36;
    text-underline-offset: 1px;
    outline: 2px dotted inherit;
    outline-offset: 1px;
    outline-color: transparent; }
  .uol-widget__link:focus:not(:focus-visible) {
    text-decoration: none;
    outline-color: transparent; }
  .uol-widget__link:focus-visible {
    color: #212529;
    outline-color: transparent;
    outline-offset: 1px;
    background-color: #ffeded; }
  @media (min-width: 48em) {
    .uol-widget__link {
      font-weight: 600; } }
  @media (max-width: 63.99em) {
    .uol-widget__link {
      margin-top: 1.5rem; } }

@media (min-width: 64em) {
  .uol-widget--featured-content .uol-widget__left-col {
    width: calc(100% / 3); } }

.uol-widget__arrow-links {
  list-style-type: none;
  margin: 0;
  padding: 0; }
  @media (min-width: 64em) {
    .uol-widget--featured-content .uol-widget__arrow-links {
      margin-top: 1.5rem; } }

.uol-widget__arrow-links-item {
  margin: 0 0 1.5rem 0; }
  .uol-widget--featured-content .uol-widget__arrow-links-item {
    margin: 0 1rem 1.5rem 1rem; }
    @media (min-width: 64em) {
      .uol-widget--featured-content .uol-widget__arrow-links-item {
        margin: 0 0 1rem 0; } }

.uol-widget__arrow-link {
  font-weight: 600;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: inline-block; }

.uol-year-of-entry {
  font-variant-numeric: lining-nums;
  margin-bottom: 0.5rem; }

.uol-year-of-entry__title {
  font-weight: 600;
  margin: 0.5rem 0;
  color: #0e0c0c; }

.uol-year-of-entry__date {
  font-weight: 500;
  color: #212529; }

.uol-year-of-entry__link {
  display: inline-block;
  font-weight: 500;
  text-decoration: none; }

.uol-article-container {
  padding: 0 0.5rem;
  margin: 0.5rem 0 4rem; }
  @media (min-width: 48em) {
    .uol-article-container {
      margin: 0.75rem 0 5rem;
      padding: 0 1rem; } }
  @media (min-width: 64em) {
    .uol-article-container {
      margin: 0.75rem 0 6rem;
      padding: 0 1rem; }
      .uol-article-container .uol-side-nav-container--populated {
        padding: 0.75rem 14px 0.75rem 0.5rem; } }
  @media (min-width: 90em) {
    .uol-article-container {
      margin: 1rem 0 5rem;
      padding: 0 1rem; }
      .uol-article-container .uol-side-nav-container--populated {
        padding: 1rem; } }
  @media (min-width: 64em) {
    .uol-article-container .uol-side-nav-container--populated + .uol-article {
      max-width: calc(100% / 12 * 8 - 2rem);
      flex-basis: calc(100% / 12 * 8 - 2rem);
      padding-left: calc(100% / 12 + 1rem) !important; } }
  @media (min-width: 64em) {
    .uol-article-container .uol-side-nav-container--populated + .uol-article .uol-article__head,
    .uol-article-container .uol-side-nav-container--populated + .uol-article .uol-article__content {
      margin-right: 0;
      margin-left: 0; } }

.uol-article {
  padding-bottom: 0 !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  max-width: calc(100% - 1rem);
  position: relative;
  flex-basis: calc(100% - 1rem);
  width: calc(100% - 1rem);
  padding: 0.5rem; }
  @media (min-width: 48em) {
    .uol-article {
      padding-left: 4px !important;
      padding-right: 4px !important; } }
  @media (min-width: 64em) {
    .uol-article {
      padding-left: 8px !important;
      padding-right: 8px !important; } }
  @media (min-width: 90em) {
    .uol-article {
      padding-left: 16px !important;
      padding-right: 16px !important; } }
  .uol-col-debug .uol-article {
    background: rgba(161, 161, 239, 0.2); }
  @media (min-width: 37.5em) {
    .uol-article > * {
      margin-left: calc(100% / 12 + 0.0625rem);
      margin-right: calc(100% / 12 + 0.0625rem); } }
  @media (min-width: 64em) {
    .uol-article > * {
      margin-left: calc(100% / 9 + 0.25rem);
      margin-right: calc(100% / 9 + 0.25rem); } }
  @media (min-width: 90em) {
    .uol-article > * {
      margin-right: calc((100% / 9 * 2) + 0.5rem); } }
  @media (min-width: 90em) {
    .uol-article > * {
      margin-right: calc(100% / 9 + 0.25rem); } }
  @media (min-width: 64em) {
    .uol-article {
      max-width: calc(100% - 1.75rem); } }
  @media (min-width: 90em) {
    .uol-article {
      max-width: calc(100% - 2rem); } }
  @media (min-width: 64em) {
    .uol-article .uol-article__head,
    .uol-article .uol-article__content {
      margin-right: calc(100% / 12 * 3);
      margin-left: calc(100% / 12 + 0.25rem); } }
  @media (min-width: 90em) {
    .uol-article .uol-article__head,
    .uol-article .uol-article__content {
      margin-right: calc(100% / 12 * 3 + 0.5rem);
      margin-left: calc(100% / 12 + 0.25rem); } }
  .uol-article .uol-rich-text > *:last-child {
    margin-bottom: 0; }
  @media (min-width: 64em) {
    .uol-article .uol-rich-text {
      max-width: inherit;
      margin-right: calc(100% / 8 * 1 + 0.125rem); } }
  @media (min-width: 90em) {
    .uol-article .uol-rich-text {
      margin-right: calc(100% / 8 * 2 + 0.5rem); } }
  .uol-article .uol-rich-text img:not(.uol-logo-group-item__logo) {
    max-width: none; }
  @media (min-width: 48em) {
    .uol-article .uol-rich-text figure {
      margin-top: 3rem; } }
  @media (min-width: 48em) {
    .uol-article .uol-rich-text .uol-gallery figure {
      margin-top: 0; } }
  .uol-article .uol-rich-text .text-highlight p {
    margin-bottom: 0; }
  .uol-article .uol-rich-text blockquote footer {
    margin-bottom: 1rem; }
  .uol-article .uol-rich-text--with-lead > p:first-of-type {
    margin-top: 1.5rem;
    margin-bottom: 1rem; }
    @media (min-width: 48em) {
      .uol-article .uol-rich-text--with-lead > p:first-of-type {
        position: relative;
        margin-top: 2.5rem;
        margin-bottom: 1.5rem; }
        .uol-article .uol-rich-text--with-lead > p:first-of-type::before {
          content: "";
          display: inline-block;
          position: absolute;
          width: calc(100% / 12 - 0.25rem);
          height: 0.25rem;
          top: 0.65em;
          left: -18px;
          transform: translateX(-100%);
          background-color: #c70000; } }
    @media (min-width: 64em) {
      .uol-article .uol-rich-text--with-lead > p:first-of-type::before {
        width: calc(100% / 7 - 20px);
        left: -28px; } }
    @media (min-width: 90em) {
      .uol-article .uol-rich-text--with-lead > p:first-of-type::before {
        width: calc(100% / 6 - 28px);
        left: -36px; } }
  @media (min-width: 64em) {
    .uol-side-nav-container--populated + .uol-article .uol-typography-pull-quote {
      margin-right: 0; } }
  @media (min-width: 90em) {
    .uol-side-nav-container--populated + .uol-article .uol-typography-pull-quote {
      position: absolute;
      max-width: calc(100% / 9 * 2 - 2rem);
      right: 1rem; } }
  .uol-article blockquote {
    margin-bottom: 1.5rem; }
    @media (min-width: 48em) {
      .uol-article blockquote {
        margin-bottom: 2rem; } }
  .uol-article .uol-typography-pull-quote {
    display: block; }
  .uol-article ul, .uol-article ol {
    margin: 2rem 0; }
    @media (min-width: 48em) {
      .uol-article ul, .uol-article ol {
        margin: 3rem 0; } }
  .uol-article .uol-typography-pull-quote {
    margin: 2rem 0; }
    @media (min-width: 48em) {
      .uol-article .uol-typography-pull-quote {
        margin: 3rem 0; } }
    @media (min-width: 90em) {
      .uol-article .uol-typography-pull-quote {
        margin: 0 0 3rem; } }
  .uol-article blockquote + *, .uol-article .uol-typography-pull-quote + * {
    margin-top: 0; }
  @media (max-width: 25.875em) {
    .uol-article .uol-featured-image {
      margin-right: -1rem;
      margin-left: -1rem;
      max-width: none; } }

@media (min-width: 25.885em) and (max-width: 37.49em) {
  .uol-article .uol-featured-image {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
    max-width: none; } }

@media (min-width: 37.5em) and (max-width: 47.99em) {
  .uol-article .uol-featured-image {
    margin-right: -13%;
    margin-left: -13%; } }
  .uol-article .uol-table-outer {
    margin: 1rem 0 2rem; }
    @media (min-width: 64em) {
      .uol-article .uol-table-outer {
        margin: 1rem 0 3rem; } }
    .uol-article .uol-table-outer .uol-table-container {
      max-width: none; }
    .uol-article .uol-table-outer table {
      margin-bottom: 0; }
  .uol-article img {
    display: block;
    margin: 2rem 0; }
    @media (min-width: 48em) {
      .uol-article img {
        margin: 3rem 0; } }
  .uol-article .uol-typography-pull-quote {
    box-sizing: border-box;
    width: 100%;
    max-width: 100%; }
    .uol-col-debug .uol-article .uol-typography-pull-quote {
      background: rgba(111, 243, 115, 0.2); }
    @media (min-width: 90em) {
      .uol-article .uol-typography-pull-quote {
        float: none;
        position: absolute;
        right: calc(1rem + 100%/12 * 3);
        max-width: calc(100% / 12 * 2 - 2rem); } }
  .uol-article .uol-info-list-container {
    margin: 2rem 0; }
    @media (min-width: 48em) {
      .uol-article .uol-info-list-container {
        margin: 3rem 0; } }
  .uol-article .uol-publications-list .uol-info-list-container {
    margin: 0; }
  .uol-article .uol-featured-image {
    margin: 2rem 0; }
    @media (min-width: 48em) {
      .uol-article .uol-featured-image {
        margin: 3rem 0; } }
  .uol-article .uol-featured-image__img, .uol-article .uol-featured-image img {
    margin: 0; }
  .uol-article .uol-cards__card__image {
    margin: 0; }
  .uol-article .uol-info-list {
    margin: 0; }
  .uol-article .uol-accordion__title {
    margin: 0 !important; }

.uol-article--news .page-heading__title {
  margin-bottom: 1rem; }

.uol-article--news .page-heading__meta__group:first-child {
  margin-bottom: 0.5rem; }

.uol-col-debug .uol-article--event .uol-info-list-container {
  background: rgba(111, 243, 115, 0.2); }

@media (min-width: 90em) {
  .uol-article--event .uol-info-list {
    margin: 0; } }

.uol-article--profile {
  margin-top: 8px; }
  @media (min-width: 48em) {
    .uol-article--profile {
      margin-top: 16px; } }
  @media (min-width: 64em) {
    .uol-article--profile {
      margin-top: 24px; } }
  .uol-article--profile .page-heading__title:before {
    top: -24px; }
    @media (min-width: 48em) {
      .uol-article--profile .page-heading__title:before {
        top: -32px; } }
  .uol-article--profile .uol-article__head {
    flex-direction: column; }
    .uol-article--profile .uol-article__head .uol-article__no-profile-image {
      width: 100%; }
      @media (min-width: 37.5em) {
        .uol-article--profile .uol-article__head .uol-article__no-profile-image {
          margin-left: calc((100% + 1rem) / 11 * 1); } }
      @media (min-width: 48em) {
        .uol-article--profile .uol-article__head .uol-article__no-profile-image {
          margin-left: calc((100% + 1rem) / 11 * 1); } }
      @media (min-width: 64em) {
        .uol-article--profile .uol-article__head .uol-article__no-profile-image {
          margin-left: calc((100% + 1.5rem + 8px) / 9 * 2); } }
      @media (min-width: 90em) {
        .uol-article--profile .uol-article__head .uol-article__no-profile-image {
          margin-left: calc((100% + 2rem + 8px) / 9 * 3); } }
    @media (min-width: 37.5em) {
      .uol-article--profile .uol-article__head {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 0;
        margin-right: 0; } }
    .uol-article--profile .uol-article__head .uol-article__head__image-container {
      background: linear-gradient(90deg, #910000 72px, rgba(255, 255, 255, 0) 0);
      padding: 16px 0 16px 32px;
      border-radius: 6px;
      margin-bottom: 48px;
      width: 192px; }
      @media (min-width: 37.5em) {
        .uol-article--profile .uol-article__head .uol-article__head__image-container {
          padding-right: 0;
          margin-bottom: 0;
          margin-right: 16px; } }
      @media (min-width: 48em) {
        .uol-article--profile .uol-article__head .uol-article__head__image-container {
          margin-left: -4px;
          margin-right: 16px;
          width: 204px; } }
      @media (min-width: 64em) {
        .uol-article--profile .uol-article__head .uol-article__head__image-container {
          margin-left: 0;
          margin-right: 24px;
          padding-right: 0; } }
      @media (min-width: 90em) {
        .uol-article--profile .uol-article__head .uol-article__head__image-container {
          background: linear-gradient(90deg, #910000 96px, rgba(255, 255, 255, 0) 0);
          padding: 0 0 0 32px;
          margin-right: 0;
          margin-left: calc((100% + 1.5rem + 8px) / 9 * 1);
          width: inherit; } }
      .uol-article--profile .uol-article__head .uol-article__head__image-container .uol-profile-image .uol-profile-image__img {
        width: 192px;
        height: 192px;
        padding-bottom: 0; }
        @media (min-width: 48em) {
          .uol-article--profile .uol-article__head .uol-article__head__image-container .uol-profile-image .uol-profile-image__img {
            width: 204px;
            height: 204px; } }
        @media (min-width: 64em) {
          .uol-article--profile .uol-article__head .uol-article__head__image-container .uol-profile-image .uol-profile-image__img {
            width: 196px;
            height: 196px;
            margin-right: 1.5rem; } }
        @media (min-width: 90em) {
          .uol-article--profile .uol-article__head .uol-article__head__image-container .uol-profile-image .uol-profile-image__img {
            width: 266px;
            height: 266px;
            margin-right: 2rem; } }
        @media (min-width: 103.75em) {
          .uol-article--profile .uol-article__head .uol-article__head__image-container .uol-profile-image .uol-profile-image__img {
            width: 311px;
            height: 311px; } }
  .uol-article--profile .uol-article__head {
    margin-right: calc((100% + 1rem + 8px) / 12 * 1 - 8px); }
    @media (min-width: 64em) {
      .uol-article--profile .uol-article__head {
        margin-right: calc((100% + 1.5rem + 8px) / 12 * 3 - 8px); } }
    @media (min-width: 90em) {
      .uol-article--profile .uol-article__head {
        margin-right: calc((100% + 2rem + 8px) / 12 * 3 - 4px); } }
  @media (min-width: 64em) {
    .uol-article--profile .uol-article__content {
      margin-right: calc((100% + 1.5rem + 8px) / 12 * 3 - 8px);
      margin-left: calc((100% + 1.5rem + 8px) / 12 * 2); } }
  @media (min-width: 90em) {
    .uol-article--profile .uol-article__content {
      margin-right: calc((100% + 2rem + 8px) / 12 * 3 - 4px);
      margin-left: calc((100% + 2rem + 8px) / 12 * 3); } }
  .uol-article--profile .uol-article__content .uol-info-list-container {
    padding-bottom: 48px;
    border-bottom: 0.25rem solid #c70000; }
  .uol-article--profile .uol-article__content .uol-publications-list .uol-info-list-container {
    padding-bottom: 0;
    border-bottom: none; }
  .uol-article--profile .uol-article-section {
    margin-right: 0; }
    .uol-article--profile .uol-article-section .uol-rich-text {
      margin-right: 0; }
    .uol-article--profile .uol-article-section h1,
    .uol-article--profile .uol-article-section h2,
    .uol-article--profile .uol-article-section h3,
    .uol-article--profile .uol-article-section h4,
    .uol-article--profile .uol-article-section h5,
    .uol-article--profile .uol-article-section h6 {
      margin: 2rem 0 0.5rem; }
      @media (min-width: 48em) {
        .uol-article--profile .uol-article-section h1,
        .uol-article--profile .uol-article-section h2,
        .uol-article--profile .uol-article-section h3,
        .uol-article--profile .uol-article-section h4,
        .uol-article--profile .uol-article-section h5,
        .uol-article--profile .uol-article-section h6 {
          margin: 3rem 0 1rem; } }
    @media (min-width: 90em) {
      .uol-article--profile .uol-article-section {
        display: flex;
        flex-direction: row;
        margin-left: calc(((100% + 2rem + 8px) / 6 * 3 ) * -1);
        margin-top: 3rem; }
        .uol-article--profile .uol-article-section .uol-article-section__content {
          width: calc(((100% + 2rem) / 9 * 6 ) * 1 - 2rem); }
          .uol-article--profile .uol-article-section .uol-article-section__content .uol-accordion:first-child {
            margin-top: 0; }
        .uol-article--profile .uol-article-section .uol-article-section__title {
          margin-right: 0; } }
    @media (min-width: 90em) {
      .uol-article--profile .uol-article-section .uol-article-section__title {
        width: calc((100% + 2rem + 8px) / 9 * 3 - 2rem);
        margin-right: 2rem;
        margin-top: 0;
        text-align: right; } }

.uol-article--person-insight .uol-article__head {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.25rem solid #c70000;
  margin-bottom: 2rem; }
  @media (min-width: 48em) {
    .uol-article--person-insight .uol-article__head {
      margin-bottom: 3rem; } }

@media (min-width: 37.5em) {
  .uol-article--person-insight .uol-article__head__heading-text {
    min-height: calc(24px + 144px + 32px); } }

@media (min-width: 48em) {
  .uol-article--person-insight .uol-article__head__heading-text {
    min-height: calc(24px + 192px + 32px); } }

@media (min-width: 64em) {
  .uol-article--person-insight .uol-article__head__heading-text {
    min-height: calc(24px + 192px + 48px); } }

@media (min-width: 90em) {
  .uol-article--person-insight .uol-article__head__heading-text {
    min-height: calc(24px + 288px + 48px); } }

.uol-article--person-insight .page-heading {
  width: 100%;
  margin-bottom: 0; }
  .uol-article--person-insight .page-heading .page-heading__meta__group {
    margin-bottom: 0; }

.uol-article--person-insight .uol-info-list {
  margin: 32px 0; }

.uol-article--person-insight .uol-profile-image {
  width: 96px;
  top: 1.5rem;
  margin-left: 1rem; }
  @media (min-width: 37.5em) {
    .uol-article--person-insight .uol-profile-image {
      width: 144px; } }
  @media (min-width: 48em) {
    .uol-article--person-insight .uol-profile-image {
      width: 192px; } }
  @media (min-width: 90em) {
    .uol-article--person-insight .uol-profile-image {
      width: 288px; } }

.uol-rich-text :is(h1, h2, h3, h4, h5, h6) + ol,
.uol-rich-text :is(h1, h2, h3, h4, h5, h6) + ul {
  margin-top: 0.5rem !important; }

.uol-rich-text :is(h1, h2, h3, h4, h5, h6) + .uol-logo-group,
.uol-rich-text :is(h1, h2, h3, h4, h5, h6) + .uol-in-text-facts__container,
.uol-rich-text :is(h1, h2, h3, h4, h5, h6) + .uol-in-text-cta,
.uol-rich-text :is(h1, h2, h3, h4, h5, h6) + .uol-accordion,
.uol-rich-text :is(h1, h2, h3, h4, h5, h6) + .uol-image-pair,
.uol-rich-text :is(h1, h2, h3, h4, h5, h6) + .text-highlight,
.uol-rich-text :is(h1, h2, h3, h4, h5, h6) + .fluid-width-video-wrapper,
.uol-rich-text :is(h1, h2, h3, h4, h5, h6) + figure:not(.uol-gallery__figure) {
  margin-top: 1.5rem !important; }
  @media (min-width: 48em) {
    .uol-rich-text :is(h1, h2, h3, h4, h5, h6) + .uol-logo-group,
    .uol-rich-text :is(h1, h2, h3, h4, h5, h6) + .uol-in-text-facts__container,
    .uol-rich-text :is(h1, h2, h3, h4, h5, h6) + .uol-in-text-cta,
    .uol-rich-text :is(h1, h2, h3, h4, h5, h6) + .uol-accordion,
    .uol-rich-text :is(h1, h2, h3, h4, h5, h6) + .uol-image-pair,
    .uol-rich-text :is(h1, h2, h3, h4, h5, h6) + .text-highlight,
    .uol-rich-text :is(h1, h2, h3, h4, h5, h6) + .fluid-width-video-wrapper,
    .uol-rich-text :is(h1, h2, h3, h4, h5, h6) + figure:not(.uol-gallery__figure) {
      margin-top: 2rem !important; } }

.uol-rich-text :is(h1, h2, h3, h4, h5, h6) + blockquote {
  margin-top: 40px; }
  @media (min-width: 48em) {
    .uol-rich-text :is(h1, h2, h3, h4, h5, h6) + blockquote {
      margin-top: 44px !important; } }

.uol-rich-text :is(h1, h2, h3, h4, h5, h6) + .uol-table-outer {
  margin-top: 1.5rem !important; }
  @media (min-width: 25.875em) {
    .uol-rich-text :is(h1, h2, h3, h4, h5, h6) + .uol-table-outer {
      margin-top: 0.5rem !important; } }
  @media (min-width: 48em) {
    .uol-rich-text :is(h1, h2, h3, h4, h5, h6) + .uol-table-outer {
      margin-top: 1rem !important; } }

.uol-article .uol-rich-text p:last-child {
  margin-bottom: 0; }

.uol-article .uol-accordion p:last-child {
  margin-bottom: 1rem; }

.uol-article .uol-accordion p:last-child {
  margin-bottom: 1rem; }

.uol-article .uol-profile-supplement {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 2rem;
  max-width: auto; }
  @media (min-width: 48em) {
    .uol-article .uol-profile-supplement {
      padding-top: 3rem; } }
  @media (min-width: 64em) {
    .uol-article .uol-profile-supplement {
      margin-right: calc(100% / 12 * 3);
      margin-left: 0; } }
  @media (min-width: 90em) {
    .uol-article .uol-profile-supplement {
      margin-right: calc(100% / 12 * 3 + 0.5rem); } }
  .uol-article .uol-profile-supplement .uol-profile-supplement__title {
    margin-top: 1rem; }
    @media (min-width: 64em) {
      .uol-article .uol-profile-supplement .uol-profile-supplement__title {
        margin-top: 1.5rem; } }
  .uol-article .uol-profile-supplement .uol-profile-supplement__text-container {
    margin-bottom: 0; }

@media (min-width: 64em) {
  .uol-side-nav-container--populated + .uol-article .uol-profile-supplement {
    margin-right: -0.5rem;
    margin-left: calc(-100% / 12 * 2 + 3%); } }

@media (min-width: 90em) {
  .uol-side-nav-container--populated + .uol-article .uol-profile-supplement {
    margin-right: 0;
    margin-left: calc(-100% / 12 * 2 + 4%); } }

.uol-article__widgets {
  margin-left: 0;
  margin-right: 0;
  margin-top: 2rem; }
  @media (min-width: 48em) {
    .uol-article__widgets {
      margin-top: 3rem; } }
  @media (min-width: 64em) {
    .uol-article__widgets {
      margin-right: calc(100% / 12 * 4);
      margin-left: 0; } }
  @media (min-width: 90em) {
    .uol-article__widgets {
      margin-right: calc(100% / 12 * 3 + 0.5rem);
      margin-left: 0; } }
  .uol-article__widgets .uol-widget-container {
    margin-bottom: 0; }
  .uol-article__widgets .uol-widget {
    margin-top: 0; }
    .uol-article__widgets .uol-widget .uol-widget__title {
      margin-top: 1rem; }
      @media (min-width: 64em) {
        .uol-article__widgets .uol-widget .uol-widget__title {
          margin-top: 1.5rem; } }
    .uol-article__widgets .uol-widget .uol-cards,
    .uol-article__widgets .uol-widget .uol-widget__content {
      margin-top: 0; }
    .uol-article__widgets .uol-widget .uol-cards {
      margin-bottom: 0; }
      .uol-article__widgets .uol-widget .uol-cards .uol-cards__card:last-of-type {
        margin-bottom: 0; }
      @media (min-width: 90em) {
        .uol-article__widgets .uol-widget .uol-cards .uol-cards__card {
          margin-bottom: 0; } }
    .uol-article__widgets .uol-widget .uol-cards__card__image {
      margin: 0; }
    @media (min-width: 90em) {
      .uol-article__widgets .uol-widget .event-card-outer {
        width: 33.333333333333333%; } }

.uol-article .uol-image-pair {
  margin: 2rem 0; }
  @media (min-width: 48em) {
    .uol-article .uol-image-pair {
      margin: 3rem 0; } }
  .uol-article .uol-image-pair .uol-image-pair__column {
    margin: 0; }

.uol-article .uol-form__container {
  margin-top: 2rem; }
  @media (min-width: 48em) {
    .uol-article .uol-form__container {
      margin-top: 3rem; } }
  @media (max-width: 47.99em) {
    .uol-article .uol-form__container {
      margin-bottom: 1.5rem; } }
  .uol-article .uol-form__container .uol-form__input-container {
    margin-bottom: 1rem; }
    @media (min-width: 64em) {
      .uol-article .uol-form__container .uol-form__input-container {
        margin-bottom: 1.5rem; } }
  .uol-article .uol-form__container .uol-typeahead__list {
    margin: 19px 0;
    padding-left: 0; }

.uol-article .uol-info-list-container {
  margin: 0; }

.uol-article .uol-gallery-container {
  margin: -0.5rem 0 -1rem; }
  @media (min-width: 48em) {
    .uol-article .uol-gallery-container {
      margin: -1rem 0; } }

.uol-article .uol-iframe {
  margin-top: -0.5rem; }
  @media (min-width: 48em) {
    .uol-article .uol-iframe {
      margin-top: 0; } }

@media (min-width: 48em) {
  .uol-article .uol-page-nav {
    margin-top: 2.75rem; } }

@media (min-width: 64em) {
  .uol-article .uol-page-nav {
    margin-bottom: 2.5rem; } }

.uol-site-footer-outer {
  background-color: #212121; }

.uol-site-footer {
  display: block;
  color: #fffbf5;
  overflow: auto; }
  .uol-site-footer > * {
    float: left; }
  .uol-site-footer a {
    transition: color 0.3s ease; }
    .uol-site-footer a:hover, .uol-site-footer a:focus {
      color: #ff4e36;
      text-decoration: underline; }
    .uol-site-footer a[aria-current] {
      color: #ff4e36; }

@media (min-width: 25.875em) {
  .uol-site-footer__nav-container .uol-site-footer__nav-outer {
    margin: -1rem; } }

@media (min-width: 64em) {
  .uol-site-footer__nav-container .uol-site-footer__nav-outer {
    margin: -1.5rem; } }

@media (min-width: 90em) {
  .uol-site-footer__nav-container .uol-site-footer__nav-outer {
    margin: -2rem; } }

.uol-site-footer__address-container {
  align-self: flex-start; }

.uol-site-footer__accreditations {
  margin: 1rem 0;
  width: 18rem; }

@media (min-width: 37.5em) and (max-width: 47.99em) {
  .uol-site-footer__accreditations {
    width: 16.5rem; } }
  @media (min-width: 64em) {
    .uol-site-footer__accreditations {
      width: 49.5rem;
      margin: 0; } }
  @media (min-width: 90em) {
    .uol-site-footer__accreditations {
      margin-top: 0.5rem; } }

@media (min-width: 37.5em) and (max-width: 47.99em) {
  .uol-site-footer__accreditations {
    width: 16.5rem; } }
  @media (min-width: 64em) {
    .uol-site-footer__accreditations {
      width: 49.5rem;
      margin: 0; } }
  @media (min-width: 90em) {
    .uol-site-footer__accreditations {
      margin-top: 0.5rem; } }
  @media (min-width: 64em) {
    .uol-site-footer__accreditations {
      height: auto; } }

.uol-site-footer__site-information-container {
  position: relative; }
  @media (min-width: 48em) {
    .uol-site-footer__site-information-container {
      min-height: 5.25rem; } }
  @media (min-width: 64em) {
    .uol-site-footer__site-information-container {
      min-height: 6.25rem; } }
  .uol-site-footer__site-information-container .footer-site-information__list {
    margin-bottom: 2rem; }
    @media (min-width: 48em) {
      .uol-site-footer__site-information-container .footer-site-information__list {
        position: absolute;
        margin: 0;
        bottom: 0.5rem;
        left: 0.5rem;
        right: 0.5rem; } }
    @media (min-width: 64em) {
      .uol-site-footer__site-information-container .footer-site-information__list {
        bottom: 0.75rem;
        left: 0.75rem;
        right: 0.75rem; } }
    @media (min-width: 90em) {
      .uol-site-footer__site-information-container .footer-site-information__list {
        bottom: 1rem;
        left: 1rem;
        right: 1rem; } }

.uol-site-footer__logo {
  display: block;
  width: 272px;
  max-width: 100%;
  float: right; }
  @media (min-width: 64em) {
    .uol-site-footer__logo {
      width: 327px; } }
  .uol-site-footer__logo svg {
    width: 272px;
    height: 78.77px; }
    @media (min-width: 64em) {
      .uol-site-footer__logo svg {
        width: 327px;
        height: 94.7px; } }
    .uol-site-footer__logo svg path {
      fill: #fffbf5;
      transition: fill 0.3s ease; }

.uol-homepage-content-container {
  overflow: hidden;
  padding: 2rem 1rem 0;
  display: flex; }
  @media (max-width: 63.99em) {
    .uol-homepage-content-container {
      display: block; } }

.uol-homepage-content {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-top: 0; }

@media (min-width: 48em) and (max-width: 63.99em) {
  .uol-homepage-content {
    padding-left: 0;
    padding-right: 0; } }

@media (min-width: 48em) and (max-width: 63.99em) {
  .uol-homepage-content {
    padding-left: 0;
    padding-right: 0; } }

@media (min-width: 64em) and (max-width: 89.99em) {
  .uol-homepage-content {
    padding-left: 0.25rem; } }

.uol-search-results__page-heading {
  padding-bottom: 0; }
  .uol-search-results__page-heading .page-heading {
    margin-bottom: 1rem; }
    @media (max-width: 25.865em) {
      .uol-search-results__page-heading .page-heading {
        padding-left: 0.5rem;
        padding-top: 0.5rem; } }
    @media (min-width: 48em) {
      .uol-search-results__page-heading .page-heading {
        margin-bottom: 2rem; } }

.uol-search--no-form .page-heading {
  margin-bottom: 0.625rem; }
  @media (min-width: 25.875em) {
    .uol-search--no-form .page-heading {
      margin-bottom: 0.125rem; } }
  @media (min-width: 48em) {
    .uol-search--no-form .page-heading {
      margin-bottom: 0.938rem; } }
  @media (min-width: 64em) {
    .uol-search--no-form .page-heading {
      margin-bottom: 1.688rem; } }
  @media (min-width: 90em) {
    .uol-search--no-form .page-heading {
      margin-bottom: 1.188rem; } }

.uol-search-results__content-area {
  padding: 0 0.5rem; }
  .site-outer .uol-search-results__content-area {
    padding-top: 0;
    margin-top: -0.438rem; }
    @media (min-width: 25.875em) {
      .site-outer .uol-search-results__content-area {
        padding-bottom: 0;
        margin-top: -0.938rem; } }
    @media (min-width: 48em) {
      .site-outer .uol-search-results__content-area {
        padding-bottom: 1rem;
        margin-top: -1.5rem; } }
    @media (min-width: 64em) {
      .site-outer .uol-search-results__content-area {
        padding-bottom: 1.75rem;
        margin-top: -1.563rem; } }
    @media (min-width: 90em) {
      .site-outer .uol-search-results__content-area {
        padding-bottom: 1rem;
        margin-top: -1.813rem; } }
  @media (min-width: 64em) {
    .site-outer .uol-search--has-map-content .uol-search-results__content-area {
      padding-bottom: 1.25rem; } }
  @media (min-width: 90em) {
    .site-outer .uol-search--has-map-content .uol-search-results__content-area {
      padding-bottom: 0.75rem; } }
  .site-outer .uol-search--no-form .uol-search-results__content-area {
    margin-bottom: -0.938rem;
    padding-top: 0.375rem; }
    @media (min-width: 25.875em) {
      .site-outer .uol-search--no-form .uol-search-results__content-area {
        padding-top: 0.875rem; } }
    @media (min-width: 48em) {
      .site-outer .uol-search--no-form .uol-search-results__content-area {
        margin-bottom: -1.063rem;
        padding-top: 1rem; } }
    @media (min-width: 64em) {
      .site-outer .uol-search--no-form .uol-search-results__content-area {
        margin-bottom: 0;
        padding-top: 0.313rem; } }
    @media (min-width: 90em) {
      .site-outer .uol-search--no-form .uol-search-results__content-area {
        padding-top: 0.813rem; } }

.uol-search-results__main {
  position: relative;
  flex-basis: calc(100% - 1rem);
  width: calc(100% - 1rem);
  padding: 0.5rem; }
  .uol-col-debug .uol-search-results__main {
    background: rgba(161, 161, 239, 0.2); }
  @media (min-width: 37.5em) {
    .uol-search-results__main > * {
      margin-left: calc(100% / 12 + 0.0625rem);
      margin-right: calc(100% / 12 + 0.0625rem); } }
  @media (min-width: 64em) {
    .uol-search-results__main > * {
      margin-left: calc(100% / 9 + 0.25rem);
      margin-right: calc(100% / 9 + 0.25rem); } }
  @media (min-width: 90em) {
    .uol-search-results__main > * {
      margin-right: calc((100% / 9 * 2) + 0.5rem); } }
  @media (min-width: 37.5em) {
    .uol-search-results__main > * {
      margin-left: 0;
      margin-right: 0; } }

@media (max-width: 63.99em) {
  .js .uol-search-result__filters {
    padding: 0; } }

.uol-search-results {
  margin-bottom: 3rem; }
  .uol-search-results .uol-form__container,
  .uol-search-results .uol-results-count {
    margin-bottom: 2rem; }
    @media (min-width: 48em) {
      .uol-search-results .uol-form__container,
      .uol-search-results .uol-results-count {
        margin-bottom: 3rem; } }
  .uol-search-results .uol-chips {
    padding: 0; }
  .uol-search-results .uol-search-results__button-chips-container .uol-button, .uol-search-results .uol-search-results__button-chips-container .js .uolcc-container .uolcc-button, .js .uolcc-container .uol-search-results .uol-search-results__button-chips-container .uolcc-button,
  .uol-search-results .uol-search-results__button-chips-container .js .uolcc-prompt-container .uolcc-button, .js .uolcc-prompt-container .uol-search-results .uol-search-results__button-chips-container .uolcc-button,
  .uol-search-results .uol-search-results__button-chips-container .js .uolcc-privacy-update-container .uolcc-button, .js .uolcc-privacy-update-container .uol-search-results .uol-search-results__button-chips-container .uolcc-button, .uol-search-results .uol-search-results__button-chips-container .uol-section-nav-ctas__link {
    margin-bottom: 1.5rem; }
    @media (min-width: 48em) {
      .uol-search-results .uol-search-results__button-chips-container .uol-button, .uol-search-results .uol-search-results__button-chips-container .js .uolcc-container .uolcc-button, .js .uolcc-container .uol-search-results .uol-search-results__button-chips-container .uolcc-button,
      .uol-search-results .uol-search-results__button-chips-container .js .uolcc-prompt-container .uolcc-button, .js .uolcc-prompt-container .uol-search-results .uol-search-results__button-chips-container .uolcc-button,
      .uol-search-results .uol-search-results__button-chips-container .js .uolcc-privacy-update-container .uolcc-button, .js .uolcc-privacy-update-container .uol-search-results .uol-search-results__button-chips-container .uolcc-button, .uol-search-results .uol-search-results__button-chips-container .uol-section-nav-ctas__link {
        margin-bottom: 2rem; } }
  .uol-search-results .uol-results-items {
    margin: 1.25rem 0 2rem;
    padding: 0; }
    @media (min-width: 48em) {
      .uol-search-results .uol-results-items {
        margin: 2.25rem 0 3rem; } }
  @media (max-width: 47.99em) {
    .uol-search-results .uol-form__inner-wrapper .uol-form__input-wrapper::before {
      bottom: 0; } }
  @media (min-width: 37.5em) {
    .uol-search-results .uol-publications-list {
      margin-right: calc(100% / 12 * 2 + 0.25rem); } }
  @media (min-width: 64em) {
    .uol-search-results .uol-publications-list {
      margin-right: calc(100% / 9 * 2 + 0.25rem); } }

.uol-search--publications .page-heading__title__main::after {
  content: " - Publications"; }

.uol-staff-a-z {
  position: relative;
  flex-basis: calc(100% - 1rem);
  width: calc(100% - 1rem);
  padding: 0.5rem; }
  .uol-col-debug .uol-staff-a-z {
    background: rgba(161, 161, 239, 0.2); }
  @media (min-width: 37.5em) {
    .uol-staff-a-z > * {
      margin-left: calc(100% / 12 + 0.0625rem);
      margin-right: calc(100% / 12 + 0.0625rem); } }
  @media (min-width: 64em) {
    .uol-staff-a-z > * {
      margin-left: calc(100% / 9 + 0.25rem);
      margin-right: calc(100% / 9 + 0.25rem); } }
  @media (min-width: 90em) {
    .uol-staff-a-z > * {
      margin-right: calc((100% / 9 * 2) + 0.5rem); } }
  @media (min-width: 37.5em) {
    .uol-staff-a-z > * {
      margin-left: calc(100% / 12 + 0.125rem);
      margin-right: calc(100% / 12 + 0.125rem); } }
  @media (min-width: 64em) {
    .uol-staff-a-z > * {
      margin-left: 0;
      margin-right: 0; } }

.uol-widget--featured-content .uol-gallery__image-caption {
  padding-left: 1rem;
  padding-right: 1rem; }
  @media (min-width: 64em) {
    .uol-widget--featured-content .uol-gallery__image-caption {
      padding-left: 0;
      padding-right: 0; } }

@media (max-width: 103.75em) {
  .uol-course {
    overflow-x: hidden; } }

.uol-course .uol-hero-banner, .uol-course .uol-key-facts {
  width: calc(100vw - 0px); }
  @media (min-width: 103.76em) {
    .uol-course .uol-hero-banner, .uol-course .uol-key-facts {
      width: calc(100vw - 9px); } }

.uol-course .uol-course__header .uol-col-12, .uol-course .uol-course__header .uol-search-results__page-heading, .uol-course .uol-course__header .uol-search-results__content-area, .uol-course .uol-course__header .uol-search-result__filters {
  padding-bottom: 0;
  padding-top: 0;
  margin-top: 1rem; }
  @media (min-width: 64em) {
    .uol-course .uol-course__header .uol-col-12, .uol-course .uol-course__header .uol-search-results__page-heading, .uol-course .uol-course__header .uol-search-results__content-area, .uol-course .uol-course__header .uol-search-result__filters {
      margin-top: 1.5rem; } }

.uol-course .uol-course__header {
  padding: 0.5rem 1rem 0; }
  @media (min-width: 25.875em) {
    .uol-course .uol-course__header {
      padding: 0.5rem 0.5rem 0; } }
  @media (min-width: 48em) {
    .uol-course .uol-course__header {
      padding-top: 1rem; } }
  @media (min-width: 64em) {
    .uol-course .uol-course__header {
      margin-top: 1rem;
      padding: 0 1rem; } }

.uol-course__main {
  padding-top: 0; }

.uol-course .uol-side-nav-container,
.uol-course .uol-side-nav-container--populated,
.uol-course .uol-course__content {
  padding-top: 0;
  padding-bottom: 0; }

.uol-course .uol-detail-nav {
  margin-bottom: 1rem;
  margin-left: 0.5rem; }
  @media (min-width: 48em) {
    .uol-course .uol-detail-nav {
      margin-bottom: 3rem; } }
  @media (min-width: 64em) {
    .uol-course .uol-detail-nav {
      margin-left: 0;
      padding-bottom: 0; } }
  .uol-course .uol-detail-nav .uol-detail-nav__title {
    margin-top: 0; }

.uol-global-marketing-banner {
  margin: 2rem 0; }
  @media (min-width: 48em) {
    .uol-global-marketing-banner {
      margin: 3rem 0; } }

.uol-table-container table {
  width: 100%; }

.uol-course-year--label {
  color: #0e0c0c; }

.uol-course-year--value {
  color: #212529;
  font-variant-numeric: lining-nums; }

.uol-course__content__page-nav-container {
  max-width: 760px; }

.uol-hero-banner + .uol-year-of-entry {
  margin: 2rem 0 1.5rem; }
  @media (min-width: 48em) {
    .uol-hero-banner + .uol-year-of-entry {
      margin: 3rem 0 2rem; } }

.uol-content-placeholder {
  display: flex;
  align-items: center;
  background-image: linear-gradient(135deg, #e8e8e8 25%, #ececec 25%, #ececec 50%, #e8e8e8 50%, #e8e8e8 75%, #ececec 75%, #ececec 100%);
  background-size: 84.85px 84.85px;
  padding: 20px;
  margin: 20px 0;
  font-size: 20px; }

@media (min-width: 64em) {
  .uol-course__content {
    margin-bottom: 2rem; } }

.uol-course__content .uol-profile-supplement {
  margin: 3rem 0; }
  @media (min-width: 48em) {
    .uol-course__content .uol-profile-supplement {
      margin: 3rem 0; } }

.uol-course table th {
  min-width: none; }

.uol-course .uol-logo-group-item__logo {
  width: inherit !important; }

.uol-course .uol-results-items__item h3 {
  margin-top: 0px; }

.uol-course .uol-link-button-container {
  margin: 1.5rem 0 2rem; }
  @media (min-width: 48em) {
    .uol-course .uol-link-button-container {
      margin: 2rem 0 3rem; } }

.uol-course__content__profile-container,
.uol-course__content__cards-container {
  margin: 0; }
  .uol-course__content__profile-container .uol-widget-container,
  .uol-course__content__cards-container .uol-widget-container {
    margin: 0; }
    .uol-course__content__profile-container .uol-widget-container .uol-widget,
    .uol-course__content__cards-container .uol-widget-container .uol-widget {
      margin: 0; }
      .uol-course__content__profile-container .uol-widget-container .uol-widget .uol-cards,
      .uol-course__content__cards-container .uol-widget-container .uol-widget .uol-cards {
        margin-top: 0; }

.uol-course__content__cards-container .uol-widget__head {
  padding-bottom: 8px; }
  @media (min-width: 48em) {
    .uol-course__content__cards-container .uol-widget__head {
      padding-bottom: 1.5rem; } }

.uol-course__content__cards-container .uol-widget__title {
  margin-top: 2rem; }
  @media (min-width: 48em) {
    .uol-course__content__cards-container .uol-widget__title {
      margin-top: 0.5rem; } }
  @media (min-width: 64em) {
    .uol-course__content__cards-container .uol-widget__title {
      margin-top: 2rem; } }

@media (max-width: 63.99em) {
  .uol-course__content__cards-container .uol-cards {
    margin-bottom: 0; } }

@media (max-width: 63.99em) {
  .uol-course__content__cards-container .uol-cards__card:last-child {
    margin-bottom: -8px; } }

.no-js .uol-course .uol-page-nav {
  display: none; }

@media (min-width: 64em) {
  .uol-course__content {
    margin-top: 20px;
    max-width: calc(100% / 12 * 9 - 32px) !important;
    flex-basis: calc(100% / 12 * 9 - 32px) !important;
    margin-left: 12px;
    padding-left: 0 !important; } }

@media (min-width: 90em) {
  .uol-course__content {
    max-width: calc(100% / 12 * 9 - 32px) !important;
    flex-basis: calc(100% / 12 * 9 - 32px) !important;
    margin-left: 16px;
    padding-left: 0 !important; } }

.uol-course__content .uol-rich-text {
  margin-right: 0; }

@media (min-width: 90em) {
  .uol-course__content .uol-rich-text--with-lead > p:first-of-type::before {
    width: calc(100% / 7 - 8px) !important;
    left: -32px !important; } }

.uol-page {
  position: relative;
  flex-basis: calc(100% - 1rem);
  width: calc(100% - 1rem);
  padding: 0.5rem; }
  .uol-col-debug .uol-page {
    background: rgba(161, 161, 239, 0.2); }
  @media (min-width: 37.5em) {
    .uol-page > * {
      margin-left: calc(100% / 12 + 0.0625rem);
      margin-right: calc(100% / 12 + 0.0625rem); } }
  @media (min-width: 64em) {
    .uol-page > * {
      margin-left: calc(100% / 9 + 0.25rem);
      margin-right: calc(100% / 9 + 0.25rem); } }
  @media (min-width: 90em) {
    .uol-page > * {
      margin-right: calc((100% / 9 * 2) + 0.5rem); } }
  @media (min-width: 90em) {
    .uol-page > * {
      margin-right: 0; } }
  @media (min-width: 90em) {
    .test-side + .uol-page > *,
    .uol-side-nav-container--populated + .uol-page > * {
      margin-right: calc(100% / 9 + 0.25rem);
      margin-left: 0; } }

.uol-page--index {
  position: relative;
  flex-basis: calc(100% - 1rem);
  width: calc(100% - 1rem);
  padding: 0.5rem; }
  .uol-col-debug .uol-page--index {
    background: rgba(161, 161, 239, 0.2); }
  @media (min-width: 37.5em) {
    .uol-page--index > * {
      margin-left: calc(100% / 12 + 0.0625rem);
      margin-right: calc(100% / 12 + 0.0625rem); } }
  @media (min-width: 64em) {
    .uol-page--index > * {
      margin-left: calc(100% / 9 + 0.25rem);
      margin-right: calc(100% / 9 + 0.25rem); } }
  @media (min-width: 90em) {
    .uol-page--index > * {
      margin-right: calc((100% / 9 * 2) + 0.5rem); } }
  @media (min-width: 37.5em) {
    .uol-page--index > * {
      margin-left: calc(100% / 12 + 0.125rem);
      margin-right: calc(100% / 12 + 0.125rem); } }
  @media (min-width: 64em) {
    .uol-page--index > * {
      margin-left: 0;
      margin-right: 0; } }
