/**
 * Layout
 * Wrappers & widths
 */

 $site-container-full: 103.75rem;

//Site containers
$site-container-sm: 			1200px;
$site-container-md: 			1400px;
$site-container-lg: 			1600px;

//Wrapper widths
$wrapper-xs: 660px;
$wrapper-sm: 810px;
$wrapper-md: 1040px;
$wrapper-lg: 1170px;
$wrapper-xl: 1300px;
