
@mixin ds-scrollbars($horizontal: false) {
  scrollbar-color: $color-brand--bright $color-black;

  // IE
  scrollbar-base-color: $color-black;
  scrollbar-3dlight-color: $color-black;
  scrollbar-track-color: $color-black;
  scrollbar-face-color: $color-brand--bright;
  scrollbar-arrow-color: $color-brand--bright;

  @media (forced-colors: active) {
    scrollbar-color: ButtonText Canvas;
  }

  &::-webkit-scrollbar {
    background-color: $color-black;
    @if $horizontal {
      height: $spacing-4;
    } @else {
      width: $spacing-4;
    }
  }

  &::-webkit-scrollbar-track {
    background-color: $color-black;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-brand;

    @if $horizontal {
      background-image: linear-gradient($color-black 0, $color-black $spacing-1, $color-brand--bright $spacing-1, $color-brand--bright $spacing-3, $color-black $spacing-3);
    } @else {
      background-image: linear-gradient(90deg, $color-black 0, $color-black $spacing-1, $color-brand--bright $spacing-1, $color-brand--bright $spacing-3, $color-black $spacing-3);
    }

    @media (forced-colors: active) {
      background: ButtonText;
    }
  }
}
