// Not used in tiles pattern
.uol-facts-figures-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;

  & > * {
    box-sizing: border-box;
  }
}

.uol-facts-figures {
  margin: 0;
  text-align: center;

  @include media("<uol-media-s") {
    margin-bottom: $spacing-4;
  }

  // ie. Not in tiles pattern
  .uol-facts-figures-container & {
    margin: $spacing-6 0;
    padding: 0 $spacing-4;
    flex-grow: 1;
    flex-basis: 100%;

    @include media("<uol-media-m") {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        display: block;
        bottom: -#{$spacing-6};
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 60%;
        max-width: 11rem;
        height: 4px;
        background: $color-grey--light;
      }
    }

    @include media(">=uol-media-m") {
      margin: $spacing-6 0;
      padding: $spacing-2 $spacing-5;
    }

    @include media(">=uol-media-m", "<uol-media-xl") {
      flex-basis: calc(50% - #{$spacing-7});

      &:nth-child(odd) {
        border-right: 4px solid $color-grey--light;
      }

      &:nth-child(odd):last-of-type {
        border-right: none;
      }

      // TODO: Find better IE hack
      .no-csspositionsticky & {
        flex-basis: 33%;
        // border-right: none;
      }
    }

    @include media(">=uol-media-xl") {
      padding: $spacing-2 $spacing-5;
      flex-basis: calc(100% / 3 - #{$spacing-7});

      &:nth-child(3n-2),
      &:nth-child(3n-1) {
        border-right: 4px solid $color-grey--light;

        &:last-of-type {
          border-right: none;
        }
      }

      // TODO: Find better IE hack
      .no-csspositionsticky & {
        flex-basis: 25%;
        // border-right: none;
      }
    }
  }
}

  .uol-facts-figures__headline {
    @extend %uol-font-serif;

    margin-top: 0;
    margin-bottom: $spacing-4;
  }

    .uol-facts-figures__headline__1 {
      @include font-size-responsive(2.25rem, 2.75rem);
      @include line-height-responsive(2.75rem, 3rem);

      display: block;
      margin-bottom: $spacing-3;
      font-weight: $font-weight-bold--serif;
      color: $color-brand;

      .uol-tiles & {
        color: $color-brand--bright;
      }
    }

      .uol-facts-figures__headline__1__emphasis {
        @include font-size-responsive(2.75rem, 4.25rem);
        @include line-height-responsive(2.75rem, 3rem);
      }

    .uol-facts-figures__headline__2 {
      @include font-size-responsive(1.125rem, 1.125rem, 1.25rem);
      @include line-height-responsive(1.5rem, 1.5rem);
    }

  .uol-facts-figures__cite {
    @include font-size-responsive(1rem, 1rem, 1.125rem);
    @include line-height-responsive(1.375rem, 1.375rem, 1.75rem);

    font-style: normal;
  }

  .uol-facts-figures__link {
    @include link_focus(3px);

    color: inherit;    

    &:hover {
      text-decoration-color: $color-brand;
    } 
  }
