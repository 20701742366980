.uol-quicklinks {

  .no-js & {
    display: none;
  }

  position: relative;
  flex-grow: 2;

  @include media("<uol-media-m") {
    width: calc(100% - 136px);
    background: $color-black--dark;

    .uol-global-masthead--quicklinks-expanded & {
      width: auto;
    }
  }
  @include media(">=uol-media-m") {
    margin-right: $spacing-5;
  }

  @include media(">=uol-media-l") {
    max-width: calc(100% - 400px - 20rem);
  }
}

  .uol-quicklinks__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    text-align: left;
  }

  .uol-quicklinks__button {
    position: relative;
    margin: 0;
    white-space: nowrap;
    background: transparent;
    color: $color-white--dark;
    font-size: 1rem;
    font-weight: 500;
    border: none;
    padding: 0.9em 0.5em;

    &:focus {
      outline: 2px dotted transparent;
      outline-offset: -6px;
    }

    @include media("<uol-media-m") {
      &[aria-expanded="true"] {
        @include font-size-responsive(1.75rem, 2.25rem, 3rem);
        @include line-height-responsive(2.25rem, 3rem, 3.5rem);

        display: block;
        text-align: left;
        width: 100vw;
        border-bottom: 2px solid $color-brand--bright;
        margin-top: -#{$spacing-2};
        padding: 1.312rem $spacing-7 1.312rem $spacing-4;
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0.2em;
      left: 0.2em;
      right: calc(100% - 0.2em);
      height: 0.375rem;
      background-color: rgba($color-brand--bright, 0);
      transition: right 0.4s ease 0.1s;

      @include media(">=uol-media-m") {
        height: $spacing-2;
      }

      @media (-ms-high-contrast: active),
      (forced-colors: active) {
        background-color: WindowText;
        background-color: CanvasText;
      }
    }

    &:hover,
    &:focus {

      &::after {
        right: 0.2em;
        background-color: $color-brand--bright;
      }
    }

    &[aria-expanded="true"] {
      &::before {
        content: "";
        position: absolute;
        right: 0.3rem;
        bottom: 0.6rem;
        width: 0;
        height: 0;
        border: $spacing-2 solid transparent;
        border-top: none;
        border-bottom-color: $color-white;
        filter: drop-shadow(0 -0.05rem 0.05rem rgba($color-black, 0.1));
        z-index: 2;

        @include media("<=uol-media-m") {
          display: none;
        }
      }
    }

    &[aria-expanded="true"] {
      @include media("<=uol-media-m") {
        &::after {
          display: none;
        }

        &:focus {
          text-decoration: underline;
        }

        &:focus:not(:focus-visible) {
          text-decoration: none;

        }

        &:focus-visible {
          outline-color: transparent;
          text-decoration: underline;
          text-underline-offset: 4px;
          text-decoration-thickness: $spacing-2;
          text-decoration-color: $color-brand--bright;
        }
      }
    }
  }

    .uol-quicklinks__button__icon {
      position: relative;
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-left: 0.2em;
      margin-right: -0.2em;

      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 0.5em;
        height: 0.13em;
        background: $color-white--dark;
        bottom: 20%;

        @media (-ms-high-contrast: active) {
          background: ButtonText;
        }
      }

      &::before {
        left: 50%;
        transform: translateX(-76%) rotate(45deg);
      }

      &::after {
        right: 50%;
        transform: translateX(76%) rotate(-45deg);
      }

      .uol-quicklinks__group--expanded & {

        @include media("<uol-media-m") {
          position: absolute;
          padding: $spacing-4;
          right: $spacing-3;
          top: 33%;
          transform: translateY(-50%);
        }

        @include media(">=uol-media-m") {

        }

        &::before {
          transform: translateX(-76%) rotate(315deg);
        }

        &::after {
          transform: translateX(76%) rotate(45deg);
        }
      }
    }

  .uol-quicklinks__group {
    position: relative;

    @include media(">=uol-media-m") {
      padding-right: $spacing-5;
    }

    .uol-quicklinks__group--more & {
      font-weight: $font-weight-bold--sans-serif;
      padding-left: $spacing-4;

      @include media(">=uol-media-m") {
        width: 50%;
      }
    }
  }

    .uol-quicklinks__group--sibling-expanded {

      @include media("<uol-media-m") {
        position: absolute !important;
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        width: 1px;
        overflow: hidden;
        left: -9999px;
      }
    }

    .uol-quicklinks__group__list,
    .uol-quicklinks__more-list {
      font-size: 1.75rem;
      display: none;
      padding: $spacing-4;
      list-style: none;
      background: $color-black;
      color: $color-white;

      @include media(">=uol-media-m") {
        position: absolute;
        top: calc(100% - #{$spacing-3});
        right: 0;
        box-sizing: border-box;
        width: 375px;
        max-width: 100vw;
        background: $color-white;
        font-weight: $font-weight-bold--sans-serif;
        color: $color-font;
        border: 1px solid rgba($color-border--light, 0.2);
        border-radius: $spacing-3;
        padding-top: $spacing-4;
        z-index: 1;
        transform: translateX($spacing-7);
        box-shadow:
          0 15px 24px 0 rgba(10, 2, 2, 0.15),
          0 5px 10px 0 rgba(33, 33, 33, 0.5);
      }

      @include media(">=uol-media-l") {
        right: auto;
        left: 0;
        transform: translateX(-#{$spacing-7});

        &::before {
          right: auto;
          left: 3.25rem;
        }
      }

      .uol-quicklinks__button[aria-expanded="true"] + & {
        display: block;
      }
    }

      .uol-quicklinks__group__list {

        .uol-quicklinks__group--more & {
          margin-left: -#{$spacing-4};
        }

        .uol-quicklinks__more-list & {
          display: block;

          @include media(">=uol-media-m") {
            position: relative;
            width: auto;
            top: auto;
            left: auto;
            right: 0;
            padding-top: 0;
            padding-bottom: 0;
            transform: none;
            box-shadow: none;
            border: 0;
          }
        }
      }

      .uol-quicklinks__more-list {
        @include media(">=uol-media-l") {
          width: 766px;
          transform: translateX(-40%);
          padding-top: $spacing-6;

          .uol-quicklinks__button[aria-expanded="true"] + & {
            display: flex;
          }
        }
      }

      .uol-quicklinks__group__item {
        padding: 0;

        .uol-quicklinks__group--more & {
          padding-left: 0;
          margin-left: -#{$spacing-4};
          margin-right: -#{$spacing-4};
        }
      }

      .uol-quicklinks__group__link {
        position: relative;
        display: block;
        padding: $spacing-4;
        font-size: 1.125rem;
        text-decoration: none;
        border-radius: 6px;
        transition: background 0.3s ease;

        @include media(">=uol-media-m") {
          color: $color-font--light;
        }

        svg {
          position: relative;
          margin-top: -0.35em;
          top: 0.35em;
          left: 0;
          margin-left: 0.5em;
          fill: rgba($color-brand--bright, 0);
          opacity: 0;
          transition: all 0.3s ease 0.2s;

          @media (-ms-high-contrast: active) {
            fill: windowText;
          }
        }

        &:hover,
        &:focus {
          background: $color-black--dark;
          text-decoration: underline;

          @include media(">=uol-media-m") {
            background: $colour-warmgrey—faded;
            color: $color-font--dark;
          }

          svg {
            left: 0.4em;
            opacity: 1;
            fill: $color-brand--bright;

            @media (-ms-high-contrast: active) {
              fill: windowText;
            }
          }
        }
      }
