.uol-typography-epic {
  @extend %text-size-epic;
  @extend %uol-font-serif;

  font-weight: $font-weight-bold--serif;

  &.uol-typography-epic--sans-serif {
    @extend %uol-font-sans-serif;

    font-weight: $font-weight-bold--sans-serif;
  }
}
