.uol-homepage-content-container {
  @extend .uol-col-container;

  overflow: hidden;
  padding: $spacing-6 $spacing-4 0;
  display: flex;

  @include media("<uol-media-l") {
    display: block;
  }
}

.uol-homepage-content {
  @extend .uol-page-width--max-width;

  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-top: 0;
  // padding: 0 $spacing-4;

  @include media(">=uol-media-m", "<uol-media-l") {
    padding-left: 0;
    padding-right: 0;
  }

  @include media(">=uol-media-m", "<uol-media-l") {
    padding-left: 0;
    padding-right: 0;
  }

  @include media(">=uol-media-l", "<uol-media-xl") {
    padding-left: $spacing-1;
  }

  .uol-side-nav-container--populated + & {
    @extend .uol-col;
    @extend .uol-col-l-9;
  }
}
