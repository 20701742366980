//UoL Reds
$color-brand--dark:     #910000;
$color-brand:           #c70000;
$color-brand--bright:   #ff4e36;
$color-brand--pastel:   #ff8a7a;
$color-brand--faded:     #ffeded;

// UoL Blacks - ‘Neutrals’ for page architecture, site masthead and footer
$color-black--dark:     #0a0202;
$color-black:           #212121;
$color-black--light:    #6e6b6b;
$color-black--faded:    #dedede;

//UoL Greens
$color-brand-2--dark:   #156265;
$color-brand-2--bright:  #026E7A;
$color-brand-2--light:  #ABC6C7;

// UoL Cool Greys  - ‘Neutrals’ for page architecture
$color-grey--dark:      #6a645d;
$color-grey--medium:    #bcb8b3;
$color-grey:            #ddd9d5;
$color-grey--light:     #eae8e6;
$color-grey--faded:     #f3f2f1;

// UoL Warm Greys  - ‘Neutrals’ for page architecture
$color-warmgrey--dark:  #5f5754;
$color-warmgrey--medium:#81746e;
$color-warmgrey:        #b7aaa4;
$color-warmgrey--light: #cac2ba;
$colour-warmgrey—faded: #efeae6;

// UoL Creams - ‘Neutrals’ for page architecture
$color-cream--dark:     #ddcfc0;
$color-cream--medium:   #e9e1d8;
$color-cream:           #f6eee5;
$color-cream—light:     #faf6f1;

// UoL Whites - ‘Neutrals’ for page architecture
$color-white--dark:     #fffbf5;
$color-white:           #ffffff;

// UoL tertiary colours - Used sparingly, to provide contrast for highlighting and messaging.
$color-blue--dark:      #00323d;
$color-blue:            #003a52;
$color-blue--bright:    #1a84c7;

// UoL text colours
$color-font--dark:      #0e0c0c;
$color-font:            #212529;
$color-font--light:     #3b3b3b;
$color-font--x-light:   #636363;

// UoL Links colours
$color-link:            #910000;
$color-link--hover:     #0e0c0c;
$color-link--visited:   #570000;
$color-link--active:    $color-link--visited;

// UoL borders colours
$color-border--light:   #c9c8c5;
$color-border:          #51504c;

// UoL Alerts colours
$color-alert--error:    #d4351c;
$color-alert--warning:  #e76f16;
$color-alert--success:  #3a9018;
$color-alert--info:     #1993bd;

// UoL Focus accent
$color-focus-accent: #197ebe;
