/**
 * Icon font CSS generated by gulp see templates -> _ds-icons-mixins.scss
 * Using lodash https://lodash.com/docs/4.17.4
 */

@mixin ds-icon() {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "ds-icons", sans-serif !important;
    speak: none;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-decoration: none;
    text-transform: none;

    //Better alignment
    transform: translate(0, -1px);
    line-height: 1;
    font-size: inherit;
    display: inline-block;
    vertical-align: middle;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/**
 * Icon font mixins
 */

@mixin ds-icon-16() {
    font-size: 16px;
}

@mixin ds-icon-24() {
    font-size: 24px;
}

@mixin ds-icon-32() {
    font-size: 32px;
}

@mixin ds-icon-48() {
    font-size: 48px;
}

@mixin ds-icon-add() {
    content: "\EA01";
}

@mixin ds-icon-alert-danger() {
    content: "\EA02";
}

@mixin ds-icon-alert-info() {
    content: "\EA03";
}

@mixin ds-icon-alert-success() {
    content: "\EA04";
}

@mixin ds-icon-alert-warning() {
    content: "\EA05";
}

@mixin ds-icon-calendar() {
    content: "\EA06";
}

@mixin ds-icon-campus-map() {
    content: "\EA07";
}

@mixin ds-icon-chevron-down() {
    content: "\EA08";
}

@mixin ds-icon-chevron-left() {
    content: "\EA09";
}

@mixin ds-icon-chevron-right() {
    content: "\EA0A";
}

@mixin ds-icon-chevron-up() {
    content: "\EA0B";
}

@mixin ds-icon-close() {
    content: "\EA0C";
}

@mixin ds-icon-download() {
    content: "\EA0D";
}

@mixin ds-icon-external() {
    content: "\EA0E";
}

@mixin ds-icon-home() {
    content: "\EA0F";
}

@mixin ds-icon-letter() {
    content: "\EA10";
}

@mixin ds-icon-mail() {
    content: "\EA11";
}

@mixin ds-icon-marker() {
    content: "\EA12";
}

@mixin ds-icon-navicon() {
    content: "\EA13";
}

@mixin ds-icon-news() {
    content: "\EA14";
}

@mixin ds-icon-phone() {
    content: "\EA15";
}

@mixin ds-icon-print() {
    content: "\EA16";
}

@mixin ds-icon-profile() {
    content: "\EA17";
}

@mixin ds-icon-remove() {
    content: "\EA18";
}

@mixin ds-icon-rss() {
    content: "\EA19";
}

@mixin ds-icon-search() {
    content: "\EA1A";
}

@mixin ds-icon-share() {
    content: "\EA1B";
}

@mixin ds-icon-social-facebook() {
    content: "\EA1C";
}

@mixin ds-icon-social-google() {
    content: "\EA1D";
}

@mixin ds-icon-social-instagram() {
    content: "\EA1E";
}

@mixin ds-icon-social-linkedin() {
    content: "\EA1F";
}

@mixin ds-icon-social-twitter() {
    content: "\EA20";
}

@mixin ds-icon-social-weibo() {
    content: "\EA21";
}

@mixin ds-icon-social-youtube() {
    content: "\EA22";
}

@mixin ds-icon-sort() {
    content: "\EA23";
}

@mixin ds-icon-star() {
    content: "\EA24";
}

@mixin ds-icon-triangle-down() {
    content: "\EA25";
}

@mixin ds-icon-triangle-left() {
    content: "\EA26";
}

@mixin ds-icon-triangle-right() {
    content: "\EA27";
}

@mixin ds-icon-triangle-up() {
    content: "\EA28";
}

@mixin ds-icon-action-add() {
    content: "\EA29";
}

@mixin ds-icon-action-bookmark() {
    content: "\EA2A";
}

@mixin ds-icon-action-call() {
    content: "\EA2B";
}

@mixin ds-icon-action-cancel() {
    content: "\EA2C";
}

@mixin ds-icon-action-create() {
    content: "\EA2D";
}

@mixin ds-icon-action-delete() {
    content: "\EA2E";
}

@mixin ds-icon-action-download() {
    content: "\EA2F";
}

@mixin ds-icon-action-link-external() {
    content: "\EA30";
}

@mixin ds-icon-action-lock() {
    content: "\EA31";
}

@mixin ds-icon-action-pause() {
    content: "\EA32";
}

@mixin ds-icon-action-play() {
    content: "\EA33";
}

@mixin ds-icon-action-print() {
    content: "\EA34";
}

@mixin ds-icon-action-remove() {
    content: "\EA35";
}

@mixin ds-icon-action-reply() {
    content: "\EA36";
}

@mixin ds-icon-action-search() {
    content: "\EA37";
}

@mixin ds-icon-action-upload() {
    content: "\EA39";
}

@mixin ds-icon-content-bar() {
    content: "\EA3A";
}

@mixin ds-icon-content-basket() {
    content: "\EA3B";
}

@mixin ds-icon-content-bbq() {
    content: "\EA3C";
}

@mixin ds-icon-content-bed() {
    content: "\EA3D";
}

@mixin ds-icon-content-bike() {
    content: "\EA3E";
}

@mixin ds-icon-content-bus() {
    content: "\EA3F";
}

@mixin ds-icon-content-cafe() {
    content: "\EA40";
}

@mixin ds-icon-content-calendar() {
    content: "\EA41";
}

@mixin ds-icon-content-couch() {
    content: "\EA42";
}

@mixin ds-icon-content-email-open() {
    content: "\EA43";
}

@mixin ds-icon-content-email() {
    content: "\EA44";
}

@mixin ds-icon-content-fitness() {
    content: "\EA45";
}

@mixin ds-icon-content-home() {
    content: "\EA46";
}

@mixin ds-icon-content-hospital() {
    content: "\EA47";
}

@mixin ds-icon-content-key() {
    content: "\EA48";
}

@mixin ds-icon-content-location() {
    content: "\EA49";
}

@mixin ds-icon-content-map() {
    content: "\EA4A";
}

@mixin ds-icon-content-music() {
    content: "\EA4B";
}

@mixin ds-icon-content-news() {
    content: "\EA4C";
}

@mixin ds-icon-content-parking() {
    content: "\EA4D";
}

@mixin ds-icon-content-restaurant() {
    content: "\EA4E";
}

@mixin ds-icon-content-rss() {
    content: "\EA4F";
}

@mixin ds-icon-content-shower() {
    content: "\EA50";
}

@mixin ds-icon-content-table-tennis() {
    content: "\EA51";
}

@mixin ds-icon-content-tag() {
    content: "\EA52";
}

@mixin ds-icon-content-train() {
    content: "\EA53";
}

@mixin ds-icon-content-tv() {
    content: "\EA54";
}

@mixin ds-icon-content-user() {
    content: "\EA55";
}

@mixin ds-icon-content-videogame() {
    content: "\EA56";
}

@mixin ds-icon-content-wc() {
    content: "\EA57";
}

@mixin ds-icon-content-wifi() {
    content: "\EA58";
}

@mixin ds-icon-device-desktop() {
    content: "\EA59";
}

@mixin ds-icon-device-laptop() {
    content: "\EA5A";
}

@mixin ds-icon-device-phone() {
    content: "\EA5B";
}

@mixin ds-icon-form-check-checked() {
    content: "\EA5C";
}

@mixin ds-icon-form-check-unchecked() {
    content: "\EA5D";
}

@mixin ds-icon-form-radio-checked() {
    content: "\EA5E";
}

@mixin ds-icon-form-radio-unchecked() {
    content: "\EA5F";
}

@mixin ds-icon-nav-arrow-dropdown-down() {
    content: "\EA60";
}

@mixin ds-icon-nav-arrow-dropdown-up() {
    content: "\EA61";
}

@mixin ds-icon-nav-chevron-down() {
    content: "\EA62";
}

@mixin ds-icon-nav-chevron-left() {
    content: "\EA63";
}

@mixin ds-icon-nav-chevron-right() {
    content: "\EA64";
}

@mixin ds-icon-nav-chevron-up() {
    content: "\EA65";
}

@mixin ds-icon-nav-more-horizontal() {
    content: "\EA66";
}

@mixin ds-icon-nav-more-vertical() {
    content: "\EA67";
}

@mixin ds-icon-nav-navicon() {
    content: "\EA68";
}

@mixin ds-icon-nav-select() {
    content: "\EA69";
}

@mixin ds-icon-content-vr() {
    content: "\EA6A";
}

@mixin ds-icon-device-tablet() {
    content: "\EA6B";
}

@mixin ds-icon-nav-chevron-left-large() {
    content: "\EA6C";
}

@mixin ds-icon-nav-chevron-right-large() {
    content: "\EA6D";
}

@mixin ds-icon-nav-chevron-down-large() {
    content: "\EA6E";
}

@mixin ds-icon-nav-chevron-up-large() {
    content: "\EA6F";
}

@mixin ds-icon-content-washing-machine() {
    content: "\EA70";
}

@mixin ds-icon-content-credit-card() {
    content: "\EA71";
}

@mixin ds-icon-content-sport() {
    content: "\EA72";
}

@mixin ds-icon-content-security() {
    content: "\EA73";
}

@mixin ds-icon-content-build() {
    content: "\EA74";
}

@mixin ds-icon-content-document() {
    content: "\EA75";
}

@mixin ds-icon-action-filter() {
    content: "\EA76";
}

@mixin ds-icon-action-card() {
    content: "\EA77";
}

@mixin ds-icon-action-list() {
    content: "\EA78";
}

@mixin ds-icon-action-cancel-thin() {
    content: "\EA79";
}

@mixin ds-icon-action-share-circle() {
    content: "\EA7A";
}

@mixin ds-icon-action-play-circle-outline() {
    content: "\EA7B";
}

@mixin ds-icon-content-announcement() {
    content: "\EA7C";
}

@mixin ds-icon-nav-dots-horizontal() {
    content: "\EA80";
}

@mixin ds-icon-content-marker() {
    content: "\EA81";
}

@mixin ds-icon-content-security-home() {
    content: "\EA82";
}

@mixin ds-icon-content-ping-pong() {
    content: "\EA83";
}

@mixin ds-icon-content-bank() {
    content: "\EA84";
}
