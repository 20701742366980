.uol-profile-image {
  position: relative;
}

  .uol-profile-image__img {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    border-radius: 50%;
    background: 50% / cover;

    @media (-ms-high-contrast: active), (forced-colors: active) {
      -ms-high-contrast-adjust: none;
      forced-color-adjust: none;
    }

    &::before,
    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
    }

    &::before {
        background: 50% / cover;
        background-image: inherit;
    }

    &::after {
        box-shadow: inset 0 2px 4px rgba($color-black, 0.2);
    }
  }
