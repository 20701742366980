.uol-typography-heading-3 {
  @extend %text-size-heading-3;
  @extend %uol-font-serif;

  font-weight: $font-weight-bold--serif;

  &.uol-typography-heading-3--sans-serif {
    @extend %uol-font-sans-serif;

    font-weight: $font-weight-bold--sans-serif;
  }
}
