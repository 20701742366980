.uol-list-featured {
  list-style-type: none;
  margin: 0;
  padding: 0;
  max-width: 52rem;
}

.uol-list-featured__item {
  @include font-size-responsive(1.125rem, 1.125rem, 1.25rem);
  @include line-height-responsive(1.556, 1.6);

  position: relative;
  margin-bottom: $spacing-4;
  padding: $spacing-2 0 $spacing-2 $spacing-6;

  @include media(">=uol-media-l") {
    margin-bottom: $spacing-5;
  }

  @include media(">=uol-media-xl") {
    margin-bottom: $spacing-6;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: $spacing-4;
    background-color: $color-grey--faded;
    z-index: -1;
  }

  a {
    @include link_focus(2px);

    padding: 0 1px;
    margin: 0 -1px;

    &:hover {
      text-decoration-color: $color-brand--bright;
      text-decoration-thickness: 3px;
      text-underline-offset: 2px;
      transition: all 0s;
    }

    .no-csspositionsticky & {
      &:hover {
        text-decoration: none;
        border-bottom: 3px solid $color-brand--bright;
      }
    }
  }
}

.uol-list-featured__item--ordered {
  padding: $spacing-4 0 $spacing-4 ($spacing-5 + $spacing-6);
  counter-increment: list-number;
  font-variant-numeric: lining-nums;

  &::before {
    font-size: 1.75rem;
    content: counter(list-number) ".";
    position: absolute;
    left: $spacing-4;
    font-family: $font-family-serif;
    color: $color-black;
    top: $spacing-4;
    line-height: 1;
    font-weight: $font-weight-bold--serif;

    @include media(">=uol-media-m") {
      font-size: 2rem;
      top: 50%;
      transform: translateY(-50%);
    }

    @include media(">=uol-media-l") {
      font-size: 2.1875rem;
    }
  }

  &::after {
    width: ($spacing-4 + $spacing-3);
  }
}
