/**
 * Toolkit globals
 */

@import "../../../node_modules/include-media/dist/include-media";

@import "partials/vendor/normalize";

@import "partials/uol-ds/vars/_border-widths.scss";
@import "partials/uol-ds/vars/_breakpoints.scss";
@import "partials/uol-ds/vars/_colours.scss";
@import "partials/uol-ds/vars/_spacing.scss";
@import "partials/uol-ds/vars/_typography.scss";
@import "partials/uol-ds/vars/_wrappers.scss";

@import "partials/uol-ds/mixins/_ds-button-focus.scss";
@import "partials/uol-ds/mixins/_ds-icons-mixins.scss";
@import "partials/uol-ds/mixins/_ds-images.scss";
@import "partials/uol-ds/mixins/_ds-link-focus.scss";
@import "partials/uol-ds/mixins/_ds-mixin-borders.scss";
@import "partials/uol-ds/mixins/_ds-mixin-clearfix.scss";
@import "partials/uol-ds/mixins/_ds-mixin-skin.scss";
@import "partials/uol-ds/mixins/_ds-mixin-transitions.scss";
@import "partials/uol-ds/mixins/_ds-scrollbars.scss";
@import "partials/uol-ds/mixins/_typography.mixins.scss";

@import "partials/uol-ds/utilities/_accessibility.scss";
@import "partials/uol-ds/utilities/_browser-resets.scss";
@import "partials/uol-ds/utilities/_buttons.scss";
@import "partials/uol-ds/utilities/_columns.scss";
@import "partials/uol-ds/utilities/_ds-icons.scss";
@import "partials/uol-ds/utilities/_fitvids.scss";
@import "partials/uol-ds/utilities/_focus.scss";
@import "partials/uol-ds/utilities/_site-container.scss";
@import "partials/uol-ds/utilities/_video-icon.scss";
@import "partials/uol-ds/utilities/typography/_chevron.scss";
@import "partials/uol-ds/utilities/typography/_lang.scss";
@import "partials/uol-ds/utilities/typography/_size.scss";

@import "partials/uol-ds/utilities/typography/size";

@import "fractal-preview";

body {
  background-color: #fff;
  color: $color-font;
}

a {
  color: inherit;
}

@import "/home/vsts/work/1/s/src/library/01-foundations/01-typography/01-typography-size/_typography-size.scss";
@import "/home/vsts/work/1/s/src/library/01-foundations/01-typography/02-typography-font/_typography-font.scss";
@import "/home/vsts/work/1/s/src/library/01-foundations/01-typography/03-typography-components/01-headings/01-epic/_epic.scss";
@import "/home/vsts/work/1/s/src/library/01-foundations/01-typography/03-typography-components/01-headings/heading-1/_heading-1.scss";
@import "/home/vsts/work/1/s/src/library/01-foundations/01-typography/03-typography-components/01-headings/heading-2/_heading-2.scss";
@import "/home/vsts/work/1/s/src/library/01-foundations/01-typography/03-typography-components/01-headings/heading-3/_heading-3.scss";
@import "/home/vsts/work/1/s/src/library/01-foundations/01-typography/03-typography-components/01-headings/heading-4/_heading-4.scss";
@import "/home/vsts/work/1/s/src/library/01-foundations/01-typography/03-typography-components/01-headings/heading-5/_heading-5.scss";
@import "/home/vsts/work/1/s/src/library/01-foundations/01-typography/03-typography-components/01-headings/heading-6/_heading-6.scss";
@import "/home/vsts/work/1/s/src/library/01-foundations/01-typography/03-typography-components/02-paragraphs/paragraph-intro/_paragraph-intro.scss";
@import "/home/vsts/work/1/s/src/library/01-foundations/01-typography/03-typography-components/02-paragraphs/paragraph-small/_paragraph-small.scss";
@import "/home/vsts/work/1/s/src/library/01-foundations/01-typography/03-typography-components/02-paragraphs/paragraph/_paragraph.scss";
@import "/home/vsts/work/1/s/src/library/01-foundations/01-typography/03-typography-components/03-lists/ordered-list/_ordered-list.scss";
@import "/home/vsts/work/1/s/src/library/01-foundations/01-typography/03-typography-components/03-lists/unordered-list/_unordered-list.scss";
@import "/home/vsts/work/1/s/src/library/01-foundations/01-typography/03-typography-components/04-blockquote/_blockquote.scss";
@import "/home/vsts/work/1/s/src/library/01-foundations/01-typography/03-typography-components/05-pull-quote/_pull-quote.scss";
@import "/home/vsts/work/1/s/src/library/01-foundations/01-typography/03-typography-components/06-hr/_hr.scss";
@import "/home/vsts/work/1/s/src/library/01-foundations/01-typography/03-typography-components/07-table/_table.scss";
@import "/home/vsts/work/1/s/src/library/01-foundations/01-typography/03-typography-components/08-in-text-cta/_in-text-cta.scss";
@import "/home/vsts/work/1/s/src/library/01-foundations/01-typography/03-typography-components/09-in-text-facts/_in-text-facts.scss";
@import "/home/vsts/work/1/s/src/library/01-foundations/01-typography/03-typography-components/09-text-highlight/_text-highlight.scss";
@import "/home/vsts/work/1/s/src/library/01-foundations/01-typography/04-typography-rich-text/_typography-rich-text.scss";
@import "/home/vsts/work/1/s/src/library/01-foundations/02-columns/_columns.scss";
@import "/home/vsts/work/1/s/src/library/01-foundations/03-utilities/arrow-link/_arrow-link.scss";
@import "/home/vsts/work/1/s/src/library/01-foundations/03-utilities/page-width/_page-width.scss";
@import "/home/vsts/work/1/s/src/library/01-foundations/03-utilities/spacing/_horizontal.scss";
@import "/home/vsts/work/1/s/src/library/02-components/accordion/_accordion.scss";
@import "/home/vsts/work/1/s/src/library/02-components/accreditation-logos/_accreditation-logos.scss";
@import "/home/vsts/work/1/s/src/library/02-components/article-highlight/_article-content.scss";
@import "/home/vsts/work/1/s/src/library/02-components/article-section/_article-section.scss";
@import "/home/vsts/work/1/s/src/library/02-components/banner-hero/_banner-hero.scss";
@import "/home/vsts/work/1/s/src/library/02-components/banner/_banner.scss";
@import "/home/vsts/work/1/s/src/library/02-components/breadcrumbs/_breadcrumbs.scss";
@import "/home/vsts/work/1/s/src/library/02-components/button/_button.scss";
@import "/home/vsts/work/1/s/src/library/02-components/cards/_cards.scss";
@import "/home/vsts/work/1/s/src/library/02-components/chips/_chips.scss";
@import "/home/vsts/work/1/s/src/library/02-components/cookie-control/_cookie-control.scss";
@import "/home/vsts/work/1/s/src/library/02-components/description-list/_description-list.scss";
@import "/home/vsts/work/1/s/src/library/02-components/detail-nav/_detail-nav.scss";
@import "/home/vsts/work/1/s/src/library/02-components/events-cards/_events-cards.scss";
@import "/home/vsts/work/1/s/src/library/02-components/facts-figures/_facts-figures.scss";
@import "/home/vsts/work/1/s/src/library/02-components/filters-panel/_filters-panel.scss";
@import "/home/vsts/work/1/s/src/library/02-components/footer-partials/contact-address/_contact-address.scss";
@import "/home/vsts/work/1/s/src/library/02-components/footer-partials/site-information/_site-information.scss";
@import "/home/vsts/work/1/s/src/library/02-components/footer-partials/social-media/_social-media.scss";
@import "/home/vsts/work/1/s/src/library/02-components/footer-supplement/_footer-supplement.scss";
@import "/home/vsts/work/1/s/src/library/02-components/form-block/_form-block.scss";
@import "/home/vsts/work/1/s/src/library/02-components/form-fields/character-count/_character-count.scss";
@import "/home/vsts/work/1/s/src/library/02-components/form-fields/error-msg/_error-msg.scss";
@import "/home/vsts/work/1/s/src/library/02-components/form-fields/form-error/_form-error.scss";
@import "/home/vsts/work/1/s/src/library/02-components/form-fields/input-container/_input-container.scss";
@import "/home/vsts/work/1/s/src/library/02-components/form-fields/input/_input--datepicker.scss";
@import "/home/vsts/work/1/s/src/library/02-components/form-fields/input/_input--typeahead.scss";
@import "/home/vsts/work/1/s/src/library/02-components/form-fields/input/_input.scss";
@import "/home/vsts/work/1/s/src/library/02-components/form/_form.scss";
@import "/home/vsts/work/1/s/src/library/02-components/gallery/_gallery-modal.scss";
@import "/home/vsts/work/1/s/src/library/02-components/gallery/_gallery.scss";
@import "/home/vsts/work/1/s/src/library/02-components/global-marketing-banner/_global-marketing-banner.scss";
@import "/home/vsts/work/1/s/src/library/02-components/header-partials/global-masthead/_global-masthead.scss";
@import "/home/vsts/work/1/s/src/library/02-components/header-partials/global-quicklinks/_global-quicklinks.scss";
@import "/home/vsts/work/1/s/src/library/02-components/header-partials/local-masthead/_local-masthead.scss";
@import "/home/vsts/work/1/s/src/library/02-components/header-partials/local-navigation/_local-navigation.scss";
@import "/home/vsts/work/1/s/src/library/02-components/header/_header.scss";
@import "/home/vsts/work/1/s/src/library/02-components/highlight-block/_highlight-block.scss";
@import "/home/vsts/work/1/s/src/library/02-components/icon/_icon.scss";
@import "/home/vsts/work/1/s/src/library/02-components/iframe/_iframe.scss";
@import "/home/vsts/work/1/s/src/library/02-components/image-pair/_image-pair.scss";
@import "/home/vsts/work/1/s/src/library/02-components/images/featured-image/_featured-image.scss";
@import "/home/vsts/work/1/s/src/library/02-components/in-page-nav/_in-page-nav.scss";
@import "/home/vsts/work/1/s/src/library/02-components/in-text-icons/_in-text-icons.scss";
@import "/home/vsts/work/1/s/src/library/02-components/index-table/_index-table.scss";
@import "/home/vsts/work/1/s/src/library/02-components/info-list/_info-list.scss";
@import "/home/vsts/work/1/s/src/library/02-components/key-facts/_key-facts.scss";
@import "/home/vsts/work/1/s/src/library/02-components/link-button/_link-button.scss";
@import "/home/vsts/work/1/s/src/library/02-components/linked-list/_linked-list.scss";
@import "/home/vsts/work/1/s/src/library/02-components/list-featured/_list-featured.scss";
@import "/home/vsts/work/1/s/src/library/02-components/list-group/_list-group.scss";
@import "/home/vsts/work/1/s/src/library/02-components/logo-group/_logo-group.scss";
@import "/home/vsts/work/1/s/src/library/02-components/logos/uol-inline/_uol-inline.scss";
@import "/home/vsts/work/1/s/src/library/02-components/multilink-results-item/_multilink-results-item.scss";
@import "/home/vsts/work/1/s/src/library/02-components/multilink-results-items/_multilink-results-items.scss";
@import "/home/vsts/work/1/s/src/library/02-components/navigation-cards/_navigation-cards.scss";
@import "/home/vsts/work/1/s/src/library/02-components/news-tiles/_news-tiles.scss";
@import "/home/vsts/work/1/s/src/library/02-components/page-heading/_page-heading.scss";
@import "/home/vsts/work/1/s/src/library/02-components/page-nav/_page-nav.scss";
@import "/home/vsts/work/1/s/src/library/02-components/pagination/_pagination.scss";
@import "/home/vsts/work/1/s/src/library/02-components/people-profile-card/_people-profile-card.scss";
@import "/home/vsts/work/1/s/src/library/02-components/people-profile-cards/_people-profile-cards.scss";
@import "/home/vsts/work/1/s/src/library/02-components/profile-image/_profile-image.scss";
@import "/home/vsts/work/1/s/src/library/02-components/profile-supplement/_profile-supplement.scss";
@import "/home/vsts/work/1/s/src/library/02-components/publications-list/_publications-list.scss";
@import "/home/vsts/work/1/s/src/library/02-components/results-count/_results-count.scss";
@import "/home/vsts/work/1/s/src/library/02-components/results-items/_results-items.scss";
@import "/home/vsts/work/1/s/src/library/02-components/section-nav-cta/_section-nav-cta.scss";
@import "/home/vsts/work/1/s/src/library/02-components/section-nav-related-content/_section-nav-related-content.scss";
@import "/home/vsts/work/1/s/src/library/02-components/section-nav/_section-nav.scss";
@import "/home/vsts/work/1/s/src/library/02-components/side-nav-container/_side-nav-container.scss";
@import "/home/vsts/work/1/s/src/library/02-components/skip-link/_skip-link.scss";
@import "/home/vsts/work/1/s/src/library/02-components/status-message/_status-message.scss";
@import "/home/vsts/work/1/s/src/library/02-components/tiles/_tiles.scss";
@import "/home/vsts/work/1/s/src/library/02-components/widget/_widget.scss";
@import "/home/vsts/work/1/s/src/library/02-components/year-of-entry/_year-of-entry.scss";
@import "/home/vsts/work/1/s/src/library/03-patterns/article/_article.scss";
@import "/home/vsts/work/1/s/src/library/03-patterns/footer/_footer.scss";
@import "/home/vsts/work/1/s/src/library/03-patterns/homepage-content/_homepage-content.scss";
@import "/home/vsts/work/1/s/src/library/03-patterns/search-results/_search-results.scss";
@import "/home/vsts/work/1/s/src/library/03-patterns/staff-a-z/_staff-a-z.scss";
@import "/home/vsts/work/1/s/src/library/03-patterns/widgets/featured-content/featured-content.scss";
@import "/home/vsts/work/1/s/src/library/04-templates/course/_course.scss";
@import "/home/vsts/work/1/s/src/library/04-templates/page/_page.scss";
