.uol-index-table-caption {
  @extend %text-size-heading-4;

  color: $color-font--dark;
  margin: $spacing-4 0 0;
  padding: $spacing-4;

  @include media("<uol-media-m") {
    border: 1px solid $color-border--light;
    border-radius: 6px 6px 0 0;
  }
}

.uol-index-table--results-container {
  padding: $spacing-4;

  @include media(">uol-media-m") {
    text-align: right;
  }
}

.uol-index-table--results {
  @include font-size-responsive(1rem);
}

.uol-index-table {
  @extend %text-size-paragraph--small;

  border-collapse: unset;
  margin-bottom: $spacing-4;
  border: 1px solid $color-border--light;
  font-variant-numeric: lining-nums;

  @include media(">=uol-media-m") {
    margin: $spacing-4;
    position: relative;
    border-radius: 12px;
  }

  // Currently unused due to Safari position: sticky bug
  // caption {
  //     font-size: 18px;
  //     font-weight: 600;
  //     color: $color-font--dark;
  // }

  thead {
    border-radius: 6px 6px 0 0;

    @include media(">=uol-media-m") {
      z-index: 2;
      border-radius: 0;
    }
  }

  th,
  td {
    vertical-align: top;

    @include media(">=uol-media-m") {
      padding: $spacing-4;
    }
  }

  th {
    font-weight: $font-weight-medium--sans-serif;

    @include media(">=uol-media-m") {
      text-align: left;
      position: sticky;
      top: 0;
      padding-bottom: 20px;
      background: $color-white;
      z-index: 2;
      // transform: translateZ(0) scaleX(1.01);

      &:first-of-type {
        border-radius: 12px 0 0 0;
      }

      &:last-of-type {
        border-radius: 0 12px 0 0;
      }

      .csspositionsticky & {
        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          bottom: -4px;
          left: 0;
          right: 0;
          background: linear-gradient(0deg, rgba(#000, 0) 0, rgba(#000, 0.4) 4px, rgba(#000, 0) 4px);
          opacity: 0;
          transition: opacity 0.7s ease;
        }
      }

      &.uol-index-table__th--numeric {
        text-align: right;
      }

      &.uol-index-table__th--image-column {
        @include media(">=uol-media-m") {
          padding-left: 6.5rem;
        }
      }
    }
  }

  .thead--stuck th {
    @include media(">=uol-media-m") {
      &::before {
        opacity: 1;
      }

      &:first-of-type,
      &:last-of-type {
        border-radius: 0;
      }
    }
  }

  td {
    color: $color-font;

    a {
      color: $color-font;
      text-decoration: underline;

      &:hover,
      &:focus {
        text-decoration-color: $color-brand;
      }
    }
  }

  tbody {
    line-height: 1.5;

    @include media(">=uol-media-m") {
      z-index: 1;
    }

    tr {
      transition: all 0.3s ease;

      @include media(">=uol-media-m") {
        &:nth-child(odd) {
          background: $color-grey--faded;
        }

        &:last-of-type {
          td {
            &:first-of-type {
              border-radius: 0 0 0 12px;
            }

            &:last-of-type {
              border-radius: 0 0 12px 0;
            }
          }
        }
      }
    }

    td {
      @include media(">=uol-media-m") {
        border-bottom: 1px solid $color-border--light;
      }
    }

    tr:last-of-type td {
      border-bottom: none;
    }
  }

  .uol-index-table__td--type-numeric {
    @include media(">=uol-media-m") {
      text-align: right;
    }
  }

  .uol-index-table__td--type-img {
    position: relative;
    margin-top: -#{$spacing-4};
    margin-left: -#{$spacing-4};
    margin-right: -#{$spacing-4};
    padding: $spacing-4 $spacing-4 $spacing-4 6.5rem;

    &::before {
      content: "";
      float: left;
      overflow: hidden;
      height: $spacing-8;
      font-size: 0;
   }
  }

  .uol-index-table__td__img {
    position: absolute;
    top: 8px;
    left: 8px;
    display: block;
    width: 80px;
    height: 80px;
    background-color: $color-white;
    border-radius: 50%;
    overflow: hidden;

    @include media(">=uol-media-m") {
      width: 64px;
      height: 64px;
      top: 16px;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: inherit;
      height: inherit;
      border-radius: inherit;
    }

    &::before {
      background: 50% / cover;
      background-image: inherit;
    }

    &::after {
      box-shadow: inset 0 2px 5px rgba($color-black, 0.16);
    }
  }

  .uol-index-table__td__img--profile {
    background: $color-white url("../img/avatar-missing.png");
  }

  .uol-index-table__sort-select {
    display: block;
    font-weight: $font-weight-bold--sans-serif;
    width: 100%;
    margin: 0;
    padding: $spacing-4;
    border-color: $color-border;
    border-radius: 0;

    @supports (-webkit-appearance:none) {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: url("../img/sort.svg") no-repeat right $spacing-4 top 50%;
    }

    @media (-ms-high-contrast: active) {
      -webkit-appearance: menulist;
      -moz-appearance: menulist;
      appearance: menulist;
      background: initial;
    }

    @include media(">=uol-media-m") {
      display: none;
    }
  }

  .uol-index-table__th-inner {
    display: flex;
  }

  .uol-index-table__btn-sort {
    position: relative;
    width: 25px;
    height: 24px;
    background: transparent;
    border: none;

    @include media("<uol-media-m") {
      display: none;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      left: 8px;
      border: 0.35rem solid transparent;
      border-bottom: none;
      border-top: 0.4rem solid $color-black--dark;

      @media (forced-colors: active) {
        border: 0.35rem solid canvas;
        border-top: 0.4rem solid $color-black--dark;
      }

      @media (-ms-high-contrast: active) {
        border: 0.35rem solid window;
        border-top: 0.4rem solid windowText;
      }
    }

    &::before {
      transform: rotate(180deg) translateY(120%);
    }

    &::after {
      transform: translateY(20%);
    }

    &.sorted--asc {

      &::after {
        opacity: 0;
      }
    }

    &.sorted--desc {

      &::before {
        opacity: 0;
      }
    }
  }
}

// uol-index-table--stacked class added with javascript
.uol-index-table--stacked {
  margin: 0 0 $spacing-4;
  border-radius: 12px;

  @include media("<uol-media-m") {
    display: block;
    border-collapse: separate;
    border-radius: 0 0 6px 6px;
    // Currently unused due to Safari position: sticky bug
    // caption {
    //     padding: 16px;
    //     line-height: 1.333;
    //     background-color: $color-cream--dark;
    //     border-bottom: 4px solid hotpink;
    // }

    thead {
      position: absolute !important;
      clip: rect(1px, 1px, 1px, 1px);
      height: 1px;
      width: 1px;
      overflow: hidden;
      left: -9999px;
    }

    tbody {
      display: block;

      tr {
        display: block;
        margin-bottom: 0;
      }

      td {
        display: block;
        padding: $spacing-4;
        color: $color-font--dark;

        &:first-of-type {
          background: $color-grey--faded;
        }

        &:not(:first-child):not(:last-child) {
          border-bottom: 1px solid $color-border--light;
        }
      }

      .uol-index-table__td--type-img {
        position: relative;
        background: $color-cream--dark;
        padding: $spacing-5 $spacing-4 $spacing-5 7rem;
        margin: 0;
      }

      .uol-index-table__td__img {
        position: absolute;
        top: $spacing-4;
        left: $spacing-4;
        display: block;
        width: $spacing-9;
        height: $spacing-9;
        background-color: $color-white;
        border-radius: 50%;
        overflow: hidden;

        &::before,
        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          width: inherit;
          height: inherit;
          border-radius: inherit;
        }

        &::before {
          background: 50% / cover;
          background-image: inherit;
        }

        &::after {
          box-shadow: inset 0 2px 5px rgba($color-black, 0.16);
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

.uol-index-table--search-results {

  @include media("<uol-media-m") {
    border-radius: 6px;

    .uol-index-table__sort-select {
      border-radius: 6px 6px 0 0;
    }
  }
}

.uol-index-table__pseudo-th {
  display: block;
  font-weight: $font-weight-bold--sans-serif;
  color: $color-font;
  margin-bottom: $spacing-1;

  @include media(">=uol-media-m") {
    display: none;
  }
}
