.uol-article-section {

}

    .uol-article-section__title {

    }

    .uol-article-section__content {
        a {
            text-decoration: underline;
            color: inherit;

            &:hover,
            &:focus {
                // color: $color-brand-1;
            }
        }

        .uol-publications-list__further-link {
          text-decoration: none;
          font-weight: $font-weight-bold--sans-serif;
        }
    }

    
