/**
 * Spacing
 * Based on a default 16px root
 */

$spacing-1: 0.25rem; // 4px
$spacing-2: 0.5rem; // 8px
$spacing-3: 0.75rem; // 12px
$spacing-4: 1rem; // 16px
$spacing-5: 1.5rem; // 24px
$spacing-6: 2rem; // 32px
$spacing-7: 3rem; // 48px
$spacing-8: 4rem; // 64px
$spacing-9: 5rem; // 80px
