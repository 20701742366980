.uol-hero-banner {
  width: calc(100vw - 0px);
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background-color: $color-black;
}

.uol-hero-banner--inner {
  max-width: 91.125rem;
  margin: 0 auto;
  display: flex;
  height: auto;
  flex-direction: column-reverse;
	background: $color-black;
  color: $color-white;
	overflow: hidden;
  max-height: 592px;

  @include media(">=uol-media-l") {
    flex-direction: row;
    align-items: center;
    padding-right: 100px;
  }

  @include media(">=uol-media-xl") {
    padding-right: 130px;
  }
}

  .uol-hero-banner__text-container {
    padding: $spacing-5 $spacing-4 $spacing-6;

    @include media(">=uol-media-m") {
      padding: $spacing-6 $spacing-4 $spacing-7 $spacing-4;
    }

    @include media(">=uol-media-l") {
      width: 38%;
      padding-left: $spacing-6;
      padding-right: $spacing-5;
    }

    @include media(">=uol-media-xxl") {
      padding-left: 0;
    }
  }

    .uol-hero-banner__title {
      @extend .uol-typography-heading-2;
      margin: 0;
    }

    .uol-hero-banner__lead {
      @extend .uol-typography-paragraph;
      margin: $spacing-2 0 0;
    }

  .uol-hero-banner__img {
    // width: calc(100% + #{$spacing-4} * 2);
    // margin: 0 -#{$spacing-4};
    // height: auto;
    @include media(">=uol-media-l") {
      width: 62%;
      padding: 39px 0 50px 100px;
    }

    @include media(">=uol-media-xl") {
      padding: 0;
    }
  }



