.uol-image-pair {
  display: flex;
  column-gap: $spacing-4;

  @include media(">=uol-media-l") {
    column-gap: $spacing-5;
  }

  @include media(">=uol-media-xl") {
    column-gap: $spacing-6;
  }

  .uol-image-pair__container {
    flex: 50%;
    margin: 0;
  }

  .uol-image-pair__image--single {
    width: 100%;
    aspect-ratio: 2 / 1;
    object-fit: cover;
  }

  .uol-image-pair__image {
    width: 100%;
    display: block;

    
  }

  .uol-image-pair__caption {
    
    @extend %text-size-caption;

    color: $color-font--x-light;
    margin-top: $spacing-2;
    padding-top: 0;

  }
}

