.uol-in-text-facts {
  margin-bottom: $spacing-4;
  
  @include media(">=uol-media-m") {
    margin-bottom: $spacing-6;
  }
}

  .uol-in-text-facts__title {
    @extend .uol-typography-heading-3;

    color: $color-font--dark;
  }

  .uol-in-text-facts__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .uol-in-text-fact__item {
    width: 100%;
    background-color: $color-brand-2--bright;
    color: $color-white;
    text-align: center;
    padding: $spacing-4 0 $spacing-5;
    margin-bottom: $spacing-4;

    &:not(:only-child) {
      @include media(">=uol-media-m") {
        width: calc(50% - #{$spacing-4} / 2);
        margin-bottom: 0;
      }

      @include media(">=uol-media-l") {
        width: calc(50% - #{$spacing-5} / 2);
      }

      @include media(">=uol-media-xl") {
        width: calc(50% - #{$spacing-6} / 2);
      }
    }
  }

    .uol-in-text-fact__item__title {
      @extend .uol-typography-heading-4;

      margin: 0 0 $spacing-1 0;
      text-decoration: none;
    }

    .uol-in-text-fact__item__description {
      @extend .uol-typography-paragraph;

      margin: 0;
    }

    .uol-in-text-fact__link {
      text-decoration: none;

      &:hover,
      &:focus-within {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, .12);
      }
    }

    .uol-in-text-fact__link .uol-in-text-fact__item__description {
      text-decoration: underline;
    }
