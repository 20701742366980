// Images sizes: Used for images and sizing of neighbours
$ProfileImageSize: 5.75rem;
$ProfileImageSizeXS: 7.25rem;
$ProfileImageSizeM: 9.75rem;
$ProfileImageSizeL: 7.875rem;
$ProfileImageSizeXL: 10.75rem;

.uol-people-profile__card {
  position: relative;
  list-style: none;
  margin-bottom: $spacing-4;
  padding: $spacing-5 $spacing-4 $spacing-6;
  border: 1px solid $color-border--light;
  border-radius: 6px;
  background-color: $color-grey--faded;
  font-variant-numeric: lining-nums;
  color: $color-font;
  overflow: auto;

  &:last-child {
    margin-bottom: $spacing-6;
  }

  // TODO: Add XXS breakpoint
  @include media("<320px") {
    display: flex;
    flex-wrap: wrap;
  }

  @include media(">=uol-media-m") {
    padding: $spacing-5 $spacing-4 $spacing-6;
  }

  @include media(">=uol-media-l") {
    margin-bottom: $spacing-5;
    padding: $spacing-5 $spacing-5 $spacing-6;

    &:last-child {
      margin-bottom: $spacing-7;
    }
  }

  @include media(">=uol-media-xl") {
    margin-bottom: $spacing-6;
    padding: $spacing-5 $spacing-6 $spacing-6;
  }

  .uol-info-list {
    @extend %text-size-paragraph--small;

    margin: 0 0 $spacing-2;
  }

  .uol-info-list-container {

    @include media("<uol-media-m") {
      clear: both;
    }


    &:last-of-type {
      .uol-info-list {
        margin-bottom: 0;
      }
    }
  }

  .uol-info-list__group {
    margin-bottom: $spacing-2;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

}

    .uol-people-profile__card--image-layout {

      .uol-info-list {
        width: 100%;

        @include media(">=uol-media-xs") {
          clear: both;
        }
        @include media(">=uol-media-m") {
          clear: none;
          float: right;
          width: calc(100% - #{$ProfileImageSizeM + $spacing-4})
        }

        @include media(">=uol-media-l") {
          width: calc(100% - #{$ProfileImageSizeL + $spacing-5})
        }

        @include media(">=uol-media-xl") {
          width: calc(100% - #{$ProfileImageSizeXL + $spacing-6})
        }
      }
    }

  .uol-people-profile__name {
    @extend %text-size-heading-4;

    margin: 0 0 $spacing-1;
    font-family: $font-family-serif;

    .uol-people-profile__card--image-layout & {
      float: right;

      // TODO: Add XXS breakpoint
      @include media("<320px") {
        width: 100%;
        margin-top: $spacing-4;
      }

      @include media(">=320px") {
        margin-top: $spacing-4;
        width: calc(100% - #{$ProfileImageSize + $spacing-4});
      }

      @include media(">=uol-media-xs") {
        margin-top: 0;
        width: calc(100% - #{$ProfileImageSizeXS + $spacing-4})
      }
      @include media(">=uol-media-m") {
        width: calc(100% - #{$ProfileImageSizeM + $spacing-4})
      }
      @include media(">=uol-media-l") {
        width: calc(100% - #{$ProfileImageSizeL + $spacing-5})
      }
      @include media(">=uol-media-xl") {
        width: calc(100% - #{$ProfileImageSizeXL + $spacing-6})
      }
    }

    a {
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
        outline: 3px dotted transparent;
      }
    }
  }

  .uol-people-profile__img {
    border-radius: 50%;
    width: $ProfileImageSize;
    height: $ProfileImageSize;

    @include media("<uol-media-xs") {
      order: -1;
      margin-bottom: $spacing-2;
    }

    @include media(">=uol-media-xs") {
      float: left;
      width: $ProfileImageSizeXS;
      height: $ProfileImageSizeXS;
      margin-bottom: $spacing-4;
    }

    @include media(">=uol-media-m") {
      width: $ProfileImageSizeM;
      height: $ProfileImageSizeM;
    }

    @include media(">=uol-media-l") {
      width: $ProfileImageSizeL;
      height: $ProfileImageSizeL;
    }

    @include media(">=uol-media-xl") {
      left: $spacing-6;
      width: $ProfileImageSizeXL;
      height: $ProfileImageSizeXL;
      margin-bottom: 0;
    }
  }

  .uol-people-profile__position {
    @extend %text-size-heading-6;

    display: inline-block;
    margin-bottom: $spacing-2;
    font-family: $font-family-serif;
    color: $color-brand;

    .uol-people-profile__card--image-layout & {

      @include media(">=uol-media-xs") {
        float: right;
        width: calc(100% - #{$ProfileImageSizeXS + $spacing-4})
      }

      @include media(">=uol-media-m") {
        width: calc(100% - #{$ProfileImageSizeM + $spacing-4})
      }

      @include media(">=uol-media-l") {
        width: calc(100% - #{$ProfileImageSizeL + $spacing-5})
      }

      @include media(">=uol-media-xl") {
        width: calc(100% - #{$ProfileImageSizeXL + $spacing-6})
      }
    }
  }

  .uol-people-profile__footer {
    clear: both;
    padding: $spacing-4 0 0;

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: $color-border--light;
    }
  }

    .uol-people-profile__footer__messages {
      @extend %text-size-paragraph--small;

      display: block;
      padding-top: $spacing-4;
      margin-bottom: -#{$spacing-4};

      .js & svg {
        height: 1.5em;
        width: 1.5em;
        min-width: 1.5em;
        margin-top: 0;
      }

      + .uol-people-profile__footer__actions {
        margin-top: $spacing-4;
      }
    }

    .uol-people-profile__footer__actions {
      margin-bottom: -#{$spacing-5};
      padding-top: $spacing-4;

      .uol-button {
        margin: 0 $spacing-4 $spacing-4 0;
      }
    }
