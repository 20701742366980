.uol-news-tiles {
  display: flex;
  flex-wrap: wrap;
}

.uol-news-tile {
  border: 1px solid #fff;
  position: relative;
  box-sizing: border-box;
  background-color: $color-black--light;
  color: $color-white;
  width: 100%;
  min-height: 12rem;

  @include media(">=uol-media-xs") {
    min-height: 13rem;
  }

  @include media(">=uol-media-s") {
    min-height: 18.75rem;
    width: 50%;

    &:first-of-type {
      width: 100%;
    }
  }

  @include media(">=uol-media-l") {
    min-height: 21.5rem;
    width: calc(100% / 3);

    &:first-of-type {
      width: calc(100% / 3 * 2);
    }
  }

  @include media(">=uol-media-xl") {
    min-height: 22.5rem;
    width: 25%;

    &:first-of-type {
      width: 50%;
    }
  }

  &:nth-of-type(2) {
    background-color: $color-brand;

    .uol-news-tile__img {
      display: none;
    }
  }

  &:nth-of-type(3) {
    background-color: $color-warmgrey--dark;
  }

  &:nth-of-type(5) {
    background-color: $color-grey--dark;
  }

  &:nth-of-type(4) {
    background-color: $color-brand--dark;

    .uol-news-tile__img {
      display: none;
    }
  }

  &:nth-of-type(7) {
    background-color: $color-black;

    .uol-news-tile__img {
      display: none;
    }
  }

  &::after {
    border: 4px solid rgba($color-brand--bright, 0);
  }

  &:focus-within {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-color: $color-brand--bright;
    }
  }
}

  .uol-news-tile__inner {
    z-index: 8;
    position: relative;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding: $spacing-8 $spacing-4 $spacing-4 $spacing-4;
    box-sizing: border-box;

    @include media(">=uol-media-s") {
      // TODO: Add to variables file
      padding-top: 128px;
    }

    .uol-news-tile:hover &,
    .uol-news-tile:focus-within & {
      &::before {
        background-color: $color-brand--bright;
      }
    }

    .uol-news-tile:nth-of-type(2) &,
    .uol-news-tile:nth-of-type(4) &,
    .uol-news-tile:nth-of-type(7) & {
      &::before {
        background-color: $color-white;
      }
    }
  }

  .uol-news-tile__content {
    &::before {
      content: "";
      position: relative;
      display: inline-block;
      top: -#{$spacing-3};
      left: 0;
      width: $spacing-5;
      height: $spacing-2;
      background-color: $color-white;
      transition: background 0.3s ease;

      @include media(">=uol-media-m") {
        width: $spacing-6;
      }

      .uol-news-tile:focus-within &,
      .uol-news-tile:hover & {
        background-color: $color-brand--bright;
      }

      .uol-news-tile:nth-of-type(2) &,
      .uol-news-tile:nth-of-type(4) &,
      .uol-news-tile:nth-of-type(7) &,
      .uol-news-tiles--no-img:focus-within &,
      .uol-news-tiles--no-img:hover &
      {
        background-color: #FFF;
      }
    }
  }

  .uol-news-tile__title {
    @extend .uol-typography-heading-4;
    @include font-size-responsive(1.25rem, 1.5rem, 1.75rem);
    @include line-height-responsive(1.75rem, 2rem, 2.375rem);

    margin: 0;
    font-variant-numeric: lining-nums;
  }

  .uol-news-tile__link {
    color: inherit;
    text-decoration: none;
    background: rgba($color-black--dark, 0.6);
    box-shadow: -.5rem 0 0 0 rgba(10,2,2,.6),.5rem 0 0 0 rgba(10,2,2,.6);
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
      text-decoration-color: $color-brand--bright;
    }

    .uol-news-tile:nth-of-type(2) &,
    .uol-news-tile:nth-of-type(4) &,
    .uol-news-tile:nth-of-type(7) &,
    .uol-news-tiles--no-img & {
      background: transparent;
      box-shadow: none;

      &:hover,
      &:focus {
        text-decoration-color: $color-white;
      }
    }
  }

  .uol-news-tile__meta {
    @extend .uol-typography-paragraph-small;

    margin: $spacing-3 0 0;
  }

    .uol-news-tile__meta__term {
      @extend .hide-accessible;
    }

    .uol-news-tile__meta__data {
      display: inline;
      margin: 0;

      + .uol-news-tile__meta__data {
        &::before {
          content: ",";
          position: relative;
          left: -0.2em;
        }
      }
    }

    .uol-news-tile__meta__data--date {
      display: inline-block;
      font-variant-numeric: lining-nums;
      background: rgba($color-black--dark, 0.6);
      padding-left: $spacing-2;
      padding-right: $spacing-2;
      margin-left: -$spacing-2;
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;

      .uol-news-tile:nth-of-type(2) &,
      .uol-news-tile:nth-of-type(4) &,
      .uol-news-tile:nth-of-type(7) &,
      .uol-news-tiles--no-img & {
        background: transparent;
      }
    }

    .uol-news-tile__meta__link {
      color: inherit;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

  .uol-news-tile__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: 50%;
    background-size: cover;

    @media (-ms-high-contrast: active), (forced-colors: active) {
      -ms-high-contrast-adjust: none;
      forced-color-adjust: CanvasText;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba($color-black, 0.1);
      transition: background 0.3s ease;

      .uol-news-tile:hover &,
      .uol-news-tile:focus-within & {
        background-color: rgba($color-black, 0);
      }
    }
  }

  .uol-news-tiles--set-of-four {
    @include media(">=uol-media-s") {
      .uol-news-tile {
        width: calc(100% / 2);
      }
    }

    @include media(">=uol-media-l") {
      .uol-news-tile {
        width: calc(100% / 3);
      }
    }

    @include media(">=uol-media-xl") {
      .uol-news-tile {
        width: calc(100% / 4);
      }
    }
  }