@mixin GlobalHeaderIconHover {

  svg {
    path,
    polygon {
      fill: $color-white;
      transition: fill 0.3s ease;

      @media (-ms-high-contrast: active) {
        fill: ButtonText;
      }
    }
  }

  &:focus {
    outline: 2px dotted transparent;
  }

  &:hover,
  &:focus {

    svg {
      path,
      polygon {
        fill: $color-brand--bright;

        @media (-ms-high-contrast: active) {
          fill: -ms-hotlight;
          fill: LinkText; // TODO: Fix HC colours
        }
      }
    }
  }
}

.uol-global-masthead-outer {
  background: $color-black--dark;
}

.uol-global-masthead {
  position: relative;
  background: $color-black--dark;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: $color-white;
  z-index: 2;

  @media (-ms-high-contrast: active) {
    border-bottom: 1px solid windowText;
  }

  .js & {
    min-height: 49px;

    @include media(">=uol-media-l") {
      flex-wrap: nowrap;
    }
  }
}

  .uol-global-masthead__inner {
    position: relative;
    background: inherit;

    @include media("<uol-media-m") {
      z-index: 2;
      flex-grow: 2;
      max-width: $spacing-8;
    }

    @include media(">=uol-media-m") {
      width: 20rem;
    }

    .uol-global-masthead--quicklinks-expanded & {

      @include media("<uol-media-m") {
        position: absolute !important;
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        width: 1px;
        overflow: hidden;
        left: -9999px;
      }
    }
  }

  .uol-global-masthead__home {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin: $spacing-1 $spacing-4 0;
    overflow: hidden;
    position: relative;

    @include media(">=uol-media-m") {
      width: 286px;
    }

    @include media(">=uol-media-l") {
      margin-left: $spacing-5;
    }

    @include media(">=uol-media-xl") {
      margin-left: $spacing-6;
    }

    .uol-logo-uol-inline {
      .uol-logo-uol-inline__img {
        fill: $color-white;

        @media screen and (-ms-high-contrast: active) {
          fill: windowText;
        }
      }
    }

    &:focus {
      outline: 2px solid $color-brand--bright;
      outline-offset: 1px;
    }

    &:focus:not(:focus-visible) {
      outline-width: 0;
    }

    &:focus-visible {
      outline: 2px solid $color-brand--bright;
      outline-offset: 1px;
    }
  }

  // Rendered with JS
  .uol-global-masthead__search-toggle {
    @include GlobalHeaderIconHover;
    @include button_focus(7px);

    border: none;
    background: transparent;
    margin: 0 $spacing-4 0 0;
    padding: $spacing-2 $spacing-2 $spacing-1;

    &::before {
      bottom: 8px;
    }

    &[aria-expanded="true"] {
      background: $color-black;
    }

    .uol-global-masthead--quicklinks-expanded & {

      @include media("<uol-media-m") {
        position: absolute !important;
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        width: 1px;
        overflow: hidden;
        left: -9999px;
      }
    }
  }

  .uol-global-masthead__search-form {
    @extend %text-size-paragraph;

    position: relative;
    display: flex;

    input[type=search] {
      -webkit-appearance: none;
    }

    &::before {
      content: "";
      position: absolute;
      left: $spacing-4;
      bottom: $spacing-3;
      width: 0;
      border-bottom: 6px solid $color-brand--bright;
      transition: width 0.5s ease 0.1s;
    }

    &:focus-within::before {
      width: calc(100% - #{$spacing-6});
    }

    @include media(">=uol-media-l") {
      &::before {
        left: 0;
        bottom: 4px;
      }

      &:focus-within::before {
        width: 100%;
      }
    }

    @include media("<uol-media-l") {
      visibility: hidden;
      width: 100%;
      padding: $spacing-4;
      height: 45px;
      background: $color-black;
      transition: margin 0.3s ease, background 0.1s ease 0.3s;
      // font-size: ;

      @media (prefers-reduced-motion) {
        transition: none;
      }
    }

    @include media(">=uol-media-l") {
      visibility: visible;
      width: 376px;
      margin-right: $spacing-4;
    }

    .js & {

      @include media("<uol-media-l") {
        margin-top: -85px;
        z-index: -1;
      }

      @include media(">=uol-media-l") {
        visibility: visible;
      }
    }

    .no-js & {
      @include media("<uol-media-l") {
        width: 100%;
        visibility: visible;
      }
    }

    &[hidden] {
      top: 0;
      background-color: $color-black--dark;
      visibility: hidden;
      transition: top 0.5s ease, background 0s ease 0s;

      @media (prefers-reduced-motion) {
        transition: none;
      }
    }

      &:focus-within {
      &::after {
        @include media(">=uol-media-l") {
          background: transparent;
        }
      }
    }
  }

  .uol-global-masthead__search-form--expanded {
    background-color: $color-black;
    visibility: visible;

    .js & {
      margin-top: 0;
    }
  }

  .uol-global-masthead__search-label {
    @extend .hide-accessible;
  }

  .uol-global-masthead__search-input {
    flex: 1;
    background: transparent;
    border: none;
    border-radius: 0;
    color: $color-white;
    padding: 6px;
    border-bottom: 1px solid $color-white;
    appearance: none;

    .no-csspositionsticky & {
      &:focus {
        border-bottom-color: $color-brand--bright;
      }
    }

    // TODO: Hack to stop iOS rounding search inputs. Needs a tidy.
    &input[type=search] {
      appearance: none;
    }

    &::placeholder {
      color: darken($color-white, 40%);
      font-size: 1.125rem;
    }

    @include media(">=uol-media-l") {
      margin: 6px 0;
    }

    &:focus {
      outline: 2px dotted transparent;

      @include media("<uol-media-m") {
        outline: 2px dotted transparent;
      }
    }
  }

  .uol-global-masthead__search-submit {
    @include GlobalHeaderIconHover;
    @include button_focus(6px);

    &::before {
      top: 0.3rem;
      bottom: 0.5625rem;
      border-width: 1px;
      border-radius: 2px;
    }

    position: absolute;
    right: $spacing-4;
    top: $spacing-4;
    background: transparent;
    border: none;
    color: $color-white;
    margin: 0 $spacing-1 0 $spacing-2;
    padding: $spacing-1 $spacing-2 0;

    @include media(">=uol-media-l") {
      top: 8px;
      right: 0;
    }
  }
