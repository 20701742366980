.uol-form__input__error {
  @include font-size-responsive(1rem, 1rem, 1.125rem);

  display: block;
  box-sizing: border-box;
  margin-bottom: $spacing-2;
  padding: $spacing-2 $spacing-4 $spacing-2 44px;
  color: $color-white;
  background: url('../img/alert-circle-outline.svg') no-repeat 13px 11px;
  background-color: $color-alert--error;
  border-radius: 6px;
  font-weight: $font-weight-medium--sans-serif;
  max-width: 25.5rem;
  line-height: 1.667;
  font-variant-numeric: lining-nums;

  a {
    @include link_focus(3px, true, darken($color-alert--error, 5%), $color-black);
    padding: 0 1px;
    margin: 0 -1px;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  @media (forced-colors: active) {
    border: 1px solid CanvasText;
  }

  @media (-ms-high-contrast: active) {
    border: 1px solid WindowText;
  }

  [role="search"] & {
    max-width: 100%;
  }

  .uol-form__input--teaxtarea-label & {
    max-width: 100%;

    @include media(">uol-media-l") {
      max-width: 46.125rem;
    }
  }

  .uol-form__custom-fieldset & {
    max-width: 27.375rem;
    margin-bottom: $spacing-4;
  }

  @include media(">uol-media-m") {
    font-weight: $font-weight-regular--sans-serif;
  }

  @include media(">uol-media-l") {
    line-height: 1.556;
  }
}