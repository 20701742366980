/**
 * Icon font CSS generated by gulp see templates -> _ds-icons.scss
 * Using lodash https://lodash.com/docs/4.17.4
 */

@import "../mixins/ds-icons-mixins"; //custom uol-ds icons, file generated by gulp

@font-face {
    font-family: "ds-icons";
    src: url("../fonts/ds-icons.eot");
    src:
        url("../fonts/ds-icons.eot?#iefix") format("eot"),
        url("../fonts/ds-icons.woff2") format("woff2"),
        url("../fonts/ds-icons.woff") format("woff"),
        url("../fonts/ds-icons.ttf") format("truetype"),
        url("../fonts/ds-icons.svg#ds-icons") format("svg");
    font-weight: normal;
    font-style: normal;
}

.ds-icon,
//.ds-icon::before,
[class*="ds-icon"] {
    @include ds-icon;
}

.ds-icon-16 {
    @include ds-icon-16();
}

.ds-icon-24 {
    @include ds-icon-24();
}

.ds-icon-32 {
    @include ds-icon-32();
}

.ds-icon-48 {
    @include ds-icon-48();
}

/**
 * Icon font class names
 */

.ds-icon-add::before {
    @include ds-icon-add();
}
.ds-icon-alert-danger::before {
    @include ds-icon-alert-danger();
}
.ds-icon-alert-info::before {
    @include ds-icon-alert-info();
}
.ds-icon-alert-success::before {
    @include ds-icon-alert-success();
}
.ds-icon-alert-warning::before {
    @include ds-icon-alert-warning();
}
.ds-icon-calendar::before {
    @include ds-icon-calendar();
}
.ds-icon-campus-map::before {
    @include ds-icon-campus-map();
}
.ds-icon-chevron-down::before {
    @include ds-icon-chevron-down();
}
.ds-icon-chevron-left::before {
    @include ds-icon-chevron-left();
}
.ds-icon-chevron-right::before {
    @include ds-icon-chevron-right();
}
.ds-icon-chevron-up::before {
    @include ds-icon-chevron-up();
}
.ds-icon-close::before {
    @include ds-icon-close();
}
.ds-icon-download::before {
    @include ds-icon-download();
}
.ds-icon-external::before {
    @include ds-icon-external();
}
.ds-icon-home::before {
    @include ds-icon-home();
}
.ds-icon-letter::before {
    @include ds-icon-letter();
}
.ds-icon-mail::before {
    @include ds-icon-mail();
}
.ds-icon-marker::before {
    @include ds-icon-marker();
}
.ds-icon-navicon::before {
    @include ds-icon-navicon();
}
.ds-icon-news::before {
    @include ds-icon-news();
}
.ds-icon-phone::before {
    @include ds-icon-phone();
}
.ds-icon-print::before {
    @include ds-icon-print();
}
.ds-icon-profile::before {
    @include ds-icon-profile();
}
.ds-icon-remove::before {
    @include ds-icon-remove();
}
.ds-icon-rss::before {
    @include ds-icon-rss();
}
.ds-icon-search::before {
    @include ds-icon-search();
}
.ds-icon-share::before {
    @include ds-icon-share();
}
.ds-icon-social-facebook::before {
    @include ds-icon-social-facebook();
}
.ds-icon-social-google::before {
    @include ds-icon-social-google();
}
.ds-icon-social-instagram::before {
    @include ds-icon-social-instagram();
}
.ds-icon-social-linkedin::before {
    @include ds-icon-social-linkedin();
}
.ds-icon-social-twitter::before {
    @include ds-icon-social-twitter();
}
.ds-icon-social-weibo::before {
    @include ds-icon-social-weibo();
}
.ds-icon-social-youtube::before {
    @include ds-icon-social-youtube();
}
.ds-icon-sort::before {
    @include ds-icon-sort();
}
.ds-icon-star::before {
    @include ds-icon-star();
}
.ds-icon-triangle-down::before {
    @include ds-icon-triangle-down();
}
.ds-icon-triangle-left::before {
    @include ds-icon-triangle-left();
}
.ds-icon-triangle-right::before {
    @include ds-icon-triangle-right();
}
.ds-icon-triangle-up::before {
    @include ds-icon-triangle-up();
}
.ds-icon-action-add::before {
    @include ds-icon-action-add();
}
.ds-icon-action-bookmark::before {
    @include ds-icon-action-bookmark();
}
.ds-icon-action-call::before {
    @include ds-icon-action-call();
}
.ds-icon-action-cancel::before {
    @include ds-icon-action-cancel();
}
.ds-icon-action-create::before {
    @include ds-icon-action-create();
}
.ds-icon-action-delete::before {
    @include ds-icon-action-delete();
}
.ds-icon-action-download::before {
    @include ds-icon-action-download();
}
.ds-icon-action-link-external::before {
    @include ds-icon-action-link-external();
}
.ds-icon-action-lock::before {
    @include ds-icon-action-lock();
}
.ds-icon-action-pause::before {
    @include ds-icon-action-pause();
}
.ds-icon-action-play::before {
    @include ds-icon-action-play();
}
.ds-icon-action-print::before {
    @include ds-icon-action-print();
}
.ds-icon-action-remove::before {
    @include ds-icon-action-remove();
}
.ds-icon-action-reply::before {
    @include ds-icon-action-reply();
}
.ds-icon-action-search::before {
    @include ds-icon-action-search();
}
.ds-icon-action-upload::before {
    @include ds-icon-action-upload();
}
.ds-icon-content-bar::before {
    @include ds-icon-content-bar();
}
.ds-icon-content-basket::before {
    @include ds-icon-content-basket();
}
.ds-icon-content-bbq::before {
    @include ds-icon-content-bbq();
}
.ds-icon-content-bed::before {
    @include ds-icon-content-bed();
}
.ds-icon-content-bike::before {
    @include ds-icon-content-bike();
}
.ds-icon-content-bus::before {
    @include ds-icon-content-bus();
}
.ds-icon-content-cafe::before {
    @include ds-icon-content-cafe();
}
.ds-icon-content-calendar::before {
    @include ds-icon-content-calendar();
}
.ds-icon-content-couch::before {
    @include ds-icon-content-couch();
}
.ds-icon-content-email-open::before {
    @include ds-icon-content-email-open();
}
.ds-icon-content-email::before {
    @include ds-icon-content-email();
}
.ds-icon-content-fitness::before {
    @include ds-icon-content-fitness();
}
.ds-icon-content-home::before {
    @include ds-icon-content-home();
}
.ds-icon-content-hospital::before {
    @include ds-icon-content-hospital();
}
.ds-icon-content-key::before {
    @include ds-icon-content-key();
}
.ds-icon-content-location::before {
    @include ds-icon-content-location();
}
.ds-icon-content-map::before {
    @include ds-icon-content-map();
}
.ds-icon-content-music::before {
    @include ds-icon-content-music();
}
.ds-icon-content-news::before {
    @include ds-icon-content-news();
}
.ds-icon-content-parking::before {
    @include ds-icon-content-parking();
}
.ds-icon-content-restaurant::before {
    @include ds-icon-content-restaurant();
}
.ds-icon-content-rss::before {
    @include ds-icon-content-rss();
}
.ds-icon-content-shower::before {
    @include ds-icon-content-shower();
}
.ds-icon-content-table-tennis::before {
    @include ds-icon-content-table-tennis();
}
.ds-icon-content-tag::before {
    @include ds-icon-content-tag();
}
.ds-icon-content-train::before {
    @include ds-icon-content-train();
}
.ds-icon-content-tv::before {
    @include ds-icon-content-tv();
}
.ds-icon-content-user::before {
    @include ds-icon-content-user();
}
.ds-icon-content-videogame::before {
    @include ds-icon-content-videogame();
}
.ds-icon-content-wc::before {
    @include ds-icon-content-wc();
}
.ds-icon-content-wifi::before {
    @include ds-icon-content-wifi();
}
.ds-icon-device-desktop::before {
    @include ds-icon-device-desktop();
}
.ds-icon-device-laptop::before {
    @include ds-icon-device-laptop();
}
.ds-icon-device-phone::before {
    @include ds-icon-device-phone();
}
.ds-icon-form-check-checked::before {
    @include ds-icon-form-check-checked();
}
.ds-icon-form-check-unchecked::before {
    @include ds-icon-form-check-unchecked();
}
.ds-icon-form-radio-checked::before {
    @include ds-icon-form-radio-checked();
}
.ds-icon-form-radio-unchecked::before {
    @include ds-icon-form-radio-unchecked();
}
.ds-icon-nav-arrow-dropdown-down::before {
    @include ds-icon-nav-arrow-dropdown-down();
}
.ds-icon-nav-arrow-dropdown-up::before {
    @include ds-icon-nav-arrow-dropdown-up();
}
.ds-icon-nav-chevron-down::before {
    @include ds-icon-nav-chevron-down();
}
.ds-icon-nav-chevron-left::before {
    @include ds-icon-nav-chevron-left();
}
.ds-icon-nav-chevron-right::before {
    @include ds-icon-nav-chevron-right();
}
.ds-icon-nav-chevron-up::before {
    @include ds-icon-nav-chevron-up();
}
.ds-icon-nav-more-horizontal::before {
    @include ds-icon-nav-more-horizontal();
}
.ds-icon-nav-more-vertical::before {
    @include ds-icon-nav-more-vertical();
}
.ds-icon-nav-navicon::before {
    @include ds-icon-nav-navicon();
}
.ds-icon-nav-select::before {
    @include ds-icon-nav-select();
}
.ds-icon-content-vr::before {
    @include ds-icon-content-vr();
}
.ds-icon-device-tablet::before {
    @include ds-icon-device-tablet();
}
.ds-icon-nav-chevron-left-large::before {
    @include ds-icon-nav-chevron-left-large();
}
.ds-icon-nav-chevron-right-large::before {
    @include ds-icon-nav-chevron-right-large();
}
.ds-icon-nav-chevron-down-large::before {
    @include ds-icon-nav-chevron-down-large();
}
.ds-icon-nav-chevron-up-large::before {
    @include ds-icon-nav-chevron-up-large();
}
.ds-icon-content-washing-machine::before {
    @include ds-icon-content-washing-machine();
}
.ds-icon-content-credit-card::before {
    @include ds-icon-content-credit-card();
}
.ds-icon-content-sport::before {
    @include ds-icon-content-sport();
}
.ds-icon-content-security::before {
    @include ds-icon-content-security();
}
.ds-icon-content-build::before {
    @include ds-icon-content-build();
}
.ds-icon-content-document::before {
    @include ds-icon-content-document();
}
.ds-icon-action-filter::before {
    @include ds-icon-action-filter();
}
.ds-icon-action-card::before {
    @include ds-icon-action-card();
}
.ds-icon-action-list::before {
    @include ds-icon-action-list();
}
.ds-icon-action-cancel-thin::before {
    @include ds-icon-action-cancel-thin();
}
.ds-icon-action-share-circle::before {
    @include ds-icon-action-share-circle();
}
.ds-icon-action-play-circle-outline::before {
    @include ds-icon-action-play-circle-outline();
}
.ds-icon-content-announcement::before {
    @include ds-icon-content-announcement();
}
.ds-icon-nav-dots-horizontal::before {
    @include ds-icon-nav-dots-horizontal();
}
.ds-icon-content-marker::before {
    @include ds-icon-content-marker();
}
.ds-icon-content-security-home::before {
    @include ds-icon-content-security-home();
}
.ds-icon-content-ping-pong::before {
    @include ds-icon-content-ping-pong();
}
.ds-icon-content-bank::before {
    @include ds-icon-content-bank();
}

/* Hidden font text for accessibility */

.icon-font {

}

.icon-font-text {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}
