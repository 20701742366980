.uol-typography-heading-1 {
  @extend %text-size-heading-1;
  @extend %uol-font-serif;

  font-weight: $font-weight-bold--serif;

  &.uol-typography-heading-1--sans-serif {
    @extend %uol-font-sans-serif;

    font-weight: $font-weight-bold--sans-serif;
  }
}
